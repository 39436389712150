import { AlertColor } from '@mui/material';
import {
    Card,
    CardSet,
    CardSetType,
    Comment,
    OnCreateNotificationSubscription,
    Playbook,
    PlaybookPage,
    PlaybookPageDataResponse,
    UserProfile,
} from '../API';
import { generateClient } from 'aws-amplify/api';
import {
    getCard,
    getCardSet,
    getCardsCardSets,
    getComment,
    getCommentReaction,
    getPlaybook,
    getPlaybookPage,
} from '../graphql/queries';
import { useLocation } from 'react-router-dom';
import { FilterCards } from '../hooks/useFilterCards';

export enum SubscriptionType {
    Comment = 'comment',
}

const client = generateClient();

export const getCardById = async (id: string, organisation: string) => {
    const query = getCard;
    const response = await client.graphql({
        query: query,
        variables: {
            id: id,
            organisation: organisation,
        },
    });

    return response.data.getCard;
};

const getCardSetById = async (id: string, organisation: string) => {
    const query = getCardSet;
    const response = await client.graphql({
        query: query,
        variables: {
            id: id,
            organisation: organisation,
        },
    });

    return response.data.getCardSet;
};
const getCardCardSetById = async (id: string, organisation: string) => {
    const query = getCardsCardSets;
    const response = await client.graphql({
        query: query,
        variables: {
            id: id,
            organisation: organisation,
        },
    });

    return response.data.getCardsCardSets;
};

const getPlaybookPageById = async (id: string, organisation: string) => {
    const query = getPlaybookPage;
    const response = await client.graphql({
        query: query,
        variables: {
            id: id,
            organisation: organisation,
        },
    });

    return response.data.getPlaybookPage;
};
const getPlaybookById = async (id: string, organisation: string) => {
    const query = getPlaybook;
    const response = await client.graphql({
        query: query,
        variables: {
            id: id,
            organisation: organisation,
        },
    });

    return response.data.getPlaybook;
};

const getCommentById = async (
    id: string,
    contextId: string,
    createdAt: string
) => {
    const query = getComment;
    const response = await client.graphql({
        query: query,
        variables: {
            id: id,
            contextId: contextId,
            createdAt: createdAt,
        },
    });

    return response.data.getComment;
};
const getCommentReactionById = async (id: string, createdBy: string) => {
    const query = getCommentReaction;
    const response = await client.graphql({
        query: query,
        variables: {
            commentId: id,
            createdBy: createdBy,
        },
    });

    return response.data.getCommentReaction;
};

interface SubscriptionVariables {
    data: OnCreateNotificationSubscription;
    setItems: any;
    showSnackbar: (options: { message: string; severity: AlertColor }) => void;
    users: UserProfile[];
    createCard?: boolean;
    cardSetId?: string;
    playbookId?: string;
    createPlaybook?: boolean;
    location?: any;
    createCardSet?: boolean;
    filterCriteria?: FilterCards;
    subscriptionType?: SubscriptionType;
    parentId?: string;
}
export const getSubscriptions = async ({
    data,
    setItems,
    showSnackbar,
    users,
    createCard,
    cardSetId,
    createPlaybook,
    playbookId,
    location,
    createCardSet,
    filterCriteria,
    subscriptionType,
    parentId,
}: SubscriptionVariables) => {
    const notification = data.onCreateNotification;

    let user = users.find((profile) => profile.id === notification?.userId);

    if (
        notification?.type === 'Create' &&
        notification.entity === 'Card' &&
        createCard
    ) {
        let card = await getCardById(
            notification?.cardId as string,
            notification.organisation
        );
        if (
            card &&
            (!filterCriteria?.cardType?.length ||
                filterCriteria.cardType.includes(card?.cardToCardTypeId ?? ''))
        ) {
            setItems((items: Card[]) => {
                const existingItems = [...items];
                existingItems.unshift(card as Card);

                return existingItems;
            });
        }
    } else if (
        notification?.type === 'Create' &&
        notification?.entity === 'Comment' &&
        subscriptionType === SubscriptionType.Comment
    ) {
        let comment = await getCommentById(
            notification?.commentId as string,
            notification?.commentContextId as string,
            notification?.commentCreatedAt as string
        );

        setItems((items: Comment[]) => {
            const existingItems = [...items];

            if (comment && notification.commentContextId === parentId) {
                comment.reactions = {
                    __typename: 'ModelCommentReactionConnection',
                    items: [],
                };
                existingItems.unshift(comment);
            }

            return existingItems;
        });
    } else if (
        notification?.type === 'Update' &&
        notification?.entity === 'Comment' &&
        subscriptionType === SubscriptionType.Comment
    ) {
        let comment = await getCommentById(
            notification?.commentId as string,
            notification?.commentContextId as string,
            notification?.commentCreatedAt as string
        );
        setItems((items: Comment[]) => {
            const existingItems = [...items];
            const index = existingItems.findIndex(
                (card) => card.id === notification.commentId
            );

            existingItems[index].message = comment?.message;

            return existingItems;
        });
    } else if (
        notification?.type === 'Delete' &&
        notification?.entity === 'Comment' &&
        subscriptionType === SubscriptionType.Comment
    ) {
        setItems((items: Comment[]) => {
            const existingItems = [...items];

            return existingItems.filter(
                (comment) => comment.id !== notification.commentId
            );
        });
    } else if (
        notification?.type === 'Create' &&
        notification?.entity === 'CommentReaction' &&
        subscriptionType === SubscriptionType.Comment
    ) {
        const commentReaction = await getCommentReactionById(
            notification.commentId as string,
            notification.commentReactionCreatedBy as string
        );

        setItems((comments: any) => {
            const existingItems = [...comments];

            const index = existingItems.findIndex(
                (comment: any) => comment.id === notification.commentId
            );

            if (index !== -1) {
                if (!existingItems[index].reactions) {
                    existingItems[index].reactions = {};
                }
                if (!existingItems[index].reactions.items) {
                    existingItems[index].reactions.items = [];
                }
                existingItems[index].reactions.items.push(commentReaction);
            }
            return existingItems;
        });
    } else if (
        notification?.type === 'Delete' &&
        notification?.entity === 'CommentReaction' &&
        subscriptionType === SubscriptionType.Comment
    ) {
        setItems((comments: any) => {
            const existingItems = [...comments];

            const index = existingItems.findIndex(
                (comment: any) => comment.id === notification.commentId
            );

            if (index !== -1) {
                const reactionIndex = existingItems[
                    index
                ].reactions.items.findIndex(
                    (item: any) =>
                        item.createdBy === notification.commentReactionCreatedBy
                );
                if (reactionIndex !== -1) {
                    existingItems[index].reactions.items.splice(
                        reactionIndex,
                        1
                    );
                }
            }

            return existingItems;
        });
    } else if (
        notification?.type === 'Update' &&
        (notification.entity === 'Card' || notification.entity === 'ScoreData')
    ) {
        const card = await getCardById(
            notification.cardId ?? '',
            notification.organisation
        );
        if (card?.deleteAfter) {
            setItems((items: Card[]) => {
                const existingItems = [...items];

                return existingItems.filter(
                    (card) => card.id !== notification.cardId
                );
            });
        } else {
            setItems((items: Card[]) => {
                const existingItems = [...items];
                const index = existingItems.findIndex(
                    (card) => card.id === notification.cardId
                );

                existingItems[index] = card as Card;

                return existingItems;
            });
        }
    } else if (
        notification?.type === 'Create' &&
        notification.entity === 'ScoreData'
    ) {
        const card = await getCardById(
            notification.cardId ?? '',
            notification.organisation
        );
        setItems((items: Card[]) => {
            const existingItems = [...items];
            const index = existingItems.findIndex(
                (card) => card.id === notification.cardId
            );

            existingItems[index] = card as Card;

            return existingItems;
        });
    } else if (
        notification?.type === 'Delete' &&
        notification.entity === 'Card'
    ) {
        setItems((items: Card[]) => {
            const existingItems = [...items];

            return existingItems.filter(
                (card) => card.id !== notification.cardId
            );
        });
    } else if (
        notification?.type === 'Create' &&
        notification?.entity === 'CardsCardSets' &&
        notification.cardSetId &&
        notification.cardSetId === cardSetId
    ) {
        const cardsCardSet = await getCardCardSetById(
            notification.cardsCardSetsId as string,
            notification.organisation
        );
        const addedCardSet = cardsCardSet;

        if (
            addedCardSet &&
            (!filterCriteria?.cardType?.length ||
                filterCriteria.cardType.includes(
                    cardsCardSet?.card?.cardToCardTypeId as string
                ))
        ) {
            const card = await getCardById(
                notification.cardId ?? '',
                notification.organisation
            );

            setItems((items: Card[]) => {
                const existingItems = [...items];

                const index = existingItems.findIndex(
                    (item) => card?.id === item.id
                );
                if (index === -1) {
                    existingItems.unshift(card as Card);
                }

                console.log(existingItems);

                return existingItems;
            });
        }
    } else if (
        notification?.type === 'Delete' &&
        notification?.entity === 'CardsCardSets' &&
        notification.cardSetId &&
        notification.cardSetId === cardSetId
    ) {
        setItems((items: Card[]) => {
            const existingItems = [...items];

            return existingItems.filter(
                (card) => card.id !== notification.cardId
            );
        });
    } else if (
        notification?.type === 'Create' &&
        notification?.entity === 'CardSet' &&
        createCardSet
    ) {
        const cardSet = await getCardSetById(
            notification.cardSetId ?? '',
            notification.organisation
        );
        if (
            cardSet?.type === 'WB' ||
            !filterCriteria?.cardType ||
            filterCriteria.cardType.includes(cardSet?.cardSetToCardTypeId ?? '')
        ) {
            setItems((items: CardSet[]) => {
                const existingItems = [...items];

                if (
                    location.pathname === '/card-sets' ||
                    location.pathname === '/workbooks'
                ) {
                    existingItems.unshift(cardSet as CardSet);
                }

                return existingItems;
            });
        }
    } else if (
        notification?.type === 'Delete' &&
        notification?.entity === 'CardSet' &&
        createCardSet
    ) {
        setItems((items: CardSet[]) => {
            const existingItems = [...items];

            return existingItems.filter(
                (card) => card.id !== notification.cardSetId
            );
        });
    } else if (
        notification?.type === 'Create' &&
        notification?.entity === 'Playbook' &&
        createPlaybook
    ) {
        const playbook = await getPlaybookById(
            notification.playbookId as string,
            notification.organisation as string
        );
        setItems((items: Playbook[]) => {
            const existingItems = [...items];
            existingItems.unshift(playbook as Playbook);

            return existingItems;
        });
    } else if (
        notification?.type === 'Delete' &&
        notification.entity === 'Playbook'
    ) {
        setItems((items: Card[]) => {
            const existingItems = [...items];

            return existingItems.filter(
                (card) => card.id !== notification.playbookId
            );
        });
    } else if (
        notification?.type === 'Create' &&
        notification.entity === 'PlaybookPage' &&
        notification.playbookId &&
        notification.playbookId === playbookId
    ) {
        const playbookPage = await getPlaybookPageById(
            notification.playbookPageId ?? '',
            notification.organisation
        );

        setItems((items: PlaybookPage[]) => {
            const existingItems = [...items];
            existingItems.unshift(playbookPage as PlaybookPage);

            return existingItems;
        });
    } else if (
        notification?.type === 'Delete' &&
        notification.entity === 'PlaybookPage' &&
        notification.playbookId &&
        notification.playbookId === playbookId
    ) {
        setItems((items: PlaybookPage[]) => {
            const existingItems = [...items];
            return existingItems.filter(
                (card) => card.id !== notification.playbookPageId
            );
        });
    }
};
