import { PropsWithChildren } from 'react';
import { Box, SxProps, useTheme } from '@mui/material';

const modalStyles: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90rem',
    width: '100%',
    zIndex: 1400,
    borderRadius: 4,
    backgroundColor: 'background.default',
    overflow: 'hidden',
};

interface ModalContainerProps {
    sx?: SxProps;
}

const ModalContainer = ({
    children,
    sx,
}: PropsWithChildren<ModalContainerProps>) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                ...modalStyles,
                ...sx,
                '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                    backgroundColor:
                        theme.palette.mode === 'dark'
                            ? '#FFFFFF'
                            : 'rgba(0, 0, 0, 0.3)',
                },
            }}
        >
            {children}
        </Box>
    );
};

export default ModalContainer;
