import React from 'react';
import {
    Box,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { monthLabels } from '../../../helpers/charts';
import { ScoreDatum, ScoreType } from '../../../API';

interface ScoreItemInterface {
    focusedIndex: number | undefined | null;
    scoreDatum: ScoreDatum;
    date: Date;
    handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        value: number
    ) => void;
    i: number;
    scoreType: ScoreType;
    reverseMaxMin: boolean;
    max: number | undefined;
    min: number | undefined;
    deleteScoreDatum: (i: number) => void;
}

const ScoreItem = ({
    focusedIndex,
    scoreDatum,
    date,
    handleInputChange,
    i,
    scoreType,
    reverseMaxMin,
    max,
    min,
    deleteScoreDatum,
}: ScoreItemInterface) => {
    return (
        <Box
            key={`${scoreDatum.date}-box`}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Typography sx={{ marginRight: 3, width: '100px' }}>{`${
                monthLabels[date.getMonth()]
            } ${date.getFullYear()}`}</Typography>

            <TextField
                inputRef={(input) =>
                    input && i === focusedIndex && input.focus()
                }
                sx={{
                    width: '12rem',
                    mr: 1,
                }}
                value={scoreDatum.value}
                key={scoreDatum.date}
                size="small"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(event, i)
                }
                InputProps={{
                    startAdornment: scoreType === ScoreType.Currency && (
                        <InputAdornment position="start">£</InputAdornment>
                    ),
                    type: 'number',
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                sx={{
                    width: '12rem',
                    mr: 1,
                }}
                name="comment"
                size="small"
                placeholder="Commentary"
                {...(scoreDatum.comment && {
                    value: scoreDatum.comment,
                })}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(event, i)
                }
            />
            <IconButton
                onClick={() => deleteScoreDatum(i)}
                title="Edit"
                color="secondary"
            >
                <ClearIcon style={{ width: '30px', height: '30px' }} />
            </IconButton>
        </Box>
    );
};

export default ScoreItem;
