import { ReactComponent as AddchartOutlinedIcon } from '../assets/icons/AddchartOutlined.svg';
import { ReactComponent as ElementIcon } from '../assets/icons/Element.svg';
import { ReactComponent as EntitiesIcon } from '../assets/icons/Entities.svg';
import { ReactComponent as LegalIcon } from '../assets/icons/Legal.svg';
import { ReactComponent as MarketIcon } from '../assets/icons/Markets.svg';
import { ReactComponent as OrganizationIcon } from '../assets/icons/Organization.svg';
import { ReactComponent as PlaybookIcon } from '../assets/icons/Playbooks.svg';
import { ReactComponent as WorkbookIcon } from '../assets/icons/Workbooks.svg';
import { ReactComponent as CardSetIcon } from '../assets/icons/CardSets.svg';
import { ReactComponent as SWOTIcon } from '../assets/icons/SWOT.svg';
import { ReactComponent as InputIcon } from '../assets/icons/Input.svg';
import { ReactComponent as PurposeIcon } from '../assets/icons/Purpose.svg';
import { ReactComponent as ObjectiveIcon } from '../assets/icons/Objective.svg';
import { ReactComponent as ActivityIcon } from '../assets/icons/Activity.svg';
import { ReactComponent as RetrospectiveIcon } from '../assets/icons/Retrospective.svg';
import { ReactComponent as FinanceIcon } from '../assets/icons/Finance.svg';
import { ReactComponent as ProcessIcon } from '../assets/icons/Process.svg';
import { ReactComponent as ProductIcon } from '../assets/icons/Product.svg';
import { ReactComponent as CorporateIcon } from '../assets/icons/Corporate.svg';
import { ReactComponent as KPIIcon } from '../assets/icons/KPIs.svg';
import { ReactComponent as StrategyIcon } from '../assets/icons/Strategy.svg';
import { ReactComponent as ReviewIcon } from '../assets/icons/Review.svg';
import { ReactComponent as CompetencyIcon } from '../assets/icons/Competency.svg';
import { ReactComponent as ImprovementIcon } from '../assets/icons/Improvement.svg';

export enum Category {
    ORGANIZATION = '1',
    SWOT = '2',
    ENTITY = '3',
    ELEMENT = '4',
    MARKETS = '5',
    INNOVATION = '6',
    FINANCE = '7',
    CORPORATE_DEVELOPMENT = '8',
    LEGAL = '9',
    KPI = '10',
    PURPOSE = '11',
    RETROSPECTIVE = '12',
    OBJECTIVE = '13',
    STRATEGY = '14',
    PROCESS = '15',
    PRODUCT = '16',
    ACTION_PLAN = '17',
    REVIEW = '18',
    COMPETENCY = '19',
    IMPROVEMENT = '20',
    // TODO: Remove with rewrite of card front
    PLAYBOOK = 'playbook',
    WORKBOOK = 'workbook',
    CARDSET = 'cardset',
}

type CategoryMap = {
    [key in Category]: {
        color: string;
        dark: string;
        light: string;
        image: string;
        text: string;
        icon: any;
    };
};

export enum CardTypeId {
    ORGANIZATION_ORGANIZATION = '1',
    SWOT_STRENGTH = '2',
    ENTITY_COMPETITOR = '3',
    SWOT_WEAKNESS = '4',
    SWOT_OPPORTUNITY = '5',
    SWOT_THREAT = '6',
    ELEMENT_RISK = '7',
    MARKET_MARKETSEGMENT = '8',
    ELEMENT_ASSUMPTION = '9',
    INNOVATION_IDEA = '10',
    MARKET_TREND = '11',
}

type TypeMap = {
    [key: string]: string;
};

export const cardTypeToImage: TypeMap = {
    [CardTypeId.ELEMENT_ASSUMPTION]: 'thumbnail_assumption.jpg',
    [CardTypeId.ELEMENT_RISK]: 'thumbnail_risk.jpg',
    [CardTypeId.ENTITY_COMPETITOR]: 'thumbnail_competitor.jpg',
    [CardTypeId.INNOVATION_IDEA]: 'thumbnail_idea.jpg',
    [CardTypeId.MARKET_MARKETSEGMENT]: 'thumbnail_market-segment.jpg',
    [CardTypeId.MARKET_TREND]: 'thumbnail_trend.jpg',
    [CardTypeId.ORGANIZATION_ORGANIZATION]: 'thumbnail_organization.jpg',
    [CardTypeId.SWOT_STRENGTH]: 'SC-strengths.jpg',
    [CardTypeId.SWOT_WEAKNESS]: 'thumbnail_weakness.jpg',
    [CardTypeId.SWOT_OPPORTUNITY]: 'thumbnail_opportunity.jpg',
    [CardTypeId.SWOT_THREAT]: 'thumbnail_threat.jpg',
};

export const categoryObject: CategoryMap = {
    [Category.ORGANIZATION]: {
        color: '#AD1357',
        dark: '#880E4F',
        light: '#EAC3D5',
        text: '#FFFFFF',
        image: 'thumbnail_organization.jpg',
        icon: OrganizationIcon,
    },
    [Category.SWOT]: {
        color: '#f7e300',
        dark: '#d1c000',
        light: '#FDF8BF',
        text: '#000000',
        image: 'bomb.png',
        icon: SWOTIcon,
    },
    [Category.ENTITY]: {
        color: '#394AAB',
        dark: '#1A2981',
        light: '#C4D9FD',
        image: 'cloud.png',
        text: '#FFFFFF',
        icon: EntitiesIcon,
    },
    [Category.ELEMENT]: {
        color: '#97BC00',
        dark: '#698300',
        light: '#E5EEBF',
        text: '#FFFFFF',
        image: 'auditorium.png',
        icon: ElementIcon,
    },
    [Category.MARKETS]: {
        color: '#02838f',
        dark: '#006069',
        light: '#BFE0E3',
        text: '#FFFFFF',
        image: 'thumbnail_market-segment.jpg',
        icon: MarketIcon,
    },
    [Category.INNOVATION]: {
        color: '#009DFF',
        dark: '#0080D0',
        light: '#BFE6FF',
        text: '#FFFFFF',
        image: 'thumbnail_idea.jpg',
        icon: InputIcon,
    },
    [Category.FINANCE]: {
        color: '#950084',
        dark: '#5F0054',
        light: '#E4BFE0',
        text: '#FFFFFF',
        image: 'thumbnail_trend.jpg',
        icon: FinanceIcon,
    },
    [Category.PROCESS]: {
        color: '#673ab7',
        dark: '#3D0F90',
        light: '#D9CDED',
        text: '#FFFFFF',
        image: 'cloud.png',
        icon: ProcessIcon,
    },
    [Category.PRODUCT]: {
        color: '#1668f7',
        dark: '#0045BE',
        light: '#BFE6FF',
        text: '#FFFFFF',
        image: 'generic.png',
        icon: ProductIcon,
    },
    [Category.CORPORATE_DEVELOPMENT]: {
        color: '#00c852',
        dark: '#009A3F',
        light: '#BFF1D3',
        text: '#FFFFFF',
        image: 'generic.png',
        icon: CorporateIcon,
    },
    [Category.LEGAL]: {
        color: '#ff6f00',
        dark: '#de4d00',
        light: '#FFDBBF',
        text: '#FFFFFF',
        image: 'generic.png',
        icon: LegalIcon,
    },
    [Category.KPI]: {
        color: '#00DBF7',
        dark: '#00BFD8',
        light: '#BFEDF4',
        text: '#000000',
        image: 'generic.png',
        icon: KPIIcon,
    },
    [Category.PURPOSE]: {
        color: '#dd2c01',
        dark: '#AF2402',
        light: '#F7CABF',
        text: '#FFFFFF',
        image: 'generic.png',
        icon: PurposeIcon,
    },
    [Category.OBJECTIVE]: {
        color: '#bd00f5',
        dark: '#77009A',
        light: '#EEBFFD',
        text: '#FFFFFF',
        image: 'thumbnail_objective.jpg',
        icon: ObjectiveIcon,
    },
    [Category.STRATEGY]: {
        color: '#00b8d3',
        dark: '#00869A',
        light: '#BFE0E3',
        text: '#FFFFFFF',
        image: 'generic.png',
        icon: StrategyIcon,
    },
    [Category.RETROSPECTIVE]: {
        color: '#936727',
        dark: '#704B15',
        light: '#E4D9C3',
        text: '#FFFFFF',
        image: 'generic.png',
        icon: RetrospectiveIcon,
    },
    [Category.ACTION_PLAN]: {
        color: '#FFAB00',
        dark: '#DE9500',
        light: '#FFEABF',
        text: '#000000',
        image: 'generic.png',
        icon: ActivityIcon,
    },
    [Category.REVIEW]: {
        color: '#EB00FF',
        dark: '#C400D5',
        light: '#FABFFF',
        text: '#FFFFFF',
        image: 'generic.png',
        icon: ReviewIcon,
    },
    [Category.COMPETENCY]: {
        color: '#1DE9B6',
        dark: '#00BFA5',
        light: '#1DE9B6',
        text: '#000000',
        image: 'generic.png',
        icon: CompetencyIcon,
    },
    [Category.IMPROVEMENT]: {
        color: '#AEEA00',
        dark: '#A3DB00',
        light: '#AEEA00',
        text: '#000000',
        image: 'generic.png',
        icon: ImprovementIcon,
    },
    [Category.PLAYBOOK]: {
        color: '#414B5A',
        dark: '#111111',
        light: '#414B5A',
        text: '#FFFFFF',
        image: 'Playbook.jpg',
        icon: PlaybookIcon,
    },
    [Category.WORKBOOK]: {
        color: '#5E7390',
        dark: '#384C67',
        light: '#5E7390',
        image: 'Workbook.jpg',
        text: '#FFFFFF',
        icon: WorkbookIcon,
    },
    [Category.CARDSET]: {
        color: '#DB9C56',
        dark: '#AD6A1F',
        light: '#D4A673',
        image: 'multi-card-image.jpg',
        text: '#FFFFFF',
        icon: CardSetIcon,
    },
};

const imageUrl = '/images/';

export const getCategoryHex = (category: string) => {
    if (Object.values(Category).includes(category as Category)) {
        return categoryObject[category as Category]?.color;
    } else {
        return '#1668f7';
    }
};
export const getCategoryHexDark = (category: string): string => {
    if (Object.values(Category).includes(category as Category)) {
        return categoryObject[category as Category]?.dark;
    } else {
        return '#1668f7';
    }
};
export const getCategoryHexLight = (category: string): string => {
    if (Object.values(Category).includes(category as Category)) {
        return categoryObject[category as Category]?.light;
    } else {
        return '#1668f7';
    }
};
export const getCategoryHexText = (category: string): string => {
    if (Object.values(Category).includes(category as Category)) {
        return categoryObject[category as Category]?.text;
    } else {
        return '#FFFFFF';
    }
};

export const getCategoryImage = (category: Category) => {
    return imageUrl + categoryObject[category].image;
};

export const getCardTypeImage = (id: string) => {
    if (cardTypeToImage[id]) {
        return imageUrl + cardTypeToImage[id];
    } else {
        return;
    }
};

export const getCategoryIcon = (category: Category): React.ElementType => {
    return categoryObject[category]?.icon;
};

export const defaultColors = [
    'rgba(136, 14, 79, 0.8)',
    'rgba(0, 200, 82, 0.8)',
    'rgba(247, 227, 0, 0.8)',
    'rgba(2, 131, 143, 0.8)',
    'rgba(247, 202, 191, 0.8)',
    'rgba(26, 41, 129, 0.8)',
    'rgba(151, 188, 0, 0.8)',
    'rgba(255, 111, 0, 0.8)',
    'rgba(0, 157, 255, 0.8)',
    'rgba(235, 0, 255, 0.8)',
    'rgba(221, 44, 1, 0.8)',
    'rgba(191, 241, 211, 0.8)',
    'rgba(103, 58, 183, 0.8)',
    'rgba(147, 103, 39, 0.8)',
    'rgba(29, 233, 182, 0.8)',
    'rgba(0, 219, 247, 0.8)',
    'rgba(0, 69, 190, 0.8)',
];

export const defaultColorsLowTransparency = [
    'rgba(0, 200, 82, 0.7)',
    'rgba(247, 227, 0, 0.7)',
    'rgba(2, 131, 143, 0.7)',
    'rgba(26, 41, 129, 0.7)',
    'rgba(151, 188, 0, 0.7)',
    'rgba(136, 14, 79, 0.7)',
    'rgba(255, 111, 0, 0.7)',
    'rgba(0, 157, 255, 0.7)',
    'rgba(235, 0, 255, 0.7)',
    'rgba(221, 44, 1, 0.7)',
    'rgba(247, 202, 191, 0.7)',
    'rgba(191, 241, 211, 0.7)',
    'rgba(103, 58, 183, 0.7)',
    'rgba(147, 103, 39, 0.7)',
    'rgba(29, 233, 182, 0.7)',
    'rgba(0, 219, 247, 0.7)',
    'rgba(0, 69, 190, 0.7)',
];

export const generateRandomColors = (count: number) => {
    const randomColors = [];

    for (let i = 0; i < count; i++) {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        const a = 0.7;

        randomColors.push(`rgba(${r},${g},${b},${a})`);
    }

    return randomColors;
};
