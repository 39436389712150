import {
    Box,
    Divider,
    FormControlLabel,
    Menu,
    Radio,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { SortActions } from './cardTypes';
import { AppContext } from '../contexts';

interface UserMenuProps {
    anchorEl: HTMLElement;
    handleClose: () => void;
    setOpenSortModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedSortAction: React.Dispatch<React.SetStateAction<SortActions>>;
    toggleIcon: (iconName: string, selectedSortAction: string) => void;
    pageIdentifier: string;
}
export const SortDirections: Record<SortActions, 'ASC' | 'DESC'> = {
    [SortActions.LAST_MODIFIED_RECENT_FIRST]: 'DESC',
    [SortActions.CREATION_RECENT_FIRST]: 'DESC',
    [SortActions.CREATION_OLDEST_FIRST]: 'ASC',
    [SortActions.LAST_MODIFIED_OLDEST_FIRST]: 'ASC',
    [SortActions.ASCENDING]: 'ASC',
    [SortActions.DESCENDING]: 'DESC',
};

const SortMenu = ({
    anchorEl,
    handleClose,
    setOpenSortModal,
    setSelectedSortAction,
    toggleIcon,
    pageIdentifier,
}: UserMenuProps) => {
    const theme = useTheme();
    const { applySort, setApplySort } =
        useContext(AppContext);
    const key = 'selectedSortAction_' + pageIdentifier;
    const storedAction = localStorage.getItem(key);
    const [selectedAction, setSelectedAction] = useState<SortActions>(
        (storedAction as SortActions) || SortActions.LAST_MODIFIED_RECENT_FIRST
    );

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const action = event.target.name as SortActions;
        setSelectedAction(
            event.target.checked
                ? (action as SortActions)
                : SortActions.LAST_MODIFIED_RECENT_FIRST
        );
        setSelectedSortAction(action as SortActions);
        toggleIcon('Sort', action as SortActions);
        const actionKey = 'selectedSortAction_' + pageIdentifier;
        localStorage.setItem(actionKey, action);
        const directionKey = 'selectedSortDirection_' + pageIdentifier;
        localStorage.setItem(directionKey, SortDirections[action]);
        if (event.target.checked) {
            setOpenSortModal(true);
        }
        setApplySort(!applySort)
        handleClose();
    };

    return (
        <Menu
            aria-labelledby="menu-button"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPaper-root': {
                    boxShadow: `1px 1px 1px 1px ${theme.palette.background.paper}, 
                    1px -1px 1px 1px ${theme.palette.background.paper}`,
                    backgroundColor: theme.palette.background.default,
                    marginTop: '-25px',
                    marginLeft: '-25px',
                },
            }}
        >
            <Box
                sx={{ display: 'flex', flexDirection: 'column', px: '1.2rem' }}
            >
                <Typography variant="h5" sx={{ marginBottom: 1 }}>
                    Sort
                </Typography>
                {Object.values(SortActions).map((action) => (
                    <Box key={action} sx={{ width: '100%' }}>
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={selectedAction === action}
                                    onChange={handleCheckboxChange}
                                    name={action}
                                    color="primary"
                                    sx={{
                                        paddingY: '5px',

                                        '@media screen and (min--moz-device-pixel-ratio: 0)':
                                            {
                                                pt: '2px',
                                            },
                                    }}
                                />
                            }
                            label={action}
                            sx={{
                                width: '100%',
                                '&:hover': {
                                    backgroundColor:
                                        theme.palette.background.paper,
                                },
                            }}
                        />
                        {action === SortActions.LAST_MODIFIED_RECENT_FIRST && (
                            <Divider
                                sx={{
                                    bgcolor: `${theme.palette.primary.light}`,
                                    height: '1px',
                                    my: 1,
                                }}
                            />
                        )}
                    </Box>
                ))}
            </Box>
        </Menu>
    );
};

export default SortMenu;
