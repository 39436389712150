import {
    Box,
    Checkbox,
    List,
    ListItem,
    ListItemAvatar,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../contexts';
import UserAvatar from '../../../profile/UserAvatar';
import { FilterCards } from '../../../../hooks/useFilterCards';
import { UserProfile } from '../../../../API';

interface OwnerListProps {
    localFilters: FilterCards;
    setLocalFilters: React.Dispatch<React.SetStateAction<FilterCards>>;
}
const OwnerList = ({ localFilters, setLocalFilters }: OwnerListProps) => {
    const theme = useTheme();
    const { activeUsers } = useContext(AppContext);
    const [checkedUsers, setCheckedUsers] = useState<UserProfile[]>([]);

    useEffect(() => {
        const checkedList = activeUsers.filter((item) =>
            localFilters.owner?.includes(item.id)
        );
        setCheckedUsers(checkedList);
    }, [localFilters]);

    const handleOwnerChange = (userId: string) => {
        setLocalFilters((prevFilters) => {
            const updatedOwners = prevFilters.owner
                ? [...prevFilters.owner]
                : [];

            if (updatedOwners.includes(userId)) {
                return {
                    ...prevFilters,
                    owner: updatedOwners.filter((id) => id !== userId),
                };
            } else {
                return {
                    ...prevFilters,
                    owner: [...updatedOwners, userId],
                };
            }
        });
    };
    const sortedUsers = [...activeUsers].sort((a, b) => {
        const aChecked = localFilters.owner?.includes(a.id);
        const bChecked = localFilters.owner?.includes(b.id);

        if (aChecked && !bChecked) {
            return -1;
        } else if (!aChecked && bChecked) {
            return 1;
        } else {
            return 0;
        }
    });
    return (
        <Box
            sx={{
                height: '300px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Typography variant="body1" sx={{ ml: 2, fontWeight: 'bold' }}>
                Users
            </Typography>
            <List
                sx={{
                    height: '100%',
                    width: '100%',
                    py: 1,
                    overflow: 'scroll',
                }}
            >
                {sortedUsers &&
                    sortedUsers.map((user) => {
                        return (
                            <ListItem
                                key={user.id}
                                alignItems="center"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    py: 0,
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor:
                                            theme.palette.background.paper,
                                    },
                                }}
                                onClick={() => handleOwnerChange(user.id)}
                            >
                                <Checkbox
                                    sx={{ color: 'primary' }}
                                    checked={
                                        localFilters.owner?.includes(user.id) ||
                                        false
                                    }
                                />
                                <ListItemAvatar>
                                    <UserAvatar
                                        userId={user.id}
                                        isProfile={false}
                                        cardCategory="default"
                                    />
                                </ListItemAvatar>
                            </ListItem>
                        );
                    })}
            </List>
        </Box>
    );
};

export default OwnerList;
