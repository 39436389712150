import { useState, useEffect } from 'react';
import { AppContext } from '../components/contexts';

export interface FilterCards {
    cardCategory?:string[],
    cardType?: string[],
    cardSet?: string[],
    workbook?: string[],
    owner?: string[], 
}

const defaultFilterStates: FilterCards = {
    cardCategory: [],
    cardType: [],
    cardSet: [],
    workbook: [],
    owner: [], 
};

const useFilterCards = (
    localStorageKey: string
): [FilterCards, React.Dispatch<React.SetStateAction<FilterCards>>] => {
    const [filterCriteria, setFilterCriteria] = useState<FilterCards>(() => {
        const storedValue = window.localStorage.getItem(localStorageKey);
        return storedValue !== null
            ? JSON.parse(storedValue)
            : defaultFilterStates;
    });

    useEffect(() => {
        window.localStorage.setItem(
            localStorageKey,
            JSON.stringify(filterCriteria)
        ); 
    }, [localStorageKey, filterCriteria]);

    return [filterCriteria, setFilterCriteria];
};

export default useFilterCards;
