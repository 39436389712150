import { useEffect } from 'react';
import { Box } from '@mui/material';
import styles from '../styles/Home.module.css';
import { useNavigate } from 'react-router-dom';
const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/workbooks');
    }, []);

    return (
        <>
            <Box>
                <title>Strategy Cards</title>
                <meta name="description" content="Strategy Cards" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <link rel="icon" href="/favicon.ico" />
            </Box>
            <main className={styles.main}>
                <div className={styles.description}>
                    <p>Welcome to Strategy Cards</p>
                </div>
            </main>
        </>
    );
};

export default Home;
