import React, { useContext, useRef } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    BubbleDataPoint,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { createRadarChartData } from '../../helpers/charts';
import { getRadarChartOptions } from './RadarChartOptions';
import { Card, ScoreDefinition } from '../../API';
import { ActiveElement } from 'chart.js';
import { ChartEvent } from 'chart.js';
import { Chart } from 'chart.js';
import { CustomDataPoint } from './BubbleChart';
import { AppContext } from '../contexts';
import { CardScoreData } from '../../helpers/worksheets';
import { Box, useTheme } from '@mui/material';
import { useDroppable } from '@dnd-kit/core';
ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);
interface RadarChartProps {
    labels: string[];
    cards: Card[];
    scoreDefinitions: ScoreDefinition[];
    thumbnail: boolean;
    onBubbleClick: (
        data: CustomDataPoint,
        position: { top: number; left: number }
    ) => void;
    setSelectedCard: React.Dispatch<React.SetStateAction<Card | null>>;
    data: CustomDataPoint[][];
    cardsArray: CardScoreData[] | undefined;
    pptView?: boolean;
    setActiveCardId?: React.Dispatch<React.SetStateAction<string | null>>;
    setRightPanel?: React.Dispatch<React.SetStateAction<boolean>>;
    activeCardId?: string | null;
}
const RadarChart = ({
    labels,
    cards,
    scoreDefinitions,
    thumbnail,
    onBubbleClick,
    setSelectedCard,
    data,
    cardsArray,
    pptView,
    setActiveCardId,
    setRightPanel,
    activeCardId
}: RadarChartProps) => {
    const chartRef = useRef<Chart<'bubble', BubbleDataPoint[], unknown> | null>(
        null
    );
    const { isOver, setNodeRef } = useDroppable({
        id: '3_',
    });
    const theme = useTheme();
    const chartData = createRadarChartData(
        labels,
        cards,
        scoreDefinitions,
        data,
        cardsArray
    );
    const options = getRadarChartOptions(thumbnail, theme, pptView, activeCardId);
    options.onClick = (
        event: ChartEvent,
        _elements: ActiveElement[],
        chart: Chart
    ) => {
        const elements = chart.getElementsAtEventForMode(
            event as unknown as Event,
            'nearest',
            { intersect: false },
            true
        );
        if (elements.length > 0) {
            const firstPoint = elements[0];
            const index = firstPoint.index;
            const datasetIndex = firstPoint.datasetIndex;
            const data: CustomDataPoint = chart.data.datasets[datasetIndex]
                .data[index] as CustomDataPoint;
            const canvas = chart.canvas;
            const boundingBox = canvas.getBoundingClientRect();
            const xPosition =
                boundingBox.left +
                window.scrollX +
                chart.getDatasetMeta(datasetIndex).data[index].x;
            const yPosition =
                boundingBox.top +
                window.scrollY +
                chart.getDatasetMeta(datasetIndex).data[index].y;

            const selectedNode = chart.data.datasets[datasetIndex] as any;
            setActiveCardId && setActiveCardId(selectedNode.originalCard.id);
            setRightPanel && setRightPanel(true)
            onBubbleClick(selectedNode, { top: yPosition, left: xPosition });
        }
    };

    return (
        <Box
            ref={setNodeRef}
            sx={{
                canvas: {
                    height: '70%',
                    width: '70%',
                    maxHeight: '900px!important',
                    maxWidth: '900px!important',
                    background: isOver ? '#99b7e4' : '',
                },
            }}
        >
            <Radar data={chartData} options={options} />
        </Box>
    );
};

export default RadarChart;
