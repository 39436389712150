import { PropsWithChildren } from 'react';
import AppBar from '@mui/material/AppBar';
import { alpha } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { appBarWidth } from '../helpers/styleConfig';

interface PageAppBarProps {
    title: string;
}

const PageAppBar = ({
    title,
    children,
}: PropsWithChildren<PageAppBarProps>) => {
    return (
        <AppBar
            variant="outlined"
            position="fixed"
            elevation={0}
            sx={{
                backgroundColor: (theme) =>
                    alpha(theme.palette.background.paper, 1),
                backdropFilter: 'blur(5px)',
                pl: `calc(${appBarWidth} + 1rem)`,
                pr: 3,
                height: '3rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: 'none',
                svg: {
                    width: '2rem',
                    height: '2rem',
                },
            }}
        >
            <Typography variant="h5" sx={{ color: 'text.secondary' }}>
                {title}
            </Typography>
            <Box sx={{ flexDirection: 'row' }}>{children}</Box>
        </AppBar>
    );
};

export default PageAppBar;
