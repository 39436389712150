import { PropsWithChildren, useState } from 'react';
import { Box, Card as MuiCard, CardActionArea, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Card, ScoreData, ScoreDefinition } from '../../API';
import LineChart from '../charts/LineChart';
import EditableChart from '../charts/EditableChart';
import Indicator from '../charts/Indicator';
import usePermissions, { PermissionsType } from '../../hooks/usePermissions';
import { UserPermissions } from '../../globals';

interface CardScoreContent {
    scoreDefinition: ScoreDefinition;
}

interface CardScoreProps {
    card: Card;
    onUpdate?: (id: string, organisation: string) => void;
    deletedCard?: boolean | undefined;
    selectedScore?: string;
    worksheetCard?: any;
}

const CardScores = ({
    card,
    onUpdate,
    deletedCard,
    selectedScore,
    worksheetCard,
}: CardScoreProps) => {
    const { toScoreData, toCardType, id, organisation } = card;

    const [expanded, setExpanded] = useState<{
        scoreData: ScoreData | undefined | null;
        scoreDefinition: ScoreDefinition;
    }>();

    const permissions = usePermissions(PermissionsType.CARD, card);

    const theme = useTheme();

    const CardContent = (props: PropsWithChildren<CardScoreContent>) => {
        const { scoreDefinition, children } = props;

        return (
            <>
                {permissions.includes(UserPermissions.EDIT) ? (
                    <CardActionArea
                        onClick={() =>
                            !deletedCard &&
                            setExpanded({
                                scoreData: toScoreData?.items.find(
                                    (item) =>
                                        item?.scoreDefinitionId ==
                                        scoreDefinition.id
                                ),
                                scoreDefinition: scoreDefinition,
                            })
                        }
                        sx={{
                            cursor: !deletedCard ? 'pointer' : 'default',
                            '&:hover': {
                                border: 'thin solid primary-main',
                            },
                        }}
                    >
                        {children}
                    </CardActionArea>
                ) : (
                    <>{children}</>
                )}
            </>
        );
    };

    if (expanded) {
        return (
            <EditableChart
                handleClose={() => setExpanded(undefined)}
                organisation={organisation}
                id={id}
                scoreData={expanded.scoreData}
                scoreDefinition={expanded.scoreDefinition}
                onUpdate={onUpdate}
            />
        );
    } else {
        return (
            <Box sx={{ height: worksheetCard ? '200px' : '' }}>
                <Grid2 container spacing={2}>
                    {
                        // Need to implement UUID
                        toCardType.scoreDefinitions?.map(
                            (scoreDefinition, i) => (
                                <Grid2
                                    key={i}
                                    xs={12}
                                    sm={worksheetCard ? 12 : 6}
                                    md={worksheetCard ? 12 : 6}
                                    lg={worksheetCard ? 12 : 6}
                                    xl={worksheetCard ? 12 : 4}
                                >
                                    <MuiCard
                                        sx={{
                                            overflow: 'initial',
                                            border:
                                                selectedScore &&
                                                selectedScore ===
                                                    scoreDefinition.id
                                                    ? `solid 3px ${theme.palette.primary.main}`
                                                    : '',
                                        }}
                                    >
                                        <CardContent
                                            scoreDefinition={scoreDefinition}
                                        >
                                            {scoreDefinition.scoreType ===
                                            'Indicator' ? (
                                                <Indicator
                                                    key={scoreDefinition.id}
                                                    scoreData={toScoreData?.items.find(
                                                        (item) =>
                                                            item?.scoreDefinitionId ==
                                                            scoreDefinition.id
                                                    )}
                                                    scoreDefinition={
                                                        scoreDefinition
                                                    }
                                                />
                                            ) : (
                                                <LineChart
                                                    key={scoreDefinition.id}
                                                    scoreData={toScoreData?.items.find(
                                                        (item) =>
                                                            item?.scoreDefinitionId ==
                                                            scoreDefinition.id
                                                    )}
                                                    scoreDefinition={
                                                        scoreDefinition
                                                    }
                                                    end={(i + 1) % 3 === 0}
                                                />
                                            )}
                                        </CardContent>
                                    </MuiCard>
                                </Grid2>
                            )
                        )
                    }
                </Grid2>
            </Box>
        );
    }
};

export default CardScores;
