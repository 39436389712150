import { Box, styled, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { createLineChartData } from '../../helpers/charts';
import { getLineChartOptions, customTitle } from './LineChartOptions';
import ScoreWithTrend from './ScoreDisplay';
import { ScoreData, ScoreDefinition } from '../../API';
import { useContext } from 'react';
import { AppContext } from '../contexts';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title
);

const StyledChartWrapper = styled('div')(({ theme }) => ({
    height: '17rem',
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
    borderRadius: '8px',
    overflow: 'initial',
}));

interface LineChartProps {
    scoreData: ScoreData | null | undefined;
    scoreDefinition: ScoreDefinition;
    end?: boolean;
}

const LineChart = ({ scoreDefinition, scoreData, end }: LineChartProps) => {
    const { users } = useContext(AppContext);
    const { minimumValue, maximumValue, scoreType } = scoreDefinition;
    const min = minimumValue || undefined;
    const max = maximumValue || undefined;
    const reverseMaxMin = scoreDefinition.target === 'Min';

    const chartData = createLineChartData({
        data: scoreData?.data,
        reverse: reverseMaxMin,
        limit: 10,
        users,
        scoreType: scoreDefinition.scoreType,
        end,
    });

    const { name, description } = scoreDefinition;

    const reverseChart = !!scoreData?.data.length && reverseMaxMin;

    return (
        <StyledChartWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6" pb="0.200rem">
                        {name}
                    </Typography>

                    <ScoreWithTrend
                        scoreData={scoreData?.data}
                        scoreDefinition={scoreDefinition}
                        reverse={reverseChart}
                    />
                </Box>

                <Box pr={3}>
                    <Typography
                        variant="body2"
                        marginBottom={1}
                        sx={{ fontSize: '0.750rem' }}
                    >
                        {description}
                    </Typography>
                </Box>
            </Box>

            <Box height="9rem">
                {/* We must call get options as a function when using getMuiCssVariables in order for it to update when switching modes
                    This shouldn't be used in production as we will be calling a new object on every chart re-render */}
                <Line
                    options={getLineChartOptions({
                        type: scoreDefinition.scoreType,
                        reverse: reverseMaxMin,
                        suggestedMin: minimumValue as number,
                        suggestedMax: maximumValue as number,
                        comments: scoreData?.data.map(
                            (item) => item.comment ?? ''
                        ),
                    })}
                    plugins={[customTitle]}
                    data={chartData}
                />
            </Box>
        </StyledChartWrapper>
    );
};

export default LineChart;
