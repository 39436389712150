import { Box, styled, Tooltip, Typography, useTheme } from '@mui/material';
import IndicatorWithTrend from './IndicatorDisplay';
import { ScoreData, ScoreDefinition } from '../../API';
import { getMonth, truncateText } from '../../helpers/utils';
import { ToolTipContent } from './IndicatorChart';

const StyledChartWrapper = styled('div')(({ theme }) => ({
    height: '17rem',
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
    borderRadius: '8px',
}));

interface LineChartProps { 
    scoreData: ScoreData | null | undefined;
    scoreDefinition: ScoreDefinition;
}

const Indicator = ({ scoreDefinition, scoreData }: LineChartProps) => {
    const theme = useTheme();

    const { name, description } = scoreDefinition;

    return (
        <StyledChartWrapper>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6" pb="0.220rem">
                        {name}
                    </Typography>

                    <IndicatorWithTrend
                        scoreData={scoreData?.data}
                        scoreDefinition={scoreDefinition}
                    />
                </Box>

                <Box pr={3}>
                    <Typography
                        variant="body2"
                        marginBottom={1}
                        sx={{ fontSize: '0.750rem' }}
                    >
                        {description}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: `solid 1px ${theme.palette.grey[400]}`,
                        pb: 1.3,
                    }}
                >
                    {scoreData?.data.map((item) => {
                        return (
                            <>
                                {item.comment ? (
                                    <Tooltip
                                        title={<ToolTipContent item={item} />}
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    background:
                                                        'rgba(0, 0, 0, 0.6)',
                                                    width: '250px',
                                                    borderRadius: '8px',
                                                },
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '23px',
                                                height: '23px',
                                                background:
                                                    item.value === 0
                                                        ? theme.palette
                                                              .grey[700]
                                                        : item.value === 1
                                                        ? theme.palette.warning
                                                              .dark
                                                        : item.value === 2
                                                        ? theme.palette.warning
                                                              .light
                                                        : theme.palette.success
                                                              .light,
                                                borderRadius: '6px',
                                            }}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Box
                                        sx={{
                                            width: '23px',
                                            height: '23px',
                                            background:
                                                item.value === 0
                                                    ? theme.palette.grey[700]
                                                    : item.value === 1
                                                    ? theme.palette.warning.dark
                                                    : item.value === 2
                                                    ? theme.palette.warning
                                                          .light
                                                    : theme.palette.success
                                                          .light,
                                            borderRadius: '6px',
                                        }}
                                    />
                                )}
                            </>
                        );
                    })}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        pt: 1.3,
                    }}
                >
                    {scoreData?.data.map((item) => {
                        return (
                            <Typography
                                variant="body2"
                                marginBottom={1}
                                sx={{ fontSize: '0.750rem' }}
                            >
                                {getMonth(item.date, 'short')}
                            </Typography>
                        );
                    })}
                </Box>
                <Box>
                    <Typography
                        variant="body2"
                        marginBottom={1}
                        sx={{
                            fontSize: '0.750rem',
                            fontWeight: 'bold',
                            mb: '2px',
                        }}
                    >
                        Commentary
                    </Typography>
                    <Typography
                        variant="body2"
                        marginBottom={1}
                        sx={{ fontSize: '0.750rem' }}
                    >
                        {scoreData?.data?.length &&
                            truncateText(
                                scoreData.data[scoreData.data.length - 1]
                                    ?.comment ?? '',
                                100
                            )}
                    </Typography>
                </Box>
            </Box>

            <Box height="9rem"></Box>
        </StyledChartWrapper>
    );
};

export default Indicator;
