import { BaseSyntheticEvent } from 'react';
import { Menu, MenuItem, useTheme } from '@mui/material';
import { UserPermissions } from '../../globals';

export interface MenuItem {
    text: string;
    action: () => void;
}

interface AddCardMenuProps {
    anchor?: HTMLElement;
    setMenu?: (e: React.MouseEvent<HTMLElement> | null) => void;
    permissions: UserPermissions[];
    secondaryAction: (() => void) | undefined;
    action: (() => void) | undefined;
}

const AddCardMenu = ({
    anchor,
    permissions,
    setMenu,
    secondaryAction,
    action,
}: AddCardMenuProps) => {
    const handleClose = (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        setMenu && setMenu(null);
    };

    const menuOpen = Boolean(anchor);
    const theme = useTheme();

    return (
        <Menu
            id="positioned-menu"
            aria-labelledby="menu-button"
            anchorEl={anchor}
            open={menuOpen}
            onClose={(e: BaseSyntheticEvent) => handleClose(e)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPaper-root': {
                    boxShadow: `1px 1px 1px 1px ${theme.palette.background.paper}`,
                    backgroundColor: theme.palette.background.default,
                },
                mt: 4,
            }}
        >
            <MenuItem
                data-testid="add-new-card"
                onClick={() => {
                    action && action();
                    setMenu && setMenu(null);
                }}
            >
                Create a new card
            </MenuItem>
            <MenuItem
                data-testid="add-existing-card"
                onClick={() => {
                    secondaryAction && secondaryAction();
                    setMenu && setMenu(null);
                }}
            >
                Add an existing card
            </MenuItem>
        </Menu>
    );
};

export default AddCardMenu;
