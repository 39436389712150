import { PropsWithChildren, useContext, useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, CircularProgress, styled } from '@mui/material';
import { defaultTabTransition } from '../../helpers/animations';
import { AppContext } from '../contexts';
import GhostCard from '../cards/GhostCard';
import { PageIdentifier } from '../cards/cardTypes';

interface GridLayoutProps {
    gridItems?: JSX.Element[];
    emptyAction?: () => void;
    action?: () => void;
    actionLabel?: string;
    secondaryAction?: () => void;
    secondaryActionLabel?: string;
    pageIdentifier: PageIdentifier;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    mediumCard?: boolean;
    onLoadMore?: (initialLoad?: boolean) => Promise<void>;
    token?: string | null;
    loading?: boolean;
}

export const StyledEmptyItem = styled('div')(
    ({ height }: { height: string }) => ({
        border: '2px dashed #999',
        borderRadius: 16,
        height: height,
        width: '100%',
        padding: '1.3rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        backgroundColor: '#f0f2f5',
    })
);
const GridLayout = ({
    emptyAction,
    action,
    actionLabel,
    secondaryAction,
    secondaryActionLabel,
    pageIdentifier,
    xs = 12,
    sm = 12,
    md = 6,
    lg = 4,
    xl = 3,
    gridItems,
    mediumCard,
    onLoadMore,
    token,
    loading
}: PropsWithChildren<GridLayoutProps>) => {
    const { showIllustrations, loaded } = useContext(AppContext);
    const [mounted, setMounted] = useState(false);
    const displayGhost = !localStorage.getItem(
        `displayGhost_${pageIdentifier}`
    );
    return (
        <>
            <AnimatePresence>
                <LayoutGroup>
                    <motion.div
                        initial={{ x: 300, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -300, opacity: 0 }}
                        transition={defaultTabTransition}
                    >
                        <Grid2 container spacing={mediumCard ? 2 : 4}>
                            {!!gridItems &&
                                gridItems.map((item) => (
                                    <Grid2
                                        key={uuidv4()}
                                        xs={xs}
                                        sm={sm}
                                        md={md}
                                        lg={lg}
                                        xl={xl}
                                    >
                                        {item}
                                    </Grid2>
                                ))}
                            <Grid2 xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                {gridItems?.length != null &&
                                    gridItems?.length <= 7 &&
                                    loaded &&
                                    displayGhost && !mediumCard && (
                                        <GhostCard
                                            pageIdentifier={
                                                pageIdentifier
                                            }
                                            action={action}
                                        />
                                    )}
                            </Grid2>
                        </Grid2>
                    </motion.div>
                </LayoutGroup>
                {token && (
                <Box
                    sx={{
                        width: '100%',
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {!loading ? (
                        <Button
                            variant="contained"
                            onClick={() => onLoadMore && onLoadMore(false)}
                        >
                            Load more
                        </Button>
                    ) : (
                        <Box
                            sx={{
                                width: '100px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            )}
            </AnimatePresence>
        </>
    );
};

export default GridLayout;
