import React, {
    useEffect,
    useState,
    useRef,
    useContext,
    createRef,
} from 'react';
import {
    Card,
    CreateCommentMutation,
    DeleteCommentExtMutation,
    EntityType,
    ListCommentsQuery,
    ModelSortDirection,
} from '../../API';
import { generateClient } from 'aws-amplify/api';
import { listComments } from '../../graphql/queries';
import {
    Alert,
    Box,
    Divider,
    IconButton,
    InputAdornment,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import UserAvatar from '../profile/UserAvatar';
import SendIcon from '@mui/icons-material/Send';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { createComment, deleteCommentExt } from '../../graphql/mutations';
import * as subscriptions from '../../graphql/subscriptions';
import {
    SubscriptionType,
    getSubscriptions,
} from '../../helpers/subscriptions';
import useSnackbar from '../../hooks/useSnackbar';
import { getUserOrganisation } from '../../helpers/auth';
import Picker from '@emoji-mart/react';
import DOMPurify from 'dompurify';
import Comment from './Comment';
import CardDelete from './CardDelete';
import { PageIdentifier } from './cardTypes';
import ModalContainer from '../ModalContainer';
import { StyledModal } from '../Modal';
import { AppContext } from '../contexts';

interface CardCommentsProps {
    card: Card;
    onUpdate?: (id: string, organisation: string) => void;
    selected?: string;
    context?: EntityType;
    worksheetCard?: boolean;
    rightPanel?: boolean;
    worksheetCarouselOpen?: boolean;
}
export const InputField = styled(TextField)(({ theme }) => ({
    flexGrow: 1,
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
    },
}));

const CardComments = ({
    card,
    onUpdate,
    selected,
    context,
    worksheetCard,
    rightPanel,
    worksheetCarouselOpen,
}: CardCommentsProps) => {
    const { users, user } = useContext(AppContext);
    const theme = useTheme();
    const [commentText, setCommentText] = useState<string>('');
    const [comments, setComments] = useState<any>(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [userId, setUserId] = useState<string | null | undefined>(null);
    const [newCommentId, setNewCommentId] = useState<string | null>(null);
    const [commentToDelete, setCommentToDelete] = useState<any>(null);
    const [expandedComments, setExpandedComments] = useState<Set<string>>(
        new Set()
    );
    const [editableCommentId, setEditableCommentId] = useState<string | null>(
        null
    );
    const [editableCommentMessage, setEditableCommentMessage] =
        useState<string>('');
    const pickerRef = useRef<HTMLDivElement>(null);
    const commentsContainerRef = useRef<HTMLDivElement>(null);
    const textFieldRef = useRef<HTMLInputElement>(null);
    const { closeSnackbar, isOpen, showSnackbar, message, severity, duration } =
        useSnackbar();

    const client = generateClient();

    useEffect(() => {
        const fetchComments = async () => {
            let nextToken = null;
            do {
                const response = (await client.graphql({
                    query: listComments,
                    variables: {
                        contextId: card.id,
                        nextToken: nextToken,
                        sortDirection: ModelSortDirection.DESC,
                    },
                })) as { data: ListCommentsQuery };

                const items = response?.data?.listComments?.items;
                if (items) {
                    setComments(items);
                }
                nextToken = response?.data?.listComments?.nextToken;
            } while (nextToken != null);
        };

        fetchComments();
    }, [card]);

    useEffect(() => {
        if (users?.length) {
            const createSub = client
                .graphql({ query: subscriptions.onCreateNotification })
                .subscribe({
                    next: ({ data }) => {
                        getSubscriptions({
                            data,
                            setItems: setComments,
                            showSnackbar,
                            users: users,
                            subscriptionType: SubscriptionType.Comment,
                            parentId: card.id,
                        });
                    },
                    error: (error) => console.warn(error),
                });
            return () => createSub.unsubscribe();
        }
    }, [users]);

    const postComment = async () => {
        setUserId(user?.userSub);
        const userOrganisation = getUserOrganisation(user);
        try {
            const response = (await client.graphql({
                query: createComment,
                variables: {
                    input: {
                        contextId: card.id,
                        ...(context && {
                            contextType: context,
                        }),
                        organisation: userOrganisation,
                        message: commentText,
                    },
                },
            })) as { data: CreateCommentMutation };

            const newComment = response?.data?.createComment;

            if (newComment) {
                showSnackbar({
                    message: 'Comment added',
                    severity: 'success',
                });
            }

            setCommentText('');
            newComment && setNewCommentId(newComment.id);
            if (commentsContainerRef.current) {
                commentsContainerRef.current.scrollTop = 0;
            }
        } catch (err) {
            console.log(err);
        }
    };
    const handleDeleteComment = async () => {
        try {
            const response = (await client.graphql({
                query: deleteCommentExt,
                variables: {
                    contextId: card.id,
                    id: commentToDelete.id,
                    createdAt: commentToDelete.createdAt,
                },
            })) as { data: DeleteCommentExtMutation };

            const updatedComments = comments.filter(
                (item: any) => item.id !== commentToDelete.id
            );
            setComments(updatedComments);
            onUpdate && onUpdate(card.id, card.organisation);
            showSnackbar({
                message: 'Comment deleted',
                severity: 'success',
            });
        } catch (err) {
            console.log(err);
        }
        setCommentToDelete(null);
    };
    const addEmoji = (emoji: any) => {
        if (textFieldRef.current) {
            const cursorPosition = (textFieldRef.current as any).selectionStart;
            const newText =
                commentText.slice(0, cursorPosition) +
                emoji.native +
                commentText.slice(cursorPosition);
            setCommentText(newText);

            const newCursorPosition = cursorPosition + emoji.native.length;

            setTimeout(() => {
                (textFieldRef.current as any).setSelectionRange(
                    newCursorPosition,
                    newCursorPosition
                );
                (textFieldRef.current as any).focus();
            }, 0);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            pickerRef.current &&
            !pickerRef.current.contains(event.target as Node)
        ) {
            setShowEmojiPicker(false);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && commentText.trim() !== '') {
            postComment();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleSubmit = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const sanitizedValue = DOMPurify.sanitize(e.target.value);
        setCommentText(sanitizedValue);
    };

    const scrollRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        setTimeout(() => {
            if (selected && scrollRef) {
                scrollRef.current?.scrollIntoView();
            }
        }, 1000);
    }, [selected]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                ref={commentsContainerRef}
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    px: 1,
                    py: 2,
                    height: worksheetCard ? 'calc(100vh - 570px)' : '100%',
                }}
            >
                {!comments ? (
                    <Typography>No comments yet.</Typography>
                ) : (
                    comments.map((item: any, index: number) => (
                        <Box
                            {...(selected === item.id && {
                                ref: scrollRef,
                            })}
                            key={item.id}
                        >
                            <Comment
                                comment={item}
                                newCommentId={newCommentId}
                                setNewCommentId={setNewCommentId}
                                expandedComments={expandedComments}
                                setExpandedComments={setExpandedComments}
                                editableCommentId={editableCommentId}
                                setEditableCommentId={setEditableCommentId}
                                editableCommentMessage={editableCommentMessage}
                                setEditableCommentMessage={
                                    setEditableCommentMessage
                                }
                                card={card}
                                onUpdate={onUpdate}
                                setCommentToDelete={setCommentToDelete}
                                selected={item.id === selected}
                                context={context}
                                worksheetCarouselOpen={worksheetCarouselOpen}
                            />
                        </Box>
                    ))
                )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }}>
                <UserAvatar userId={userId} avatarOnly={true} />
                <InputField
                    variant="outlined"
                    size="small"
                    placeholder="Add comment"
                    value={commentText}
                    onChange={(e) => handleSubmit(e)}
                    onKeyDown={handleKeyDown}
                    inputRef={textFieldRef}
                    multiline
                    minRows={1}
                    maxRows={4}
                    sx={{
                        background: theme.palette.background.paper,
                        borderRadius: '16px',
                        height: '100%',
                        ml: 1,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title={'Add emoji'}>
                                    <IconButton
                                        color="default"
                                        onClick={() =>
                                            setShowEmojiPicker(!showEmojiPicker)
                                        }
                                    >
                                        <InsertEmoticonIcon />
                                    </IconButton>
                                </Tooltip>
                                {commentText && (
                                    <Tooltip title={'Post comment'}>
                                        <IconButton
                                            color={
                                                commentText
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                            onClick={postComment}
                                        >
                                            <SendIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </InputAdornment>
                        ),
                        inputProps: {
                            maxLength: 1000,
                        },
                    }}
                />
                {showEmojiPicker && (
                    <Box
                        data-testid="emoji-picker"
                        ref={pickerRef}
                        sx={{
                            position: 'absolute',
                            bottom: '60px',
                            right: '10px',
                        }}
                    >
                        <Picker onEmojiSelect={addEmoji} />
                    </Box>
                )}
            </Box>
            {commentToDelete && (
                <StyledModal
                    key="delete-comment-modal"
                    open={!!commentToDelete}
                    onClose={() => {
                        setCommentToDelete(null);
                    }}
                    disableEnforceFocus
                    sx={{
                        zIndex: 1500,
                    }}
                >
                    <ModalContainer sx={{ maxWidth: '40rem' }}>
                        {' '}
                        <CardDelete
                            commentId={commentToDelete.id}
                            toggleDeleteWizard={() => setCommentToDelete(null)}
                            confirmCommentDelete={handleDeleteComment}
                            pageIdentifier={PageIdentifier.CARDS}
                        />
                    </ModalContainer>
                </StyledModal>
            )}
            <Snackbar
                open={isOpen}
                autoHideDuration={duration}
                onClose={closeSnackbar}
                sx={{ zIndex: 1600 }}
            >
                <Alert
                    variant="filled"
                    severity={severity}
                    sx={{ width: '100%' }}
                    onClose={closeSnackbar}
                >
                    {message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default CardComments;
