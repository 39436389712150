import { useContext, useEffect, useState } from 'react';
import { Box, Modal, useTheme } from '@mui/material';
import { Card } from '../../../API';
import { cardToCardComponentProps } from '../../../pages/cards';
import CardComponent, { Lightbox } from '../../cards/CardComponent';
import CardDetails from '../../cards/CardDetails';
import CardInfo from '../../cards/CardInfo';
import CardRelationships from '../../cards/CardRelationships';
import StyledCardContent from '../../cards/CardContent';
import CardScores from '../../cards/CardScores';
import { CardPage } from '../../cards/cardTypes';
import { StyledModal, SwotModal } from '../../Modal';
import { CardContext } from '../../cards/context';
import CardActivity from '../../cards/CardActivity';
import { motion } from 'framer-motion';
import { defaultTabTransition } from '../../../helpers/animations';
import CardComments from '../../cards/CardComments';

const AnalysisModal = ({
    card,
    handleClose,
    cardPage,
    fullWidth,
    setSelectedCard,
}: {
    card: Card | null;
    handleClose?: () => void;
    cardPage?: CardPage;
    fullWidth?: boolean | undefined;
    setSelectedCard?: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
    const theme = useTheme();
    const { refreshCard, handleRemove, handleDelete, handleCopy } =
        useContext(CardContext);
    const [activeCardPage, setActiveCardPage] = useState<CardPage>(
        CardPage.DETAILS
    );

    const handleClick = (cardId: string, cardPage?: CardPage) => {
        if (cardPage && setSelectedCard) {
            setActiveCardPage(cardPage);
        }
    };

    useEffect(() => {
        if (cardPage) {
            setActiveCardPage(cardPage);
        }
    }, [cardPage]);
    const modalStyles = {
        position: 'fixed',
        top: fullWidth ? '50%' : '55%',
        left: fullWidth ? '50%' : '28%',
        transform: 'translate(-50%, -50%)',
        maxWidth: {
            xs: '95vw',
            lg: '48rem',
        },
        width: '100%',
        zIndex: 1400,
    };
    return (
        <>
            {card && fullWidth ? (
                <Lightbox
                    onClick={() => setSelectedCard && setSelectedCard(null)}
                />
            ) : (
                <></>
            )}
            <Box
                key="modal"
                sx={{
                    ...modalStyles,
                    borderRadius: '1rem',
                }}
            >
                {card && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.7 }}
                    >
                        <CardComponent
                            data={cardToCardComponentProps(card)}
                            handleDelete={handleDelete}
                            onClick={(cardId: string, cardPage?: CardPage) =>
                                handleClick(cardId, cardPage)
                            }
                            showPage={activeCardPage}
                            handleCopy={handleCopy}
                            handleClose={handleClose}
                            handleRemove={handleRemove}
                            expanded
                        >
                            {!!activeCardPage && (
                                <StyledCardContent expanded={true}>
                                    {
                                        {
                                            [CardPage.DETAILS]: (
                                                <CardDetails
                                                    card={card}
                                                    onUpdate={refreshCard}
                                                    isCard={true}
                                                />
                                            ),
                                            [CardPage.SCORES]: (
                                                <CardScores
                                                    card={card}
                                                    onUpdate={refreshCard}
                                                />
                                            ),
                                            [CardPage.INFO]: (
                                                <CardInfo card={card} />
                                            ),
                                            [CardPage.RELATIONSHIPS]: (
                                                <CardRelationships
                                                    card={card}
                                                />
                                            ),
                                            [CardPage.ACTIVITY]: (
                                                <CardActivity card={card} />
                                            ),
                                            [CardPage.COMMENTS]: (
                                                <CardComments card={card} />
                                            ),
                                        }[activeCardPage]
                                    }
                                </StyledCardContent>
                            )}
                        </CardComponent>
                    </motion.div>
                )}
            </Box>
            <Modal
                className="custom-modal"
                key="modal"
                open={!!card}
                onClose={() => setSelectedCard && setSelectedCard(null)}
                aria-labelledby=""
                aria-describedby=""
                disableEnforceFocus
                hideBackdrop={fullWidth}
                sx={{
                    zIndex: 1299,
                    width: '100%',
                    ml: 10,
                    mt: 19,
                }}
            >
                <Box />
            </Modal>
        </>
    );
};

export default AnalysisModal;
