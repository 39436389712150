import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Typography, useTheme } from '@mui/material';
import { ScoreDefinition, ScoreType, ScoreDatum } from '../../API';
import { formatScore } from '../../helpers/utils';

interface Trend {
    trend: boolean;
    small?: boolean;
}

const TrendComponent = ({ trend, small }: Trend) =>
    trend ? (
        <ArrowDropUpIcon
            style={{ fill: '#00c852', width: small ? '15px' : '' }}
        />
    ) : (
        <ArrowDropDownIcon
            style={{ fill: '#ff6f00', width: small ? '15px' : '' }}
        />
    );

interface ScoreDisplayProps {
    scoreType: ScoreType;
    value: string | number;
    small?: boolean;
    pptView?: boolean | undefined;
}

const ScoreDisplayComponent = ({
    scoreType,
    value,
    small,
    pptView,
}: ScoreDisplayProps) => (
    <Box
        sx={{
            display: 'flex',
            fontSize: pptView ? '16px' : small ? '10px' : '20px',
            marginTop: '5px',
        }}
    >
        {scoreType === ScoreType.Currency && '£'}
        {value.toString().length > 2 ? formatScore(value as number) : value}

        {scoreType === ScoreType.Percentage ? (
            <Typography
                variant="subtitle1"
                sx={{ fontSize: pptView ? '14px' : small ? '8px' : 'inherit' }}
            >
                <strong>%</strong>
            </Typography>
        ) : (
            ''
        )}
    </Box>
);

interface ScoreWithTrendProps {
    scoreData?: ScoreDatum[];
    scoreDefinition: ScoreDefinition;
    reverse?: boolean;
    playbook?: boolean;
    small?: boolean;
    pptView?: boolean | undefined;
}

const ScoreWithTrend = ({
    scoreDefinition,
    scoreData,
    reverse,
    playbook,
    small,
    pptView,
}: ScoreWithTrendProps) => {
    if (scoreData == null || !scoreData.length) {
        return <></>;
    }
    const theme = useTheme();
    const { scoreType } = scoreDefinition;

    const latestDatum = scoreData[scoreData.length - 1]?.value;

    let trend;

    if (scoreType === 'Indicator') {
        const second = scoreData[scoreData.length - 2]?.value;
        trend =
            latestDatum === 3
                ? true
                : latestDatum === 2 && second === 1
                ? true
                : false;
    } else {
        trend =
            latestDatum === scoreData[scoreData.length - 2]?.value
                ? null
                : latestDatum > scoreData[scoreData.length - 2]?.value;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'space-between',
                alignSelf: 'center',
            }}
        >
            {scoreType === 'Indicator' ? (
                <Box
                    sx={{
                        width: small ? '10px' : playbook ? '30px' : '45px',
                        height: small ? '10px' : playbook ? '30px' : '45px',
                        borderRadius: 2,
                        background:
                            latestDatum === 0
                                ? theme.palette.grey[700]
                                : latestDatum === 1
                                ? theme.palette.warning.main
                                : latestDatum === 2
                                ? theme.palette.warning.light
                                : theme.palette.success.light,
                    }}
                ></Box>
            ) : (
                <Typography variant="h4" fontSize="2em">
                    <ScoreDisplayComponent
                        scoreType={scoreType}
                        value={latestDatum}
                        small={small}
                        pptView={pptView}
                    />
                </Typography>
            )}

            {scoreData.length > 1 && trend !== null ? (
                <TrendComponent
                    trend={reverse ? !trend : trend}
                    small={small}
                />
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', pl: '5px' }}>
                    <Box
                        sx={{
                            width: '12px',
                            height: '3px',
                            marginTop: '5px',
                            background: 'transparent',
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default ScoreWithTrend;
