import { Box, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { worksheets } from '../worksheets';
import {
    WorksheetSetting,
    getWorksheetThumbnail,
} from '../../../helpers/worksheets';
import { WorksheetSelectorFieldProps } from './inputFieldTypes';
import { ReactNode } from 'react';

const CustomTooltipContent = ({ children }: { children: ReactNode }) => (
    <Box sx={{ width: 200 }}>
        <Typography variant="body2">{children}</Typography>
    </Box>
);

const WorksheetSelector = (props: WorksheetSelectorFieldProps) => {
    const theme = useTheme();
    const { workbookForm } = props;
    const Avatar = ({ worksheet }: { worksheet: string }) => {
        const WorksheetIcon = getWorksheetThumbnail(worksheet);
        if (!WorksheetIcon) {
            console.error(`Icon not found for worksheet: ${worksheet}`);
            return null;
        }
        return <WorksheetIcon />;
    };

    const removed = props.removed ?? [];
    const selected = props.selected ?? [];

    const renderWorksheetBox = (worksheet: WorksheetSetting) => {
        const isSelectable = props.loading
            ? false
            : !selected.includes(worksheet.i) &&
              (worksheet.e || removed.includes(worksheet.i));
        const boxContent = (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: isSelectable ? 'pointer' : 'default',
                    border:
                        props.selectValue === worksheet.i
                            ? `3px solid ${theme.palette.primary.main}`
                            : 'inherit',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    px: 1,
                    height: '100%',
                    textAlign: 'center',
                    color: isSelectable
                        ? 'inherit'
                        : theme.palette.text.disabled,
                    '&:hover': {
                        border:
                            isSelectable && !workbookForm
                                ? `3px solid ${theme.palette.primary.main}`
                                : props.selectValue === worksheet.i
                                ? `3px solid ${theme.palette.primary.main}`
                                : 'inherit',
                    },
                }}
                onClick={() => {
                    if (isSelectable) {
                        props.onChange &&
                            props.onChange({
                                target: { value: worksheet.i },
                            });
                    }
                }}
            >
                <Box sx={{ opacity: isSelectable ? 1 : 0.5 }}>
                    <Avatar worksheet={worksheet.n} />
                </Box>
                <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', my: 0.5 }}
                >
                    {worksheet.n}
                </Typography>
                <Typography variant="body2">{worksheet.d}</Typography>
            </Box>
        );
        if (!worksheet.e) {
            return (
                <Tooltip
                    title={
                        <CustomTooltipContent>
                            We are sorry, this item is not available yet, coming
                            soon.
                        </CustomTooltipContent>
                    }
                    placement="bottom"
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -170],
                                },
                            },
                        ],
                    }}
                >
                    {boxContent}
                </Tooltip>
            );
        }
        return boxContent;
    };

    return (
        <Box
            sx={{
                mb: 2,
                pr: 1,
                height: workbookForm ? '200px' : '100%',
                overflow: 'auto',
            }}
        >
            <Grid container spacing={1} sx={{ height: '100%' }}>
                {worksheets
                    .filter(
                        (worksheet) =>
                            worksheet.t === 'Chart' &&
                            !selected.includes(worksheet.i)
                    )
                    .sort((a, b) => Number(b.e) - Number(a.e))
                    .map((worksheet) => (
                        <Grid item md={3} key={worksheet.i}>
                            {renderWorksheetBox(worksheet)}
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};

export default WorksheetSelector;
