import { Attribute, AttributeDefinition, AttributeType } from '../../API';
import FieldProps, {
    DateFieldProps,
    MultilineTextFieldProps,
    SelectFieldProps,
    TextFieldProps,
    URLFieldProps,
    UserFieldProps,
    CardFieldProps,
    CardsFieldProps,
    CardSetFieldProps,
} from './input-fields/inputFieldTypes';
import { dateFromISO } from '../../helpers/utils';

export const stringsToSelectOptions = (values: string[]) => {
    if (typeof values === 'string') {
        values = JSON.parse(values);
    }
    const options = values.map((value) => ({
        label: value,
        value,
    }));

    return options;
};

const getDisplayValueFromAttribute = (
    attribute: AttributeDefinition,
    attributes: (Attribute | null)[]
) => {
    const defaultValue = attributes?.find(
        (item) => item?.attributeDefinitionID === attribute?.id
    )?.value;
    const parsedDefaultValue: string = defaultValue && JSON.parse(defaultValue);

    return parsedDefaultValue;
};

const parseAttributeConfiguration = (config: string) => {
    return JSON.parse(config);
};

export const getFormSchemaPropType = (
    attributeDefinition: AttributeDefinition,
    attributes?: (Attribute | null)[] | null
): FieldProps => {
    // Common props all inputs will require
    const commonProps = {
        key: attributeDefinition.name,
        name: attributeDefinition.name, // Needs to overwritten on component for registered name,
        label: attributeDefinition.name,
    };

    switch (attributeDefinition.attributeType) {
        case AttributeType.Text:
            const textProps: TextFieldProps = {
                ...commonProps,
                type: AttributeType.Text,
                defaultValue: attributes
                    ? getDisplayValueFromAttribute(
                          attributeDefinition,
                          attributes
                      )
                    : '',
            };
            return textProps;

        case AttributeType.MultilineText:
            const multilineProps: MultilineTextFieldProps = {
                ...commonProps,
                type: AttributeType.MultilineText,
                defaultValue: attributes
                    ? getDisplayValueFromAttribute(
                          attributeDefinition,
                          attributes
                      )
                    : '',
            };
            return multilineProps;

        case AttributeType.SingleSelect:
            const config = attributeDefinition.configuration
                ? parseAttributeConfiguration(attributeDefinition.configuration)
                : [];
            const selectProps: SelectFieldProps = {
                ...commonProps,
                type: AttributeType.SingleSelect,
                options:
                    config.length > 0
                        ? stringsToSelectOptions(config)
                        : undefined,
                defaultValue: attributes
                    ? getDisplayValueFromAttribute(
                          attributeDefinition,
                          attributes
                      )
                    : '',
            };
            return selectProps;

        case AttributeType.URL:
            const urlProps: URLFieldProps = {
                ...commonProps,
                type: AttributeType.URL,
                defaultValue: attributes
                    ? getDisplayValueFromAttribute(
                          attributeDefinition,
                          attributes
                      )
                    : '',
            };
            return urlProps;

        case AttributeType.Date:
            const dateProps: DateFieldProps = {
                ...commonProps,
                type: AttributeType.Date,
                ...(attributes && {
                    defaultValue: dateFromISO(
                        getDisplayValueFromAttribute(
                            attributeDefinition,
                            attributes
                        )
                    ),
                }),
            };
            return dateProps;

        case AttributeType.User:
            const userProps: UserFieldProps = {
                ...commonProps,
                type: AttributeType.User,
                defaultValue: attributes
                    ? getDisplayValueFromAttribute(
                          attributeDefinition,
                          attributes
                      )
                    : '',
            };
            return userProps;

        case AttributeType.Card:
            const cardConfig = attributeDefinition.configuration
                ? parseAttributeConfiguration(attributeDefinition.configuration)
                : [];
            const cardProps: CardFieldProps = {
                ...commonProps,
                type: AttributeType.Card,
                values:
                    cardConfig.length > 0 ? JSON.parse(cardConfig) : undefined,
                defaultValue: attributes
                    ? getDisplayValueFromAttribute(
                          attributeDefinition,
                          attributes
                      )
                    : '',
            };
            return cardProps;

        case AttributeType.Cards:
            const cardsConfig = attributeDefinition.configuration
                ? parseAttributeConfiguration(attributeDefinition.configuration)
                : [];
            const cardsProps: CardsFieldProps = {
                ...commonProps,
                type: AttributeType.Cards,
                values:
                    cardsConfig.length > 0
                        ? JSON.parse(cardsConfig)
                        : undefined,
                defaultValue: attributes
                    ? getDisplayValueFromAttribute(
                          attributeDefinition,
                          attributes
                      )
                    : '',
            };
            return cardsProps;

        case AttributeType.CardSet:
            const cardSetConfig = attributeDefinition.configuration
                ? parseAttributeConfiguration(attributeDefinition.configuration)
                : [];
            const cardSetProps: CardFieldProps = {
                ...commonProps,
                type: AttributeType.CardSet,
                values:
                    cardSetConfig.length > 0
                        ? JSON.parse(cardSetConfig)
                        : undefined,
                defaultValue: attributes
                    ? getDisplayValueFromAttribute(
                          attributeDefinition,
                          attributes
                      )
                    : '',
            };
            return cardSetProps;

        default:
            throw new Error(
                `Invalid attribute type: "${attributeDefinition.attributeType}"`
            );
    }
};
