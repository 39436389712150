import { Box, keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';

const blink = keyframes`
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

const Dot = styled(Box)(({ theme }) => ({
    width: '6px',
    height: '6px',
    margin: '0 2px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    animation: `${blink} 1s infinite`,
}));

const TypingDots = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 3 }}>
            <Dot sx={{ animationDelay: '0s' }} />
            <Dot sx={{ animationDelay: '0.2s' }} />
            <Dot sx={{ animationDelay: '0.4s' }} />
        </Box>
    );
};

export default TypingDots;
