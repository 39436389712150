import { UserProfile } from '../API';

export const getUserOrganisation = (user: any) => {
    return user?.tokens?.accessToken?.payload['cognito:groups'][0];
};

export const getUserName = (id: string, users: UserProfile[]) => {
    let userId = id.indexOf(':') === -1 ? id : id.substring(0, id.indexOf(':'));

    const user = users.find((user) => user.id === userId);

    if (user) {
        return `${user.firstName} ${user.lastName}`;
    }

    return '';
};
