import { ChartData } from 'chart.js';
import {
    Card,
    ScoreDatum,
    ScoreDefinition,
    ScoreType,
    UserProfile,
} from '../API';
import {
    dateFromISO,
    dateStringFromISO,
    getCurrencySymbol,
    shortDateStringFromISO,
} from './utils';
import { CustomDataPoint } from '../components/charts/BubbleChart';
import {
    NodeColorOptions,
    NodeSizeOptions,
} from '../components/playbooks/playbookTypes';
import {
    defaultColors,
    defaultColorsLowTransparency,
    generateRandomColors,
} from './category';
import { CardScoreData } from './worksheets';

export const monthLabels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

interface LineChartData {
    data?: ScoreDatum[];
    highlightIndex?: number;
    reverse?: boolean;
    limit?: number;
    users: UserProfile[];
    scoreType: ScoreType;
    end?: boolean;
}
interface Commentary {
    comment: string;
    authorInfo: string;
}
interface DataSet {
    data: number[];
    commentary: Commentary[];
    backgroundColor?: string[];
    end?: boolean;
}

interface ExtendedChartData {
    labels: string[];
    datasets: DataSet[];
}

export const getUserName = (users: UserProfile[], author?: string | null) => {
    if (!author) return '';

    const user = users.find((user) => user.id === author);

    if (user) {
        return `${user.firstName} ${user.lastName}`;
    } else {
        return '';
    }
};
export const createLineChartData = ({
    data,
    reverse,
    limit,
    users,
    scoreType,
    end,
}: LineChartData): ExtendedChartData => {
    const labels: string[] = [];
    const values: number[] = [];
    const backgroundColor: string[] = [];

    data?.forEach((datum, i) => {
        const date = dateFromISO(datum.date);
        const dateString = dateStringFromISO(datum.date).slice(-2);

        labels.push(`${monthLabels[date.month - 1]} '${dateString}`);
        values.push(datum.value);

        const valueGreaterThanPrevious = datum.value > data[i - 1]?.value;
        const sameAsPrevious = datum.value === data[i - 1]?.value;
        const direction = reverse
            ? !valueGreaterThanPrevious
            : valueGreaterThanPrevious;
        backgroundColor.push(
            i === 0 || sameAsPrevious
                ? 'grey'
                : direction
                ? '#00c852'
                : '#ff6f00'
        );
    });

    return {
        labels: limit ? labels.slice(-limit) : labels,
        datasets: [
            {
                data: limit ? values.slice(-limit) : values,
                end: end,
                commentary:
                    data?.map((item) => {
                        return {
                            value:
                                scoreType === ScoreType.Percentage
                                    ? `${item.value}%`
                                    : scoreType === ScoreType.Currency
                                    ? `${getCurrencySymbol()}${item.value}`
                                    : item.value,
                            comment: item.comment ?? '',
                            authorInfo: `${getUserName(
                                users,
                                item.commentAuthor
                            )}, ${shortDateStringFromISO(
                                item.commentDate ?? ''
                            )}`,
                        };
                    }) ?? [],
                backgroundColor,
            },
        ],
    };
};

export const createPieChartData = (
    labels: string[],
    data: number[],
    defaultLabel?: string,
    colors?: string[]
): ChartData<'pie'> => {
    const positiveColors = ['#00c852', '#055765', '#02838f'];
    //const negativeColors = ['#aa0000', '#dd2c01', '#ff6f00', '#f3ae3d'];

    if (defaultLabel && labels.includes(defaultLabel)) {
        positiveColors.unshift('#cccccc');
    }

    return {
        labels,
        datasets: [
            {
                data,
                backgroundColor: colors ? colors : positiveColors,
            },
        ],
    };
};
export const createBubbleChartData = (
    labels: string[],
    data: CustomDataPoint[][],
    colorMap: { [key: string]: string },
    categoryColor: string,
    thumbnail: boolean,
    nodeSizeOption: NodeSizeOptions,
    nodeColorOption: NodeColorOptions
) => {
    const allValues = data.flat().map((dp) => dp.r);
    const maxValue = Math.max(...allValues);

    const datasets = data.map((dataset, index) => ({
        label: labels[index],
        data: dataset.map((dp) => {
            let proportionalRadius = (dp.r / maxValue) * 30;
            if (
                nodeSizeOption === NodeSizeOptions.CAGR ||
                nodeSizeOption === NodeSizeOptions.TAM ||
                nodeSizeOption === NodeSizeOptions.SAM ||
                nodeSizeOption === NodeSizeOptions.Maturity
            ) {
                return {
                    ...dp,
                    r: thumbnail
                        ? Math.min(proportionalRadius, 20)
                        : proportionalRadius,
                };
            } else {
                return {
                    ...dp,
                    r: thumbnail ? Math.min(proportionalRadius, 20) : dp.r,
                };
            }
        }),
        backgroundColor: dataset.map((dp) =>
            nodeColorOption === NodeColorOptions.DisableNodeColor
                ? categoryColor
                : dp.name === ''
                ? 'grey'
                : colorMap[dp.name]
        ),
    }));

    const chartData = {
        datasets: datasets,
    };
    return chartData;
};

const adjustBrightness = (color: string, percent: number) => {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = Math.min(255, Math.floor((R * (100 + percent)) / 100));
    G = Math.min(255, Math.floor((G * (100 + percent)) / 100));
    B = Math.min(255, Math.floor((B * (100 + percent)) / 100));

    const RR = R.toString(16).padStart(2, '0');
    const GG = G.toString(16).padStart(2, '0');
    const BB = B.toString(16).padStart(2, '0');

    return '#' + RR + GG + BB;
};

export const generateColorShades = (
    mainColor: string,
    numberOfShades: number
) => {
    const shades = [mainColor];

    if (numberOfShades > 1) {
        const halfPoint = Math.ceil(numberOfShades / 2);
        const step = 80 / halfPoint;

        for (let i = 1; i < halfPoint; i++) {
            const shade = adjustBrightness(mainColor, step * i);
            shades.push(shade);
        }

        for (let i = 1; i <= numberOfShades - halfPoint; i++) {
            const shade = adjustBrightness(mainColor, -step * i);
            shades.push(shade);
        }
    }

    return shades;
};

export const generateGreenShades = (colorsArray: string[]) => {
    const greenColor = [0, 255, 0];
    const maxNumber = 10;
    const totalRange = 255;
    const minGreenValue = 1;
    const stepSize = Math.floor((totalRange - minGreenValue) / maxNumber);

    const colors = colorsArray.map((value) => {
        const number = parseInt(value, 10);
        if (number === 0) {
            return 'grey';
        } else if (!isNaN(number) && number > 0 && number <= maxNumber) {
            const greenValue = Math.max(
                minGreenValue,
                greenColor[1] - stepSize * number
            );
            const colorString = `rgba(${greenColor[0]}, ${greenValue}, ${greenColor[2]}, 0.8)`;

            return colorString;
        } else {
            return 'grey';
        }
    });

    return colors;
};

export const getPlaybookLineChartData = (data: ScoreDatum[]) => {
    let scores = data.slice(0, 6);

    if (scores.length === 6 || scores.length === 0) return scores;

    let total = 6 - scores.length;

    for (let x = 0; x < total; x++) {
        let first = {
            ...scores[0],
            comment: null,
            commentDate: null,
            commentAuthor: null,
        };

        scores.unshift(first);
    }

    return scores;
};

export const createRadarChartData = (
    labels: string[],
    cards: Card[],
    scoreDefinitions: ScoreDefinition[],
    data: CustomDataPoint[][],
    cardsArray: CardScoreData[] | undefined
): ChartData<'radar'> => {
    const colorsArray =
        cards.length <= 17
            ? defaultColorsLowTransparency
            : generateRandomColors(cards.length);
    const datasets = cards.map((card, index) => {
        const data = labels.map((label) => {
            const scoreDef = scoreDefinitions.find((sd) => sd.name === label);
            if (!scoreDef) return null;
            const item = card.toScoreData?.items?.find(
                (item) => item?.scoreDefinitionId === scoreDef.id
            );
            if (item && item.data) {
                let value = item.data.reduce(
                    (acc, dataItem) => acc + (dataItem.value ?? 0),
                    0
                );
                if (scoreDef.scoreType === ScoreType.Percentage) {
                    value /= 10;
                }

                return value;
            } else {
                return null;
            }
        });

        const color = colorsArray[index % defaultColors.length];
        const cardWithScores = cardsArray?.find(
            (item) => item.originalCard.id === card.id
        );

        return {
            id: card.id,
            label: card.name,
            originalCard: card,
            card: cardWithScores,
            data: data,
            fill: true,
            backgroundColor: color,
            borderColor: color,
            pointBackgroundColor: color,
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: color,
        };
    });

    return {
        labels: labels,
        datasets: datasets,
    };
};

