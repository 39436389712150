import { PropsWithChildren, useEffect } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import PageAppBar from '../PageAppBar';
import TileViewIcon from '../../assets/images/menubar/grid-view-unselected.png';
import TileViewIconActive from '../../assets/images/menubar/grid-view.png';
import ListViewIcon from '../../assets/images/menubar/list-view-unselected.png';
import ListViewIconActive from '../../assets/images/menubar/list-view.png';
import AnalyzeIcon from '../../assets/images/menubar/analyze-unselected.png';
import AnalyzeIconActive from '../../assets/images/menubar/analyze.png';
import { useNavigate } from 'react-router-dom';
import { signOut } from '@aws-amplify/auth';
import LogoutIcon from '@mui/icons-material/Logout';

export enum ViewType {
    GRID = 'grid',
    LIST = 'list',
    FEATURE = 'feature',
    ANALYZE = 'analyze',
}

export interface ViewOptions {
    [ViewType.GRID]?: boolean;
    [ViewType.LIST]?: boolean;
    [ViewType.ANALYZE]?: boolean;
}

interface PageViewProps {
    selectedViewType: ViewType;
    title: string;
    disableAppBar?: boolean;
    viewOptions?: ViewOptions;
    setViewOption?: (viewType: ViewType) => void;
    fullWidth?: boolean;
}

const PageView = ({
    children,
    disableAppBar,
    selectedViewType,
    setViewOption,
    title,
    viewOptions,
    fullWidth,
}: PropsWithChildren<PageViewProps>) => {
    useEffect(() => {
        if (
            setViewOption &&
            viewOptions &&
            !(selectedViewType in viewOptions)
        ) {
            setViewOption(ViewType.GRID);
        }
    }, [selectedViewType, viewOptions]);

    const navigate = useNavigate();

    const handleSignout = async () => {
        await signOut();
        navigate('/');
    };
    const iconStyles = { width: 40, height: 40, display: 'inline' };
    return (
        <>
            {!disableAppBar && (
                <PageAppBar title={title}>
                    {!!setViewOption && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            {viewOptions?.[ViewType.GRID] && (
                                <Tooltip title="Grid view">
                                    <IconButton
                                        aria-label="Grid View"
                                        onClick={() =>
                                            setViewOption(ViewType.GRID)
                                        }
                                    >
                                        <img
                                            src={
                                                selectedViewType ===
                                                ViewType.GRID
                                                    ? TileViewIconActive
                                                    : TileViewIcon
                                            }
                                            style={iconStyles}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {viewOptions?.[ViewType.LIST] && (
                                <Tooltip title="List view">
                                    <IconButton
                                        aria-label="List View"
                                        onClick={() =>
                                            setViewOption(ViewType.LIST)
                                        }
                                    >
                                        <img
                                            src={
                                                selectedViewType ===
                                                ViewType.LIST
                                                    ? ListViewIconActive
                                                    : ListViewIcon
                                            }
                                            style={iconStyles}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {viewOptions?.[ViewType.ANALYZE] && (
                                <Tooltip title="Analyze view">
                                    <IconButton
                                        aria-label="Analyze View"
                                        onClick={() =>
                                            setViewOption(ViewType.ANALYZE)
                                        }
                                    >
                                        <img
                                            src={
                                                selectedViewType ===
                                                ViewType.ANALYZE
                                                    ? AnalyzeIconActive
                                                    : AnalyzeIcon
                                            }
                                            style={iconStyles}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    )}
                    {title === 'Profile' && (
                        <Tooltip title="Logout">
                            <IconButton
                                aria-label="Log out"
                                onClick={handleSignout}
                            >
                                <LogoutIcon style={{ color: 'grey' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                </PageAppBar>
            )}
            <Box
                component={'main'}
                sx={{
                    minHeight: '100vh',
                    px: fullWidth ? 0 : 8,
                    maxWidth: fullWidth ? '100%' : '103rem',
                    m: '0 auto',
                    pt: 6,
                }}
            >
                {children}
            </Box>
        </>
    );
};

export default PageView;
