import React, { useContext, useEffect, useRef, useState } from 'react';
import BubbleChart, { CustomDataPoint } from '../../charts/BubbleChart';
import { Box, Button, IconButton, Modal, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { generateGreenShades } from '../../../helpers/charts';
import CustomLegend from '../../charts/CustomLegend';
import {
    AttributeType,
    Card,
    PlaybookPageDataResponse,
    ScoreType,
} from '../../../API';
import { AppContext, WorksheetContext } from '../../contexts';
import {
    AxisOptions,
    NodeColorOptions,
    NodeSizeOptions,
} from '../playbookTypes';
import {
    CardData,
    CardScoreData,
    calculateBubbleRadius,
    calculateBubbleValue,
    calculateScoreValues,
    checkGaps,
    getAttributeCard,
    getChartSettings,
    getMaxValue,
    processCardData,
} from '../../../helpers/worksheets';
import { Attribute } from '../../../API';
import { defaultColors, getCategoryHex } from '../../../helpers/category';
import BubbleModal from './BubbleModal';
import { Node } from '../../charts/BubbleChart';
import BubbleKebabMenu from '../../workbooks/BubbleKebabMenu';
import { worksheets } from '../../forms/worksheets';
import { UserPermissions } from '../../../globals';
import { BubbleDataPoint, Chart } from 'chart.js';
import { ViewType } from '../../layouts/PageView';
import { PageIdentifier } from '../../cards/cardTypes';
import CardListView from '../../cards/views/CardListView';
import { cardToCardComponentProps } from '../../../pages/cards';

interface BubblePageProps {
    cards: Card[];
    page: any;
    setCardType?: () => void;
    thumbnail: boolean;
    fullWidth?: boolean;
    workbook?: boolean;
    carousel?: boolean;
    pageOptions?: any;
    worksheetId?: string;
    permissions?: UserPermissions[] | undefined;
    pptView?: boolean;
    cardTypeId?: string;
    addCardToWorkbook?: (card: Card, addToWorksheet: boolean) => Promise<void>;
    setActiveCardId?: React.Dispatch<React.SetStateAction<string | null>>;
    rightPanel?: boolean;
    setRightPanel?: React.Dispatch<React.SetStateAction<boolean>>;
    selectedViewType?: ViewType;
    cardsObject?: {
        [key: string]: Card;
    };
    pageIdentifier?: PageIdentifier;
    tableStyle?: any;
    handleTableRowClick?: (id: string) => void;
}
export interface ColorMapping {
    [key: string]: string;
}
export interface AttributeCards {
    [key: string]: string | undefined;
}
interface IAxisOptionsSubset<T> {
    [key: string]: T;
}

export const removeQuotes = (str: string) => {
    let valueToDisplay;
    if (str.startsWith('"') && str.endsWith('"')) {
        valueToDisplay = str.slice(1, -1);
    }
    return valueToDisplay || str;
};
const BubblePage = ({
    cards,
    page,
    thumbnail,
    fullWidth,
    workbook,
    carousel,
    setCardType,
    pageOptions,
    worksheetId,
    permissions,
    pptView,
    cardTypeId,
    addCardToWorkbook,
    setActiveCardId,
    rightPanel,
    setRightPanel,
    selectedViewType,
    cardsObject,
    pageIdentifier,
    tableStyle,
    handleTableRowClick,
}: BubblePageProps) => {
    const {
        handleDelete,
        handleRemoveFromWorkbook,
        handleRemoveFromWorksheet,
        handleCopy,
    } = useContext(WorksheetContext);
    const menuItems: any = [];
    const [menuCard, setMenuCard] = useState<Card | null>(null);
    const theme = useTheme();
    const [localPageOptions, setLocalPageOptions] = useState(pageOptions);
    const { cardTypeObject } = useContext(AppContext);
    const scoreDefinitions =
        cardTypeObject[cards[0]?.cardToCardTypeId]?.scoreDefinitions;
    const attributeDefinitions =
        cardTypeObject[cards[0]?.cardToCardTypeId]?.attributeDefinitions;
    const [cardsArrayState, setCardsArrayState] = useState<CardScoreData[]>();
    const [chartData, setChartData] = useState<CustomDataPoint[][]>([]);
    const [legendLabels, setLegendLabels] = useState<string[]>([]);
    const [nodeColorSelection, setNodeColorSelection] = useState<any>();
    const [attributeType, setAttributeType] = useState<AttributeType>();
    const [attributeCards, setAttributeCards] = useState<AttributeCards>();
    const [hideGaps, setHideGaps] = useState<boolean>();
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const bubbleRef = useRef<Chart<
        'bubble',
        BubbleDataPoint[],
        unknown
    > | null>(null);

    const [nodes, setNodes] = useState<Node[]>([]);
    const [selectedBubble, setSelectedBubble] =
        useState<CustomDataPoint | null>(null);
    const [selectedCard, setSelectedCard] = useState<Card | null>(null);
    const [allScores, setAllScores] = useState<string[]>();
    const [allAttributes, setAllAttributes] = useState<string[]>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [xAxisOptions, setXAxisOptions] = useState<any>({
        lowTickLabel: 'Very low',
        highTickLabel: 'Very high',
        title: localPageOptions?.chartData?.xAxis,
        scoreType: ScoreType.Number,
    });
    const [yAxisOptions, setYAxisOptions] = useState<any>({
        lowTickLabel: 'Very low',
        highTickLabel: 'Very high',
        title: localPageOptions?.chartData?.yAxis,
        scoreType: ScoreType.Number,
    });
    const categoryColor = getCategoryHex(cards[0]?.cardToCardCategoryId);
    const [colors, setColors] = useState(defaultColors);
    if (
        handleRemoveFromWorkbook &&
        permissions?.includes(UserPermissions.EDIT)
    ) {
        menuItems.push({
            text: 'Remove from workbook',
            action: () => {
                menuCard && handleRemoveFromWorkbook(menuCard);
            },
        });
    }
    if (
        handleRemoveFromWorksheet &&
        permissions?.includes(UserPermissions.EDIT)
    ) {
        menuItems.push({
            text: 'Remove from worksheet',
            action: () => {
                menuCard && handleRemoveFromWorksheet(menuCard.id);
            },
        });
    }
    if (permissions?.includes(UserPermissions.DELETE)) {
        menuItems.push({
            text: 'Delete',
            action: () => {
                menuCard && handleDelete(menuCard);
            },
        });
    }
    if (handleCopy) {
        menuItems.push({
            text: 'Copy',
            action: () => {
                menuCard && handleCopy(menuCard, page?.id);
            },
        });
    }
    const zoomStateRef = useRef<{
        xMin: number | undefined;
        xMax: number | undefined;
        yMin: number | undefined;
        yMax: number | undefined;
    }>({
        xMin: undefined,
        xMax: undefined,
        yMin: undefined,
        yMax: undefined,
    });

    useEffect(() => {
        if (page && page.options && thumbnail) {
            setLocalPageOptions(JSON.parse(page.options));
        } else {
            setLocalPageOptions(pageOptions);
        }
        const worksheet = worksheets.find((item) => item.i === page?.typeId);
        const settings = getChartSettings(worksheet?.n || '');
        setAllScores(settings?.options?.chartData?.xAxisOptions);
        setAllAttributes(settings?.options.chartData.nodeColor.selection);
    }, [page, pageOptions, worksheetId]);

    useEffect(() => {
        const xScore = scoreDefinitions?.find(
            (item) => item.name === localPageOptions?.chartData?.xAxis
        );
        const yScore = scoreDefinitions?.find(
            (item) => item.name === localPageOptions?.chartData?.yAxis
        );
        setXAxisOptions({
            lowTickLabel: 'Very low',
            highTickLabel: 'Very high',
            title: localPageOptions?.chartData?.xAxis,
            scoreType: xScore?.scoreType,
        });
        setYAxisOptions({
            lowTickLabel: 'Very low',
            highTickLabel: 'Very high',
            title: localPageOptions?.chartData?.yAxis,
            scoreType: yScore?.scoreType,
        });
        if (!page?.options) return;
        if (JSON.parse(page?.options).chartData?.hideGaps) {
            const displayGaps = checkGaps(localPageOptions);
            setHideGaps(displayGaps);
        }
    }, [localPageOptions, cards]);

    useEffect(() => {
        const getData = async () => {
            if (
                localPageOptions?.chartData?.nodeColor?.selection ===
                NodeColorOptions.DisableNodeColor
            ) {
                setNodeColorSelection(NodeColorOptions.DisableNodeColor);
                setLegendLabels([]);
                return;
            }
            const cardsArray = processCardData(cards, scoreDefinitions);
            if (localPageOptions && attributeDefinitions) {
                const currentAttributeDef = attributeDefinitions.find(
                    (item) =>
                        item?.name ===
                        localPageOptions?.chartData?.nodeColor?.selection
                );
                const currentAttributeId = currentAttributeDef?.id;
                setAttributeType(currentAttributeDef?.attributeType);
                const attributeType = currentAttributeDef?.attributeType;

                if (currentAttributeId) {
                    setNodeColorSelection(currentAttributeId);
                    const newAttributeCards: AttributeCards = {};
                    const promises = (cardsArray || []).flatMap((card) =>
                        card.attributes
                            ? card.attributes
                                  .filter(
                                      (attribute: Attribute) =>
                                          attribute !== null &&
                                          attribute.attributeDefinitionID ===
                                              currentAttributeId
                                  )
                                  .map(async (attribute: Attribute) => {
                                      if (
                                          attributeType ===
                                              AttributeType.Card &&
                                          attribute.value
                                      ) {
                                          return getAttributeCard(
                                              attribute.value
                                          )
                                              .then((attributeCard) => {
                                                  if (!attribute.value) return;
                                                  newAttributeCards[
                                                      attribute.value
                                                  ] = attributeCard;
                                                  return {
                                                      attributeDefinitionID:
                                                          attribute.attributeDefinitionID,
                                                      value: attributeCard,
                                                  };
                                              })
                                              .catch((error) => {
                                                  console.error(error);
                                                  return {
                                                      attributeDefinitionID:
                                                          attribute.attributeDefinitionID,
                                                      value: null,
                                                  };
                                              });
                                      } else {
                                          return Promise.resolve({
                                              attributeDefinitionID:
                                                  attribute.attributeDefinitionID,
                                              value: attribute.value,
                                          });
                                      }
                                  })
                            : []
                    );

                    Promise.all(promises.flat()).then((extractedAttributes) => {
                        const labels = Array.from(
                            new Set(
                                extractedAttributes
                                    .map((item) => item?.value)
                                    .filter(
                                        (value) =>
                                            value !== null &&
                                            value !== undefined
                                    )
                                    .map(removeQuotes)
                            )
                        );
                        setLegendLabels(labels);
                        setAttributeCards(newAttributeCards);
                    });
                }
            }
        };
        getData();
    }, [localPageOptions, attributeDefinitions]);

    const labels = ['Dataset 1', 'Dataset 2', 'Dataset 3'];
    const axisOptionsSubset: IAxisOptionsSubset<AxisOptions> = {
        PlanCost: AxisOptions.PlanCost,
        Value: AxisOptions.Value,
        AnnualContractValue: AxisOptions.AnnualContractValue,
        CustomerLifetimeValue: AxisOptions.CustomerLifetimeValue,
        CustomerSatisfaction: AxisOptions.CustomerSatisfaction,
    };
    const getMaxValuesForNodeRadius = (cardsArray: any[]) => {
        const maxValues: { [key: string]: number } = {};
        Object.keys(axisOptionsSubset).forEach((axis) => {
            maxValues[axis] = getMaxValue(
                cardsArray.map(
                    (card) =>
                        card.scoreData[axisOptionsSubset[axis]]?.value || 0
                )
            );
        });
        return maxValues;
    };

    useEffect(() => {
        const cardsArray = processCardData(cards, scoreDefinitions);
        const maxValues = getMaxValuesForNodeRadius(cardsArray);

        const updatedCardsArray: CardScoreData[] = [];

        const newChartData: CustomDataPoint[][] = cardsArray?.reduce(
            (accumulatedData, card) => {
                const xValue = calculateBubbleValue(
                    localPageOptions?.chartData?.xAxis,
                    card.scoreData,
                    localPageOptions?.chartData?.xAxis,
                    localPageOptions
                );
                const yValue = calculateBubbleValue(
                    localPageOptions?.chartData?.yAxis,
                    card.scoreData,
                    localPageOptions?.chartData?.yAxis,
                    localPageOptions
                );
                const radius = calculateBubbleRadius(
                    localPageOptions?.chartData?.nodeSize.selection,
                    card.scoreData,
                    thumbnail,
                    maxValues
                );

                if (
                    !(
                        (hideGaps && (xValue === 0 || yValue === 0)) ||
                        (hideGaps && radius <= 0)
                    )
                ) {
                    let nameAttr: string = '';
                    if (card.attributes) {
                        let attribute = card.attributes.find(
                            (item: any) =>
                                item?.attributeDefinitionID ===
                                nodeColorSelection
                        );

                        if (attribute && attribute.value) {
                            attributeType === AttributeType.Card
                                ? (nameAttr = JSON.stringify(
                                      removeQuotes(attribute.value)
                                  ))
                                : (nameAttr = removeQuotes(attribute.value));
                        }
                    }
                    const name =
                        localPageOptions?.chartData?.nodeColor?.selection ===
                        NodeColorOptions.StrategicImportance
                            ? card?.scoreData[
                                  NodeColorOptions.StrategicImportance
                              ]?.value?.toString()
                            : localPageOptions?.chartData?.nodeColor
                                  ?.selection ===
                              NodeColorOptions.CompetitiveIntensity
                            ? card?.scoreData[
                                  NodeColorOptions.CompetitiveIntensity
                              ]?.value?.toString()
                            : localPageOptions?.chartData?.nodeColor
                                  ?.selection === NodeColorOptions.Risk
                            ? card?.scoreData[
                                  NodeColorOptions.Risk
                              ]?.value?.toString()
                            : attributeType === AttributeType.Card
                            ? attributeCards?.[nameAttr] ?? ''
                            : nameAttr;

                    const index = accumulatedData.length;

                    accumulatedData.push([
                        {
                            label: card.name,
                            x: xValue,
                            y: yValue,
                            r: radius,
                            name: name,
                            card: card,
                            originalCard: card.originalCard,
                        },
                    ]);

                    updatedCardsArray.push(card);
                }

                return accumulatedData;
            },
            [] as CustomDataPoint[][]
        );

        const strategicImportanceValues = calculateScoreValues(
            AxisOptions.StrategicImportance,
            cardsArray
        );
        const competitiveValues = calculateScoreValues(
            AxisOptions.CompetitiveIntensity,
            cardsArray
        );

        const riskValues = calculateScoreValues(AxisOptions.Risk, cardsArray);

        if (
            localPageOptions?.chartData?.nodeColor?.selection ===
            NodeColorOptions.StrategicImportance
        ) {
            setLegendLabels(strategicImportanceValues);
            const generatedColors = generateGreenShades(
                strategicImportanceValues
            );
            setColors(generatedColors);
            setNodeColorSelection(NodeColorOptions.StrategicImportance);
        } else if (
            localPageOptions?.chartData?.nodeColor?.selection ===
            NodeColorOptions.CompetitiveIntensity
        ) {
            setLegendLabels(competitiveValues);
            const generatedColors = generateGreenShades(competitiveValues);
            setColors(generatedColors);
            setNodeColorSelection(NodeColorOptions.CompetitiveIntensity);
        } else if (
            localPageOptions?.chartData?.nodeColor?.selection ===
            NodeColorOptions.Risk
        ) {
            setLegendLabels(riskValues);
            const generatedColors = generateGreenShades(riskValues);
            setColors(generatedColors);
            setNodeColorSelection(NodeColorOptions.Risk);
        } else {
            setColors(defaultColors);
        }
        setChartData(newChartData);
        setCardsArrayState(updatedCardsArray);
    }, [
        cards,
        localPageOptions,
        thumbnail,
        hideGaps,
        nodeColorSelection,
        attributeType,
        attributeCards,
    ]);

    const colorMapping: ColorMapping = {};

    legendLabels.forEach((label, index) => {
        colorMapping[label] = colors[index % colors.length];
    });

    const thumbnailStyles = {
        pl: 2,
    };
    const chartRef = useRef<HTMLDivElement>(null);

    const handleBubbleHover = (
        data: CustomDataPoint,
        position: { top: number; left: number }
    ) => {
        setSelectedBubble(data);
        setSelectedCard(data.originalCard);
        const chartBounds = chartRef.current?.getBoundingClientRect();
        const modalWidth = 250;
        const chartRightEdge = chartBounds
            ? chartBounds.right
            : window.innerWidth;
        const isCloseToRightEdge = position.left + modalWidth > chartRightEdge;
        let adjustedLeft = position.left;
        if (isCloseToRightEdge) {
            adjustedLeft = position.left - modalWidth;
        }
        setIsModalOpen(true);
        setModalPosition({ top: position.top, left: adjustedLeft });
    };
    const handleBubbleClick = (data: CustomDataPoint) => {
        setActiveCardId && setActiveCardId(data.card.originalCard.id);
        setRightPanel && setRightPanel(true);
    };
    const onGenerateData = (data: Node[]) => {
        if (workbook) {
            setNodes(data);
        }
    };
    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add('body-scroll-lock');
        } else {
            document.body.classList.remove('body-scroll-lock');
        }
    }, [isModalOpen]);
    const handleResetZoom = () => {
        if (bubbleRef.current) {
            bubbleRef.current.resetZoom();
        }
    };
    return selectedViewType === ViewType.LIST ? (
        <Box sx={{ height: '100%' }}>
            <CardListView
                cardItems={
                    cards?.map((card) => cardToCardComponentProps(card)) || []
                }
                cardsObject={cardsObject}
                pageIdentifier={pageIdentifier}
                tableStyle={tableStyle}
                worksheetRowClick={handleTableRowClick}
                worksheetMenuItems={menuItems}
                setMenuCard={setMenuCard}
            />
        </Box>
    ) : (
        <Box
            sx={{
                display: fullWidth ? 'flex' : 'initial',
                flexDirection: rightPanel
                    ? 'column-reverse'
                    : fullWidth
                    ? 'row-reverse'
                    : 'initial',
                justifyContent: 'flex-end',
                mt: pptView ? 2 : 0,
            }}
        >
            {!fullWidth && (
                <Box
                    sx={
                        thumbnail
                            ? thumbnailStyles
                            : { display: 'flex', flexDirection: 'column' }
                    }
                >
                    {workbook &&
                        permissions?.includes(UserPermissions.EDIT) && (
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '45px',
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 30,
                                        borderRadius: '50px',
                                        border: 'dashed 2px blue',
                                    }}
                                    onClick={() => setCardType && setCardType()}
                                >
                                    <AddIcon
                                        sx={{
                                            fontSize: '3rem',
                                            width: '25px',
                                            height: '23px',
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            position: 'relative',
                            height: !thumbnail ? '500px' : '200px',
                        }}
                        ref={chartRef}
                    >
                        <BubbleChart
                            setSelectedCard={setSelectedCard}
                            labels={labels}
                            data={chartData}
                            colors={colorMapping}
                            xAxisOptions={xAxisOptions}
                            yAxisOptions={yAxisOptions}
                            displayGrid={
                                localPageOptions?.layout &&
                                localPageOptions?.layout[0].layout?.find(
                                    (item: { value: string }) =>
                                        item.value === 'grid'
                                )?.enabled
                                    ? true
                                    : false
                            }
                            thumbnail={thumbnail}
                            categoryColor={categoryColor}
                            onBubbleClick={handleBubbleClick}
                            {...(workbook && {
                                onGenerateData: onGenerateData,
                            })}
                            nodes={nodes}
                            nodeSizeOption={
                                localPageOptions?.chartData?.nodeSize
                                    .selection ||
                                NodeSizeOptions.DefaultNodeSize
                            }
                            workbook={workbook}
                            carousel={carousel}
                            nodeColorOption={
                                localPageOptions?.chartData?.nodeColor.selection
                            }
                            pptView={pptView}
                            cardTypeId={cardTypeId}
                            chartRef={bubbleRef}
                            zoomStateRef={zoomStateRef}
                            handleBubbleHover={handleBubbleHover}
                        />
                        {!thumbnail && (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleResetZoom}
                                sx={{ mb: 2, mr: 4 }}
                            >
                                Reset zoom
                            </Button>
                        )}
                    </Box>
                </Box>
            )}
            <Box
                sx={{
                    pl: !thumbnail ? '4rem' : '2.3rem',
                    mt: thumbnail ? -1.5 : '',
                    minWidth: fullWidth ? '25%' : 'inherit',
                }}
            >
                {localPageOptions?.chartData?.nodeColor?.selection !==
                    NodeColorOptions.DisableNodeColor && (
                    <CustomLegend
                        labels={legendLabels}
                        colors={colors}
                        title={
                            localPageOptions &&
                            localPageOptions?.chartData?.nodeColor?.selection
                        }
                        sx={{ color: theme.palette.grey[600] }}
                        thumbnail={thumbnail}
                        chartData={chartData}
                        workbook={workbook}
                        pptView={pptView}
                        rightPanel={rightPanel}
                        width={'100%'}
                    />
                )}
            </Box>
            {fullWidth && (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        position: 'relative',
                        mr: -5,
                    }}
                >
                    <BubbleChart
                        setSelectedCard={setSelectedCard}
                        labels={labels}
                        data={chartData}
                        colors={colorMapping}
                        xAxisOptions={xAxisOptions}
                        yAxisOptions={yAxisOptions}
                        displayGrid={
                            localPageOptions?.layout &&
                            localPageOptions?.layout[0].enabled
                        }
                        thumbnail={thumbnail}
                        categoryColor={categoryColor}
                        onBubbleClick={handleBubbleClick}
                        {...(workbook && {
                            onGenerateData: onGenerateData,
                        })}
                        nodes={nodes}
                        nodeSizeOption={
                            localPageOptions?.chartData?.nodeSize.selection ||
                            NodeSizeOptions.DefaultNodeSize
                        }
                        workbook={workbook}
                        carousel={carousel}
                        nodeColorOption={
                            localPageOptions?.chartData?.nodeColor.selection
                        }
                        pptView={pptView}
                        cardTypeId={cardTypeId}
                        chartRef={bubbleRef}
                        zoomStateRef={zoomStateRef}
                        handleBubbleHover={handleBubbleHover}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleResetZoom}
                        sx={{ mb: 2, mr: 4 }}
                    >
                        Reset zoom
                    </Button>
                </Box>
            )}
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                style={{
                    top: modalPosition.top,
                    left: modalPosition.left,
                    position: 'absolute',
                }}
                sx={{
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                    },
                }}
            >
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        width: '14rem',
                        borderRadius: '8px',
                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
                        '&:focus-visible': {
                            outline: 'none',
                        },
                    }}
                >
                    {selectedBubble && selectedCard && (
                        <BubbleModal
                            node={selectedBubble}
                            attributeDefinitions={attributeDefinitions}
                            setIsModalOpen={setIsModalOpen}
                            allScores={allScores}
                            allAttributes={allAttributes}
                            selectedCard={selectedCard}
                            workbook={workbook}
                        />
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default BubblePage;
