import { Box, Tooltip, Typography } from '@mui/material';
import { ScoreDatum } from '../../API';
import { useTheme } from '@mui/material';
import {
    getMonth,
    shortDateStringFromISO,
    truncateText,
} from '../../helpers/utils';
import { useContext } from 'react';
import { AppContext } from '../contexts';
import { getUserName } from '../../helpers/charts'; 

interface IndicatorChartProps {
    data: ScoreDatum[];
}

export const ToolTipContent = ({ item }: { item: ScoreDatum }) => {
    const theme = useTheme();
    const { users } = useContext(AppContext);

    return (
        <Box
            sx={{
                width: 'auto',
                marginLeft: '8px',
                marginRight: '8px',
                marginTop: '8px',
                marginBottom: '8px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '5px',
                        background:
                            item.value === 0
                                ? theme.palette.grey[700]
                                : item.value === 1
                                ? theme.palette.warning.dark
                                : item.value === 2
                                ? theme.palette.warning.light
                                : theme.palette.success.light,
                        border: item.value === 0 ? 'solid 2px #ffffff' : 'none',
                    }}
                />
            </Box>
            <Box
                sx={{
                    color: '#fff',
                    fontSize: '14px',
                    marginTop: '10px',
                }}
            >
                {truncateText(item.comment ?? '', 100)}
            </Box>
            <Box
                sx={{
                    color: '#fff',
                    fontSize: '13px',
                    marginTop: '10px',
                }}
            >
                {`${getUserName(users, item.commentAuthor)},
        
        ${shortDateStringFromISO(item.commentDate ?? '')}`}
            </Box>
        </Box>
    );
};

const IndicatorChart = ({ data }: IndicatorChartProps) => {
    const theme = useTheme();
    let items = data.slice(-6);
    const { users } = useContext(AppContext);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: `solid 1px ${theme.palette.grey[400]}`,
                    pb: 1.3,
                }}
            >
                {items.map((item) => {
                    return (
                        <>
                            {item.comment ? (
                                <Tooltip
                                    title={<ToolTipContent item={item} />}
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                background:
                                                    'rgba(0, 0, 0, 0.6)',
                                                width: '250px',
                                                borderRadius: '8px',
                                            },
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '30px',
                                            height: '30px',
                                            background:
                                                item.value === 0
                                                    ? theme.palette.grey[700]
                                                    : item.value === 1
                                                    ? theme.palette.warning.dark
                                                    : item.value === 2
                                                    ? theme.palette.warning
                                                          .light
                                                    : theme.palette.success
                                                          .light,
                                            borderRadius: '6px',
                                        }}
                                    />
                                </Tooltip>
                            ) : (
                                <Box
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                        background:
                                            item.value === 0
                                                ? theme.palette.grey[700]
                                                : item.value === 1
                                                ? theme.palette.warning.dark
                                                : item.value === 2
                                                ? theme.palette.warning.light
                                                : theme.palette.success.light,
                                        borderRadius: '6px',
                                    }}
                                />
                            )}
                        </>
                    );
                })}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: 1.3,
                }}
            >
                {items.map((item) => {
                    return (
                        <Typography
                            variant="body2"
                            marginBottom={1}
                            sx={{ fontSize: '0.750rem' }}
                        >
                            {getMonth(item.date, 'short')}
                        </Typography>
                    );
                })}
            </Box>
        </Box>
    );
};

export default IndicatorChart;
