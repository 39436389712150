import { Dispatch, SetStateAction, createContext } from 'react';
import {
    AuditEntry,
    CardType,
    CardCategory,
    UserProfile,
    GetOrganisationQuery,
    Organisation,
    Card,
} from '../API';
import { AuthSession } from '../globals';

import { ViewType } from './layouts/PageView';
import { INotification, Unread } from './notifications';
import { HoverPosition } from './worksheets/WorksheetBottomPanel';

export const AppContext = createContext<{
    cardTypes: CardType[];
    cardTypeObject: { [key: string]: CardType };
    cardCategoryObject?: { [key: string]: CardCategory };
    userProfileObject?: { [key: string]: UserProfile };
    setUserProfileObject?: Dispatch<
        SetStateAction<{ [key: string]: UserProfile } | undefined>
    >;
    userUpdated?: boolean;
    setUserUpdated?: Dispatch<SetStateAction<boolean>>;
    users: UserProfile[];
    activeUsers: UserProfile[];
    globalAdmin: string;
    getUsers?: () => void;
    showIllustrations?: boolean;
    setShowIllustrations?: Dispatch<SetStateAction<boolean>>;
    viewType: ViewType;
    setViewType: Dispatch<SetStateAction<ViewType>>;
    isGlobalAdmin: boolean;
    loaded?: boolean;
    setLoaded?: Dispatch<SetStateAction<boolean>>;
    applyFilter: boolean;
    setApplyFilter: Dispatch<SetStateAction<boolean>>;
    applySort: boolean;
    setApplySort: Dispatch<SetStateAction<boolean>>;
    organisationProfile: Organisation | null | undefined;
    setOrganisationProfile: React.Dispatch<
        React.SetStateAction<Organisation | null | undefined>
    >;
    user: AuthSession | null;
    setUser: Dispatch<SetStateAction<AuthSession | null>>;
    showNotifications?: boolean;
    setShowNotifications?: Dispatch<SetStateAction<boolean>>;
    userImages: { name: string; image: string }[];
    setUserImages: Dispatch<SetStateAction<{ name: string; image: string }[]>>;
}>({
    cardTypes: [],
    cardTypeObject: {},
    cardCategoryObject: {},
    userProfileObject: {},
    setUserProfileObject: () => {},
    userUpdated: false,
    setUserUpdated: () => {},
    users: [],
    activeUsers: [],
    globalAdmin: '',
    getUsers: () => {},
    showIllustrations: true,
    setShowIllustrations: () => {},
    viewType: ViewType.GRID,
    setViewType: () => {},
    isGlobalAdmin: false,
    loaded: false,
    setLoaded: () => {},
    applyFilter: false,
    setApplyFilter: () => {},
    applySort: false,
    setApplySort: () => {},
    organisationProfile: null,
    setOrganisationProfile: () => {},
    user: null,
    setUser: () => {},
    showNotifications: false,
    setShowNotifications: () => {},
    userImages: [],
    setUserImages: () => {},
});

export const NotificationContext = createContext<{
    showNotifications?: boolean;
    setShowNotifications?: Dispatch<SetStateAction<boolean>>;
    unreadNotifications: INotification[];
    setUnreadNotifications: Dispatch<SetStateAction<INotification[]>>;
    notifications: INotification[];
    setNotifications: Dispatch<SetStateAction<INotification[]>>;
    scoreNames: { name: string; id: string }[];
    setScoreNames: Dispatch<SetStateAction<{ name: string; id: string }[]>>;
    selectedNotification: INotification | null;
    setSelectedNotification: Dispatch<SetStateAction<INotification | null>>;
    modalOpen: boolean;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}>({
    showNotifications: false,
    setShowNotifications: () => {},
    unreadNotifications: [],
    setUnreadNotifications: () => {},
    notifications: [],
    setNotifications: () => {},

    scoreNames: [],
    setScoreNames: () => {},
    selectedNotification: null,
    setSelectedNotification: () => {},
    modalOpen: false,
    setModalOpen: () => {},
});

export const WorksheetContext = createContext<{
    worksheetCards: Card[];
    setWorksheetCards: Dispatch<SetStateAction<Card[]>>;
    handleDelete: (card: Card) => void;
    handleRemoveFromWorkbook?: (card: Card) => void;
    handleRemoveFromWorksheet?: (cardId: string) => void;
    handleCopy?: (card: Card, worksheetId?: string) => void;
    activeId: string | null;
    setActiveId: Dispatch<SetStateAction<string | null>>;
    selectedTypeId: string | null;
    setSelectedTypeId: Dispatch<SetStateAction<string | null>>;
    hoverId: string | null;
    setHoverId: Dispatch<SetStateAction<string | null>>;
    removeId: string | null;
    setRemoveId: Dispatch<SetStateAction<string | null>>;
    hoverPosition: HoverPosition | null;
    setHoverPosition: Dispatch<SetStateAction<HoverPosition | null>>;
}>({
    worksheetCards: [],
    setWorksheetCards: () => {},
    handleDelete: () => {},
    handleRemoveFromWorkbook: () => {},
    handleRemoveFromWorksheet: () => {},
    handleCopy: () => {},
    activeId: null,
    setActiveId: () => {},
    selectedTypeId: null,
    setSelectedTypeId: () => {},
    hoverId: null,
    setHoverId: () => {},
    removeId: null,
    setRemoveId: () => {},
    hoverPosition: null,
    setHoverPosition: () => {},
});

export const WorkbookContext = createContext<{
    handleDelete: (card: Card) => void;
    handleRemoveFromWorkbook?: (id: string) => void;
    handleCopy?: (card: Card, worksheetId?: string) => void;
}>({
    handleDelete: () => {},
    handleRemoveFromWorkbook: () => {},
    handleCopy: () => {},
});
