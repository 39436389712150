import { useEffect, useState } from 'react';
import '@aws-amplify/ui-react/styles.css';
import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { fetchAuthSession } from '@aws-amplify/auth';
import { AppContext } from './components/contexts';
import Layout from './components/layouts/Layout';

import {
    CardType,
    GetOrganisationQuery,
    GetUserProfileQuery,
    ModelSortDirection,
    Organisation,
    UserProfile,
} from './API';

import './styles/globals.css';
import RouterProvider from './routes';
import { BrowserRouter } from 'react-router-dom';

import { getUserOrganisation } from './helpers/auth';
import {
    getOrganisation,
    getUserProfile,
    listCardTypes,
    listUserProfiles,
} from '../src/graphql/queries';
import useAuthHub from './hooks/useAuthHub';
import { ViewType } from './components/layouts/PageView';

import theme from './themes';
import { generateClient } from 'aws-amplify/api';
import { AuthSession } from './globals';
import NotificationPanel from './components/notifications/NotificationPanel';
import { downloadData } from 'aws-amplify/storage';

const client = generateClient();

const App = () => {
    const [showIllustrations, setShowIllustrations] = useState<boolean>(() => {
        const storedValue = localStorage.getItem('showIllustrations');
        return storedValue !== null ? JSON.parse(storedValue) : true;
    });
    const [loaded, setLoaded] = useState<boolean>(false);
    const { userUpdated, setUserUpdated } = useAuthHub();
    const [cardTypeObject, setCardTypeObject] = useState<{
        [key: string]: CardType;
    }>({});
    const [userProfileObject, setUserProfileObject] = useState<
        { [key: string]: UserProfile } | undefined
    >(undefined);
    const [viewType, setViewType] = useState<ViewType>(ViewType.GRID);
    const [users, setUsers] = useState<UserProfile[]>([]);
    const [cardTypes, setCardTypes] = useState<CardType[]>([]);
    const [activeUsers, setActiveUsers] = useState<UserProfile[]>([]);
    const [showNotifications, setShowNotifications] = useState<UserProfile[]>(
        []
    );
    const [userImages, setUserImages] = useState<
        { name: string; image: string }[]
    >([]);
    const [globalAdmin, setGlobalAdmin] = useState<string>('');
    const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
    const [organisationProfile, setOrganisationProfile] =
        useState<Organisation | null>();
    const [applyFilter, setApplyFilter] = useState(false);
    const [applySort, setApplySort] = useState(false);

    const [user, setUser] = useState<AuthSession | null>(null);

    useEffect(() => {
        const getUser = async () => {
            const user = await fetchAuthSession();

            user && setUser(user as AuthSession);
        };
        getUser();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const userOrganisation = await getUserOrganisation(user);
                const getOrganisationInfo = async () => {
                    try {
                        const response = (await client.graphql({
                            query: getOrganisation,
                            variables: {
                                id: userOrganisation,
                            },
                        })) as { data: GetOrganisationQuery };

                        if (
                            response.data.getOrganisation?.owningUserProfileID
                        ) {
                            setOrganisationProfile(
                                response.data.getOrganisation
                            );
                            setGlobalAdmin(
                                response.data.getOrganisation
                                    ?.owningUserProfileID
                            );
                            setIsGlobalAdmin(
                                response.data.getOrganisation
                                    ?.owningUserProfileID ===
                                    user?.tokens?.idToken?.payload.sub
                            );
                        }
                    } catch (error) {
                        console.error(
                            'Error fetching user organisation info:',
                            error
                        );
                    }
                };
                const getUser = async () => {
                    try {
                        const response = (await client.graphql({
                            query: getUserProfile,
                            variables: {
                                id: user?.tokens?.idToken?.payload.sub ?? '',
                                organisation: userOrganisation,
                            },
                        })) as { data: GetUserProfileQuery };
                        if (response.data.getUserProfile) {
                            setUserProfileObject({
                                ['getUserProfile']:
                                    response.data.getUserProfile,
                            });
                            if (response.data.getUserProfile.preferences) {
                                const preferences = JSON.parse(
                                    response.data.getUserProfile.preferences
                                );
                                if (
                                    preferences.showIllustrations ||
                                    preferences.showIllustrations === false
                                ) {
                                    setShowIllustrations(
                                        preferences.showIllustrations
                                    );
                                }
                            }
                        }
                    } catch (error) {
                        console.error('Error fetching user profile:', error);
                    }
                };

                await Promise.all([getOrganisationInfo(), getUser()]);
            } catch (error) {
                console.error(
                    'Error fetching authenticated user or users:',
                    error
                );
            }
        };
        if (user) {
            fetchData();
        }
    }, [userUpdated, user]);

    useEffect(() => {
        const preloadedUserAvatars = async () => {
            users.forEach(async (user) => {
                if (!userImages.find((image) => image.name === user.id)) {
                    let image: string = '';

                    try {
                        const downloadResult: any = await downloadData({
                            key: `uploaded/${user.organisation}/${user.id}`,
                        }).result;

                        image = URL.createObjectURL(downloadResult.body);
                    } catch {}

                    if (setUserImages && image) {
                        setUserImages((images) => {
                            const userImages = [...images];

                            if (
                                userImages.findIndex(
                                    (userImage) => userImage.name === user.id
                                ) === -1
                            ) {
                                userImages.push({
                                    name: user.id,
                                    image: image,
                                });
                            }

                            return userImages;
                        });
                    }
                }
            });
        };
        if (users.length) {
            preloadedUserAvatars();
        }
    }, [users]);

    const getUsers = async () => {
        const userOrganisation = await getUserOrganisation(user);
        try {
            const users = await client.graphql({
                query: listUserProfiles,
                variables: {
                    organisation: userOrganisation,
                    sortDirection: ModelSortDirection.DESC,
                },
            });
            const sortedUsers = users?.data?.listUserProfiles?.items.sort(
                (a: UserProfile, b: UserProfile) => {
                    if (!a.firstName && !b.firstName) return 0;
                    if (!a.firstName) return 1;
                    if (!b.firstName) return -1;

                    if (a.firstName < b.firstName) return -1;
                    if (a.firstName > b.firstName) return 1;
                    return 0;
                }
            );
            setActiveUsers(
                sortedUsers.filter(
                    (user: UserProfile) => user.status !== 'Deleted'
                )
            );
            setUsers(sortedUsers);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
    const getCardTypes = async () => {
        try {
            const cardTypes = await client.graphql({
                query: listCardTypes,
            });
            setCardTypes(cardTypes.data.listCardTypes.items as CardType[]);
            const cardTypeObject: {
                [key: string]: CardType;
            } = {};

            cardTypes.data.listCardTypes.items.forEach((item) => {
                if (item?.id) {
                    cardTypeObject[item.id] = item as CardType;
                }
            });

            setCardTypeObject(cardTypeObject);
        } catch (error) {
            console.error('Error fetching card types', error);
        }
    };
    useEffect(() => {
        if (user) {
            getCardTypes();
            getUsers();
        }
    }, [userUpdated, user]);

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <CssVarsProvider theme={theme}>
                <CssBaseline />
                {user && (
                    <AppContext.Provider
                        value={{
                            cardTypes,
                            cardTypeObject,
                            userProfileObject,
                            setUserProfileObject,
                            userUpdated,
                            setUserUpdated,
                            users,
                            globalAdmin,
                            getUsers,
                            showIllustrations,
                            setShowIllustrations,
                            loaded,
                            setLoaded,
                            viewType,
                            setViewType,
                            activeUsers,
                            isGlobalAdmin,
                            applyFilter,
                            setApplyFilter,
                            applySort,
                            setApplySort,
                            organisationProfile,
                            setOrganisationProfile,
                            user,
                            setUser,
                            userImages,
                            setUserImages,
                        }}
                    >
                        <BrowserRouter>
                            <Layout
                                header="Strategy Cards"
                                title="Strategy Cards"
                            >
                                <RouterProvider />
                            </Layout>
                        </BrowserRouter>
                    </AppContext.Provider>
                )}
            </CssVarsProvider>
        </LocalizationProvider>
    );
};

export default App;
