import {
    Box,
    Divider,
    Paper,
    Tooltip,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { NotificationContext } from '../contexts';
import Notification from './Notification';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { ReactComponent as ContentIcon } from '../../assets/icons/Content.svg';
import { ReactComponent as ActivityIcon } from '../../assets/icons/Event.svg';
import { ReactComponent as ScoresIcon } from '../../assets/icons/Scores.svg';
import { ReactComponent as UpdateUserIcon } from '../../assets/icons/update-user.svg';
import { ReactComponent as RelationshipsIcon } from '../../assets/icons/Relationships.svg';
import { ReactComponent as FingerprintIcon } from '../../assets/icons/Fingerprint.svg';
import { ReactComponent as CommentsIcon } from '../../assets/icons/Comments.svg';
import { INotification } from '.';

import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
    timelineItemClasses,
} from '@mui/lab';
import { EntityType } from '../../API';
import CardContainer from './CardContainer';

interface BoxProps {
    padding?: number;
}

export interface BatchGroupedEntry {
    batchId: string;
    entries: INotification[];
}

export interface TimeAndBatchGroup {
    time: string;
    batches: any;
}

const StyledNotificationComponent = styled(Box)<BoxProps>(({ theme }) => {
    return {
        color: theme.palette.text.primary,
        height: '100%',
        position: 'fixed',
        left: '75px',
        top: 0,
        zIndex: 1600,
        boxSizing: 'border-box',
    };
});
const filterIcons = [
    {
        Component: ContentIcon,
        entityType: [EntityType.Attribute],
        tooltipText: 'Just details',
    },
    {
        Component: UpdateUserIcon,
        entityType: [EntityType.UserProfile, EntityType.Organisation],
        tooltipText: 'Just membership',
    },
    {
        Component: CommentsIcon,
        entityType: [EntityType.Comment],
        tooltipText: 'Just comments',
    },
];

const TimeSeparator = ({ time }: { time: string }) => (
    <TimelineItem sx={{ ml: 1.2, mt: 3 }}>
        <TimelineSeparator>
            <TimelineDot variant="filled" sx={{ width: 18, height: 18 }} />
            <TimelineConnector sx={{ mb: -9, mt: -3, zIndex: 3 }} />
        </TimelineSeparator>
        <TimelineContent>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                {time}
            </Typography>
        </TimelineContent>
    </TimelineItem>
);
const NotificationPanel = ({
    loadMore,
    token,
    fingerprintOn,
    setFingerprintOn,
    updateLastSeen,
}: {
    loadMore: () => void;
    token: string | null;
    fingerprintOn: boolean;
    setFingerprintOn: Dispatch<SetStateAction<boolean>>;
    updateLastSeen: () => void;
}) => {
    const {
        showNotifications,
        notifications,
        unreadNotifications,
        selectedNotification,
        setSelectedNotification,
    } = useContext(NotificationContext);
    const [groupedEntries, setGroupedEntries] = useState<
        TimeAndBatchGroup[] | null
    >();
    history.scrollRestoration = 'manual';

    const [entityTypes, setEntityTypes] = useState<EntityType[]>([]);
    const listInnerRef = useRef();
    const theme = useTheme();

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                token !== null && loadMore();
            }
        }
    };
    const getColor = (array: EntityType[]): string => {
        return array.some((item) => entityTypes.includes(item))
            ? theme.palette.primary.main
            : 'gray';
    };
    const handleAuditTypeClick = (entityType: EntityType) => {
        setEntityTypes((current) => {
            const isSelected = current.includes(entityType);
            if (isSelected) {
                return current.filter((type) => type !== entityType);
            } else {
                return [...current, entityType];
            }
        });
    };
    const groupEntriesByDate = (notifications: INotification[]) => {
        const groups: TimeAndBatchGroup[] = [
            { time: 'Last 24 hours', batches: [] },
            { time: 'Last six days', batches: [] },
            { time: 'Older', batches: [] },
        ];
        const today = new Date();
        today.setDate(today.getDate() - 1);

        const sixDays = new Date();
        sixDays.setDate(sixDays.getDate() - 6);

        const allNotifications = notifications;

        groups.forEach((group, index) => {
            const entries: INotification[] =
                index === 0
                    ? allNotifications.filter(
                          (notification) =>
                              new Date(notification.createdAt).toISOString() >
                              today.toISOString()
                      )
                    : index === 1
                    ? allNotifications.filter(
                          (notification) =>
                              new Date(notification.createdAt).toISOString() >=
                                  sixDays.toISOString() &&
                              today.toISOString() >
                                  new Date(notification.createdAt).toISOString()
                      )
                    : allNotifications.filter(
                          (notification) =>
                              new Date(notification.createdAt).toISOString() <
                              sixDays.toISOString()
                      );

            group.batches = entries.map((entry) => ({
                batchId: entry.batchId,
                entries: [{ ...entry }],
            }));
        });

        setGroupedEntries(groups);
    };

    useEffect(() => {
        const result = groupEntriesByDate(notifications);
    }, [notifications, unreadNotifications]);

    return (
        <StyledNotificationComponent
            style={{ display: showNotifications ? 'flex' : 'none' }}
        >
            <Box
                sx={{
                    padding: '15px',
                    backgroundColor:
                        theme.palette.mode === 'dark' ? '#000000' : '#ffffff',
                    borderRadius: '3px',
                    boxShadow:
                        'var(--mui-shadows-4, 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31))',
                    display: 'flex',
                }}
            >
                <Box
                    ref={listInnerRef}
                    onScroll={onScroll}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        overflow: 'scroll',
                        paddingRight: '10px',
                        width: '700px',
                    }}
                >
                    <Typography variant="h5">Notifications</Typography>
                    <Box
                        sx={{
                            height: '35px',
                            display: 'flex',
                            justifyContent: 'center',
                            svg: {
                                height: '35px',
                                width: '35px',
                            },
                        }}
                    >
                        <Tooltip title="Just mine" placement="top">
                            <FingerprintIcon
                                width={35}
                                height={35}
                                fill={
                                    fingerprintOn
                                        ? theme.palette.primary.main
                                        : 'gray'
                                }
                                style={{ marginRight: 10, cursor: 'pointer' }}
                                onClick={() => {
                                    updateLastSeen();
                                    setFingerprintOn(!fingerprintOn);
                                }}
                            />
                        </Tooltip>
                        {filterIcons.map(
                            ({ Component, entityType, tooltipText }, index) => (
                                // <Tooltip title={tooltipText} placement="top">
                                <Component
                                    key={index}
                                    width={35}
                                    height={35}
                                    // color={getColor(entityType)}
                                    color="#dddddd"
                                    style={{
                                        marginRight: 10,
                                        // cursor: 'pointer',
                                    }}
                                    // onClick={() =>
                                    //     entityType.forEach((type) =>
                                    //         handleAuditTypeClick(type)
                                    //     )
                                    // }
                                />
                                // </Tooltip>
                            )
                        )}
                    </Box>
                    <Divider
                        sx={{ paddingTop: '15px', paddingBottom: '60px' }}
                    />
                    {unreadNotifications.length === 0 && (
                        <Box
                            sx={{ margin: '20px 0' }}
                            className="new-notification"
                        >
                            <Typography
                                variant="h6"
                                sx={{ textAlign: 'center' }}
                            >
                                There's nothing new to see here
                            </Typography>
                        </Box>
                    )}
                    <Timeline
                        position="right"
                        sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 2,
                            },
                            p: 0,
                            m: 0,
                        }}
                    >
                        {groupedEntries?.map((group: any) => (
                            <Box key={group.month}>
                                <TimeSeparator time={group.time} />
                                {group.batches.map(
                                    (batch: BatchGroupedEntry) => {
                                        const items = batch.entries;

                                        return (
                                            <Notification
                                                notification={batch.entries[0]}
                                                unread={false}
                                                key={batch.entries[0].id}
                                            />
                                        );
                                    }
                                )}
                            </Box>
                        ))}
                    </Timeline>
                    {token === null && (
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                alignItems: 'center',
                                paddingTop: '20px',
                            }}
                            className="no-new-notifications"
                        >
                            <CheckCircle
                                sx={{ color: theme.palette.primary.main }}
                            />
                            <Typography
                                sx={{
                                    margin: '20px 60px',
                                    textAlign: 'center',
                                    maxWidth: '200px',
                                }}
                            >
                                That's it - there are no more updates to review
                                from the last 30 days
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
            {selectedNotification && (
                <CardContainer notification={selectedNotification} />
            )}
        </StyledNotificationComponent>
    );
};

export default NotificationPanel;
