import { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { Card, CardSet, Playbook, UserProfile } from '../../API';
import { AppContext } from '../contexts';
import UserAvatar from '../profile/UserAvatar';

interface CardPermissionProps {
    owner: string | null | undefined;
    cardCategory: string;
    selected: UserProfile[];
    addEditors: boolean;
    selectedEditors: string[];
    setSelectedEditors: React.Dispatch<React.SetStateAction<string[]>>;
    onSelect: (user: UserProfile) => void;
    onSave: () => void;
    onClose: () => void;
    card: Card | CardSet | Playbook;
    allUsersEnabled: boolean;
    setAllUsersEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    padding?: number;
}

interface UserItemProps {
    user: UserProfile;
    selectable?: boolean;
}
const CardPermissions = ({
    owner,
    cardCategory,
    selected,
    selectedEditors,
    setSelectedEditors,
    addEditors,
    onSelect,
    onSave,
    onClose,
    card,
    allUsersEnabled,
    setAllUsersEnabled,
    padding,
}: CardPermissionProps) => {
    const theme = useTheme();
    const { activeUsers } = useContext(AppContext);
    const userIds = activeUsers.map((item) => item.id);
    useEffect(() => {
        if (card.orgEdit && addEditors) {
            setSelectedEditors(userIds);
            setAllUsersEnabled(true);
        }
    }, [card]);

    const toggleAllUsers = () => {
        setAllUsersEnabled(!allUsersEnabled);
        if (allUsersEnabled) {
            setSelectedEditors([]);
        } else {
            setSelectedEditors(userIds);
        }
    };

    const handleClick = (user: UserProfile) => {
        onSelect(user);
        if (!addEditors) return;
        let updatedSelectedEditors: string[] = [];
        if (selectedEditors.includes(user.id)) {
            updatedSelectedEditors = selectedEditors.filter(
                (id) => id !== user.id
            );
        } else {
            updatedSelectedEditors = [...selectedEditors, user.id];
        }
        setSelectedEditors(updatedSelectedEditors);
        const allUsersSelected = activeUsers.every(
            (u) => u.id === owner || updatedSelectedEditors.includes(u.id)
        );
        setAllUsersEnabled(allUsersSelected);
    };

    const UserItem = ({ user, selectable }: UserItemProps) => {
        return (
            <ListItem
                alignItems="center"
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    py: 0,
                    '&:hover': selectable
                        ? {
                              bgcolor: (theme) => theme.palette.primary.main,

                              color: (theme) =>
                                  theme.palette.primary.contrastText,
                              cursor: 'pointer',
                              '.MuiSvgIcon-root': {
                                  color: (theme) =>
                                      theme.palette.primary.contrastText,
                              },
                          }
                        : '',
                }}
                {...(selectable && {
                    onClick: () => handleClick(user),
                })}
            >
                <ListItemAvatar>
                    <UserAvatar
                        userId={user.id}
                        isProfile={false}
                        cardCategory={cardCategory}
                    />
                </ListItemAvatar>
                {selectable && (
                    <Checkbox
                        sx={{
                            color: (theme) => theme.palette.grey[600],
                            '&.Mui-checked': {
                                color: '#fff',
                                '.MuiSvgIcon-root': {
                                    background: (theme) =>
                                        theme.palette.primary.main,
                                },
                            },
                        }}
                        checked={
                            addEditors
                                ? selectedEditors.findIndex(
                                      (item) => item === user.id
                                  ) !== -1
                                : selected.findIndex(
                                      (item) => item.id === user.id
                                  ) !== -1
                        }
                    />
                )}
            </ListItem>
        );
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box sx={{ padding: padding ?? 3, position: 'relative' }}>
                <Typography variant="h5">
                    {addEditors ? 'Update members' : 'Reassign Owner'}
                </Typography>
                <IconButton
                    aria-label="close"
                    sx={{
                        color: (theme) => theme.palette.grey[600],
                        position: 'absolute',
                        top: 20,
                        right: 20,
                    }}
                    size="large"
                    onClick={() => onClose()}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <Grid container spacing={0}>
                <Grid item xs={12} md={5}>
                    <Box
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.background.paper,
                            py: 1,
                            px: 3,
                            borderTop: 'solid 1px #ccc',
                            borderBottom: 'solid 1px #ccc',
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: 600,
                                fontSize: 16,
                            }}
                        >
                            Users
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            height: '100%',
                            minHeight: '300px',
                            display: 'flex',
                            flexDirection: 'column',
                            overflowY: 'auto',
                            backgroundColor: (theme) =>
                                theme.palette.background.paper,
                        }}
                    >
                        <List
                            sx={{
                                height: '100%',
                                width: '100%',
                                py: 1,
                            }}
                        >
                            {addEditors && (
                                <ListItem>
                                    <ListItemText primary="All users" />
                                    <Checkbox
                                        checked={allUsersEnabled}
                                        onChange={toggleAllUsers}
                                    />
                                </ListItem>
                            )}
                            {activeUsers
                                .filter((user) => user.id !== owner)
                                .map((user) => {
                                    return (
                                        <UserItem
                                            key={user.id}
                                            user={user}
                                            selectable={true}
                                        />
                                    );
                                })}
                        </List>
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.background.paper,
                            py: 1,
                            px: 3,
                            borderTop: 'solid 1px #ccc',
                            borderBottom: 'solid 1px #ccc',
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: 600,
                                fontSize: 16,
                            }}
                        >
                            Selected
                        </Typography>
                    </Box>

                    <List
                        sx={{
                            height: '300px',
                            width: '100%',
                            py: 1,
                            overflowY: 'auto',
                        }}
                    >
                        {addEditors
                            ? selectedEditors.map((user) => {
                                  return (
                                      <Box
                                          key={user}
                                          sx={{
                                              mt: 1,
                                              ml: 1,
                                              height: '2.1rem',
                                          }}
                                      >
                                          <UserAvatar
                                              userId={user}
                                              isProfile={false}
                                              cardCategory={cardCategory}
                                          />
                                      </Box>
                                  );
                              })
                            : selected.map((user) => {
                                  return <UserItem key={user.id} user={user} />;
                              })}
                    </List>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            position: 'absolute',
                            bottom: '1rem',
                            left: 0,
                            width: '100%',
                        }}
                    >
                        <Button
                            size="small"
                            sx={{ mr: 2 }}
                            variant="contained"
                            onClick={() => onSave()}
                        >
                            Save changes
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CardPermissions;
