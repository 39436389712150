import { Box, Link } from '@mui/material';
import { Authenticator, CheckboxField } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import Terms from './pages/termsandconditions';
import App from './App';

import awsExports from './aws-exports';
import './styles/globals.css';

Amplify.configure({ ...awsExports }, {
    Storage: {
        S3: {
            prefixResolver: async ({ accessLevel, targetIdentityId}) => {
                return '';
            }
        }
    }
});

try {
    const config: AwsRumConfig = {
        sessionSampleRate: 1,
        guestRoleArn:
            'arn:aws:iam::333380576980:role/RUM-Monitor-eu-west-2-333380576980-7834551432861-Unauth',
        identityPoolId: 'eu-west-2:c132bf3a-e3eb-42b0-a4cb-c59d7a02bce5',
        endpoint: 'https://dataplane.rum.eu-west-2.amazonaws.com',
        telemetries: ['performance', 'errors', 'http'],
        allowCookies: true,
        enableXRay: true,
    };

    const APPLICATION_ID: string = '305d513c-f3b6-4bd8-b61f-77716b0a6f67';
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'eu-west-2';

    const awsRum: AwsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
    );
} catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
}

const formFields = {
    signUp: {
        given_name: {
            order: 1,
            isRequired: true,
            label: 'First Name',
            placeholder: 'Enter your First Name',
        },
        family_name: {
            order: 2,
            isRequired: true,
            label: 'Last Name',
            placeholder: 'Enter your Last Name',
        },
        'custom:organisation': {
            order: 3,
            isRequired: true,
            label: 'Organization Name',
            placeholder: 'Enter your Organization Name',
        },
        email: {
            order: 4,
        },
        password: {
            order: 5,
        },
        confirm_password: {
            order: 6,
        },
    },
};

const TermsAndConditions = () => {
    return (
        <Box>
            I have read and agree with the{' '}
            <Link href="/termsandconditions" target="_blank">
                Strategy Cards Terms and Conditions
            </Link>
        </Box>
    );
};

const AuthComponent = () => {
    return (
        <>
            {window.location.href.indexOf('termsandconditions') > -1 ? (
                <Terms />
            ) : (
                <Authenticator
                    formFields={formFields}
                    signUpAttributes={['given_name', 'family_name', 'email']}
                    components={{
                        SignUp: {
                            FormFields() {
                                return (
                                    <>
                                        {/* Re-use default `Authenticator.SignUp.FormFields` */}
                                        <Authenticator.SignUp.FormFields />

                                        {/* Append & require Terms & Conditions field to sign up  */}
                                        <CheckboxField
                                            isRequired
                                            name="acknowledgement"
                                            value="yes"
                                            label={<TermsAndConditions />}
                                        />
                                    </>
                                );
                            },
                        },
                    }}
                >
                    {({ user, signOut }) => <App />}
                </Authenticator>
            )}
        </>
    );
};

export default AuthComponent;
