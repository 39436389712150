import { FormControlLabel, FormGroup, FormLabel, Tooltip } from '@mui/material';
import MuiCheckbox from '@mui/material/Checkbox';
import { CheckboxFieldProps } from './inputFieldTypes';
import { truncateText } from '../../../helpers/utils';

const CheckboxFields = (props: CheckboxFieldProps) => {
    const { name, label, options, sx, required, onChangeCheckbox, values } =
        props;

    return (
        <>
            {label && <FormLabel component="legend">{label}</FormLabel>}

            <FormGroup sx={sx}>
                {options?.map((option, i) => (
                    <>
                        <Tooltip title={option.label} key={option.label}>
                            <FormControlLabel
                                key={option.label}
                                label={truncateText(option.label, 50)}
                                control={
                                    <MuiCheckbox
                                        defaultChecked={option.defaultChecked}
                                        size="small"
                                        required={required}
                                        onChange={(event) =>
                                            onChangeCheckbox &&
                                            onChangeCheckbox(name, option.value)
                                        }
                                        checked={values?.includes(option.value)}
                                    />
                                }
                            />
                        </Tooltip>
                        {option.children}
                    </>
                ))}
            </FormGroup>
        </>
    );
};

export default CheckboxFields;
