import { Box, Button } from '@mui/material';
import InputFromAttribute from './input-fields/InputFromAttribute';
import { FieldType, FormConfiguration } from './input-fields/inputFieldTypes';
import { PlaybookPageDataResponse, PlaybookPageType } from '../../API';
import { CardSettings } from '../../components/playbooks/EditablePlaybookPage';
import { ChartType } from '../../helpers/worksheets';
import { RadarOption } from './input-fields/ChartSettings';

interface FormProps {
    disableSubmit: boolean;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    handleCancel?: () => void;
    formConfiguration: FormConfiguration;
    onChange?: (name: string, value: string) => void;
    values?: {
        [key: string]: string | CardSettings | null;
    };
    checkboxValues?: Array<string | null> | null;
    submitText?: string;
    cardSelector?: CardSettings;
    chartType?: ChartType | undefined;
    chartSettings?: any;
    page?: PlaybookPageDataResponse;
    pageOptions?: any;
    setPageOptions?: React.Dispatch<React.SetStateAction<undefined>>;
    radarOptions?: RadarOption[] | null | undefined;
    setRadarOptions?: React.Dispatch<
        React.SetStateAction<RadarOption[] | null | undefined>
    >;
    titlePage?: boolean;
}

const Form = ({
    handleCancel,
    handleSubmit,
    formConfiguration,
    disableSubmit,
    onChange,
    values,
    checkboxValues,
    submitText,
    cardSelector,
    chartType,
    chartSettings,
    page,
    pageOptions,
    setPageOptions,
    radarOptions,
    setRadarOptions,
    titlePage,
}: FormProps) => {
    return (
        <form onSubmit={handleSubmit}>
            {formConfiguration.map((input, i) =>
                Array.isArray(input) ? (
                    <Box sx={{ display: 'flex' }} key={i}>
                        {input.map((innerInput) => (
                            <Box key={innerInput.name} sx={{ mr: 2 }}>
                                <InputFromAttribute
                                    key={innerInput.name}
                                    {...innerInput}
                                    handleInputChange={(name, value) =>
                                        onChange && onChange(name, value)
                                    }
                                    checkboxValues={checkboxValues}
                                />
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <InputFromAttribute
                        key={input.name}
                        {...input}
                        handleInputChange={(name, value) =>
                            onChange && onChange(name, value)
                        }
                        {...(values &&
                            values[input.name] && {
                                selectValue: values[input.name] as string,
                            })}
                        {...(cardSelector && {
                            cardSelector: cardSelector,
                        })}
                    />
                )
            )}
            {(chartType === ChartType.BUBBLES ||
                chartType === ChartType.RADAR) &&
            chartSettings ? (
                <InputFromAttribute
                    key={chartSettings.name}
                    {...chartSettings}
                    page={page}
                    handleInputChange={(name, value) =>
                        onChange && onChange(name, value)
                    }
                    pageOptions={pageOptions}
                    setPageOptions={setPageOptions}
                    radarOptions={radarOptions}
                    setRadarOptions={setRadarOptions}
                />
            ) : (
                chartType === ChartType.PORTERS_FIVE_FORCES && (
                    <InputFromAttribute
                        key={chartSettings?.name ?? 'layoutsettings'}
                        {...chartSettings}
                        type={FieldType.LAYOUTSETTINGS}
                        page={page}
                        handleInputChange={(name, value) =>
                            onChange && onChange(name, value)
                        }
                        pageOptions={pageOptions}
                        setPageOptions={setPageOptions}
                    />
                )
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {handleCancel && (
                    <Button
                        onClick={handleCancel}
                        variant="outlined"
                        color="secondary"
                        sx={{ justifySelf: 'right', mr: 2, px: 4 }}
                        disabled={disableSubmit}
                    >
                        {'Cancel'}
                    </Button>
                )}
                <Button
                    variant="contained"
                    type="submit"
                    sx={{ justifySelf: 'right' }}
                    disabled={disableSubmit}
                >
                    {submitText ?? 'Submit'}
                </Button>
            </Box>
        </form>
    );
};

export default Form;
