import React from 'react';
import { Box, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import MuiCheckbox from '@mui/material/Checkbox';
import { CheckboxFieldProps, CheckboxOptionType } from './inputFieldTypes';

interface CheckboxProps {
    onChange?: (value: string) => void;
    checkboxValues?: string[];
}

export const CheckboxFields = (props: CheckboxFieldProps & CheckboxProps) => {
    const { name, label, onChange, options, sx, subgroup, checkboxValues } =
        props;

    return (
        <>
            {label && (
                <FormLabel component="legend" sx={{ paddingTop: 1 }}>
                    {label}
                </FormLabel>
            )}

            <>
                {options?.map((option, i) => {
                    return (
                        <>
                            <FormControlLabel
                                key={option.label}
                                label={option.label}
                                control={
                                    <MuiCheckbox
                                        onChange={(event) =>
                                            !!props.onChange
                                                ? props.onChange(option.value)
                                                : () => {}
                                        }
                                        checked={checkboxValues?.includes(
                                            option.value
                                        )}
                                        size="small"
                                        disabled={
                                            props.disabled || option.disabled
                                        }
                                    />
                                }
                            />
                            {option.children?.props.type === 'checkbox' &&
                            checkboxValues?.includes(option.value) ? (
                                <>
                                    <>
                                        {Array.isArray(
                                            option.children.props.options
                                        ) &&
                                            option.children.props.options?.map(
                                                (
                                                    option: CheckboxOptionType,
                                                    i: number
                                                ) => {
                                                    return (
                                                        <>
                                                            <FormControlLabel
                                                                sx={{
                                                                    ml: 2,
                                                                }}
                                                                key={
                                                                    option.label
                                                                }
                                                                label={
                                                                    option.label
                                                                }
                                                                control={
                                                                    <MuiCheckbox
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            !!props.onChange
                                                                                ? props.onChange(
                                                                                      option.value
                                                                                  )
                                                                                : () => {}
                                                                        }
                                                                        checked={
                                                                            checkboxValues &&
                                                                            checkboxValues.includes(
                                                                                option.value
                                                                            )
                                                                        }
                                                                        size="small"
                                                                        disabled={
                                                                            props.disabled ||
                                                                            option.disabled
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    );
                                                }
                                            )}
                                    </>
                                </>
                            ) : React.isValidElement(option.children) ? (
                                option.children
                            ) : (
                                ''
                            )}
                        </>
                    );
                })}
            </>
        </>
    );
};

export const CheckboxGroupFields = (props: CheckboxFieldProps) => {
    const { sx, subgroup } = props;

    return subgroup ? (
        <CheckboxFields {...props} />
    ) : (
        <FormGroup sx={sx}>
            <CheckboxFields {...props} />
        </FormGroup>
    );
};

export default CheckboxGroupFields;
