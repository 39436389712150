import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import TitlePageConfiguration from '../input-fields/TitlePageConfiguration';
import { FieldType } from '../input-fields/inputFieldTypes';
import { NewWorksheet } from '.';

interface LayoutColumnProps {
    newWorksheet?: NewWorksheet;
    setNewWorksheet?: React.Dispatch<React.SetStateAction<NewWorksheet>>;
}

const LayoutColumn = ({ newWorksheet, setNewWorksheet }: LayoutColumnProps) => {
    const theme = useTheme();
    const [selectValue, setSelectValue] = useState<string | null>(null);

    useEffect(() => {
        const options = newWorksheet?.options
            ? JSON.parse(newWorksheet?.options)
            : null;

        if (options?.titleconfig) {
            setSelectValue(options.titleconfig);
        }
    }, [newWorksheet]);

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                borderLeft: 'solid 1px #ccc',
                borderRight: 'solid 1px #ccc',
            }}
        >
            <Box
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : '#efefef',
                    py: 1,
                    px: 3,
                    borderTop: 'solid 1px #ccc',
                    borderBottom: 'solid 1px #ccc',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    Layout
                </Typography>
            </Box>
            <Box
                sx={{
                    position: 'relative',
                    ml: '20px',
                }}
            >
                <TitlePageConfiguration
                    hideTitle={true}
                    type={FieldType.TITLECONFIG}
                    name="titleconfig"
                    {...(selectValue && {
                        selectValue: selectValue,
                    })}
                    maxWidth="100%"
                    padding="80px"
                    onChange={(event) =>
                        setNewWorksheet &&
                        setNewWorksheet((newWorksheet) => {
                            const worksheet = { ...newWorksheet };
                            worksheet.options = JSON.stringify({
                                titleconfig: event.target.value,
                            });

                            return worksheet;
                        })
                    }
                />
            </Box>
        </Box>
    );
};

export default LayoutColumn;
