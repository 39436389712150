import { Box, Button, CircularProgress } from '@mui/material';
import { AttributeType } from '../../../API';
import TextField from '../input-fields/TextField';

interface CardSetDetailsColumnProps {
    loading: boolean;
    cardSet: { name: string; briefDescription: string };
    setCardSetObject: (cardSet: {
        name: string;
        briefDescription: string;
    }) => void;
}

const CardSetDetailsColumn: React.FC<CardSetDetailsColumnProps> = ({
    loading,
    cardSet,
    setCardSetObject,
}) => {
    return (
        <Box
            sx={{
                py: 2,
                px: 3,
                display: 'flex',
                flexDirection: 'column',
                height: '90%',
                justifyContent: 'space-between',
            }}
        >
            <Box>
                <TextField
                    required={true}
                    defaultValue={cardSet?.name ?? ''}
                    type={AttributeType.Text}
                    value={cardSet?.name ?? ''}
                    name="Name"
                    onChange={(event) =>
                        setCardSetObject({
                            ...cardSet,
                            name: event.target.value,
                        })
                    }
                    label="Name"
                    inputProps={{
                        maxLength: 256,
                    }}
                />
                <TextField
                    defaultValue={cardSet?.briefDescription}
                    type={AttributeType.Text}
                    value={cardSet?.briefDescription ?? ''}
                    name="briefDescription"
                    onChange={(event) =>
                        setCardSetObject({
                            ...cardSet,
                            briefDescription: event.target.value,
                        })
                    }
                    label="Brief description"
                    multiline
                    rows={4}
                    inputProps={{
                        maxLength: 512,
                    }}
                />
            </Box>
            <Button
                disabled={loading}
                variant="contained"
                type="submit"
                sx={{ width: '100%' }}
            >
                {loading ? (
                    <CircularProgress color="inherit" size={'1rem'} />
                ) : (
                    'Create card set'
                )}
            </Button>
        </Box>
    );
};

export default CardSetDetailsColumn;
