import { Box, Typography } from '@mui/material';
import React from 'react';

interface HelpTexts {
    s1: string;
    s2: string;
}

interface HelpContentProps {
    content: HelpTexts | null;
    textColor: string;
    padding: number;
}
const HelpContent = ({ content, textColor, padding }: HelpContentProps) => {
    return (
        <Box sx={{ p: padding, color: textColor }}>
            <Box sx={{ mb: 2 }}>
                <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Theory
                </Typography>
                <Typography variant="body2">{content?.s1}</Typography>
            </Box>
            <Box>
                <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
                    In Strategy Cards
                </Typography>
                <Typography variant="body2">{content?.s2}</Typography>
            </Box>
        </Box>
    );
};

export default HelpContent;
