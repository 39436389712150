import { Box, IconButton, Typography } from '@mui/material';
import { Card, PlaybookPageDataResponse } from '../../../API';
import AddIcon from '@mui/icons-material/Add';
import { truncateText } from '../../../helpers/utils';
import { AuthSession, UserPermissions, WorksheetSize } from '../../../globals';
import { PagePreview } from '../../workbooks/WorkbookDrawerPage';
import { useContext, useEffect, useRef, useState } from 'react';
import image from '../../../assets/images/5-forces-image.svg';
import { colors } from '../../../helpers/scores';
import { AppContext, WorksheetContext } from '../../contexts';
import BusinessImpactLegend from '../../charts/BusinessImpactLegend';
import { StyledModal } from '../../Modal';
import AnalysisModal from './AnalysisModal';
import { CardContext } from '../../cards/context';
import ModalContainer from '../../ModalContainer';
import CreateCard from '../../forms/CreateCard';
import { GhostGridCard } from './PestleAnalysis';
import { OptionsValue } from './SwotGrid';
import { DragOverlay, useDraggable, useDroppable } from '@dnd-kit/core';
import CardComponentSmall from '../../cards/CardComponentSmall';
import { cardToCardComponentProps } from '../../../pages/cards';
import CardMenu from '../../cards/CardMenu';
import { ReactComponent as MenuIcon } from '../../../assets/icons/Menu.svg';
import { ViewType } from '../../layouts/PageView';
import CardListView from '../../cards/views/CardListView';
import { PageIdentifier } from '../../cards/cardTypes';
import { Category, getCategoryHex } from '../../../helpers/category';

interface PortersFiveForcesProps {
    cards: Card[];
    size: WorksheetSize;
    page: PlaybookPageDataResponse | PagePreview | undefined;
    workbookId?: string;
    pptView?: boolean | undefined;
    pageOptions?: any;
    selectedCard?: string | null;
    setSelectedCard?: React.Dispatch<React.SetStateAction<string | null>>;
    fullWidth?: boolean | undefined;
    addCardToWorkbook?: (card: Card, addToWorksheet: boolean) => Promise<void>;
    setActiveCardId?: React.Dispatch<React.SetStateAction<string | null>>;
    workbookPermissions?: UserPermissions[] | undefined;
    setRightPanel?: React.Dispatch<React.SetStateAction<boolean>>;
    selectedViewType?: ViewType;
    cardsObject?: {
        [key: string]: Card;
    };
    pageIdentifier?: PageIdentifier;
    tableStyle?: any;
    handleTableRowClick?: (id: string) => void;
    showScoreColors?: boolean;
    setInnerHeight?: React.Dispatch<React.SetStateAction<number>>;
}

const PortersFiveForces = ({
    cards,
    size,
    page,
    workbookId,
    pptView,
    pageOptions,
    selectedCard,
    setSelectedCard,
    fullWidth,
    addCardToWorkbook,
    setActiveCardId,
    workbookPermissions,
    setRightPanel,
    selectedViewType,
    cardsObject,
    pageIdentifier,
    tableStyle,
    handleTableRowClick,
    showScoreColors,
    setInnerHeight,
}: PortersFiveForcesProps) => {
    const {
        handleDelete,
        handleRemoveFromWorkbook,
        handleRemoveFromWorksheet,
        handleCopy,
    } = useContext(WorksheetContext);
    const menuItems: any = [];
    const [menuCard, setMenuCard] = useState<Card | null>(null);
    const { removeId } = useContext(WorksheetContext);
    const { cardTypeObject, user } = useContext(AppContext);
    const borderRadius = size === WorksheetSize.SMALL ? '4px' : '6px';
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [selectedCategoryAttr, setSelectedCategoryAttr] = useState<
        string | null
    >(null);
    const { permissions, handleClose } = useContext(CardContext);

    const MAX_LENGTH = 5;

    const trend = Object.values(cardTypeObject).find(
        (type) => type.id === '12'
    );

    const category = trend?.attributeDefinitions?.find(
        (att) => att?.id === '12-A-1'
    );
    let forces: string = category?.configuration ?? '';
    forces = JSON.parse(forces);
    let categories: string[] = Array.isArray(forces)
        ? forces
        : JSON.parse(forces);
    const gridColumns = categories.map((category) => {
        return {
            name: category,
            items: cards.filter((card) => {
                let subjectAtt =
                    card.attributes?.find(
                        (attribute) =>
                            attribute?.attributeDefinitionID === '12-A-1'
                    )?.value ?? '';

                subjectAtt = subjectAtt.replace(/^"(.*)"$/, '$1');
                return subjectAtt === category;
            }),
        };
    });

    const columns = [...gridColumns];
    const maxItemsLength = columns.sort(
        (a, b) => b.items.length - a.items.length
    )[0].items.length;

    if (
        handleRemoveFromWorkbook &&
        workbookPermissions?.includes(UserPermissions.EDIT)
    ) {
        menuItems.push({
            text: 'Remove from workbook',
            action: () => {
                menuCard && handleRemoveFromWorkbook(menuCard);
            },
        });
    }
    if (
        handleRemoveFromWorksheet &&
        workbookPermissions?.includes(UserPermissions.EDIT)
    ) {
        menuItems.push({
            text: 'Remove from worksheet',
            action: () => {
                menuCard && handleRemoveFromWorksheet(menuCard.id);
            },
        });
    }
    if (workbookPermissions?.includes(UserPermissions.DELETE)) {
        menuItems.push({
            text: 'Delete',
            action: () => {
                menuCard && handleDelete(menuCard);
            },
        });
    }
    if (handleCopy) {
        menuItems.push({
            text: 'Copy',
            action: () => {
                menuCard && handleCopy(menuCard, page?.id);
            },
        });
    }
    function Draggable({ id, children, start, index }: any) {
        const { attributes, listeners, setNodeRef } = useDraggable({
            id: `remove_${id}`,
        });

        return (
            <div
                ref={setNodeRef}
                {...listeners}
                {...attributes}
                style={{
                    width: '100%',
                    gridColumnStart: start + 1,
                    gridColumnEnd: start + 2,
                    gridRowStart: index + 2,
                }}
            >
                {children}
            </div>
        );
    }

    function DroppableCell({
        start,
        category,
        index,
        children,
        items,
    }: {
        start: any;
        category: any;
        index: any;
        children: React.ReactNode;
        items: any;
    }) {
        const droppableId = `12_${category}-${index}`;
        const { setNodeRef, isOver } = useDroppable({
            id: droppableId,
        });

        const style = {
            width: '100%',
            height: pptView
                ? '40px'
                : size === WorksheetSize.SMALL
                ? '35px'
                : '70px',
            background: isOver ? '#99b7e4' : '#f2f2f2',
            borderRadius: borderRadius,
            gridColumnStart: start + 1,
            gridColumnEnd: start + 2,
            gridRowStart: items.length + 2 + index,
            position: 'relative',
            ...(workbookId && {
                '&:hover .hoverBox': {
                    opacity: 1,
                    visibility: 'visible',
                },
            }),
        };
        return (
            <Box
                ref={setNodeRef}
                sx={{
                    ...style,
                    background: isOver ? '#99b7e4' : style.background,
                }}
            >
                {children}
            </Box>
        );
    }

    const getItems = (
        items: Card[],
        start: number,
        category: string,
        user: AuthSession | null
    ) => {
        const length =
            maxItemsLength > MAX_LENGTH ? maxItemsLength : MAX_LENGTH;
        const total = length - items.length;

        const remaining = {
            blankItems: Array.from(Array(total).keys()),
            categoryName: category,
        };

        const handleCardClick = (card: Card) => {
            if (setSelectedCard) {
                setSelectedCard(null);
                setTimeout(() => setSelectedCard(card.id), 500);
            }
            setRightPanel && setRightPanel(true);
            setActiveCardId && setActiveCardId(card.id);
        };

        const includes: OptionsValue[] = pageOptions?.layout[0].include ?? null;

        const CardItem = ({ item, index }: { item: Card; index: number }) => {
            const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(
                null
            );

            const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
                if (e) {
                    e.stopPropagation();
                    setMenuAnchor(e.currentTarget);
                    setMenuCard(item);
                } else {
                    setMenuAnchor(null);
                }
            };

            let score = item.toScoreData?.items.find(
                (scoreItem) => scoreItem?.scoreDefinitionId === '12-S-1'
            )?.data;

            let value = score ? score[score.length - 1].value : 0;
            let color = colors.find((colorItem) => colorItem.score == value);

            return removeId && removeId === item.id ? (
                <Box
                    style={{
                        width: '100%',
                        gridColumnStart: start + 1,
                        gridColumnEnd: start + 2,
                        gridRowStart: index + 2,
                    }}
                />
            ) : (
                <Draggable id={item?.id} start={start} index={index}>
                    <Box
                        sx={{
                            position: 'relative',
                            background: showScoreColors
                                ? color?.value
                                : getCategoryHex(Category.MARKETS),
                            borderRadius: borderRadius,
                            padding: '0.5em',
                            cursor: !!workbookId ? 'pointer' : 'initial',
                            height: pptView
                                ? '40px'
                                : size === WorksheetSize.SMALL
                                ? '35px'
                                : '70px',
                            transition: 'border 2s, transform 0.5s',
                            borderColor: 'transparent',
                            borderWidth: '3px',
                            borderStyle: 'solid',
                            ...(workbookId && {
                                ':hover': {
                                    borderColor: '#5e7390',
                                    transform: 'scale(0.94)',
                                },
                            }),
                        }}
                        onClick={() => {
                            if (workbookId) {
                                handleCardClick(item);
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                maxHeight: '100%',
                                height: '100%',
                                justifyContent: 'flex-start',
                                width: pptView ? '90%' : '100%',
                                color: '#fff',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    fontSize={pptView ? '1em' : '0.8em'}
                                    lineHeight="1.250em"
                                >
                                    <strong>
                                        {truncateText(
                                            item.name,
                                            size === WorksheetSize.CAROUSEL
                                                ? 40
                                                : 12
                                        )}
                                    </strong>
                                </Typography>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: '100%',
                                        pl: 0.5,
                                        marginTop: pptView
                                            ? '-3px'
                                            : size === WorksheetSize.SMALL
                                            ? '-4px'
                                            : '-10px',
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        fontSize={pptView ? '1em' : '1.2em'}
                                    >
                                        {value > 0 ? `+${value}` : value}
                                    </Typography>
                                </Box>
                            </Box>
                            {includes?.find(
                                (includeItem) =>
                                    includeItem.value === 'brief description'
                            )?.enabled &&
                                !pptView && (
                                    <Typography
                                        variant="body2"
                                        fontSize="0.775em"
                                    >
                                        {truncateText(
                                            item?.briefDescription ?? '',
                                            size === WorksheetSize.CAROUSEL
                                                ? 110
                                                : 40
                                        )}
                                    </Typography>
                                )}
                        </Box>

                        {workbookId && (
                            <IconButton
                                aria-label=""
                                id="menu-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setMenu(e);
                                }}
                                sx={{
                                    ml: 2,
                                    color: '#fff',
                                    position: 'absolute',
                                    right: -8,
                                    bottom: -8,
                                }}
                            >
                                <MenuIcon width={'20px'} height={'20px'} />
                            </IconButton>
                        )}
                        {menuAnchor && (
                            <CardMenu
                                permissions={[
                                    UserPermissions.EDIT,
                                    UserPermissions.DELETE,
                                ]}
                                anchor={menuAnchor}
                                data={{
                                    organisation: item.organisation || '',
                                    id: item.id,
                                }}
                                menuItems={menuItems}
                                setMenu={setMenu}
                            />
                        )}
                    </Box>
                </Draggable>
            );
        };

        return (
            <>
                {items.map((item, index) => (
                    <CardItem item={item} index={index} key={item.id} />
                ))}
                {remaining.blankItems.map((_, index) => (
                    <DroppableCell
                        key={index}
                        start={start}
                        category={category}
                        index={index}
                        items={items}
                    >
                        {workbookPermissions?.includes(
                            UserPermissions.EDIT
                        ) && (
                            <GhostGridCard
                                top={0}
                                onClick={() => {
                                    setSelectedType('12');
                                    setSelectedCategoryAttr(
                                        remaining.categoryName
                                    );
                                }}
                            />
                        )}
                    </DroppableCell>
                ))}
            </>
        );
    };

    const includes: OptionsValue[] = pageOptions?.layout[0].include ?? null;

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref && ref.current?.clientHeight) {
            setInnerHeight && setInnerHeight(ref.current.clientHeight);
        }
    }, [ref.current?.clientHeight]);

    return selectedViewType === ViewType.LIST ? (
        <Box sx={{ height: '100%' }}>
            <CardListView
                cardItems={
                    cards?.map((card) => cardToCardComponentProps(card)) || []
                }
                cardsObject={cardsObject}
                pageIdentifier={pageIdentifier}
                tableStyle={tableStyle}
                worksheetRowClick={handleTableRowClick}
                worksheetMenuItems={menuItems}
                setMenuCard={setMenuCard}
            />
        </Box>
    ) : (
        <Box ref={ref} sx={{ display: 'flex', flexDirection: 'column' }}>
            {includes?.find((item) => item.value === 'illustration')
                ?.enabled && (
                <Box
                    component="img"
                    sx={{
                        height: size === WorksheetSize.SMALL ? 100 : 320,
                        width: 'auto',
                    }}
                    alt="Porter's 5 Forces"
                    src={image}
                />
            )}

            {workbookId && permissions?.includes(UserPermissions.EDIT) && (
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'flex-end',
                    }}
                >
                    <Box
                        sx={{
                            width: '45px%',
                            height: '45px',
                            marginBottom: '20px',
                        }}
                    >
                        <IconButton
                            color="primary"
                            sx={{
                                borderRadius: '50px',
                                border: 'dashed 2px blue',
                            }}
                            onClick={() => setSelectedType('12')}
                        >
                            <AddIcon
                                sx={{
                                    fontSize: '3rem',
                                    width: '25px',
                                    height: '23px',
                                }}
                            />
                        </IconButton>
                    </Box>
                </Box>
            )}

            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gridTemplateRows: `repeat(${
                        (maxItemsLength > MAX_LENGTH
                            ? maxItemsLength
                            : MAX_LENGTH) + 1
                    }, ${pptView ? '40px' : '1fr'})`,
                    gridGap: '3px',
                    width: '100%',
                    flex: 1,
                }}
            >
                {gridColumns.map((gridColumn, index) => {
                    return (
                        <>
                            <Box
                                key={gridColumn.name}
                                sx={{
                                    gridRowStart: 1,
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    height:
                                        size === WorksheetSize.SMALL
                                            ? '35px'
                                            : '70px',
                                }}
                            >
                                <Box
                                    sx={{
                                        height: pptView ? '50%' : '80%',
                                        flex: 1,
                                        background: '#5e7390',
                                        borderRadius: borderRadius,
                                        px:
                                            size === WorksheetSize.SMALL
                                                ? 0.4
                                                : 2,
                                        my: pptView ? 4 : 0,
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        color="#ffffff"
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: pptView
                                                ? '0.875rem'
                                                : size === WorksheetSize.SMALL
                                                ? '0.4rem'
                                                : '.8rem',
                                            pl: pptView
                                                ? 0
                                                : size === WorksheetSize.SMALL
                                                ? 0.5
                                                : 1,
                                            pt: pptView
                                                ? 0
                                                : size === WorksheetSize.SMALL
                                                ? 0.5
                                                : 1,
                                        }}
                                    >
                                        {`${gridColumn.name} (${gridColumn.items.length})`}
                                    </Typography>
                                </Box>
                            </Box>
                            {getItems(
                                gridColumn.items,
                                index,
                                gridColumn.name,
                                user
                            )}
                        </>
                    );
                })}
            </Box>
            <BusinessImpactLegend size={size} colors={colors} />
            {!!workbookId && (
                <StyledModal
                    key="modal"
                    open={!!selectedType}
                    onClose={() => setSelectedType(null)}
                >
                    <Box>
                        {!!selectedType && (
                            <ModalContainer sx={{ maxWidth: '35rem' }}>
                                <CreateCard
                                    cardTypeFromDrawer={selectedType}
                                    cardSet={workbookId}
                                    handleClose={() => {
                                        handleClose();
                                        setSelectedType(null);
                                    }}
                                    selectedAttributes={[
                                        {
                                            __typename: 'Attribute',
                                            attributeDefinitionID: '12-A-1',
                                            value: selectedCategoryAttr,
                                        },
                                    ]}
                                    addCardToWorkbook={addCardToWorkbook}
                                    worksheet={true}
                                />
                            </ModalContainer>
                        )}
                    </Box>
                </StyledModal>
            )}
            {selectedCard && cards.find((card) => card.id === selectedCard) && (
                <AnalysisModal
                    card={
                        cards.find((card) => card.id === selectedCard) ?? null
                    }
                    handleClose={() => {
                        setSelectedCard && setSelectedCard(null);
                    }}
                    fullWidth={fullWidth}
                    setSelectedCard={setSelectedCard}
                />
            )}
            {removeId && (
                <DragOverlay>
                    {cards.find((card) => card.id === removeId) && (
                        <Box sx={{ zIndex: 9999 }}>
                            <CardComponentSmall
                                data={cardToCardComponentProps(
                                    cards.find(
                                        (card) => card.id === removeId
                                    ) as Card
                                )}
                                smallCard={true}
                                swotGrid={true}
                                swotColor=""
                            />
                        </Box>
                    )}
                </DragOverlay>
            )}
        </Box>
    );
};

export default PortersFiveForces;
