import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserPermissions } from '../../../globals';
import { Card, PlaybookPageDataResponse, ScoreDefinition } from '../../../API';
import RadarChart from '../../charts/RadarChart';
import { RadarOption } from '../../forms/input-fields/ChartSettings';
import { AppContext, WorksheetContext } from '../../contexts';
import CustomLegend from '../../charts/CustomLegend';
import { Box, IconButton, Modal, useTheme } from '@mui/material';
import { createRadarChartData } from '../../../helpers/charts';
import {
    defaultColors,
    defaultColorsLowTransparency,
    generateRandomColors,
} from '../../../helpers/category';
import AddIcon from '@mui/icons-material/Add';
import { CustomDataPoint } from '../../charts/BubbleChart';
import BubbleModal from './BubbleModal';
import { CardScoreData, processCardData } from '../../../helpers/worksheets';
import { ViewType } from '../../layouts/PageView';
import { PageIdentifier } from '../../cards/cardTypes';
import CardListView from '../../cards/views/CardListView';
import { cardToCardComponentProps } from '../../../pages/cards';

interface RadarPageProps {
    cards: Card[];
    page: PlaybookPageDataResponse | undefined;
    setCardType?: () => void;
    thumbnail: boolean;
    fullWidth?: boolean;
    workbook?: boolean;
    carousel?: boolean;
    pageOptions?: any;
    worksheetId?: string;
    permissions?: UserPermissions[] | undefined;
    radarOptions?: RadarOption[] | null;
    setRadarOptions?:
        | React.Dispatch<React.SetStateAction<RadarOption[] | null | undefined>>
        | undefined;
    pptView?: boolean;
    addCardToWorkbook?: (card: Card, addToWorksheet: boolean) => Promise<void>;
    rightPanel?: boolean;
    setActiveCardLabel?: React.Dispatch<React.SetStateAction<string | null>>;
    setActiveCardId?: React.Dispatch<React.SetStateAction<string | null>>;
    setRightPanel?: React.Dispatch<React.SetStateAction<boolean>>;
    activeCardId?: string | null;
    selectedViewType?: ViewType;
    cardsObject?: {
        [key: string]: Card;
    };
    pageIdentifier?: PageIdentifier;
    tableStyle?: any;
    handleTableRowClick?: (id: string) => void;
}

const RadarPage = ({
    cards,
    page,
    thumbnail,
    fullWidth,
    workbook,
    carousel,
    setCardType,
    pageOptions,
    worksheetId,
    permissions,
    radarOptions,
    setRadarOptions,
    pptView,
    addCardToWorkbook,
    rightPanel,
    setActiveCardLabel,
    setActiveCardId,
    setRightPanel,
    activeCardId,
    selectedViewType,
    cardsObject,
    pageIdentifier,
    tableStyle,
    handleTableRowClick,
}: RadarPageProps) => {
    const {
        handleDelete,
        handleRemoveFromWorkbook,
        handleRemoveFromWorksheet,
        handleCopy,
    } = useContext(WorksheetContext);
    const menuItems: any = [];
    const [menuCard, setMenuCard] = useState<Card | null>(null);
    const cardTypeId = cards[0]?.cardToCardTypeId;
    const [selectedBubble, setSelectedBubble] =
        useState<CustomDataPoint | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [selectedCard, setSelectedCard] = useState<Card | null>(null);
    const [cardsArrayState, setCardsArrayState] = useState<CardScoreData[]>();
    const [chartData, setChartData] = useState<CustomDataPoint[][]>([]);
    const theme = useTheme();
    const { cardTypeObject } = useContext(AppContext);
    const scoreDefinitions: ScoreDefinition[] =
        cardTypeObject?.[cardTypeId]?.scoreDefinitions || [];
    const attributeDefinitions =
        cardTypeObject?.[cardTypeId]?.attributeDefinitions || [];
    const labels =
        radarOptions
            ?.filter((score: RadarOption) => score.selected)
            .map((item: RadarOption) => {
                return item.name;
            }) || [];
    const cardNames = cards.map((item) => item.name);
    const colorsArray =
        cards.length <= 17
            ? defaultColorsLowTransparency
            : generateRandomColors(cards.length);
    const containerStyle = fullWidth
        ? {
              display: 'flex',
              flexDirection: rightPanel ? 'column' : 'row',
              alignItems: rightPanel ? 'center' : 'flex-start',
              mt: pptView ? 2 : 0,
              pt: 1,
          }
        : { display: 'initial' };
    const chartBoxStyle = {
        width: pptView
            ? '100%'
            : fullWidth && !workbook && !pptView
            ? '40%'
            : fullWidth && workbook && rightPanel
            ? '70%'
            : '100%',
        mt: fullWidth ? '-50px' : 'initial',
        height: pptView ? '700px' : 'initial',
    };
    if (
        handleRemoveFromWorkbook &&
        permissions?.includes(UserPermissions.EDIT)
    ) {
        menuItems.push({
            text: 'Remove from workbook',
            action: () => {
                menuCard && handleRemoveFromWorkbook(menuCard);
            },
        });
    }
    if (
        handleRemoveFromWorksheet &&
        permissions?.includes(UserPermissions.EDIT)
    ) {
        menuItems.push({
            text: 'Remove from worksheet',
            action: () => {
                menuCard && handleRemoveFromWorksheet(menuCard.id);
            },
        });
    }
    if (permissions?.includes(UserPermissions.DELETE)) {
        menuItems.push({
            text: 'Delete',
            action: () => {
                menuCard && handleDelete(menuCard);
            },
        });
    }
    if (handleCopy) {
        menuItems.push({
            text: 'Copy',
            action: () => {
                menuCard && handleCopy(menuCard, page?.id);
            },
        });
    }
    const chartRef = useRef<HTMLDivElement>(null);
    const handleBubbleClick = (
        data: CustomDataPoint,
        position: { top: number; left: number }
    ) => {
        setSelectedBubble(data);
        const chartBounds = chartRef.current?.getBoundingClientRect();
        const modalWidth = 250;
        const chartRightEdge = chartBounds
            ? chartBounds.right
            : window.innerWidth;
        const isCloseToRightEdge = position.left + modalWidth > chartRightEdge;
        let adjustedLeft = position.left;
        if (isCloseToRightEdge) {
            adjustedLeft = position.left - modalWidth;
        }
        setIsModalOpen(true);
        setModalPosition({ top: position.top, left: adjustedLeft });
    };

    useEffect(() => {
        const cardsArray = processCardData(cards, scoreDefinitions);
        let updatedCardsArray: any = [];
        const newChartData: CustomDataPoint[][] = cardsArray?.reduce(
            (accumulatedData, card) => {
                const index = accumulatedData.length;

                accumulatedData.push([
                    {
                        label: card.name,
                        name: card.name,
                        card: card,
                        originalCard: card.originalCard,
                        x: 0,
                        y: 0,
                        r: 0,
                    },
                ]);

                updatedCardsArray.push(card);
                setCardsArrayState(updatedCardsArray);
                return accumulatedData;
            },
            [] as CustomDataPoint[][]
        );
        setChartData(newChartData);
    }, []);
    const allAttributes = cardTypeObject[cardTypeId]?.attributeDefinitions
        ?.filter((attr) => attr !== null && attr.name !== 'Products')
        .map((item) => item?.name)
        .filter(Boolean) as string[];
    const allScores = cardTypeObject[cardTypeId]?.scoreDefinitions
        ?.filter((attr) => attr !== null)
        .map((item) => item?.name)
        .filter(Boolean) as string[];
    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add('body-scroll-lock');
        } else {
            document.body.classList.remove('body-scroll-lock');
        }
    }, [isModalOpen]);
    return selectedViewType === ViewType.LIST ? (
        <Box sx={{ height: '100%' }}>
            <CardListView
                cardItems={
                    cards?.map((card) => cardToCardComponentProps(card)) || []
                }
                cardsObject={cardsObject}
                pageIdentifier={pageIdentifier}
                tableStyle={tableStyle}
                worksheetRowClick={handleTableRowClick}
                worksheetMenuItems={menuItems}
                setMenuCard={setMenuCard}
            />
        </Box>
    ) : (
        <Box sx={containerStyle}>
            <Box sx={chartBoxStyle} ref={chartRef}>
                <RadarChart
                    labels={labels}
                    cards={cards}
                    data={chartData}
                    scoreDefinitions={scoreDefinitions}
                    thumbnail={thumbnail}
                    onBubbleClick={handleBubbleClick}
                    setSelectedCard={setSelectedCard}
                    cardsArray={cardsArrayState}
                    pptView={pptView}
                    activeCardId={activeCardId}
                    setActiveCardId={setActiveCardId}
                    setRightPanel={setRightPanel}
                />
            </Box>
            <CustomLegend
                labels={cardNames}
                colors={colorsArray}
                title={'Competitors'}
                sx={{ color: theme.palette.grey[600] }}
                thumbnail={thumbnail}
                workbook={workbook}
                pptView={pptView}
                setActiveCardLabel={setActiveCardLabel}
                rightPanel={rightPanel}
                width={
                    pptView
                        ? '30%'
                        : workbook && !rightPanel
                        ? '50%'
                        : (carousel || fullWidth) && !rightPanel
                        ? '80%'
                        : '100%'
                }
            />
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                style={{
                    top: modalPosition.top,
                    left: modalPosition.left,
                    position: 'absolute',
                }}
                sx={{
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                    },
                }}
            >
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        width: '14rem',
                        borderRadius: '8px',
                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
                        '&:focus-visible': {
                            outline: 'none',
                        },
                    }}
                >
                    {selectedBubble && selectedCard && (
                        <BubbleModal
                            node={selectedBubble}
                            attributeDefinitions={attributeDefinitions}
                            setIsModalOpen={setIsModalOpen}
                            allScores={allScores}
                            allAttributes={allAttributes}
                            selectedCard={selectedCard}
                            workbook={workbook}
                        />
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default RadarPage;
