import { Box, FormControl, Typography } from '@mui/material';
import { TitleConfigFieldProps } from './inputFieldTypes';
import {
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    RadioGroup,
    Radio,
    Tooltip,
    useTheme,
} from '@mui/material';
import { useCallback, useRef, useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import useClickOutside from '../../../hooks/useClickOutside';

import { ReactComponent as ContentTopUnselected } from '../../../assets/icons/content-top-unselected.svg';
import { ReactComponent as ContentMiddleUnselected } from '../../../assets/icons/content-middle-unselected.svg';
import { ReactComponent as ContentBottomUnselected } from '../../../assets/icons/contentbottomunselected.svg';

import { ReactComponent as LogoTopUnselected } from '../../../assets/icons/logo-top-unselected.svg';
import { ReactComponent as LogoBottomUnselected } from '../../../assets/icons/logo-bottom-unselected.svg';
import { ReactComponent as LogoNoneUnselected } from '../../../assets/icons/logo-none.svg';

import { ReactComponent as ContentTopSelected } from '../../../assets/icons/content-top-selected.svg';
import { ReactComponent as ContentMiddleSelected } from '../../../assets/icons/content-middle.svg';
import { ReactComponent as ContentBottomSelected } from '../../../assets/icons/content-bottom-selected.svg';

import { ReactComponent as LogoTopSelected } from '../../../assets/icons/logo-top-selected.svg';
import { ReactComponent as LogoBottomSelected } from '../../../assets/icons/logo-bottom-selected.svg';

interface Settings {
    [key: string]: any;
}

interface PopOverProps {
    index: number;
    label: string;
    color: string;
    onChange: (value: string) => void;
    onClose: (values: Settings) => void;
    values: any;
}

const PopoverPicker = ({
    index,
    label,
    color,
    onChange,
    onClose,
    values,
}: PopOverProps) => {
    const popover: React.MutableRefObject<any> = useRef();
    const [isOpen, toggle] = useState(false);

    const close = useCallback(() => {
        onClose(values);
        toggle(false);
    }, [values]);
    useClickOutside(popover, close);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 3.5 }}>
            <Box
                sx={{
                    width: '30px',
                    height: '30px',
                    background: color,
                    border: 'solid 1px #ededed',
                    borderRadius: '3px',
                    cursor: 'pointer',
                }}
                style={{ backgroundColor: color }}
                onClick={() => toggle(true)}
            />
            <Typography variant="body1" sx={{ ml: 1 }}>
                {label}
            </Typography>
            {isOpen && (
                <Box ref={popover}>
                    <HexColorPicker
                        color={color}
                        onChange={onChange}
                        style={{
                            marginTop: '-200px',
                            position: 'absolute',
                            cursor: 'pointer',
                            left: `${index * 80 + 30}px`,
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

const TitlePageConfiguration = (props: TitleConfigFieldProps) => {
    const { name, label, required, hideTitle, maxWidth, padding } = props;
    const [contentValue, setContentValue] = useState('top');
    const [values, setValues] = useState<Settings>({
        content: 'middle',
        logo: 'top',
        color: { text: '#ffffff', background: '#414B5A' },
    });
    const theme = useTheme();

    const layouts = [
        {
            name: 'content',
            label: 'Content',
            items: [
                {
                    name: 'top',
                    img: 'ContentTopUnselected',
                    selected: 'ContentTopSelected',
                },
                {
                    name: 'middle',
                    img: 'ContentMiddleUnselected',
                    selected: 'ContentMiddleSelected',
                },
                {
                    name: 'bottom',
                    img: 'ContentBottomUnselected',
                    selected: 'ContentBottomSelected',
                },
            ],
        },
        {
            name: 'logo',
            label: 'Logo',
            items: [
                {
                    name: 'top',
                    img: 'LogoTopUnselected',
                    selected: 'LogoTopSelected',
                },
                {
                    name: 'bottom',
                    img: 'LogoBottomUnselected',
                    selected: 'LogoBottomSelected',
                },
                {
                    name: 'none',
                    img: 'LogoNoneUnselected',
                    selected: 'LogoNoneUnselected',
                },
            ],
        },
    ];

    const colors = [
        { name: 'text', label: 'Text' },
        { name: 'background', label: 'Background' },
    ];

    const components: { [key: string]: any } = {
        ContentTopUnselected: ContentTopUnselected,
        ContentMiddleUnselected: ContentMiddleUnselected,
        ContentBottomUnselected: ContentBottomUnselected,
        LogoTopUnselected: LogoTopUnselected,
        LogoBottomUnselected: LogoBottomUnselected,
        LogoNoneUnselected: LogoNoneUnselected,
        ContentTopSelected: ContentTopSelected,
        ContentMiddleSelected: ContentMiddleSelected,
        ContentBottomSelected: ContentBottomSelected,
        LogoTopSelected: LogoTopSelected,
        LogoBottomSelected: LogoBottomSelected,
        LogoNoneSelected: LogoNoneUnselected,
    };

    function RadioImage(props: {
        name: string;
        style: { [key: string]: any };
    }) {
        const RadioComponent = components[props.name];
        return <RadioComponent {...props} />;
    }

    useEffect(() => {
        if (props.selectValue) {
            setValues(JSON.parse(props.selectValue));
        }
    }, [props.selectValue]);

    const setLayout = (name: string, value: string) => {
        const settings = { ...values };
        settings[name] = value;

        props.onChange &&
            props.onChange({ target: { value: JSON.stringify(settings) } });
    };

    const setColor = (name: string, value: string) => {
        setValues((values) => {
            const settings = { ...values };
            settings.color[name] = value;

            return settings;
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '20px',
            }}
        >
            <Typography
                variant="body1"
                sx={{
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontWeight: 600,
                    marginBottom: '8px',
                }}
            >
                {!hideTitle && 'Layout'}
            </Typography>
            <Box
                sx={{
                    background: theme.palette.background.paper,
                    width: '100%',
                    maxWidth: maxWidth ? maxWidth : '300px',
                    borderRadius: '8px',
                    padding: padding ? padding : 3.5,
                    pt: 2,
                }}
            >
                {layouts.map((layout) => {
                    return (
                        <Box sx={{ marginTop: 3 }}>
                            <FormControl
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <FormLabel
                                    id={`${layout.name}-layout-group-label`}
                                    sx={{
                                        fontWeight: 600,
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        marginBottom: '8px',
                                    }}
                                >
                                    {layout.label}
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby={`${layout.name}-layout-group-label`}
                                    name={`${layout}-layout-group`}
                                    sx={{
                                        flex: 1,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {layout.items.map((item) => {
                                        return (
                                            <FormControlLabel
                                                sx={{
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                    '.MuiRadio-root': {
                                                        display: 'none',
                                                    },
                                                    '.MuiTypography-root': {
                                                        height: '70px',
                                                    },
                                                    height: '70px',
                                                }}
                                                value={item.name}
                                                onChange={(event: any) => {
                                                    setLayout(
                                                        layout.name,
                                                        event.target.value
                                                    );
                                                }}
                                                control={<Radio />}
                                                label={
                                                    <RadioImage
                                                        name={
                                                            values[
                                                                layout.name
                                                            ] === item.name
                                                                ? item.selected
                                                                : item.img
                                                        }
                                                        style={{
                                                            width: '70px',
                                                            height: '70px',
                                                            border: 'solid 1px #d7d7d7',
                                                            borderRadius: '3px',
                                                        }}
                                                    />
                                                }
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    );
                })}

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 3,
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontWeight: 600,
                            marginBottom: '8px',
                        }}
                    >
                        Color
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                        {colors.map((color, index) => {
                            let colorSettings = values.color;

                            return (
                                <PopoverPicker
                                    index={index}
                                    label={color.label}
                                    color={colorSettings[color.name]}
                                    onChange={(value) =>
                                        setColor(color.name, value)
                                    }
                                    values={values}
                                    onClose={(values) =>
                                        props.onChange &&
                                        props.onChange({
                                            target: {
                                                value: JSON.stringify(values),
                                            },
                                        })
                                    }
                                />
                            );
                        })}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default TitlePageConfiguration;
