import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../contexts';

import {
    AxisOptions,
    NodeColorOptions,
    NodeSizeOptions,
} from '../../playbooks/playbookTypes';
import InputFromAttribute from './InputFromAttribute';
import { ScoreType } from '../../../API';

export interface RadarOption {
    name: string;
    selected: boolean;
    scoreType: ScoreType;
}

const ChartSettings = (props: any) => {
    const theme = useTheme();
    const location = useLocation();
    const { pageOptions, setPageOptions, radarOptions, setRadarOptions } =
        props;
    const defaultValues = {
        xAxis: AxisOptions.StrategicImportance,
        yAxis: AxisOptions.CurrentProficiency,
        nodeSize: NodeSizeOptions.SkillGapCurrentVsDesired,
        nodeColor: NodeColorOptions.Category,
        hideGaps: true,
        showGridLines: true,
    };
    const [layoutOptions, setLayoutOptions] = useState<any>([]);

    const handleInputChange = (event: any) => {
        const { name, value, checked, type } = event.target;

        setPageOptions((prevOptions: any) => {
            const newOptions = { ...prevOptions };

            let keys = name.split('.');
            let lastKey = keys.pop();
            let ref = newOptions;
            keys.forEach((key: any) => (ref = ref[key]));
            ref[lastKey] = value;
            return newOptions;
        });
    };
    const handleCheckboxChange = (event: any) => {
        const { name, checked } = event.target;

        setPageOptions((prevOptions: any) => {
            const newOptions = JSON.parse(JSON.stringify(prevOptions));

            if (!newOptions.layout.length || !newOptions.layout[0].layout) {
                let layout = [{ value: name, enabled: checked }];

                if (!newOptions.layout.length) {
                    newOptions.layout.push({ layout: layout });
                } else {
                    newOptions.layout[0].layout = layout;
                }
            } else {
                const index = newOptions.layout[0]?.layout?.findIndex(
                    (value: any) => value.value === name
                );

                if (index !== -1) {
                    newOptions.layout[0].layout[index].enabled = checked;
                } else {
                    newOptions.layout[0].layout.push({
                        value: name,
                        enabled: checked,
                    });
                }
            }
            return newOptions;
        });
    };
    const handleRadarCheckboxChange = (radarOption: RadarOption) => {
        setRadarOptions((prevOptions: RadarOption[]) =>
            prevOptions.map((option: RadarOption) => {
                if (option.name === radarOption.name) {
                    return { ...option, selected: !option.selected };
                }
                return option;
            })
        );
    };

    const boxStyle = {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        px: '16px',
        mt: 1,
    };

    useEffect(() => {
        if (props?.options?.layout) {
            const options = [...props.options.layout];

            if (location.pathname.includes('workbook')) {
                const index = options.findIndex(
                    (option) => option.value === 'logo'
                );

                if (index !== -1) {
                    options.splice(index, 1);
                }
            }

            setLayoutOptions(options);
        }
    }, [props?.options?.layout]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Box
                sx={{
                    width: '50%',
                    mr: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography variant="h6">Chart data</Typography>
                {radarOptions ? (
                    <Box
                        sx={{
                            ...boxStyle,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {radarOptions
                            .sort((a: RadarOption, b: RadarOption) => {
                                if (
                                    a.scoreType === ScoreType.Number &&
                                    b.scoreType !== ScoreType.Number
                                ) {
                                    return -1;
                                } else if (
                                    b.scoreType === ScoreType.Number &&
                                    a.scoreType !== ScoreType.Number
                                ) {
                                    return 1;
                                }
                                return 0;
                            })
                            .filter(
                                (score: RadarOption) =>
                                    score.scoreType !== ScoreType.Currency
                            )
                            .map((item: RadarOption) => {
                                return (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={item.name}
                                                checked={item.selected}
                                                onChange={() =>
                                                    handleRadarCheckboxChange(
                                                        item
                                                    )
                                                }
                                            />
                                        }
                                        label={item.name}
                                        sx={{
                                            '& .MuiTypography-root': {
                                                fontSize: '14px',
                                            },
                                        }}
                                    />
                                );
                            })}
                    </Box>
                ) : (
                    <Box sx={boxStyle}>
                        <FormControl
                            fullWidth
                            margin="normal"
                            variant="standard"
                        >
                            <InputLabel>X-axis</InputLabel>
                            <Select
                                name="chartData.xAxis"
                                value={
                                    pageOptions?.chartData?.xAxis ||
                                    defaultValues.xAxis
                                }
                                onChange={handleInputChange}
                                sx={{ fontSize: '14px' }}
                            >
                                {props.options.chartData.xAxisOptions.map(
                                    (item: string) => {
                                        return (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                                sx={{ fontSize: '14px' }}
                                            >
                                                {item}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                        <FormControl
                            fullWidth
                            margin="normal"
                            variant="standard"
                        >
                            <InputLabel>Y-axis</InputLabel>
                            <Select
                                name="chartData.yAxis"
                                value={
                                    pageOptions?.chartData?.yAxis ||
                                    defaultValues.yAxis
                                }
                                onChange={handleInputChange}
                                sx={{ fontSize: '14px' }}
                            >
                                {props.options.chartData.yAxisOptions.map(
                                    (item: string) => {
                                        return (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                                sx={{ fontSize: '14px' }}
                                            >
                                                {item}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                        <FormControl
                            fullWidth
                            margin="normal"
                            variant="standard"
                        >
                            <InputLabel>Node size</InputLabel>
                            <Select
                                name="chartData.nodeSize.selection"
                                value={
                                    pageOptions?.chartData?.nodeSize
                                        ?.selection || defaultValues.nodeSize
                                }
                                onChange={handleInputChange}
                                sx={{ fontSize: '14px' }}
                            >
                                {props.options.chartData.nodeSize.selection.map(
                                    (item: string) => {
                                        return (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                                sx={{
                                                    fontSize: '14px',
                                                    borderTop:
                                                        item ===
                                                        NodeSizeOptions.DefaultNodeSize
                                                            ? '1px solid rgba(0, 0, 0, 0.12)'
                                                            : 'none',
                                                }}
                                            >
                                                {item}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                        <FormControl
                            fullWidth
                            margin="normal"
                            variant="standard"
                        >
                            <InputLabel>Node color</InputLabel>
                            <Select
                                name="chartData.nodeColor.selection"
                                value={
                                    pageOptions?.chartData?.nodeColor
                                        ?.selection || defaultValues.nodeColor
                                }
                                onChange={handleInputChange}
                                sx={{ fontSize: '14px' }}
                            >
                                {props.options.chartData.nodeColor.selection.map(
                                    (item: string) => {
                                        return (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                                sx={{
                                                    fontSize: '14px',
                                                    borderTop:
                                                        item ===
                                                        NodeColorOptions.DisableNodeColor
                                                            ? '1px solid rgba(0, 0, 0, 0.12)'
                                                            : 'none',
                                                }}
                                            >
                                                {item}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                        {props.options.chartData.hideGaps && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="chartData.hideGaps.enabled"
                                        checked={
                                            pageOptions?.chartData?.hideGaps
                                                ?.enabled
                                        }
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label={props.options.chartData.hideGaps.text}
                                sx={{
                                    '& .MuiTypography-root': {
                                        fontSize: '14px',
                                    },
                                }}
                            />
                        )}
                    </Box>
                )}
            </Box>
            {layoutOptions.length > 0 && (
                <Box sx={{ width: '45%' }}>
                    <Typography variant="h6">Layout</Typography>
                    <Box sx={boxStyle}>
                        {layoutOptions.map((item: any, index: number) => {
                            let enabled =
                                pageOptions?.layout[0]?.layout?.find(
                                    (value: any) => value.value === item.value
                                )?.enabled ?? false;

                            return (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={enabled}
                                            onChange={handleCheckboxChange}
                                            name={item.value}
                                            sx={{ padding: '6px' }}
                                        />
                                    }
                                    label={item.text}
                                    sx={{
                                        '& .MuiTypography-root': {
                                            fontSize: '14px',
                                        },
                                    }}
                                />
                            );
                        })}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ChartSettings;
