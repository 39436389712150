import { Box, IconButton, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import Notifications from '@mui/icons-material/Notifications';
import { useCallback, useRef, useState } from 'react';
import { AppContext, NotificationContext } from '../contexts';
import * as subscriptions from '../../graphql/subscriptions';
import NotificationPanel from './NotificationPanel';
import useClickOutside from '../../hooks/useClickOutside';
import {
    cardsOrderedByName,
    getUserNotifications,
} from '../../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import {
    AuditEntry,
    GetUserNotificationsQuery,
    GetUserProfileQuery,
    UpdateUserProfileMutation,
} from '../../API';
import { updateUserProfile } from '../../graphql/mutations';
import { queryNotifications } from '../../actions/QueryData';
import { getUserOrganisation } from '../../helpers/auth';
import { UserProfile } from '../../models';
import { getCardDataWithScoreData } from '../../graphql/custom-queries';

export interface Unread {
    id: string;
}
type CardName = {
    name: string;
};

export interface INotification extends AuditEntry, CardName {}

const Notification = () => {
    const [showNotifications, setShowNotifications] = useState(false);
    const [unreadNotifications, setUnreadNotifications] = useState<
        INotification[]
    >([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [fingerprintOn, setFingerprintOn] = useState(false);
    const [scoreNames, setScoreNames] = useState<
        { name: string; id: string }[]
    >([]);
    const [lastSeen, setLastSeen] = useState<string | null>(null);
    const [selectedNotification, setSelectedNotification] =
        useState<INotification | null>(null);

    const { users, userProfileObject, setUserProfileObject, user, cardTypes } =
        useContext(AppContext);

    const [token, setToken] = useState<string | null>('');

    const clickArea: React.MutableRefObject<any> = useRef();
    const client = generateClient();

    const close = useCallback(() => {
        setShowNotifications((openState: boolean) => {
            if (modalOpen) {
                return openState;
            } else {
                return false;
            }
        });
    }, [showNotifications, modalOpen]);
    useClickOutside(clickArea, close);

    useEffect(() => {
        let scoreNames: { name: string; id: string }[] = [];

        cardTypes.forEach((cardType) => {
            const scores = cardType.scoreDefinitions.map((scoreName) => {
                return { name: scoreName.name, id: scoreName.id };
            });

            scoreNames = scoreNames.concat(scores);
        });

        setScoreNames(scoreNames);
    }, [cardTypes]);

    const updateLastSeen = async () => {
        const date = new Date();

        const response = (await client.graphql({
            query: updateUserProfile,
            variables: {
                input: {
                    id: userProfileObject?.getUserProfile.id ?? '',
                    organisation:
                        userProfileObject?.getUserProfile?.organisation ?? '',
                    lastNotificationSeen: date.toISOString(),
                },
            },
        })) as { data: UpdateUserProfileMutation };

        setLastSeen(date.toISOString());
    };

    useEffect(() => {
        const getNotifications = async () => {
            const date = new Date();
            date.setDate(date.getDate() - 30);
            const userGroup = await getUserOrganisation(user);

            const { data, nextToken, names } = await queryNotifications({
                initialLoad: true,
                token: null,
                cutoffTS: date.toISOString(),
                limit: 100,
                userGroup,
                fingerprintOn,
            });

            const notificationNames = JSON.parse(names);

            let allNotifications = data.map((item) => ({
                ...item,
                name: notificationNames[item?.contextId as string],
            })) as INotification[];

            allNotifications = allNotifications.filter(
                (notification) => notification.contextType === 'Card'
            );

            if (!userProfileObject?.getUserProfile.lastNotificationSeen) {
                setNotifications(allNotifications);
            } else {
                const date =
                    lastSeen ??
                    new Date(
                        userProfileObject?.getUserProfile.lastNotificationSeen
                    ).toISOString();

                setNotifications(allNotifications);
                setUnreadNotifications(
                    allNotifications.filter(
                        (notification) =>
                            new Date(notification.createdAt).toISOString() >=
                            date
                    )
                );
            }

            setToken(nextToken);
        };
        if (userProfileObject) {
            const lastSeenDate = !!userProfileObject?.getUserProfile
                ?.lastNotificationSeen
                ? new Date(
                      userProfileObject?.getUserProfile?.lastNotificationSeen ??
                          ''
                  ).toISOString()
                : new Date().toISOString();
            setLastSeen(lastSeenDate);
            getNotifications();
        }
    }, [userProfileObject, fingerprintOn]);

    const fetchUpdates = async (fingerprintOn: boolean) => {
        const date = new Date();
        date.setSeconds(date.getSeconds() - 10);

        const response = (await client.graphql({
            query: getUserNotifications,
            variables: {
                limit: 5,
                cutoffTS: date.toISOString(),
                filter: fingerprintOn
                    ? {
                          owner: true,
                          creator: true,
                      }
                    : { owner: true, creator: true, member: true },
            },
        })) as { data: GetUserNotificationsQuery };

        const names = JSON.parse(
            response?.data?.getUserNotifications?.names ?? ''
        );

        return response?.data?.getUserNotifications?.entries?.map((item) => ({
            ...item,
            name: names[item?.contextId as string],
        })) as INotification[];
    };

    useEffect(() => {
        if (users?.length) {
            const createSub = client
                .graphql({ query: subscriptions.onCreateNotification })
                .subscribe({
                    next: async ({ data }) => {
                        if (
                            data.onCreateNotification.entity ===
                                'CommentReaction' ||
                            data.onCreateNotification.entity === 'Comment' ||
                            data.onCreateNotification.entity === 'Card' ||
                            data.onCreateNotification.entity === 'Relationship'
                        ) {
                            let newNotifications = await fetchUpdates(
                                fingerprintOn
                            );

                            newNotifications = newNotifications.filter(
                                (notification) =>
                                    notification.contextType === 'Card'
                            );
                            setUnreadNotifications((notifications) => {
                                newNotifications = newNotifications.filter(
                                    (note) =>
                                        notifications.findIndex(
                                            (item) => item.id === note.id
                                        ) === -1
                                );
                                return newNotifications.concat(notifications);
                            });
                            setNotifications((notifications) => {
                                newNotifications = newNotifications.filter(
                                    (note) =>
                                        notifications.findIndex(
                                            (item) => item.id === note.id
                                        ) === -1
                                );
                                return newNotifications.concat(notifications);
                            });
                        }
                    },
                    error: (error) => console.warn(error),
                });
            return () => createSub.unsubscribe();
        }
    }, [users, fingerprintOn]);

    const loadMore = async () => {
        const date = new Date();
        date.setDate(date.getDate() - 30);

        const userGroup = await getUserOrganisation(user);

        if (token !== null) {
            const { data, nextToken, names } = await queryNotifications({
                initialLoad: false,
                token,
                cutoffTS: date.toISOString(),
                limit: 100,
                userGroup,
                fingerprintOn,
            });

            const notificationNames = JSON.parse(names);

            if (nextToken) {
                setToken(nextToken);
            }

            let allNotifications = data.map((item) => ({
                ...item,
                name: notificationNames[item?.contextId as string],
            })) as INotification[];

            allNotifications = allNotifications.filter(
                (notification) => notification.contextType === 'Card'
            );

            setNotifications((notifications) => {
                return notifications.concat(allNotifications);
            });

            setToken(nextToken);
        }
    };

    return (
        <NotificationContext.Provider
            value={{
                showNotifications,
                setShowNotifications,
                unreadNotifications,
                setUnreadNotifications,
                notifications,
                setNotifications,
                scoreNames,
                setScoreNames,
                selectedNotification,
                setSelectedNotification,
                modalOpen,
                setModalOpen,
            }}
        >
            <Box
                onClick={() => {
                    setShowNotifications(!showNotifications);
                    updateLastSeen();
                }}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                }}
            >
                {unreadNotifications.length > 0 && (
                    <Box
                        sx={{
                            width: '15px',
                            height: '15px',
                            borderRadius: '50%',
                            background: 'red',
                            color: '#ffffff',
                            fontSize: '12px',
                            lineHeight: '17px',
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                            zIndex: 10,
                            textAlign: 'center',
                            fontWeight: 600,
                            cursor: 'pointer',
                        }}
                    >
                        {unreadNotifications.length}
                    </Box>
                )}
                <IconButton
                    aria-label="close"
                    sx={{
                        width: '30px',
                        height: '30px',
                        mb: 1,
                    }}
                    size="large"
                >
                    <Notifications />
                </IconButton>
            </Box>
            <Box ref={clickArea}>
                {showNotifications && (
                    <NotificationPanel
                        loadMore={loadMore}
                        token={token}
                        fingerprintOn={fingerprintOn}
                        setFingerprintOn={setFingerprintOn}
                        updateLastSeen={updateLastSeen}
                    />
                )}
            </Box>
        </NotificationContext.Provider>
    );
};

export default Notification;
