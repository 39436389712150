export enum UserPermissions {
    EDIT = 'edit',
    DELETE = 'delete',
}
export enum WorksheetSize {
    DEFAULT = 'default',
    SMALL = 'small',
    CAROUSEL = 'carousel',
}

export interface AuthSession {
    userSub: string;
    tokens: {
        idToken: {
            payload: {
                sub: string;
            };
        };
    };
}
