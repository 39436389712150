import {
    PropsWithChildren,
    useState,
    useEffect,
    useMemo,
    useContext,
} from 'react';
import { Box, IconButton, darken, styled } from '@mui/material';
import { ReactComponent as MenuIcon } from '../../assets/icons/Menu.svg';
import { useNavigate, Link } from 'react-router-dom';
import CardActionArea from '@mui/material/CardActionArea';

import { Category, getCategoryHex } from '../../helpers/category';
import { getScoreColorSx } from '../../helpers/scores';
import HeaderScore from '../playbooks/HeaderScore';
import CardBase from './CardBase';
import CardSummary from './CardSummary';
import { CardComponentProps, CardComponentType, CardPage } from './cardTypes';

import { UserPermissions } from '../../globals';
import CardMenu from './CardMenu';
import CardHelp from './CardHelp';
import { Lightbox } from './CardComponent';

const StyledLayerOne = styled('div')<{ categoryId: string }>(
    ({ categoryId }) => ({
        borderRadius: '16px',
        backgroundColor: darken(getCategoryHex(categoryId), 0.1),
        boxShadow: '0px 0px 13px rgba(0,0,0,0.40)',
        position: 'absolute',
        zIndex: '-1',
        width: '100%',
        height: '100%',
        top: '0.150rem',
        left: '0.250rem',
    })
);

const CardComponentSmall = (props: PropsWithChildren<CardComponentProps>) => {
    const [permissions, setPermissions] = useState<UserPermissions[]>([]);
    const [helpTextAnchor, setHelpTextAnchor] = useState<HTMLElement | null>();
    const {
        data,
        onClick,
        handleCardClick,
        showPage,
        expanded,
        handleClose,
        multiSelectedCards,
        setMultiSelectedCards,
        cardsObject,
        mediumCard,
        smallCard,
        handleSelect,
        clickedItems,
        swotGrid,
        swotColor,
        hideHelp,
        activeId,
    } = props;

    const {
        id,
        briefDescription,
        // cardCategory,
        cardComponentType,
        cardCategoryId,
        cardToCardCategoryId,
        cardTypeId,
        cardTypeName,
        link,
        name,
        scoreDefinition,
        scoreValue,
        scoreValues,
        organisation,
        scoreName,
    } = data;

    const isSelected = useMemo(() => {
        if (clickedItems) {
            return clickedItems.some((card) => card.id === id);
        } else if (multiSelectedCards) {
            return multiSelectedCards.some((card) => card.id === id);
        }
    }, [multiSelectedCards, id]);

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();

    const menuItems = [];

    menuItems.push({
        text: 'Remove from workbook',
        action: () => {
            () => {};
        },
    });

    menuItems.push({
        text: 'Copy',
        action: () => {},
    });

    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            e.stopPropagation();
            setMenuAnchor(e.currentTarget);
        } else {
            setMenuAnchor(null);
        }
    };

    const handleClick = (cardPage?: CardPage) => {
        if (!!onClick) {
            onClick(data.id, cardPage);
        }
        if (!!handleCardClick) {
            handleCardClick(data);
        }
    };

    const handleHeaderClick = () => {
        if (
            cardComponentType === CardComponentType.CARD &&
            setMultiSelectedCards &&
            organisation &&
            permissions.includes(UserPermissions.DELETE)
        ) {
            setMultiSelectedCards((prevCards) => {
                const newCard = {
                    id: id,
                    organisation: organisation,
                    cardTypeId: cardsObject
                        ? cardsObject[id].toCardType.id
                        : undefined,
                    cardCategoryId: cardsObject
                        ? cardsObject[id].toCardCategory.id
                        : undefined,
                };
                const index = prevCards.findIndex((card) => card.id === id);

                if (index > -1) {
                    return [
                        ...prevCards.slice(0, index),
                        ...prevCards.slice(index + 1),
                    ];
                } else {
                    return [...prevCards, newCard];
                }
            });
        }
    };
    const setHelpTextModal = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            e?.stopPropagation();
            setHelpTextAnchor(e.currentTarget);
        } else {
            setHelpTextAnchor(null);
        }
    };

    return (
        <>
            {helpTextAnchor && <Lightbox />}
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    cursor: activeId ? 'grabbing' : 'pointer',
                }}
            >
                {isSelected && (
                    <Box
                        onClick={
                            handleSelect
                                ? () => handleSelect(data)
                                : handleHeaderClick
                        }
                        sx={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            borderRadius: '16px',
                            top: 0,
                            left: 0,
                            backgroundColor: `rgba(50, 50, 50, 0.5)`,
                            zIndex: 1009,
                            cursor: 'pointer',
                        }}
                    />
                )}
                <CardBase
                    sx={{
                        ...(swotGrid && {
                            boxShadow: 'none',
                            '.MuiPaper-root ': {
                                '&.MuiCard-root': {
                                    boxShadow: 'none!important',
                                },
                            },
                        }),
                        '.MuiCardHeader-avatar': {
                            svg: {
                                width: '25px',
                                height: '25px',
                            },
                        },
                    }}
                    hideHelp={hideHelp}
                    hideShadow={swotGrid ? true : false}
                    mediumCard={mediumCard}
                    smallCard={smallCard}
                    workbookCard={true}
                    headerRight={
                        scoreName && (
                            <HeaderScore
                                borderColor={getScoreColorSx(
                                    data.scoreValue,
                                    data.scoreDefinition?.minimumValue,
                                    data.scoreDefinition?.maximumValue,
                                    typeof scoreValue === 'number' &&
                                        scoreValue < 0,
                                    true
                                )}
                                name={scoreName}
                                value={scoreValue}
                                type={data.scoreDefinition?.scoreType}
                                mediumCard={mediumCard}
                                smallCard={smallCard}
                                swotColor={swotColor}
                                cardTypeName={cardTypeName}
                            />
                        )
                    }
                    data={{
                        id,
                        name,
                        category: cardCategoryId as Category,
                        cardTypeName,
                        cardComponentType,
                        organisation,
                    }}
                    swotColor={swotColor}
                    expanded={expanded}
                    subHeader={showPage}
                    handleClose={handleClose}
                    handleHeaderClick={handleHeaderClick}
                    activeId={activeId}
                    {...(cardComponentType === CardComponentType.CARD &&
                        !hideHelp && {
                            setHelpTextModal,
                        })}
                >
                    {props.children || (
                        <CardActionArea
                            {...{
                                onClick:
                                    !link && !mediumCard
                                        ? (e: any) => {
                                              e.stopPropagation();
                                              handleClick(CardPage.DETAILS);
                                          }
                                        : handleSelect
                                        ? () => handleSelect(data)
                                        : undefined,
                            }}
                            sx={{
                                overflow: 'hidden',
                                borderRadius: 'initial',
                                cursor: activeId ? 'grabbing' : 'pointer',
                            }}
                        >
                            <CardSummary
                                briefDescription={briefDescription}
                                name={name}
                                categoryId={cardCategoryId as Category}
                                cardTypeId={cardTypeId}
                                mediumCard={mediumCard}
                                smallCard={smallCard}
                                workbookCard
                                swotGrid={swotGrid}
                                swotColor={swotColor}
                            />
                        </CardActionArea>
                    )}

                    {cardComponentType === CardComponentType.CARD_SET && (
                        <>
                            <StyledLayerOne categoryId={cardCategoryId} />
                        </>
                    )}
                    {helpTextAnchor && (
                        <CardHelp
                            anchor={helpTextAnchor}
                            setHelpTextModal={setHelpTextModal}
                            setHelpTextAnchor={setHelpTextAnchor}
                            cardTypeId={cardTypeId}
                            cardCategoryId={cardCategoryId}
                        />
                    )}
                </CardBase>
            </Box>
        </>
    );
};

export default CardComponentSmall;
