import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {
    Box,
    IconButton,
    Skeleton,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserProfile } from '../../API';
import { AppContext, NotificationContext } from '../contexts';
import { truncateText } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import { downloadData } from 'aws-amplify/storage';

interface UserAvatarProps {
    userId?: string | null;
    isProfile?: boolean;
    cardCategory?: string | null;
    fullNameOnly?: boolean;
    avatarOnly?: boolean;
    smallAvatar?: boolean;
    fontSize?: string;
    width?: string;
    height?: string;
    column?: boolean;
}

const UserAvatar = ({
    userId,
    isProfile = false,
    cardCategory,
    fullNameOnly = false,
    avatarOnly = false,
    smallAvatar = false,
    fontSize,
    width,
    height,
    column,
}: UserAvatarProps) => {
    const theme = useTheme();
    const pageUrl = location.pathname;
    const { userProfileObject, users, userImages, setUserImages } =
        useContext(AppContext);
    const [initials, setInitials] = useState<string | null>(null);
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [image, setImage] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [avatarBackground, setAvatarBackground] = useState<string | null>('');
    const [deletedUserText, setDeletedUserText] = useState<string>('');

    const determineButtonColor = (currentPath: string, targetPath: string) => {
        return currentPath.includes(targetPath)
            ? theme.palette.primary.main
            : 'grey';
    };

    const createInitials = (profile: UserProfile | null) => {
        return `${profile?.firstName?.[0]?.toUpperCase() || ''}${
            profile?.lastName?.[0]?.toUpperCase() || ''
        }`;
    };

    const handleLoading = () => {
        setLoading(true);
        if (userProfileObject && !userId) {
            const profileData = userProfileObject.getUserProfile;
            setProfile(profileData);
            setInitials(createInitials(profileData));
            setLoading(false);
            return;
        }

        if (userId) getUser();
    };

    const getUser = async () => {
        try {
            const userProfile =
                users.find((user) => user.id === userId) ?? null;
            setProfile(userProfile);
            setInitials(createInitials(userProfile));

            if (userProfile?.status === 'Deleted') {
                setAvatarBackground('grey');
                setDeletedUserText('(Deleted user)');
            } else if (cardCategory) {
                setAvatarBackground(theme.palette.primary.main);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleLoading();
    }, [userId, userProfileObject]);

    useEffect(() => {
        handleLoading();
        const avatar = profile?.avatar;

        if (avatar) {
            const fetchFile = async () => {
                try {
                    let image: string;
                    if (
                        userImages &&
                        userImages.find((image) => image.name === avatar)
                    ) {
                        image =
                            userImages.find((image) => image.name === avatar)
                                ?.image ?? '';
                    } else {
                        const downloadResult: any = await downloadData({
                            key: `uploaded/${profile.organisation}/${avatar}`,
                        }).result;

                        image = URL.createObjectURL(downloadResult.body);

                        if (setUserImages) {
                            setUserImages((images) => {
                                const userImages = [...images];

                                if (
                                    userImages.findIndex(
                                        (userImage) => userImage.name === avatar
                                    ) === -1
                                ) {
                                    userImages.push({
                                        name: avatar,
                                        image: image,
                                    });
                                }

                                return userImages;
                            });
                        }
                    }

                    setImage(image);
                } catch (error) {
                    console.log('Error : ', error);
                }
            };
            if (avatar) {
                fetchFile();
            }
        }
    }, [profile]);

    if (loading && !fullNameOnly)
        return <Skeleton variant="circular" width={40} height={40} />;

    if (fullNameOnly && initials) {
        return (
            <Tooltip title={`${profile?.firstName} ${profile?.lastName}`} arrow>
                <Typography variant="body2" fontSize={fontSize ?? '0.875em'}>
                    {`${truncateText(profile?.firstName!, 10)} ${truncateText(
                        profile?.lastName!,
                        10
                    )}`}
                </Typography>
            </Tooltip>
        );
    }

    if (isProfile && !initials) {
        return (
            <Link to="/profile" style={{ textDecoration: 'none' }}>
                <IconButton>
                    <ManageAccountsIcon />
                </IconButton>
            </Link>
        );
    }

    if (initials) {
        const avatarElement = (
            <Typography
                style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: isProfile ? 'grey' : 'inherit',
                }}
            >
                {initials}
            </Typography>
        );

        if (isProfile) {
            return (
                <Tooltip title="Profile & settings" placement="right-start">
                    <Box
                        component={Link}
                        to="/profile"
                        sx={{
                            textDecoration: 'none',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: `2px solid grey`,
                            borderRadius: '50%',
                            width: width
                                ? width
                                : smallAvatar
                                ? '20px'
                                : '40px',
                            height: height
                                ? height
                                : smallAvatar
                                ? '20px'
                                : '40px',
                            paddingTop: image ? 0 : 0.3,
                            borderColor:
                                pageUrl === '/profile'
                                    ? theme.palette.primary.main
                                    : 'grey',
                            mt: '12px',
                            backgroundColor:
                                pageUrl === '/profile' ? '#fff' : 'inherit',
                            '@media screen and (min--moz-device-pixel-ratio: 0)':
                                {
                                    pt: !!image ? 0 : 0.5,
                                },
                            '&:hover': {
                                borderColor: theme.palette.primary.main,
                                backgroundColor: '#fff',
                            },
                        }}
                    >
                        {!!image ? (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    background: `url(${image})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    borderRadius: '50%',
                                }}
                            />
                        ) : (
                            avatarElement
                        )}
                    </Box>
                </Tooltip>
            );
        } else {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: '0px',
                        marginRight: '10px',
                    }}
                >
                    {!!image ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: width
                                        ? width
                                        : smallAvatar
                                        ? '15px'
                                        : '30px',
                                    height: height
                                        ? height
                                        : smallAvatar
                                        ? '15px'
                                        : '30px',
                                    background: `url(${image})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    borderRadius: '50%',
                                    mr: avatarOnly ? 1 : 0,
                                }}
                            />
                            {!avatarOnly && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        ml: 1,
                                        fontSize:
                                            fontSize ??
                                            (smallAvatar ? '12px' : 'inherit'),
                                    }}
                                >
                                    {`${profile?.firstName} ${profile?.lastName}`}
                                </Typography>
                            )}
                        </Box>
                    ) : (
                        <>
                            <Typography
                                sx={{
                                    backgroundColor:
                                        avatarBackground ||
                                        theme.palette.primary.dark,
                                    width: smallAvatar ? '15px' : '30px',
                                    height: smallAvatar ? '15px' : '30px',
                                    lineHeight: '30px',
                                    borderRadius: '50%',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    fontSize: smallAvatar ? '7px' : '14px',
                                    color: '#fff',
                                    '@media screen and (min--moz-device-pixel-ratio: 0)':
                                        {
                                            pt: '2px',
                                        },
                                }}
                            >
                                {initials}
                            </Typography>
                            {!avatarOnly && (
                                <Tooltip
                                    title={`${profile?.firstName} ${profile?.lastName}`}
                                    arrow
                                >
                                    <Box sx={{ width: '30px' }}>
                                        <Typography
                                            variant="body2"
                                            fontSize={
                                                smallAvatar ? '12px' : '0.875em'
                                            }
                                            sx={{ ml: 1 }}
                                        >
                                            {`${truncateText(
                                                profile?.firstName!,
                                                10
                                            )} ${truncateText(
                                                profile?.lastName!,
                                                10
                                            )} ${deletedUserText}`}
                                        </Typography>
                                    </Box>
                                </Tooltip>
                            )}
                        </>
                    )}
                </Box>
            );
        }
    }

    return null;
};

export default UserAvatar;
