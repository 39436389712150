import {
    AttributeDefinition,
    Card,
    CardSet,
    CardType,
    ScoreData,
    ScoreDefinition,
} from '../API';
import { MonthAndBatchGroup } from '../components/cards/CardActivity';
import { getMuiCssVar } from './utils';
import { lightGreen } from '@mui/material/colors';

const positiveScoreTlsColors = [
    'success.light',
    'success.main',
    'success.dark',
];

const headerNegativeColors = [
    '#AA0202',
    '#DD2D02',
    '#FF7002',
    '#FF8002',
    '#FFAB02',
];
const negativeScoreTlsColors = [
    'warning.light',
    'error.light',
    'warning.main',
    'warning.dark',
    'error.main',
    'error.dark',
];
export interface ScoreDef {
    [key: string]: ScoreDefinition;
}

export const getPrimaryScore = (
    scores?: (ScoreData | null)[] | null,
    defaultScoreDefinitionId?: string | null
) => {
    const score = scores?.find(
        (score) => score?.scoreDefinitionId === defaultScoreDefinitionId
    );

    return score;
};

export const getPrimaryScoreValue = (
    scores?: (ScoreData | null)[] | null,
    defaultScoreDefinitionId?: string | null
) => {
    const { data } = getPrimaryScore(scores, defaultScoreDefinitionId) || {};
    return data?.[data?.length - 1]?.value;
};

export const getScoreColorSx = (
    score?: number,
    min?: number | null,
    max?: number | null,
    negative?: boolean,
    header?: boolean
) => {
    if (score == null || min == null || max == null) {
        return 'primary.default';
    }

    const colors = negative
        ? header
            ? headerNegativeColors
            : negativeScoreTlsColors
        : positiveScoreTlsColors;

    const rangeSize = Math.abs(max - min) + 1;

    const stepSize = rangeSize / colors.length;

    let colorIdx = header
        ? Math.round(score - min)
        : Math.floor((score - min) / stepSize);

    if (colorIdx < 0) {
        colorIdx = 0;
    } else if (colorIdx >= colors.length) {
        colorIdx = colors.length - 1;
    }

    return colors[colorIdx];
};
export const getScoreColorVariable = (
    score?: number,
    min?: number | null,
    max?: number | null,
    negative?: boolean
) => {
    if (!score) {
        return '';
    }

    const paletteNames = getScoreColorSx(score, min, max, negative).split('.');
    const paletteName = getMuiCssVar(paletteNames.join('-'));

    return paletteName;
};

export const colors = [
    { score: -5, value: '#aa0202' },
    { score: -4, value: '#dd2d02' },
    { score: -3, value: '#ff7002' },
    { score: -2, value: '#ff8002' },
    { score: -1, value: '#ffab02' },
    { score: 0, value: '#9e9e9e' },
    { score: 1, value: '#02c853' },
    { score: 2, value: '#04af4b' },
    { score: 3, value: '#0ba34a' },
    { score: 4, value: '#08a248' },
    { score: 5, value: '#0f8740' },
];

export const getCardScoreDefinitions = (
    cardTypeObject: {
        [key: string]: CardType;
    },

    groupedEntries: MonthAndBatchGroup[] | null | undefined,
    card: Card | CardSet
) => {
    const scores: ScoreDefinition[] = [];
    const typeIds: string[] = [];

    if (card.__typename === 'Card') {
        typeIds.push(card.cardToCardTypeId);
    }

    groupedEntries?.forEach((groupedEntry) => {
        groupedEntry.batches.forEach((batch) => {
            batch.entries.forEach((entry) => {
                if (
                    entry.operation === 'CardTypeChanged' &&
                    entry.old &&
                    !typeIds.includes(entry.old)
                ) {
                    typeIds.push(entry.old);
                }
            });
        });
    });

    typeIds.forEach((typeId) => {
        if (cardTypeObject[typeId].scoreDefinitions) {
            cardTypeObject[typeId].scoreDefinitions.forEach(
                (scoreDefinition) => {
                    scores.push(scoreDefinition);
                }
            );
        }
    });

    return scores;
};
export const getAllScoreDefinitions = (cardTypes: CardType[]) => {
    const scores: ScoreDef = {};

    cardTypes.forEach((cardType) => {
        const scoreDefinitions = cardType.scoreDefinitions;

        scoreDefinitions.map((scoreDefinition) => {
            scores[scoreDefinition.id] = scoreDefinition;
        });
    });

    return scores;
};

export const getAllAttributeDefinitions = (
    cardTypeObject: {
        [key: string]: CardType;
    },
    groupedEntries: MonthAndBatchGroup[] | null | undefined,
    card: Card | CardSet
) => {
    const attributes: AttributeDefinition[] = [];
    const typeIds: string[] = [];

    if (card.__typename === 'Card') {
        typeIds.push(card.cardToCardTypeId);
    }

    groupedEntries?.forEach((groupedEntry) => {
        groupedEntry.batches.forEach((batch) => {
            batch.entries.forEach((entry) => {
                if (
                    entry.operation === 'CardTypeChanged' &&
                    entry.old &&
                    !typeIds.includes(entry.old)
                ) {
                    typeIds.push(entry.old);
                }
            });
        });
    });

    typeIds.forEach((typeId) => {
        if (cardTypeObject[typeId].attributeDefinitions) {
            cardTypeObject[typeId].attributeDefinitions?.forEach(
                (attributeDefinition) => {
                    attributes.push(attributeDefinition as AttributeDefinition);
                }
            );
        }
    });

    return attributes;
};
