// useAuthHub.ts
import { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify/utils';

type UseAuthHubResponse = {
    userUpdated: boolean;
    setUserUpdated: React.Dispatch<React.SetStateAction<boolean>>;
};

const useAuthHub = (): UseAuthHubResponse => {
    const [userUpdated, setUserUpdated] = useState(false);

    useEffect(() => {
        const removeListener = Hub.listen('auth', (data) => {
            const { payload } = data;
            if (payload.event === 'signedIn') {
                setUserUpdated(true);
            }
            if (payload.event === 'signedOut') {
                setUserUpdated(false);
            }
        });
        return () => removeListener();
    }, []);

    return { userUpdated, setUserUpdated };
};

export default useAuthHub;
