import { FC } from 'react';
import { StyledSelect, StyledTextField } from '../FormElements';
import CheckboxFields from './ControlledCheckboxFields';
import FieldProps, { FieldType } from './inputFieldTypes';
import RadioField from './RadioField';
import SelectField from './SelectField';
import TextField from './TextField';
import DateField from './DateField';
import UserField from './UserField';
import CardField from './CardField';
import CardsField from './CardsField';
import CardSelector from './CardSelector';
import WorksheetSelector from './WorksheetSelector';
import TitlePageConfiguration from './TitlePageConfiguration';
import { AttributeType } from '../../../API';
import { CardSettings } from '../../playbooks/EditablePlaybookPage';
import ChartSettings, { RadarOption } from './ChartSettings';
import LayoutSettings from './LayoutSettings';

interface SelectFieldProps {
    selectValue?: string | null;
    helperText?: string;
    handleInputChange?: (name: string, value: string) => void;
    checkboxValues?: Array<string | null> | null;
    maxLength?: number;
    pageOptions?: any;
    setPageOptions?: React.Dispatch<React.SetStateAction<undefined>>;
    radarOptions?: RadarOption[] | null | undefined;
    setRadarOptions?: React.Dispatch<
        React.SetStateAction<RadarOption[] | null | undefined>
    >;
}

type AllFieldProps = FieldProps & SelectFieldProps;

const InputField: FC<AllFieldProps> = (props) => {
    switch (props.type) {
        case AttributeType.Text:
            return (
                <TextField
                    {...props}
                    {...(props.selectValue && {
                        value: props.selectValue,
                    })}
                    {...(!!props.handleInputChange && {
                        onChange: (e) =>
                            props.handleInputChange &&
                            props.handleInputChange(props.name, e.target.value),
                    })}
                    maxLength={props.maxLength}
                />
            );
        case AttributeType.URL:
            return (
                <TextField
                    {...props}
                    {...(props.selectValue && {
                        value: props.selectValue,
                    })}
                    helperText={props.helperText}
                />
            );
        case AttributeType.MultilineText:
            return (
                <TextField
                    {...props}
                    multiline={true}
                    rows={4}
                    {...(props.selectValue && {
                        value: props.selectValue,
                    })}
                    {...(!!props.handleInputChange && {
                        onChange: (e) =>
                            props.handleInputChange &&
                            props.handleInputChange(props.name, e.target.value),
                    })}
                    sx={{ whiteSpace: 'pre-wrap' }}
                />
            );
        case AttributeType.SingleSelect:
            return <SelectField {...props} value={props.selectValue} />;
        case AttributeType.MultiSelect:
            return <StyledSelect {...props} multiple={true} />;
        case AttributeType.Date:
            return <DateField {...props} />;
        case AttributeType.User:
            return (
                <UserField
                    {...props}
                    {...(props.selectValue && {
                        value: props.selectValue,
                    })}
                />
            );
        case AttributeType.Card:
            return (
                <CardField
                    {...props}
                    {...(props.selectValue && {
                        value: props.selectValue,
                    })}
                />
            );
        case AttributeType.Cards:
            return (
                <CardsField
                    {...props}
                    {...(props.selectValue && {
                        value: props.selectValue,
                    })}
                />
            );
        case AttributeType.CardSet:
            return (
                <CardField
                    {...props}
                    {...(props.selectValue && {
                        value: props.selectValue,
                    })}
                />
            );
        case FieldType.CHECKBOX:
            return (
                <CheckboxFields
                    {...props}
                    {...(!!props.handleInputChange && {
                        onChange: (value: string) =>
                            props.handleInputChange &&
                            props.handleInputChange(props.name, value),
                    })}
                />
            );
        case FieldType.RADIO:
            return <RadioField {...props} />;
        case FieldType.CARDSELECTOR:
            return (
                <CardSelector
                    {...props}
                    {...(!!props.handleInputChange && {
                        onChangeCards: (name: string, value: string) =>
                            props.handleInputChange &&
                            props.handleInputChange(name, value),
                    })}
                    {...(props.cardSelector && {
                        cardSelector: props.cardSelector,
                    })}
                />
            );
        case FieldType.WORKSHEETSELECTOR:
            return (
                <WorksheetSelector
                    {...props}
                    {...(props.selectValue && {
                        value: props.selectValue,
                    })}
                    workbookForm
                />
            );
        case FieldType.CHARTSETTINGS:
            return (
                <ChartSettings
                    {...props}
                    {...(props.pageOptions && {
                        pageOptions: props.pageOptions,
                    })}
                    {...(props.setPageOptions && {
                        setPageOptions: props.setPageOptions,
                    })}
                    {...(props.radarOptions && {
                        radarOptions: props.radarOptions,
                    })}
                    {...(props.setRadarOptions && {
                        setRadarOptions: props.setRadarOptions,
                    })}
                />
            );
        case FieldType.LAYOUTSETTINGS:
            return (
                <LayoutSettings
                    {...props}
                    {...(props.pageOptions && {
                        pageOptions: props.pageOptions,
                    })}
                    {...(props.setPageOptions && {
                        setPageOptions: props.setPageOptions,
                    })}
                />
            );
        case FieldType.TITLECONFIG:
            return (
                <TitlePageConfiguration
                    {...props}
                    {...(props.selectValue && {
                        value: props.selectValue,
                    })}
                    {...(!!props.handleInputChange && {
                        onChange: (event: { target: { value: string } }) =>
                            props.handleInputChange &&
                            props.handleInputChange(
                                props.name,
                                event.target.value
                            ),
                    })}
                />
            );
        default:
            throw new Error('Invalid type');
    }
};

export default InputField;
