import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import React, { useEffect, useContext, useState } from 'react';
import { CheckboxOptionType } from './inputFieldTypes';

import { useLocation } from 'react-router-dom';

const LayoutSettings = (props: any) => {
    const theme = useTheme();
    const { pageOptions, setPageOptions } = props;
    const [layoutOptions, setLayoutOptions] = useState<any>([]);
    const location = useLocation();

    const handleCheckboxChange = (event: any) => {
        const { name, checked } = event.target;

        setPageOptions((prevOptions: any) => {
            const newOptions = { ...prevOptions };

            if (!newOptions.layout.length || !newOptions.layout[0].include) {
                let include = [{ value: name, enabled: checked }];

                if (!newOptions.layout.length) {
                    newOptions.layout = [];
                    newOptions.layout.push({ include: include });
                } else {
                    newOptions.layout[0].include = include;
                }
            } else {
                const index = newOptions.layout[0]?.include?.findIndex(
                    (value: any) => value.value === name
                );

                if (index !== -1) {
                    newOptions.layout[0].include[index].enabled = checked;
                } else {
                    newOptions.layout[0].include.push({
                        value: name,
                        enabled: checked,
                    });
                }
            }
            return newOptions;
        });
    };

    const handleLayoutCheckboxChange = (event: any) => {
        const { name, checked } = event.target;

        setPageOptions((prevOptions: any) => {
            const newOptions = JSON.parse(JSON.stringify(prevOptions));

            if (!newOptions.layout?.length || !newOptions.layout?.[0]?.layout) {
                let layout = [{ value: name, enabled: checked }];

                if (!newOptions.layout?.length) {
                    newOptions.layout = [];
                    newOptions.layout.push({ layout: layout });
                } else {
                    newOptions.layout[0].layout = layout;
                }
            } else {
                const index = newOptions.layout[0]?.layout?.findIndex(
                    (value: any) => value.value === name
                );

                if (index !== -1) {
                    newOptions.layout[0].layout[index].enabled = checked;
                } else {
                    newOptions.layout[0].layout.push({
                        value: name,
                        enabled: checked,
                    });
                }
            }
            return newOptions;
        });
    };

    useEffect(() => {
        if (props?.options?.layout) {
            const options = [...props.options.layout];

            if (location.pathname.includes('workbook')) {
                const index = options.findIndex(
                    (option) => option.value === 'logo'
                );

                if (index !== -1) {
                    options.splice(index, 1);
                }
            }

            setLayoutOptions(options);
        }
    }, [props?.options?.layout]);

    const boxStyle = {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px',
        px: '16px',
        my: 0.5,
        py: 1,
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Box
                sx={{
                    width: '50%',
                    mr: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography
                    variant="body1"
                    sx={{ fontWeight: 600, marginBottom: 0 }}
                >
                    Include
                </Typography>
                <Box sx={boxStyle}>
                    {props.options?.include?.map((item: any, index: number) => {
                        let enabled =
                            pageOptions?.layout?.[0]?.include?.find(
                                (value: any) => value.value === item.value
                            )?.enabled ?? false;

                        return (
                            <>
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={enabled}
                                            onChange={handleCheckboxChange}
                                            name={item.value}
                                            sx={{ padding: '6px' }}
                                        />
                                    }
                                    label={item.label}
                                    sx={{
                                        '& .MuiTypography-root': {
                                            fontSize: '14px',
                                        },
                                    }}
                                />
                                {item.children?.props.type === 'checkbox' &&
                                enabled ? (
                                    <>
                                        <>
                                            {Array.isArray(
                                                item.children?.props.options
                                            ) &&
                                                item.children?.props.options?.map(
                                                    (
                                                        option: CheckboxOptionType,
                                                        i: number
                                                    ) => {
                                                        let enabled =
                                                            pageOptions?.layout?.[0]?.include?.find(
                                                                (value: any) =>
                                                                    value.value ===
                                                                    option.value
                                                            )?.enabled ?? false;
                                                        return (
                                                            <>
                                                                <FormControlLabel
                                                                    sx={{
                                                                        ml: 2,
                                                                    }}
                                                                    key={
                                                                        option.label
                                                                    }
                                                                    label={
                                                                        option.label
                                                                    }
                                                                    control={
                                                                        <Checkbox
                                                                            checked={
                                                                                enabled
                                                                            }
                                                                            onChange={
                                                                                handleCheckboxChange
                                                                            }
                                                                            name={
                                                                                option.value
                                                                            }
                                                                            sx={{
                                                                                padding:
                                                                                    '6px',
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            </>
                                                        );
                                                    }
                                                )}
                                        </>
                                    </>
                                ) : (
                                    ''
                                )}
                            </>
                        );
                    })}
                </Box>
            </Box>
            {layoutOptions.length > 0 && (
                <Box sx={{ width: '45%' }}>
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: 600, marginBottom: 0 }}
                    >
                        Layout
                    </Typography>
                    <Box sx={boxStyle}>
                        {layoutOptions?.map((item: any, index: number) => {
                            let enabled =
                                pageOptions?.layout?.[0]?.layout?.find(
                                    (value: any) => value.value === item.value
                                )?.enabled ?? false;

                            return (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={enabled}
                                            onChange={
                                                handleLayoutCheckboxChange
                                            }
                                            name={item.value}
                                            sx={{ padding: '6px' }}
                                        />
                                    }
                                    label={item.text}
                                    sx={{
                                        '& .MuiTypography-root': {
                                            fontSize: '14px',
                                        },
                                    }}
                                />
                            );
                        })}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default LayoutSettings;
