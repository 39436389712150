import { Box, Tooltip, Typography } from '@mui/material';
import {
    AttributeDefinition,
    AuditEntry,
    AuditType,
    CardType,
    EntityType,
    EventContext,
    Organisation,
    ScoreDefinition,
} from '../API';
import {
    shortDateStringFromISO,
    getMonth,
    timeStringFromISO,
    truncateText,
} from './utils';
import UserAvatar from '../components/profile/UserAvatar';
import {
    BatchGroupedEntry,
    DiffText,
    MonthAndBatchGroup,
} from '../components/cards/CardActivity';
import { worksheets } from '../components/forms/worksheets';
import { ScoreDef } from './scores';

type Names = { [key: string]: string };

export const toSentenceCase = (input: string): string => {
    const parts = input.split(/(?=[A-Z])/);
    const lowercased = parts.map((part, index) =>
        index === 0
            ? part.toLowerCase()
            : part.charAt(0).toLowerCase() + part.slice(1)
    );
    const result = lowercased.join(' ');
    return result.charAt(0).toUpperCase() + result.slice(1);
};
export const groupByMonthAndBatch = (
    entries: AuditEntry[]
): MonthAndBatchGroup[] => {
    const monthGroups = entries.reduce(
        (acc: Record<string, AuditEntry[]>, entry: AuditEntry) => {
            const month = getMonth(entry.createdAt, 'long');
            if (!acc[month]) {
                acc[month] = [];
            }
            acc[month].push(entry);
            return acc;
        },
        {}
    );
    const finalGroups: MonthAndBatchGroup[] = Object.entries(monthGroups).map(
        ([month, monthEntries]) => {
            const batchGroups: Record<string, AuditEntry[]> =
                monthEntries.reduce(
                    (
                        batchAcc: Record<string, AuditEntry[]>,
                        entry: AuditEntry
                    ) => {
                        const batchId = entry.batchId || 'other';

                        if (!batchAcc[batchId]) {
                            batchAcc[batchId] = [];
                        }
                        batchAcc[batchId].push(entry);
                        return batchAcc;
                    },
                    {}
                );
            const batches = Object.entries(batchGroups).map(
                ([batchId, entries]): BatchGroupedEntry => ({
                    batchId,
                    entries,
                })
            );

            return { month, batches };
        }
    );

    return finalGroups;
};
export const splitString = (
    input: string,
    scoreDefinitions?: ScoreDef | null
) => {
    const parts = input.split('#');
    if (scoreDefinitions) {
        const scoreDetail = scoreDefinitions[parts[0]];

        if (!scoreDetail) {
            throw new Error('No matching score definition found');
        }
        return {
            name: scoreDetail.name,
            date: parts[1],
        };
    } else {
        return {
            score: parts[0],
            commentary: parts[1],
        };
    }
};
export const renderSecondaryAttributeCreation = (
    entry: AuditEntry,
    attributeDefinitions: (AttributeDefinition | null)[] | null | undefined,
    setDiffText: React.Dispatch<React.SetStateAction<DiffText>>,
    setDiffModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => (
    <Typography variant="body2" sx={{ mb: 1 }}>
        {
            attributeDefinitions?.find((item) => item?.id === entry.subContext)
                ?.name
        }
        &nbsp;was added as&nbsp;
        <span>"{entry.new && truncateText(entry.new, 100)}".</span>
        {entry?.new && entry?.new?.length > 100 && (
            <span
                style={{
                    fontStyle: 'italic',
                    fontSize: '10px',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setDiffText({
                        old: '',
                        new: entry.new || '',
                    });
                    setDiffModalOpen(true);
                }}
            >
                &nbsp;more
            </span>
        )}
    </Typography>
);
export const renderSecondaryAttributeUpdate = (
    entry: AuditEntry,
    attributeDefinitions: (AttributeDefinition | null)[] | null | undefined,
    setDiffText: React.Dispatch<React.SetStateAction<DiffText>>,
    setDiffModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    if (entry.old) {
        return (
            <Box>
                <Typography variant="body2" sx={{ mb: 1 }}>
                    {
                        attributeDefinitions?.find(
                            (item) => item?.id === entry.subContext
                        )?.name
                    }{' '}
                    was updated as follows: from&nbsp;
                    <span>"{truncateText(entry.old, 100)}"</span>
                    &nbsp;to&nbsp;<span>"{entry.new}".</span>
                    {entry.new && entry.old.length + entry.new.length > 100 && (
                        <span
                            style={{
                                fontStyle: 'italic',
                                fontSize: '10px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setDiffText({
                                    old: entry.old || '',
                                    new: entry.new || '',
                                });
                                setDiffModalOpen(true);
                            }}
                        >
                            &nbsp;more
                        </span>
                    )}
                </Typography>
            </Box>
        );
    }
};
export const renderMainAttributeCreation = (
    entry: AuditEntry,
    setDiffText: React.Dispatch<React.SetStateAction<DiffText>>,
    setDiffModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => (
    <Typography variant="body2">
        {entry?.subContext && toSentenceCase(entry.subContext)} was added as
        &nbsp;"
        {entry.new && truncateText(entry.new, 200)}".
        {entry?.new && entry?.new?.length > 200 && (
            <span
                style={{
                    fontStyle: 'italic',
                    fontSize: '10px',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setDiffText({
                        old: '',
                        new: entry.new || '',
                    });
                    setDiffModalOpen(true);
                }}
            >
                &nbsp;more
            </span>
        )}
    </Typography>
);
export const renderMainAttributeUpdate = (
    entry: AuditEntry,
    setDiffText: React.Dispatch<React.SetStateAction<DiffText>>,
    setDiffModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) =>
    entry.old && (
        <Box>
            <Typography variant="body2" sx={{ mb: 1 }}>
                {entry?.subContext && toSentenceCase(entry.subContext)}
                &nbsp;was updated as follows: from{' '}
                <span>"{truncateText(entry.old, 100)}"</span>
                &nbsp;to&nbsp;
                <span>"{entry.new && truncateText(entry.new, 100)}".</span>
                {entry.new && entry.old.length + entry.new.length > 100 && (
                    <span
                        style={{
                            fontStyle: 'italic',
                            fontSize: '10px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setDiffText({
                                old: entry.old || '',
                                new: entry.new || '',
                            });
                            setDiffModalOpen(true);
                        }}
                    >
                        &nbsp;more
                    </span>
                )}
            </Typography>
        </Box>
    );
const renderCommentCreation = (entry: AuditEntry, names?: Names) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <UserAvatar
                    userId={entry?.user?.split('::')[0]}
                    fontSize={'14px'}
                    cardCategory={'default'}
                    avatarOnly
                />
                <Typography variant="body2">
                    &nbsp;added the following comment{' '}
                    <span style={{ fontStyle: 'italic' }}>
                        {names && `to ${names[entry.contextId]} `}
                    </span>{' '}
                    on{' '}
                    <Tooltip
                        title={`${timeStringFromISO(entry?.createdAt)}`}
                        placement="top"
                    >
                        <span style={{ fontWeight: 'bold' }}>
                            {shortDateStringFromISO(entry?.createdAt)}:
                        </span>
                    </Tooltip>
                </Typography>
            </Box>
            <Typography variant="body2" sx={{ mt: 2, ml: 0.3 }}>
                "{entry.new}"
            </Typography>
        </Box>
    );
};
const renderCommentUpdate = (
    entry: AuditEntry,
    setDiffText: React.Dispatch<React.SetStateAction<DiffText>>,
    setDiffModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    if (!entry.new) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <UserAvatar
                    userId={entry?.user?.split('::')[0]}
                    fontSize={'14px'}
                    cardCategory={'default'}
                    avatarOnly
                />
                <Typography variant="body2">
                    &nbsp;deleted a comment on
                    <Tooltip
                        title={`${timeStringFromISO(entry?.createdAt)}`}
                        placement="top"
                    >
                        <span
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {' '}
                            {shortDateStringFromISO(entry?.createdAt)}.
                        </span>
                    </Tooltip>
                </Typography>
            </Box>
        );
    } else {
        return (
            entry.old && (
                <Box>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                        A comment &nbsp;was updated as follows: from{' '}
                        <span>"{truncateText(entry.old, 100)}"</span>
                        &nbsp;to&nbsp;
                        <span>
                            "{entry.new && truncateText(entry.new, 100)}".
                        </span>
                        {entry.new &&
                            entry.old.length + entry.new.length > 100 && (
                                <span
                                    style={{
                                        fontStyle: 'italic',
                                        fontSize: '10px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setDiffText({
                                            old: entry.old || '',
                                            new: entry.new || '',
                                        });
                                        setDiffModalOpen(true);
                                    }}
                                >
                                    &nbsp;more
                                </span>
                            )}
                    </Typography>
                </Box>
            )
        );
    }
};
const renderReactionUpdate = (entry: AuditEntry, names?: Names) => {
    const action =
        entry.operation === AuditType.ReactionAdded ? 'liked' : 'unliked';
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <UserAvatar
                    userId={entry?.user?.split('::')[0]}
                    fontSize={'14px'}
                    cardCategory={'default'}
                    avatarOnly
                />
                <Typography variant="body2">
                    &nbsp;{`${action}`} a comment on
                    <span style={{ fontStyle: 'italic' }}>
                        {names && ` ${names[entry.contextId]}`}
                    </span>
                    {names && ` on`}
                    <Tooltip
                        title={`${timeStringFromISO(entry?.createdAt)}`}
                        placement="top"
                    >
                        <span
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {' '}
                            {shortDateStringFromISO(entry?.createdAt)}:
                        </span>
                    </Tooltip>
                </Typography>
            </Box>
            <Typography variant="body2" sx={{ mt: 2, ml: 0.3 }}>
                "{names && names[entry.subContext as string]}"
            </Typography>
        </Box>
    );
};
const renderUpdate = (
    entry: AuditEntry,
    setDiffText: React.Dispatch<React.SetStateAction<DiffText>>,
    setDiffModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    scoreDefinitions: ScoreDef | null,
    cardTypeName: string
) => {
    if (entry.subContextType === EntityType.ScoreData) {
        return renderScoreUpdate(entry, scoreDefinitions, cardTypeName);
    } else if (entry.subContextType === EntityType.Comment) {
        return renderCommentUpdate(entry, setDiffText, setDiffModalOpen);
    }
};
const renderCardCreation = (
    entry: AuditEntry,
    cardTypeName: string,
    names?: Names
) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <UserAvatar
            userId={entry?.user?.split('::')[0]}
            fontSize={'14px'}
            cardCategory={'default'}
            avatarOnly
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body2">
                Created {entry.contextType?.toLowerCase()} &nbsp;
                <span style={{ fontStyle: 'italic' }}>
                    {names && names[entry.contextId]}
                </span>
                &nbsp; on{' '}
                <Tooltip
                    title={`${timeStringFromISO(entry?.createdAt)}`}
                    placement="top"
                >
                    <span style={{ fontWeight: 'bold' }}>
                        {shortDateStringFromISO(entry?.createdAt)}
                        {entry.eventContext !== EventContext.Worksheet && '.'}
                    </span>
                </Tooltip>
            </Typography>
            {entry.eventContext === EventContext.Worksheet && (
                <Typography variant="body2">
                    from worksheet{' '}
                    <span style={{ fontStyle: 'italic' }}>
                        {names && names[entry.eventContextId as string]}
                    </span>
                    .
                </Typography>
            )}
        </Box>
    </Box>
);
export const renderItemCreation = (
    entry: AuditEntry,
    scoreDefinitions: ScoreDef | null,
    cardTypeName: string,
    names?: Names
) => {
    if (entry.subContextType === EntityType.Comment) {
        return renderCommentCreation(entry, names);
    } else if (entry.subContextType === EntityType.ScoreData) {
        return renderScoreUpdate(entry, scoreDefinitions, cardTypeName);
    }
    return renderCardCreation(entry, cardTypeName, names);
};

export const renderRestore = (entry: AuditEntry, cardTypeName: string) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <UserAvatar
                userId={entry?.user?.split('::')[0]}
                fontSize={'14px'}
                cardCategory={'default'}
                avatarOnly
            />
            <Typography variant="body2">
                &nbsp;restored the {entry.contextType?.toLowerCase()} on
                <Tooltip
                    title={`${timeStringFromISO(entry?.createdAt)}`}
                    placement="top"
                >
                    <span
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {' '}
                        {shortDateStringFromISO(entry?.createdAt)}.
                    </span>
                </Tooltip>
            </Typography>
        </Box>
    );
};
export const renderSoftDelete = (entry: AuditEntry, cardTypeName: string) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <UserAvatar
                userId={entry?.user?.split('::')[0]}
                fontSize={'14px'}
                cardCategory={'default'}
                avatarOnly
            />
            <Typography variant="body2">
                &nbsp;deleted the {entry.contextType?.toLowerCase()} on
                <Tooltip
                    title={`${timeStringFromISO(entry?.createdAt)}`}
                    placement="top"
                >
                    <span
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {' '}
                        {shortDateStringFromISO(entry?.createdAt)}.
                    </span>
                </Tooltip>
            </Typography>
        </Box>
    );
};
export const renderRelationshipUpdate = (
    entry: AuditEntry,
    cardTypeName: string
) => {
    if (entry.subContextType === EntityType.PlaybookPage) {
        const addedWorksheetName = worksheets.find(
            (item) => item.i === entry?.new?.split('#')[1]
        )?.n;
        const removedWorksheetName = worksheets.find(
            (item) => item.i === entry?.old?.split('#')[1]
        )?.n;
        return (
            <Typography variant="body2">
                {entry.old
                    ? `"${removedWorksheetName}" worksheet was removed.`
                    : `"${addedWorksheetName}" worksheet was added.`}
            </Typography>
        );
    }
    if (entry.operation === AuditType.ItemRemovedFrom) {
        return (
            <Typography variant="body2">
                "{entry?.old?.split('#')[1]}" was removed.
            </Typography>
        );
    } else if (entry.operation === AuditType.ItemAddedTo) {
        return (
            <Typography variant="body2">
                "{entry?.new?.split('#')[1]}" was added.
            </Typography>
        );
    }
};

export const renderPermissionUpdate = (
    entry: AuditEntry,
    organisationProfile: Organisation | null | undefined,
    cardTypeName: string
) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
            <UserAvatar
                userId={
                    entry?.subContext?.includes('::')
                        ? entry?.subContext?.split('::')[0]
                        : entry.subContext
                }
                fontSize={'14px'}
                cardCategory={'default'}
                avatarOnly
            />
            <Typography variant="body2">
                &nbsp;
                {organisationProfile?.id === entry.subContext
                    ? `Organization-wide edit permissions was ${
                          entry.operation === AuditType.PermissionRevoked
                              ? `revoked`
                              : `granted`
                      }.`
                    : entry.operation === AuditType.PermissionRevoked
                    ? `was removed from the member list.`
                    : `was added as a member.`}
            </Typography>
        </Box>
    );
};
export const renderOwnerChange = (entry: AuditEntry, cardTypeName: string) => (
    <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2">
            Card owner was changed from&nbsp;
        </Typography>{' '}
        <UserAvatar
            userId={entry?.old?.split('::')[0]}
            fontSize={'14px'}
            cardCategory={'default'}
            avatarOnly
        />{' '}
        <Typography variant="body2">&nbsp;to&nbsp;</Typography>{' '}
        <UserAvatar
            userId={entry?.new?.split('::')[0]}
            fontSize={'14px'}
            cardCategory={'default'}
            avatarOnly
        />
        .
    </Box>
);

export const renderScoreUpdate = (
    entry: AuditEntry,
    scoreDefinitions: ScoreDef | null,
    cardTypeName: string
) => {
    const scoreData = splitString(entry.subContext || '', scoreDefinitions);
    if (!entry.new) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <UserAvatar
                    userId={entry?.user?.split('::')[0]}
                    fontSize={'14px'}
                    cardCategory={'default'}
                    avatarOnly
                />
                <Typography variant="body2">
                    &nbsp;deleted "{scoreData.name}" score for{' '}
                    {shortDateStringFromISO(scoreData.date || '')} on{' '}
                    <Tooltip
                        title={`${timeStringFromISO(entry?.createdAt)}`}
                        placement="top"
                    >
                        <span
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {' '}
                            {shortDateStringFromISO(entry?.createdAt)}.
                        </span>
                    </Tooltip>
                </Typography>
            </Box>
        );
    }
    if (entry.old) {
        const oldScore = splitString(entry.old);
        const newScore = splitString(entry.new || '');
        const sameScoreValue = oldScore.score === newScore.score;
        const sameCommentary = newScore.commentary === oldScore.commentary;
        return (
            <Box sx={{ mb: 1 }}>
                <Typography variant="body2">
                    "{scoreData.name}" score for{' '}
                    {shortDateStringFromISO(scoreData.date || '')}
                    {!sameScoreValue
                        ? ` was changed
                    from "${oldScore.score}" to "${newScore.score}" `
                        : ' remained the same'}
                    {newScore.commentary &&
                        !sameCommentary &&
                        ` and the commentary ${`was ${
                            oldScore.commentary === undefined
                                ? `added as`
                                : `changed from`
                        }
    ${oldScore.commentary !== undefined ? `"${oldScore.commentary}" to` : ''}
    "${newScore.commentary}"`}.`}
                </Typography>
            </Box>
        );
    } else {
        const newScore = splitString(entry.new || '');

        return (
            <Box sx={{ mb: 1 }}>
                <Typography variant="body2">
                    "{scoreData.name}" score for{' '}
                    {shortDateStringFromISO(scoreData.date || '')} was added
                    with a value of "{newScore.score}"
                    {!newScore.commentary && '.'}
                    {newScore.commentary &&
                        ` and a commentary of "${newScore.commentary}".`}
                </Typography>
            </Box>
        );
    }
};

const renderAttributes = (
    entry: AuditEntry,
    attributeDefinitions: (AttributeDefinition | null)[] | null | undefined,
    setDiffText: React.Dispatch<React.SetStateAction<DiffText>>,
    setDiffModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    cardTypeName: string
) => {
    const { subContext, old } = entry;

    if (subContext?.includes('-')) {
        return old ? (
            <>
                {renderSecondaryAttributeUpdate(
                    entry,
                    attributeDefinitions,
                    setDiffText,
                    setDiffModalOpen
                )}
            </>
        ) : (
            <>
                {renderSecondaryAttributeCreation(
                    entry,
                    attributeDefinitions,
                    setDiffText,
                    setDiffModalOpen
                )}
            </>
        );
    } else {
        return old ? (
            <>
                {renderMainAttributeUpdate(
                    entry,
                    setDiffText,
                    setDiffModalOpen
                )}
            </>
        ) : (
            <>
                {renderMainAttributeCreation(
                    entry,
                    setDiffText,
                    setDiffModalOpen
                )}
            </>
        );
    }
};

export const renderTypeChange = (entry: AuditEntry, cardTypes?: CardType[]) => {
    const oldType = cardTypes?.find((cardType) => cardType.id === entry.old);
    const newType = cardTypes?.find((cardType) => cardType.id === entry.new);

    return (
        <Typography variant="body2">{`Changed card type from ${oldType?.name} to ${newType?.name}`}</Typography>
    );
};

export const renderRelationship = (entry: AuditEntry, names?: Names) => {
    if (
        (entry.contextType === EntityType.Worksheet ||
            entry.contextType === EntityType.Card) &&
        entry.subContextType === 'Relationship'
    ) {
        const newValue = entry.new?.split('#');
        const oldValue = entry.old?.split('#');

        let cardName = '';
        let worksheetName = '';

        if (names) {
            if (entry.contextType === EntityType.Worksheet) {
                cardName = newValue
                    ? names[newValue[1]]
                    : oldValue
                    ? names[oldValue[1]]
                    : '';
            } else if (entry.contextType === EntityType.Card) {
                worksheetName = newValue
                    ? names[newValue[1]]
                    : oldValue
                    ? names[oldValue[1]]
                    : '';
            }
        }

        if (
            (newValue && newValue[0] === 'Card') ||
            (oldValue && oldValue[0] === 'Card') ||
            (newValue && newValue[0] === 'Worksheet') ||
            (oldValue && oldValue[0] === 'Worksheet')
        ) {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <UserAvatar
                        userId={entry?.user?.split('::')[0]}
                        fontSize={'14px'}
                        cardCategory={'default'}
                        avatarOnly
                    />
                    {(newValue && newValue[0] === 'Card') ||
                    (oldValue && oldValue[0] === 'Card') ? (
                        <Typography variant="body2">
                            {newValue ? 'Added' : 'Removed'} card &nbsp;
                            <span style={{ fontStyle: 'italic' }}>
                                {cardName ?? 'DELETED'}
                            </span>{' '}
                            {newValue ? 'to' : 'from'} worksheet on{' '}
                            <Tooltip
                                title={`${timeStringFromISO(entry?.createdAt)}`}
                                placement="top"
                            >
                                <span style={{ fontWeight: 'bold' }}>
                                    {shortDateStringFromISO(entry?.createdAt)}:
                                </span>
                            </Tooltip>
                        </Typography>
                    ) : (
                        <Typography variant="body2">
                            {newValue ? 'Added' : 'Removed'} card &nbsp;
                            {newValue ? 'to' : 'from'} worksheet{' '}
                            <span style={{ fontStyle: 'italic' }}>
                                {worksheetName ?? 'DELETED'}
                            </span>{' '}
                            on{' '}
                            <Tooltip
                                title={`${timeStringFromISO(entry?.createdAt)}`}
                                placement="top"
                            >
                                <span style={{ fontWeight: 'bold' }}>
                                    {shortDateStringFromISO(entry?.createdAt)}:
                                </span>
                            </Tooltip>
                        </Typography>
                    )}
                </Box>
            );
        } else {
            return 'deleted';
        }
    }
};
export const createUpdateMessage = (
    entry: AuditEntry,
    attributeDefinitions: (AttributeDefinition | null)[] | null | undefined,
    setDiffText: React.Dispatch<React.SetStateAction<DiffText>>,
    setDiffModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    organisationProfile: Organisation | null | undefined,
    scoreDefinitions: ScoreDef | null,
    cardTypeName: string,
    cardTypes?: CardType[],
    names?: { [key: string]: string }
) => {
    const { operation } = entry;
    switch (operation) {
        case AuditType.ItemAddedTo:
        case AuditType.ItemRemovedFrom:
            return <>{renderRelationshipUpdate(entry, cardTypeName)}</>;
        case AuditType.ScoreUpdated:
            return (
                <>{renderScoreUpdate(entry, scoreDefinitions, cardTypeName)}</>
            );
        case AuditType.OwnerChanged:
            return <>{renderOwnerChange(entry, cardTypeName)}</>;
        case AuditType.PermissionGranted:
        case AuditType.PermissionRevoked:
            return (
                <>
                    {renderPermissionUpdate(
                        entry,
                        organisationProfile,
                        cardTypeName
                    )}
                </>
            );
        case AuditType.ItemCreated:
            return (
                <>
                    {renderItemCreation(
                        entry,
                        scoreDefinitions,
                        cardTypeName,
                        names
                    )}
                </>
            );
        case AuditType.ItemRestored:
            return <>{renderRestore(entry, cardTypeName)}</>;
        case AuditType.ReactionAdded:
        case AuditType.ReactionRemoved:
            return <>{renderReactionUpdate(entry, names)}</>;
        case AuditType.ItemSoftDeleted:
            return <>{renderSoftDelete(entry, cardTypeName)}</>;
        case AuditType.AttributeUpdated:
            return (
                <>
                    {renderAttributes(
                        entry,
                        attributeDefinitions,
                        setDiffText,
                        setDiffModalOpen,
                        cardTypeName
                    )}
                </>
            );
        case AuditType.ItemUpdated:
        case AuditType.ItemHardDeleted:
            return (
                <>
                    {renderUpdate(
                        entry,
                        setDiffText,
                        setDiffModalOpen,
                        scoreDefinitions,
                        cardTypeName
                    )}
                </>
            );
        case AuditType.CardTypeChanged:
            return <>{renderTypeChange(entry, cardTypes)}</>;
        case AuditType.ItemLinked:
        case AuditType.ItemUnlinked:
            return <>{renderRelationship(entry, names)}</>;
    }
};
