import { generateClient } from 'aws-amplify/api';
import { GraphQLQuery } from '@aws-amplify/api';
import { CreateCardsCardSetsInput, CreateCardsCardSetsMutation } from '../API';
import { copyCardSet, createCardsCardSets } from '../graphql/mutations';

const client = generateClient();

export const addCardToCardSet = async (data: CreateCardsCardSetsInput) =>
    (await client.graphql<GraphQLQuery<CreateCardsCardSetsMutation>>({
        query: createCardsCardSets,
        variables: {
            input: {
                ...data,
            },
        },
    })) as { data: CreateCardsCardSetsMutation };

export const handleCopyCardSet = async (cardSetId: string) => {
    try {
        const res = await client.graphql({
            query: copyCardSet,
            variables: {
                originalCardSetId: cardSetId,
            },
        });
        return res.data.copyCardSet;
    } catch (err) {
        console.log(err);
    }
};
