import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FilterOptions, PageIdentifier } from '../cardTypes';
import { Box, Typography, useTheme } from '@mui/material';

interface PrimaryFilterColumnProps {
    checked: Record<FilterOptions, boolean>;
    selectedPrimaryFilter: FilterOptions | null;
    setSelectedPrimaryFilter: React.Dispatch<
        React.SetStateAction<FilterOptions | null>
    >;
    pageIdentifier: string;
}

const PrimaryFilterColumn = ({
    checked,
    selectedPrimaryFilter,
    setSelectedPrimaryFilter,
    pageIdentifier,
}: PrimaryFilterColumnProps) => {
    const theme = useTheme();
    const getFiltersToShow = () => {
        switch (pageIdentifier) {
            case PageIdentifier.CARD_SETS:
            case PageIdentifier.WORKBOOK_INSIDE:
                return {
                    [FilterOptions.CARD_TYPE]: FilterOptions.CARD_TYPE,
                    [FilterOptions.OWNER]: FilterOptions.OWNER,
                };
            case PageIdentifier.CARD_SET_INSIDE:
                return {
                    [FilterOptions.CARD_TYPE]: FilterOptions.CARD_TYPE,
                    [FilterOptions.OWNER]: FilterOptions.OWNER,
                };
            case PageIdentifier.WORKBOOKS:
            case PageIdentifier.PLAYBOOKS:
                return {
                    [FilterOptions.OWNER]: FilterOptions.OWNER,
                };
            default:
                return FilterOptions;
        }
    };

    const primaryFilters = Object.entries(getFiltersToShow());

    const handleLabelClick = (value: FilterOptions) => {
        setSelectedPrimaryFilter(value);
    };
    return (
        <Box sx={{ borderRight: '1px solid #f0f2f5', height: '100%' }}>
            <Typography variant="body1" sx={{ ml: 2, fontWeight: 'bold' }}>
                Filter by
            </Typography>
            {primaryFilters.map(([key, value]) => {
                const isSelected =
                    selectedPrimaryFilter === (value as FilterOptions);
                return (
                    <Box
                        key={key}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 1.8,
                            color: isSelected ? '#fff' : 'inherit',
                            backgroundColor: isSelected
                                ? theme.palette.primary.main
                                : checked[value as FilterOptions]
                                ? theme.palette.background.paper
                                : 'transparent',
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: !isSelected
                                    ? theme.palette.background.paper
                                    : theme.palette.primary.main,
                            },
                        }}
                        onClick={() => handleLabelClick(value as FilterOptions)}
                    >
                        <Checkbox
                            data-testid="filter-option"
                            checked={checked[value as FilterOptions]}
                            name={value}
                            color={isSelected ? 'default' : 'primary'}
                            style={{ color: isSelected ? '#fff' : '' }}
                        />
                        <Typography
                            sx={{
                                height: '35px',
                                lineHeight: '35px',
                            }}
                        >
                            {value}
                        </Typography>
                    </Box>
                );
            })}
        </Box>
    );
};

export default PrimaryFilterColumn;
