import { generateClient } from 'aws-amplify/api';
import { aiRequest } from '../graphql/queries';
import { AiResponse } from '../API';

/**
 * A function that returns a response from a thread in OpenAi
 *
 * Firstly you should already have your API set in the global settings.
 * Next you should go to your dashboard in OpenAI and navigate to the assistant playground.
 * Either you can take an existing assistant or create a new one that matches your needs, adding some context and/or specifying
 * how you want the data returned.
 *
 * For reference I have created the assistant asst_yU6RO6EotP0xvnL5s5SfCIeA which gives a SWOT a analysis
 *
 * The request params are: query - a string specifying what you want openai to do, ie 'give me a SWOT analysis for a ___ company'
 * assistantId - the id of your assistant in you openai playground
 * threadId - optional. If not provided this will create a new thread.
 *
 * Return values are content, as specified in your assistant and threadId which allows you to make subsequent requests to the same
 * thread ie if you have a chat application.
 *
 *
 */
export const AiRequest = async (
    query: string,
    assistantId: string,
    threadId?: string
) => {
    const client = generateClient();

    const res = await client.graphql({
        query: aiRequest,
        variables: {
            params: {
                query: query,
                assistantId: assistantId,
                threadId: threadId,
            },
        },
    });

    return res.data.aiRequest as AiResponse;
};
