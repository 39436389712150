import { ReactNode, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts';
import {
    FormControl,
    InputLabel,
    Select,
    Typography,
    useTheme,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import MuiSelect, {
    SelectProps as MuiSelectProps,
    SelectChangeEvent,
} from '@mui/material/Select';
import { CardsFieldProps } from './inputFieldTypes';
import { StyledSelect } from '../FormElements';
import MenuItem from '@mui/material/MenuItem';
import SelectField from './SelectField';
import {
    AttributeType,
    Card,
    CardsOrderedByNameOnlyIdAndNameQuery,
} from '../../../API';

import { generateClient } from 'aws-amplify/api';

import { getUserOrganisation } from '../../../helpers/auth';
import { cardsOrderedByNameOnlyIdAndName } from '../../../graphql/custom-queries';

interface CardsProps {
    id?: string;
    value?: string | string[];
    onChange?: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
    exclude?: string;
}

const CardsField = (props: CardsFieldProps & CardsProps) => {
    const { value, label, onChange, exclude } = props;
    const { users, user } = useContext(AppContext);
    const [cards, setCards] = useState<Card[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [selectedCards, setSelectedCards] = useState<string[]>([]);
    const theme = useTheme();

    const client = generateClient();

    useEffect(() => {
        if (value) {
            let values = typeof value === 'string' ? JSON.parse(value) : value;
            setSelectedCards(values as string[]);
        }
    }, [value]);

    useEffect(() => {
        const getCards = async () => {
            const userGroup = await getUserOrganisation(user);

            const query = cardsOrderedByNameOnlyIdAndName;

            const filter: {
                cardToCardTypeId?: { eq: String };
            }[] = [];

            props.values?.forEach((value: String) => {
                filter.push({ cardToCardTypeId: { eq: value } });
            });

            let allCards: Card[] = [];
            let nextToken = null;

            do {
                const cards = (await client.graphql({
                    query: query,
                    variables: {
                        organisation: userGroup,
                        filter: {
                            or: filter,
                            not: { id: { eq: exclude ?? '' } },
                        },
                        sortDirection: 'ASC',
                        nextToken: nextToken,
                    },
                })) as { data: CardsOrderedByNameOnlyIdAndNameQuery };

                allCards = [
                    ...allCards,
                    ...(cards?.data?.cardsOrderedByName?.items as Card[]),
                ];
                nextToken = cards?.data?.cardsOrderedByName?.nextToken;
            } while (nextToken);

            setCards(allCards);
            setLoaded(true);
        };
        if (user) {
            getCards();
        }
    }, [user]);

    return (
        <FormControl
            size="small"
            data-testid={`select-${label}`}
            sx={{ paddingTop: '25px' }}
        >
            <InputLabel
                shrink
                htmlFor="styled-input"
                sx={{
                    background: 'initial',
                    left: '-12px',
                    color: theme.palette.text.primary,
                    fontSize: '1.6em',
                    fontFamily: 'Antonio,sans-serif',
                    mb: 2,
                    '&.Mui-focused': {
                        color: theme.palette.text.primary,
                    },
                }}
            >
                {label}
            </InputLabel>
            {cards.length > 0 ? (
                <StyledSelect
                    labelId="cards-multiple-name-label"
                    id="cards-multiple-name"
                    multiple
                    value={selectedCards ?? []}
                    displayEmpty={true}
                    onChange={onChange}
                    name="Cards"
                    sx={{
                        minWidth: '10rem',
                        borderRadius: 0,
                        mb: 3,
                        background: 'inherit',
                        legend: {},
                    }}
                >
                    <MenuItem value="">Select</MenuItem>
                    {cards
                        .map((item) => {
                            return {
                                value: item.id,
                                label: item.name,
                            };
                        })
                        .map((name) => (
                            <MenuItem key={name.label} value={name.value}>
                                {name.label}
                            </MenuItem>
                        ))}
                </StyledSelect>
            ) : (
                <StyledSelect
                    type={AttributeType.SingleSelect}
                    disabled={loaded ? true : false}
                    sx={{
                        minWidth: '10rem',
                        borderRadius: 0,
                        mb: 3,
                        background: 'inherit',
                        legend: {},
                    }}
                    displayEmpty
                    value={[]}
                >
                    <MenuItem value="">
                        {' '}
                        {loaded && !cards.length ? '- No cards available' : ''}
                    </MenuItem>
                </StyledSelect>
            )}
        </FormControl>
    );
};

export default CardsField;
