import { PropsWithChildren, ReactNode } from 'react';
import { Box, IconButton, Tooltip, styled } from '@mui/material';
import MuiCard from '@mui/material/Card';
import MuiCardHeader from '@mui/material/CardHeader';
import { AnimatePresence, motion } from 'framer-motion';
import { truncateText } from '../../helpers/utils';
import { ReactComponent as MinimizeIcon } from '../../assets/icons/Minimize.svg';
import { ReactComponent as SparkIcon } from '../../assets/icons/spark-icon.svg';
import background from '../../assets/images/paper-clip.png';
import {
    Category,
    getCategoryHex,
    getCategoryHexDark,
    getCategoryHexText,
    getCategoryIcon,
} from '../../helpers/category';
import { CardComponentType } from './cardTypes';
import { UserPermissions } from '../../globals';
import { ReactComponent as MultiCardIcon } from '../../assets/icons/multi-card.svg';

export interface CardBaseData {
    id: string;
    name: string;
    cardTypeName?: string;
    category: Category;
    cardComponentType?: CardComponentType;
    organisation?: string | null;
}

export interface CardBaseProps {
    headerRight?: ReactNode;
    data: CardBaseData;
    expanded?: boolean;
    subHeader?: string;
    handleClose?: () => void;
    hideHelp?: boolean;
    handleHeaderClick?: () => void;
    permissions?: UserPermissions[];
    setHelpTextModal?: (e: React.MouseEvent<HTMLElement> | null) => void;
    mediumCard?: boolean;
    smallCard?: boolean;
    workbookCard?: boolean;
    hideShadow?: boolean;
    swotColor?: string;
    activeId?: string | null;
    worksheetCard?: boolean;
    worksheet?: boolean;
}

const CardWithAnimation = (props: PropsWithChildren<CardBaseProps>) => {
    const {
        data: { id },
    } = props;

    return (
        <AnimatePresence>
            <motion.div layout layoutId={id} key={id}>
                <StyledCardBase {...props} />
            </motion.div>
        </AnimatePresence>
    );
};

const StyledMuiCard = styled(MuiCard)<{
    bg: string;
    permissions?: UserPermissions[];
    mediumCard?: boolean;
    smallCard?: boolean;
}>(({ bg, permissions, theme, mediumCard, smallCard }) => {
    return {
        borderRadius: '16px',
        background: bg,
        color: '#fff',
        boxShadow: '0px 0px 13px rgba(0,0,0,0.40)',
        overflow: 'initial',
        position: 'relative',
        height: '100%',
        cursor:
            permissions?.includes(UserPermissions.DELETE) &&
            !mediumCard &&
            !smallCard
                ? 'pointer'
                : 'default',
        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            backgroundColor:
                theme.palette.mode === 'dark'
                    ? '#FFFFFF'
                    : 'rgba(0, 0, 0, 0.3)',
        },
    };
});

const StyledDefaultMuiCardHeader = styled(MuiCardHeader)<{
    bg: string;
    textColor: string;
    mediumCard?: boolean;
    smallCard?: boolean;
    worksheetCard?: boolean;
}>(({ theme, bg, textColor, mediumCard, smallCard, worksheetCard }) => {
    return {
        background: bg,
        textTransform: 'uppercase',
        color: textColor,
        paddingTop: '',
        paddingBottom: '',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        height: mediumCard || smallCard ? '28px' : '48px',
        paddingLeft: '6px',
        svg: {
            width: mediumCard ? '18px' : smallCard ? '20px' : '32px',
            height: mediumCard ? '18px' : smallCard ? '20px' : '32px',
        },
        '.MuiCardHeader-avatar': {
            marginRight: smallCard
                ? theme.spacing(0.6)
                : mediumCard
                ? theme.spacing(0.2)
                : theme.spacing(1),
        },
        '.MuiCardHeader-title': {
            fontSize: smallCard ? '13px' : mediumCard ? '8px' : '15px',
            marginRight: theme.spacing(1),
        },
    };
});

const StyledExpandedMuiCardHeader = styled(StyledDefaultMuiCardHeader)<{
    worksheetCard?: boolean;
}>(({ worksheetCard }) => {
    return {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        height: worksheetCard ? '40px' : '',
    };
});

export const CardBase = (props: PropsWithChildren<CardBaseProps>) => {
    const {
        data,
        expanded,
        children,
        headerRight,
        subHeader,
        handleClose,
        handleHeaderClick,
        permissions,
        setHelpTextModal,
        mediumCard,
        smallCard,
        hideShadow,
        swotColor,
        hideHelp,
        activeId,
        worksheetCard,
        worksheet,
        workbookCard,
    } = props;

    const { category, cardComponentType, cardTypeName, name } = data;

    const key = Object.keys(Category).find(
        (k) => Category[k as keyof typeof Category] === category
    );

    const CategoryIcon =
        category === 'cardset' ? MultiCardIcon : getCategoryIcon(category);

    const cardBackground = swotColor
        ? swotColor
        : cardTypeName === 'Spark'
        ? 'linear-gradient(90deg, #1F3A93 0%,#2E86C1 25%, #85C1E9 50%, #2E86C1 75%, #1F3A93 100%)'
        : getCategoryHex(category);

    const darkBackground = swotColor
        ? swotColor
        : cardTypeName === 'Spark'
        ? 'linear-gradient(90deg, #1F3A93 0%,#2E86C1 25%, #85C1E9 50%, #2E86C1 75%, #1F3A93 100%)'
        : getCategoryHexDark(category);
    const textColor = getCategoryHexText(category);

    const StyledCardHeader = expanded
        ? StyledExpandedMuiCardHeader
        : StyledDefaultMuiCardHeader;

    const PaperClip = ({ mediumCard }: { mediumCard?: boolean }) => {
        return (
            <Box
                sx={{
                    height: mediumCard ? '50px' : '100px',
                    width: mediumCard ? '30px' : '60px',
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '90%',
                    position: 'absolute',
                    zIndex: 1,
                    top: mediumCard ? '-5px' : '-15px',
                    left: mediumCard ? '-10px' : '-20px',
                }}
            />
        );
    };

    return (
        <StyledMuiCard
            bg={cardBackground}
            permissions={permissions}
            mediumCard={mediumCard}
            smallCard={smallCard}
            sx={{
                ...(hideShadow && {
                    boxShadow: 'none',
                }),
                height: '100%',
            }}
        >
            {cardComponentType === CardComponentType.CARD_SET && (
                <PaperClip mediumCard={mediumCard} />
            )}
            <StyledCardHeader
                sx={{ cursor: activeId ? 'grabbing' : 'inherit' }}
                mediumCard={mediumCard}
                smallCard={smallCard}
                worksheetCard={worksheetCard}
                onClick={handleHeaderClick}
                bg={darkBackground}
                textColor={!!swotColor ? '#ffffff' : textColor}
                avatar={
                    worksheet ? (
                        ''
                    ) : (
                        <Tooltip title={key} placement="top">
                            {cardTypeName === 'Spark' ? (
                                <SparkIcon />
                            ) : (
                                <CategoryIcon
                                    color={!!swotColor ? '#ffffff' : textColor}
                                    style={{
                                        marginRight: workbookCard ? '-4px' : '',
                                        marginLeft: workbookCard ? '-4px' : '',
                                    }}
                                />
                            )}
                        </Tooltip>
                    )
                }
                title={
                    worksheet ? (
                        <strong
                            style={{
                                fontSize: '15px',
                            }}
                        >
                            {name}
                        </strong>
                    ) : !hideHelp ? (
                        <Tooltip
                            title={
                                cardComponentType === CardComponentType.CARD
                                    ? 'Help'
                                    : ''
                            }
                            placement="top"
                        >
                            <strong
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setHelpTextModal && setHelpTextModal(e);
                                }}
                                style={{
                                    cursor: 'help',
                                    fontSize: smallCard ? '8px' : 'inherit',
                                }}
                            >
                                {expanded
                                    ? truncateText(name, 35)
                                    : cardTypeName}{' '}
                                {subHeader ? `- ${subHeader}` : ''}
                            </strong>
                        </Tooltip>
                    ) : (
                        <strong
                            style={{
                                cursor: 'help',
                                fontSize: smallCard ? '8px' : 'inherit',
                            }}
                        >
                            {expanded ? truncateText(name, 35) : cardTypeName}{' '}
                            {subHeader ? `- ${subHeader}` : ''}
                        </strong>
                    )
                }
                titleTypographyProps={{
                    fontSize:
                        expanded && name.length < 35
                            ? '1.5rem'
                            : expanded
                            ? '1.2rem'
                            : '',
                }}
                action={
                    !expanded ? (
                        headerRight
                    ) : (
                        <Tooltip title="Minimize" placement="top">
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    cursor: 'pointer',
                                    p: 0,
                                    width: worksheetCard ? '25px' : '40px',
                                }}
                            >
                                <MinimizeIcon color={textColor} />
                            </IconButton>
                        </Tooltip>
                    )
                }
            />
            {children}
        </StyledMuiCard>
    );
};

const StyledCardBase = styled(CardBase)<CardBaseProps>(
    ({ data, swotColor }) => {
        const { category } = data;

        return {
            borderRadius: '16px',
            backgroundColor: swotColor ?? getCategoryHex(category),
            color: '#fff',
            boxShadow: '0px 0px 13px rgba(0,0,0,0.40)',
        };
    }
);

export default StyledCardBase;
