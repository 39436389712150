import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import { ReactComponent as FingerprintIcon } from '../../assets/icons/Fingerprint.svg';
import { ReactComponent as PinIcon } from '../../assets/icons/Pin.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/Filter.svg';
import { ReactComponent as SortIcon } from '../../assets/icons/Sort.svg';
import SortMenu from './SortMenu';
import { SortActions } from './cardTypes';
import FilterCards from './FilterCards';
import { FilterCards as FilterCardsType } from '../../hooks/useFilterCards';

interface ManagerControlsProps {
    activeIcons: { [key: string]: boolean };
    toggleIcon: (iconName: string, selectedSortAction: string) => void;
    pageIdentifier: string;
    filterCriteria: FilterCardsType;
    setFilterCriteria: React.Dispatch<React.SetStateAction<FilterCardsType>>;
    worksheetControls?: boolean;
}
const icons = [
    {
        name: 'Fingerprint',
        tooltipTrue: 'Show all items',
        tooltipFalse: 'Show my items only',
        IconComponent: FingerprintIcon,
    },
    // {
    //     name: 'Pin',
    //     tooltipTrue: 'Show all items',
    //     tooltipFalse: 'Show pinned items only',
    //     IconComponent: PinIcon,
    // },
    {
        name: 'Filter',
        tooltipTrue: 'Remove filter',
        tooltipFalse: 'Apply filter',
        IconComponent: FilterIcon,
    },
    {
        name: 'Sort',
        tooltipTrue: '',
        tooltipFalse: 'Apply sort',
        IconComponent: SortIcon,
    },
];

const ManagerControls = ({
    activeIcons,
    toggleIcon,
    pageIdentifier,
    filterCriteria,
    setFilterCriteria,
    worksheetControls,
}: ManagerControlsProps) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
    const greyColor = theme.palette.grey[500];
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openSortModal, setOpenSortModal] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const filterActive = Object.values(filterCriteria).some(
        (arr) => arr.length > 0
    );
    const sortIconRef = useRef(null);
    const sortKey = 'selectedSortAction_' + pageIdentifier;
    let storedAction = localStorage.getItem(sortKey);
    if (storedAction === null) {
        storedAction = SortActions.LAST_MODIFIED_RECENT_FIRST;
        localStorage.setItem(sortKey, storedAction);
    }
    const [selectedSortAction, setSelectedSortAction] = useState<SortActions>(
        storedAction as SortActions
    );

    const handleIconClick = (iconName: string) => {
        if (iconName === 'Sort') {
            setOpenSortModal(!openSortModal);
        } else if (iconName === 'Filter') {
            setOpenFilterModal(!openFilterModal);
        } else {
            toggleIcon(iconName, selectedSortAction);
        }
        setAnchorEl(sortIconRef.current);
    };
    useEffect(() => {
        if (openSortModal || openFilterModal) {
            document.body.classList.add('body-scroll-lock');
        } else {
            document.body.classList.remove('body-scroll-lock');
        }
    }, [openSortModal, openFilterModal]);

    const iconStyles = {
        width: worksheetControls ? '45px' : '50px',
        height: worksheetControls ? '45px' : '50px',
        cursor: 'pointer',
        svg: {
            height: '100%',
        },
    };

    return (
        <Box sx={{ display: 'flex' }}>
            {icons.map((icon) => {
                return (
                    <Tooltip
                        key={icon.name}
                        title={
                            icon.name === 'Filter'
                                ? icon.tooltipTrue
                                : activeIcons[icon.name]
                                ? icon.tooltipTrue
                                : icon.tooltipFalse
                        }
                    >
                        <span>
                            <IconButton
                                onClick={
                                    icon.name === 'Sort' ||
                                    icon.name === 'Filter'
                                        ? () => handleIconClick(icon.name)
                                        : () =>
                                              toggleIcon(
                                                  icon.name,
                                                  selectedSortAction
                                              )
                                }
                                sx={{ ...iconStyles }}
                                ref={icon.name === 'Sort' ? sortIconRef : null}
                            >
                                <icon.IconComponent
                                    fill={
                                        icon.name === 'Filter' && filterActive
                                            ? primaryColor
                                            : icon.name === 'Sort'
                                            ? selectedSortAction ===
                                              SortActions.LAST_MODIFIED_RECENT_FIRST
                                                ? greyColor
                                                : primaryColor
                                            : activeIcons[icon.name]
                                            ? primaryColor
                                            : greyColor
                                    }
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                );
            })}
            {openSortModal && anchorEl && (
                <SortMenu
                    anchorEl={anchorEl}
                    handleClose={() => {
                        setOpenSortModal(false);
                        setAnchorEl(null);
                    }}
                    setOpenSortModal={setOpenSortModal}
                    setSelectedSortAction={setSelectedSortAction}
                    toggleIcon={toggleIcon}
                    pageIdentifier={pageIdentifier}
                />
            )}
            {openFilterModal && anchorEl && (
                <FilterCards
                    anchorEl={anchorEl}
                    handleClose={() => {
                        setOpenFilterModal(false);
                        setAnchorEl(null);
                    }}
                    filterCriteria={filterCriteria}
                    setFilterCriteria={setFilterCriteria}
                    pageIdentifier={pageIdentifier}
                />
            )}
        </Box>
    );
};
export default ManagerControls;
