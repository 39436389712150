import {
    Box,
    IconButton,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { ScoreDatum } from '../../../API';
import { dateFromISO } from '../../../helpers/utils';
import { useContext } from 'react';
import { AppContext } from '../../contexts';

interface ChartFormProps {
    newScoreDatum: ScoreDatum;
    handleNewDateInputChange: (date: DateTime | null) => void;
    disableDate: (date: DateTime) => boolean;
    setNewScoreDatum: (data: ScoreDatum) => void;
    insertDate: () => void;
}

const IndicatorForm = ({
    newScoreDatum,
    handleNewDateInputChange,
    disableDate,
    setNewScoreDatum,
    insertDate,
}: ChartFormProps) => {
    const theme = useTheme();

    const radioValues = [
        { value: 0, color: theme.palette.grey[700] },
        { value: 1, color: theme.palette.warning.main },
        { value: 2, color: theme.palette.warning.light },
        { value: 3, color: theme.palette.success.light },
    ];
    const date = new Date();
    const { userProfileObject } = useContext(AppContext);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexShrink: 0,
                alignItems: 'flex-start',
                pb: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexShrink: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                    pb: 2,
                }}
            >
                <DatePicker
                    label={'Select Date'}
                    views={['month', 'year']}
                    autoFocus
                    value={dateFromISO(newScoreDatum.date)}
                    onChange={(date: DateTime | null) =>
                        handleNewDateInputChange(date)
                    }
                    sx={{
                        '.MuiPickersPopper-root': {
                            zIndex: 2000,
                        },
                        mr: 2,
                        width: '12rem',
                    }}
                    slotProps={{
                        popper: {
                            sx: {
                                zIndex: 2000,
                            },
                        },
                        textField: {
                            size: 'small',
                            InputLabelProps: {
                                shrink: true,
                            },
                        },
                    }}
                    shouldDisableMonth={(date: DateTime) => disableDate(date)}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="health-radio-buttons"
                            name="row-radio-buttons-group"
                            value={newScoreDatum.value}
                        >
                            {radioValues.map((button) => {
                                return (
                                    <FormControlLabel
                                        value={button.value}
                                        control={<Radio />}
                                        label={button.value}
                                        onChange={(
                                            event: React.BaseSyntheticEvent
                                        ) => {
                                            setNewScoreDatum({
                                                ...newScoreDatum,
                                                value: parseInt(
                                                    event.target.value
                                                ),
                                            });
                                        }}
                                        sx={{
                                            mr: 1,
                                            '.MuiTypography-root': {
                                                display: 'none',
                                            },
                                            '.MuiSvgIcon-root': {
                                                background: button.color,
                                                fill: 'none',
                                            },
                                            '.Mui-checked': {
                                                '.MuiSvgIcon-root': {
                                                    border: `solid 3px ${theme.palette.primary.light}`,
                                                },
                                            },
                                        }}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </Box>

                <IconButton
                    onClick={insertDate}
                    title="Insert point"
                    color="primary"
                    disabled={
                        !!!newScoreDatum.date ||
                        ((newScoreDatum.value === 1 ||
                            newScoreDatum.value === 2) &&
                            !newScoreDatum.comment)
                    }
                >
                    <AddIcon
                        sx={{
                            fontSize: '3rem',
                            width: '40px',
                            height: '40px',
                        }}
                    />
                </IconButton>
            </Box>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <TextField
                        sx={{
                            width: '25rem',
                            mr: 1,
                        }}
                        rows={4}
                        multiline
                        size="small"
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                            setNewScoreDatum({
                                ...newScoreDatum,
                                comment: event.target.value,
                                commentDate: date.toISOString().split('T')[0],
                                commentAuthor:
                                    userProfileObject?.getUserProfile.id,
                            })
                        }
                        inputProps={{ maxLength: 1000 }}
                        value={newScoreDatum.comment}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required={
                            newScoreDatum.value === 1 ||
                            newScoreDatum.value === 2
                        }
                        placeholder="Commentary"
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default IndicatorForm;
