import { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ReactComponent as Navigate } from '../../../assets/icons/Navigate.svg';
import {
    Card,
    CardSet,
    GetCardQuery,
    GetCardSetQuery,
    AttributeType,
} from '../../../API';
import { getCard, getCardSet } from '../../../graphql/queries';
import { getUserOrganisation } from '../../../helpers/auth';
import { CardContext } from '../context';
import { generateClient } from 'aws-amplify/api';
import { AppContext } from '../../contexts';

interface CardDetailsProps {
    id: string;
    type: AttributeType;
    playbookCard?: boolean;
}

const CardDetailsCard = ({ id, type, playbookCard }: CardDetailsProps) => {
    const [card, setCard] = useState<Card | CardSet | null>(null);

    const { setRelationCard } = useContext(CardContext);
    const { user } = useContext(AppContext);

    const client = generateClient();

    useEffect(() => {
        const getCardInformation = async () => {
            const userGroup = await getUserOrganisation(user);

            if (type === AttributeType.Card) {
                try {
                    const response = (await client.graphql({
                        query: getCard,
                        variables: {
                            id: id,
                            organisation: userGroup,
                        },
                    })) as { data: GetCardQuery };

                    const card = response.data.getCard;

                    if (card) {
                        setCard(card as Card);
                    }
                } catch (e) {
                    console.log(e);
                }
            } else {
                try {
                    const response = (await client.graphql({
                        query: getCardSet,
                        variables: {
                            id: id,
                            organisation: userGroup,
                        },
                    })) as { data: GetCardSetQuery };

                    const card = response.data.getCardSet;

                    if (card) {
                        setCard(card as CardSet);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        };
        if (user) {
            getCardInformation();
        }
    }, [user]);

    return (
        <>
            {playbookCard ? (
                <>{card?.name}</>
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{card?.name}</Typography>
                    <Tooltip title="Navigate" placement="right-start">
                        <IconButton
                            data-testid="card-relation"
                            onClick={() => {
                                card &&
                                    setRelationCard &&
                                    setRelationCard(card as Card);
                            }}
                            sx={{
                                cursor: 'pointer',
                                width: '40px',
                                height: '40px',
                            }}
                        >
                            <Navigate style={{ width: '30px' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
        </>
    );
};

export default CardDetailsCard;
