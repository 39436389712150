import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';
import { truncateText } from '../../../helpers/utils';

interface RelationshipItemsProps {
    title: string;
    items: any[] | undefined;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    colorFn?: (id: string) => string;
}

const RelationshipItems = ({
    title,
    items,
    Icon,
    colorFn,
}: RelationshipItemsProps) => {
    const theme = useTheme();
    const greyColor = theme.palette.grey[500];
    return (
        <Box sx={{ mt: 1 }}>
            <Typography variant="h6">{title}</Typography>
            <Box sx={{ py: 1 }}>
                {items && items.length > 0 ? (
                    items.map((item) => (
                        <Box
                            key={item.id}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 1,
                            }}
                        >
                            <Icon
                                className="name-icon"
                                width={26}
                                height={26}
                                color={
                                    !item?.cardSetToCardCategoryId
                                        ? '#AD6A1F'
                                        : colorFn
                                        ? colorFn(item.toCardCategory?.id ?? '')
                                        : greyColor
                                }
                            />
                            <Tooltip title={item.name}>
                                <Typography variant="body1">
                                    {truncateText(item.name, 50)}
                                </Typography>
                            </Tooltip>
                        </Box>
                    ))
                ) : (
                    <Typography variant="body1">No items</Typography>
                )}
            </Box>
        </Box>
    );
};

export default RelationshipItems;
