import React, { useState, useEffect, useContext } from 'react';
import {
    Card,
    CardCategory,
    CardSet,
    CardType,
    Playbook,
    UpdateCardMutation,
    UpdateCardSetMutation,
    UserProfile,
} from '../../API';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Typography,
} from '@mui/material';
import { GraphQLQuery } from '@aws-amplify/api';
import { StyledSection } from '../StyledElements';
import { dateStringFromISO } from '../../helpers/utils';
import { StyledModal } from '../Modal';
import ModalContainer from '../ModalContainer';
import CardPermissions from '../forms/CardPermissions';
import UserAvatar from '../profile/UserAvatar';
import { AppContext } from '../contexts';
import {
    CardAPITypeName,
    CardComponentType,
    updateMutationMap,
} from './cardTypes';
import { generateClient } from 'aws-amplify/api';
import { getUserOrganisation } from '../../helpers/auth';

const INFO_BOX_STYLES = {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    alignItems: { xs: 'flex-column', sm: 'flex-end' },
    marginBottom: '1em',
    minWidth: '200px',
};

const LABEL_STYLES = {
    width: { xs: '100%', sm: '40%' },
};

const CardInfoField = ({
    label,
    value,
    worksheetCard,
}: {
    label: string;
    value: string;
    worksheetCard: any;
}) => (
    <Box sx={INFO_BOX_STYLES}>
        <Typography
            variant="h6"
            fontSize={worksheetCard ? '1.15em' : '1.25em'}
            sx={LABEL_STYLES}
        >
            {label}
        </Typography>
        <Typography variant="body2" fontSize={"0.875em"}>
            {value}
        </Typography>
    </Box>
);

const CardInfoFieldWithUser = ({
    label,
    value,
    cardCategory,
    showReassign,
    showEdit,
    worksheetCard,
}: {
    label: string;
    value: string | null;
    cardCategory: string;
    showReassign?: boolean;
    showEdit?: (show: boolean) => void;
    worksheetCard: any;
}) => (
    <Box sx={INFO_BOX_STYLES}>
        <Typography
            variant="h6"
            fontSize={worksheetCard ? '1.15em' : '1.25em'}
            sx={LABEL_STYLES}
        >
            {label}
        </Typography>
        <UserAvatar
            userId={value}
            isProfile={false}
            cardCategory={cardCategory}
        />
        {showReassign && (
            <Button
                onClick={() => showEdit && showEdit(true)}
                variant="contained"
                type="submit"
                disabled={false}
                size="small"
                sx={{ height: '30px', ml: 3, alignSelf: 'center' }}
            >
                Reassign
            </Button>
        )}
    </Box>
);

interface CardInfoProps {
    card: Card | CardSet | Playbook;
    onUpdate?: (id: string, organisation: string) => Promise<void>;
    deletedCard?: boolean | undefined;
    worksheetCard?: any;
}

const CardInfo = ({
    card,
    onUpdate,
    deletedCard,
    worksheetCard,
}: CardInfoProps) => {
    let toCardType: CardType | null | undefined;
    let toCardCategory: CardCategory | null | undefined;
    const [allUsersEnabled, setAllUsersEnabled] = useState(false);
    let updatedBy;
    if ('toCardType' in card) {
        toCardType = card.toCardType;
        toCardCategory = card.toCardCategory;
    }
    const { createdAt, updatedAt, owner, editors, createdBy } = card;
    if (
        (card.__typename === 'Card' || card.__typename === 'CardSet') &&
        card.updatedBy
    ) {
        updatedBy = card.updatedBy.split('::')[0];
    }
    const { isGlobalAdmin, user } = useContext(AppContext);
    const formattedCreatedAt = dateStringFromISO(createdAt);
    const formattedUpdatedAt = dateStringFromISO(updatedAt);

    const [selected, setSelected] = useState<UserProfile[]>([]);
    const [selectedEditors, setSelectedEditors] = useState<string[]>([]);
    const [orgId, setOrgId] = useState<string>('');

    const [edit, setEdit] = useState(false);
    const [addEditors, setAddEditors] = useState(false);
    const [loading, setLoading] = useState(false);

    const client = generateClient();

    let isWorkbook = false;

    if (card.__typename === CardAPITypeName.CardSet && card.type === 'WB') {
        isWorkbook = true;
    }

    useEffect(() => {
        if (user) {
            const userOrganisation = getUserOrganisation(user);
            setOrgId(userOrganisation);

            if (card.orgEdit) {
                setAllUsersEnabled(true);
            }
        }

        editors && setSelectedEditors(editors);
    }, []);

    const updateCardDetails = async () => {
        setLoading(true);
        const query = updateMutationMap[card.__typename];
        setEdit(false);
        const data = {
            id: card.id,
            owner:
                selected.length > 0
                    ? `${selected[0].id}::${selected[0].id}`
                    : `${owner}::${owner}`,
            organisation: card.organisation,
            editors: selectedEditors,
            ...('type' in card ? { type: card.type } : {}),
            ...(card.__typename === 'CardSet' && {
                capitalName: card.name.toUpperCase(),
            }),
            ...(card.__typename === 'CardSet' && { createdAt: card.createdAt }),
            orgEdit: allUsersEnabled ? orgId : null,
        };
        try {
            await client.graphql<
                GraphQLQuery<UpdateCardMutation | UpdateCardSetMutation>
            >({
                query: query,
                variables: {
                    input: data,
                },
            });
            onUpdate && (await onUpdate(card.id, card.organisation));
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
        setAddEditors(false);
    };

    const onSelect = (user: UserProfile) => {
        if (addEditors) {
            setSelectedEditors((prevSelectedEditors) => {
                if (prevSelectedEditors.includes(user.id)) {
                    return prevSelectedEditors.filter((id) => id !== user.id);
                } else {
                    return [...prevSelectedEditors, user.id];
                }
            });
        } else {
            setSelected([user]);
        }
    };

    return (
        <Box sx={{ height: worksheetCard ? '200px' : '' }}>
            {(card.__typename === CardAPITypeName.Card ||
                card.__typename === CardAPITypeName.CardSet) &&
                !isWorkbook && (
                    <>
                        <StyledSection>
                            <CardInfoField
                                label={'Card type'}
                                value={toCardType?.name || 'Multiple'}
                                worksheetCard={worksheetCard}
                            />

                            <CardInfoField
                                label="Card category"
                                value={toCardCategory?.name || 'Multiple'}
                                worksheetCard={worksheetCard}
                            />
                        </StyledSection>
                        <Divider color="#fff" />
                    </>
                )}

            <StyledSection
                sx={{
                    marginTop: '1em',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <CardInfoField
                    label="Created on"
                    value={formattedCreatedAt}
                    worksheetCard={worksheetCard}
                />
                {createdBy && (
                    <CardInfoFieldWithUser
                        label="Created by"
                        value={createdBy}
                        cardCategory={
                            isWorkbook
                                ? 'workbook'
                                : toCardCategory?.id ?? 'default'
                        }
                        worksheetCard={worksheetCard}
                    />
                )}

                {owner && (
                    <CardInfoFieldWithUser
                        label="Owner"
                        value={owner}
                        cardCategory={
                            isWorkbook
                                ? 'workbook'
                                : toCardCategory?.id ?? 'default'
                        }
                        showEdit={(edit) => setEdit(edit)}
                        worksheetCard={worksheetCard}
                    />
                )}

                {updatedBy && (
                    <CardInfoField
                        label="Last updated"
                        value={formattedUpdatedAt}
                        worksheetCard={worksheetCard}
                    />
                )}
                {updatedBy && (
                    <CardInfoFieldWithUser
                        label="Last updated by"
                        value={updatedBy}
                        cardCategory={
                            isWorkbook
                                ? 'workbook'
                                : toCardCategory?.id ?? 'default'
                        }
                        worksheetCard={worksheetCard}
                    />
                )}
                {(owner === user?.tokens?.idToken?.payload.sub ||
                    isGlobalAdmin) &&
                    !deletedCard && (
                        <Button
                            onClick={() => setEdit(true)}
                            variant="contained"
                            type="submit"
                            disabled={loading}
                            size="small"
                            sx={{
                                alignSelf: 'flex-end',
                                width: worksheetCard ? '150px' : '191px',
                                p: 0,
                            }}
                        >
                            {loading ? (
                                <CircularProgress size={12} />
                            ) : (
                                'Reassign owner'
                            )}
                        </Button>
                    )}
            </StyledSection>
            <Divider color="#fff" />
            <StyledSection sx={{ marginTop: '1em' }}>
                <Box sx={INFO_BOX_STYLES}>
                    <Typography
                        variant="h6"
                        fontSize="1.25em"
                        sx={{ ...LABEL_STYLES, alignSelf: 'flex-start' }}
                    >
                        Members
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                        }}
                    >
                        {editors &&
                            !allUsersEnabled &&
                            editors
                                .filter((editor) => editor !== owner)
                                .map((editor) => {
                                    return (
                                        <Box key={editor} sx={{ mb: 2 }}>
                                            {' '}
                                            <UserAvatar
                                                userId={editor}
                                                isProfile={false}
                                                cardCategory={
                                                    toCardCategory?.id ??
                                                    'default'
                                                }
                                            />
                                        </Box>
                                    );
                                })}
                        {card.orgEdit && (
                            <Typography variant="body1" sx={{ my: '0.5rem' }}>
                                Organization-wide edit permissions granted.
                            </Typography>
                        )}
                        {owner === user?.tokens?.idToken?.payload.sub &&
                            !deletedCard && (
                                <Button
                                    onClick={() => {
                                        setEdit(true);
                                        setAddEditors(true);
                                    }}
                                    variant="contained"
                                    type="submit"
                                    disabled={loading}
                                    size="small"
                                    sx={{
                                        alignSelf: 'flex-end',
                                        width: worksheetCard
                                            ? '150px'
                                            : '191px',
                                        p: 0,
                                    }}
                                >
                                    {loading ? (
                                        <CircularProgress size={12} />
                                    ) : (
                                        'Update members'
                                    )}
                                </Button>
                            )}
                    </Box>
                </Box>
            </StyledSection>
            <StyledModal
                key="edit-card-info"
                open={edit}
                onClose={() => {
                    setEdit(false);
                    setAddEditors(false);
                    editors && setSelectedEditors(editors);
                }}
                disableEnforceFocus
                sx={{
                    zIndex: 1500,
                }}
            >
                <Box>
                    {edit && (
                        <ModalContainer sx={{ maxWidth: '48rem' }}>
                            <CardPermissions
                                owner={owner}
                                addEditors={addEditors}
                                cardCategory={toCardCategory?.id ?? 'default'}
                                selected={selected}
                                selectedEditors={selectedEditors}
                                setSelectedEditors={setSelectedEditors}
                                onSelect={onSelect}
                                onSave={updateCardDetails}
                                onClose={() => {
                                    setEdit(false);
                                    setAddEditors(false);
                                    editors && setSelectedEditors(editors);
                                }}
                                card={card}
                                allUsersEnabled={allUsersEnabled}
                                setAllUsersEnabled={setAllUsersEnabled}
                            />
                        </ModalContainer>
                    )}
                </Box>
            </StyledModal>
        </Box>
    );
};

export default CardInfo;
