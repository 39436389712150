import {
    FormControl,
    InputLabel,
    MenuItem,
    styled,
    useTheme,
} from '@mui/material';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import { StyledSelect } from '../FormElements';
import { SelectFieldProps } from './inputFieldTypes';

const SelectField = (props: SelectFieldProps & MuiSelectProps) => {
    const {
        name,
        label,
        options,
        id,
        value,
        required,
        onChange,
        unAvailable,
        disabled,
    } = props;

    const theme = useTheme();

    return (
        <FormControl
            size="small"
            data-testid={`select-${name}`}
            sx={{ paddingTop: '25px' }}
        >
            <InputLabel
                shrink
                htmlFor="styled-input"
                sx={{
                    background: 'initial',
                    left: '-12px',
                    color: theme.palette.text.primary,
                    fontSize: '1.6em',
                    fontFamily: 'Antonio,sans-serif',
                    mb: 2,
                    '&.Mui-focused': {
                        color: theme.palette.text.primary,
                    },
                }}
            >
                {label} {required ? '*' : ''}
            </InputLabel>
            <StyledSelect
                value={value != null ? value : ''}
                name={name}
                required={required}
                labelId={label?.split(' ').join('-')}
                displayEmpty
                onChange={onChange}
                size="small"
                disabled={disabled}
                MenuProps={{
                    sx: {
                        zIndex: 2000,
                        height: '220px',
                        width: '400px',
                        marginLeft: '20px',
                        maxWidth: '100%',
                    },
                }}
                sx={{
                    minWidth: '10rem',
                    borderRadius: 0,
                    mb: 3,
                    background: 'inherit',
                }}
            >
                <MenuItem value="">
                    {unAvailable ? unAvailable : 'Select'}
                </MenuItem>
                {options?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </StyledSelect>
        </FormControl>
    );
};

export const StyledSelectField = styled(SelectField)(({ theme }) => ({
    width: '100%',
    marginBottom: theme.spacing(4),
    '.MuiSelect-select': {
        display: 'flex',
        flexDirection: 'row',
    },
}));

export default StyledSelectField;
