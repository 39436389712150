import { fetchAuthSession } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import {
    AttributeInput,
    Card,
    CardAttributesReferencingCardQuery,
    CardSet,
    CardsCardSetsByCardIdQuery,
    GetCardQuery,
    GetCardSetQuery,
    ScoreDefinition,
    UpdateCardMutation,
} from '../API';
import { cardsCardSetsToDeleteByCardId } from '../graphql/custom-queries';
import {
    CardComponentData,
    CardComponentType,
    CardPage,
    SortActions,
} from '../components/cards/cardTypes';
import { getUserOrganisation } from './auth';
import { ReactComponent as ContentIcon } from '../assets/icons/Content.svg';
import { ReactComponent as DetailsIcon } from '../assets/icons/Details.svg';
import { ReactComponent as ScoresIcon } from '../assets/icons/Scores.svg';
import { ReactComponent as RelationshipsIcon } from '../assets/icons/Relationships.svg';
import { ReactComponent as ActivityIcon } from '../assets/icons/Event.svg';
import { ReactComponent as CommentsIcon } from '../assets/icons/Comments.svg';
import {
    cardAttributesReferencingCard,
    getCard,
    getCardSet,
} from '../graphql/queries';
import { createOrUpdateScoreData, updateCard } from '../graphql/mutations';
import { addScores } from '../components/forms/CreateCard';
import {
    likelihoodScores,
    severityScores,
} from '../components/playbooks/analysis/RiskAnalysis';
import { Over } from '@dnd-kit/core';

const client = generateClient();

export const copyCard = (card: Card, user: string) => {
    return {
        organisation: card.organisation,
        owner: user,
        createdBy: user,
        editors: null,
        name: `${card.name} copy`,
        toCardCategory: card.toCardCategory,
        fullDescription: card.fullDescription,
        briefDescription: card.briefDescription,
        attributes: card.attributes,
        toScoreData: card.toScoreData,
        toCardType: card.toCardType,
        toCardSets: card.toCardSets,
        cardTypeToCardsId: card.cardToCardTypeId,
        cardToCardCategoryId: card.cardToCardCategoryId,
        cardToCardTypeId: card.cardToCardTypeId,
    };
};
export const getCardWithId = async (relatedId: string) => {
    try {
        const user = await fetchAuthSession();
        const userOrganisation = getUserOrganisation(user);
        const response = (await client.graphql({
            query: getCard,
            variables: {
                id: relatedId,
                organisation: userOrganisation,
            },
        })) as { data: GetCardQuery };
        return response?.data?.getCard || null;
    } catch (err) {
        console.log(err);
        return [];
    }
};
export const getCardSetWithId = async (cardSetId: string) => {
    try {
        const user = await fetchAuthSession();
        const userOrganisation = getUserOrganisation(user);
        const response = (await client.graphql({
            query: getCardSet,
            variables: {
                id: cardSetId,
                organisation: userOrganisation,
            },
        })) as { data: GetCardSetQuery };
        return response?.data?.getCardSet || null;
    } catch (err) {
        console.log(err);
        return [];
    }
};
export const getCardWithAttributeReference = async (cardId: string) => {
    try {
        const response = (await client.graphql({
            query: cardAttributesReferencingCard,
            variables: {
                refToCardId: cardId,
            },
        })) as { data: CardAttributesReferencingCardQuery };
        const cards =
            response?.data?.cardAttributesReferencingCard?.items
                .map((item) => item?.card)
                .filter((card) => card !== null && card !== undefined) || [];
        return cards as Card[];
    } catch (err) {
        console.log(err);
        return [];
    }
};
export const getRelatedCardSets = async (id: string) => {
    try {
        const response = (await client.graphql({
            query: cardsCardSetsToDeleteByCardId,
            variables: {
                cardId: id,
            },
        })) as { data: CardsCardSetsByCardIdQuery };

        const relatedCardSets =
            response.data.cardsCardSetsByCardId?.items.map(
                (cardSet) => cardSet!.cardSet
            ) || [];

        return relatedCardSets as CardSet[];
    } catch (err) {
        console.log(err);
        return [];
    }
};
interface IconStates {
    [key: string]: boolean;
}

export const getCardMenu = ({
    handleClick,
    link,
    showPage,
    deletedCard,
    cardComponentType,
    scoreDefinition,
}: {
    handleClick: (cardItem: any) => void;
    link?: string;
    showPage?: CardPage;
    deletedCard?: boolean;
    cardComponentType?: CardComponentType;
    scoreDefinition?: ScoreDefinition | null;
}) => {
    const menuActions = [
        {
            action: handleClick || link,
            value: CardPage.DETAILS,
            Icon: ContentIcon,
            label: 'Details',
            active: showPage === CardPage.DETAILS,
        },
        {
            action: handleClick,
            value: CardPage.SCORES,
            Icon: ScoresIcon,
            label: 'Scores',
            active: showPage === CardPage.SCORES,
        },
        {
            action: handleClick || link,
            value: CardPage.INFO,
            Icon: DetailsIcon,
            label: 'Info',
            active: showPage === CardPage.INFO,
        },
    ];

    if (!deletedCard && cardComponentType === CardComponentType.CARD) {
        menuActions.push({
            action: handleClick || link,
            value: CardPage.RELATIONSHIPS,
            Icon: RelationshipsIcon,
            label: 'Relationships',
            active: showPage === CardPage.RELATIONSHIPS,
        });
    }

    if (cardComponentType === CardComponentType.CARD) {
        menuActions.splice(2, 0, {
            action: handleClick,
            value: CardPage.COMMENTS,
            Icon: CommentsIcon,
            label: 'Comments',
            active: showPage === CardPage.COMMENTS,
        });
    }
    if (
        cardComponentType === CardComponentType.CARD ||
        cardComponentType === CardComponentType.CARD_SET ||
        cardComponentType === CardComponentType.WORKBOOK
    ) {
        menuActions.splice(3, 0, {
            action: handleClick || link,
            value: CardPage.ACTIVITY,
            Icon: ActivityIcon,
            label: 'Activity',
            active: showPage === CardPage.ACTIVITY,
        });
    }

    if (!scoreDefinition) {
        let index = menuActions.findIndex((item) => item.label === 'Scores');
        if (index !== -1) {
            menuActions.splice(index, 1);
        }
    }

    return menuActions;
};

export const updateRiskCardScoresInWorksheet = async (
    over: any,
    card: Card
) => {
    let riskDropId: string = over?.id as string;
    const parts = riskDropId.split('_')[1].split('-');
    const severityScore = parts[0];
    const likelihoodScore = parts[1];
    const riskScores = [
        {
            scoreDefinition: '7-S-1',
            value: severityScores[severityScore.toString()],
        },
        {
            scoreDefinition: '7-S-2',
            value: likelihoodScores[likelihoodScore.toString()],
        },
    ];
    await Promise.all(
        riskScores.map(async (cardScore) => {
            const currentDate = new Date().toISOString().split('T')[0];
            const scoreData = {
                input: {
                    data: [{ date: currentDate, value: cardScore.value }],
                    cardId: card?.id,
                    organisation: card?.organisation,
                    scoreDefinitionId: cardScore.scoreDefinition || '',
                },
            };

            try {
                await client.graphql({
                    query: createOrUpdateScoreData,
                    variables: scoreData,
                });
            } catch (err) {
                console.log(err);
            }
        })
    );
    const response = (await client.graphql({
        query: getCard,
        variables: {
            id: card.id,
            organisation: card.organisation,
        },
    })) as { data: GetCardQuery };

    return response.data.getCard;
};

export const updateCardAttributesInWorksheet = async (
    data: any,
    attributes: any
) => {
    const query = updateCard;
    const attributesArray = attributes?.map((item: any) => {
        if (item?.value) {
            return {
                attributeDefinitionID: item?.attributeDefinitionID,
                value: JSON.stringify(item?.value),
            };
        }
    });
    const data2 = {
        id: data?.id,
        organisation: data?.organisation,
        createdAt: data.createdAt,
        attributes: attributesArray,
    };
    try {
        const res = (await client.graphql({
            query,
            variables: {
                input: {
                    ...data2,
                },
            },
        })) as { data: UpdateCardMutation };
        return res.data.updateCard;
    } catch (error) {
        console.log(error);
    }
};

export const extractAttributes = (
    dropId: '12' | '11',
    over: Over | null,
    workbookCard?: boolean
) => {
    if (dropId === '12') {
        const droppable = over?.id as string;
        const value = droppable?.split('_')[1].split('-')[0];
        const attributes = [
            {
                value: workbookCard ? value : `\"${value}\"`,
                attributeDefinitionID: '12-A-1',
            },
        ];
        return attributes;
    } else if (dropId === '11') {
        const droppable = over?.id as string;
        const value = droppable?.split('_');
        const [category, timescale] = value[1].split('+').filter(Boolean);
        const attributes = [
            {
                value: workbookCard ? category : `\"${category}\"`,
                attributeDefinitionID: '11-A-2',
            },
            {
                value: workbookCard ? timescale : `\"${timescale}\"`,
                attributeDefinitionID: '11-A-3',
            },
        ];
        return attributes;
    }
    return;
};
