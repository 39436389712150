import { useContext, useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    Snackbar,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { generateClient } from 'aws-amplify/api';
import { GraphQLQuery } from '@aws-amplify/api';
import { addCardToCardSet } from '../../../actions/CardSets';
import {
    AttributeInput,
    Card,
    CardCategory,
    CardType,
    CreateCardInput,
    CreateCardMutation,
    ListCardCategoriesQuery,
    CardSet,
    PlaybookPageType,
    CreatePlaybookPageMutation,
    PlaybookPageDataResponse,
    AddOrRemoveCardsFromSetMutation,
    UpdateCardMutation,
    EntityType,
} from '../../../API';
import {
    addOrRemoveCardsFromSet,
    convertCard,
    createCard,
    createOrUpdateScoreData,
    createPlaybookPage,
    updateCard,
    updateUserProfile,
} from '../../../graphql/mutations';
import { listCardCategories } from '../../../graphql/queries';
import CategoriesColumn from './CategoriesColumn';
import PagesColumn from './PagesColumn';
import TypesColumn from './TypesColumn';
import AttributesColumn from './AttributesColumn';
import useSnackbar from '../../../hooks/useSnackbar';
import { CreateCardOptions, PageIdentifier } from '../../cards/cardTypes';
import OptionSelector from './OptionSelector';
import ProcessColumn from './ProcessColumn';
import StepColumn from './StepColumn';
import WorksheetsColumn from './WorksheetsColumn';
import { getUserOrganisation } from '../../../helpers/auth';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { worksheets } from '../worksheets';
import {
    getChartSettings,
    getListSettings,
    getRadarOptions,
} from '../../../helpers/worksheets';
import { AppContext } from '../../contexts';
import { RadarOption } from '../input-fields/ChartSettings';
import LayoutColumn from './LayoutColumn';
import { CardContext } from '../../cards/context';
import { getContext } from '../../../helpers/utils';

interface CreateCardProps {
    card?: Card;
    cardCategories?: CardCategory[];
    cardTypes?: CardType[];
    handleClose?: (card?: Card) => void;
    cardSet?: string;
    cardSetToCardCategoryId?: string | null;
    cardSetToCardTypeId?: string;
    fetchCards?: (initialLoad?: boolean) => void;
    selectedCardIds?: string[];
    setSelectedCardIds?: React.Dispatch<React.SetStateAction<string[]>>;
    workbook?: CardSet;
    pageIdentifier?: PageIdentifier;
    playbookId?: string;
    lowestOrdinal?: number | null;
    setPages?: React.Dispatch<React.SetStateAction<PlaybookPageDataResponse[]>>;
    pages?: PlaybookPageDataResponse[];
    cardTypeFromDrawer?: string;
    setCardType?: React.Dispatch<React.SetStateAction<string | null>>;
    pageOptions?: any;
    radarOptions?: RadarOption[] | null;
    selectedAttributes?: any;
    riskScores?: CardScore[] | null;
    convertSparkCardId?: string;
    cardToConvert?: Card | null;
    addCardToWorkbook?: (card: Card, addToWorksheet: boolean) => Promise<void>;
    worksheet?: boolean;
}
interface WorkbookActivity {
    cardTypeId: string | null;
    cardCategoryId: string | null;
    processId: string | null;
    stepId: string | null;
}
interface CardsActivity {
    cardTypeId: string | null;
    cardCategoryId: string | null;
}
interface LatestUserActivity {
    workbook: WorkbookActivity;
    cards: CardsActivity;
}
export interface UserPreferences {
    latestActivity: LatestUserActivity;
}
export type NewCard = Pick<
    Card,
    | 'briefDescription'
    | 'cardToCardCategoryId'
    | 'cardToCardTypeId'
    | 'fullDescription'
    | 'name'
    | 'organisation'
    | 'attributes'
>;
export type SparkCard = Pick<
    Card,
    | 'id'
    | 'briefDescription'
    | 'cardToCardCategoryId'
    | 'cardToCardTypeId'
    | 'fullDescription'
    | 'name'
    | 'organisation'
    | 'attributes'
>;
export type NewWorksheet = {
    title: string;
    commentary: string;
    playbookPageType: PlaybookPageType;
    options?: string;
};

export type SelectedWorksheet = {
    i: string;
    n: string;
    t: string;
    e: boolean;
    d: string;
};

export interface CardScore {
    scoreDefinition: string;
    value: number;
}
export const addScores = (card: any, cardScores: any) => {
    const currentDate = new Date().toISOString().split('T')[0];
    const scoreGroups = cardScores.reduce((groups: any, data: any) => {
        if (!groups[data.scoreDefinition]) {
            groups[data.scoreDefinition] = [];
        }
        groups[data.scoreDefinition].push({
            value: data.value,
            date: new Date().toISOString(),
            comment: null,
            commentDate: null,
            commentAuthor: null,
        });
        return groups;
    }, {});
    const existingScores = card.toScoreData.items.filter(
        (item: any) => item.createdAt.split('T')[0] === currentDate
    );

    if (existingScores.length > 0) {
        existingScores.forEach((existingScore: any) => {
            const scoreDefinitionId = existingScore.scoreDefinitionId;
            if (scoreGroups[scoreDefinitionId]) {
                existingScore.data.push(...scoreGroups[scoreDefinitionId]);
                existingScore.updatedAt = new Date().toISOString();
            }
        });
    } else {
        const mappedItems = Object.keys(scoreGroups).map(
            (scoreDefinitionId) => ({
                cardId: card.id,
                scoreDefinitionId: scoreDefinitionId,
                data: scoreGroups[scoreDefinitionId],
                organisation: card.organisation,
                createdBy: card.createdBy,
                updatedBy: card.updatedBy,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                __typename: 'ScoreDatum',
            })
        );
        card.toScoreData?.items.push(...(mappedItems as any));
    }

    return card;
};

const CreateCard = ({
    card,
    cardCategories,
    handleClose,
    cardSet,
    cardSetToCardCategoryId,
    cardSetToCardTypeId,
    fetchCards,
    selectedCardIds,
    setSelectedCardIds,
    workbook,
    pageIdentifier,
    playbookId,
    lowestOrdinal,
    setPages,
    pages,
    cardTypeFromDrawer,
    setCardType,
    pageOptions,
    radarOptions,
    selectedAttributes,
    riskScores,
    convertSparkCardId,
    cardToConvert,
    addCardToWorkbook,
    worksheet,
}: CreateCardProps) => {
    let score: number;
    const [scores, setScores] = useState({
        x: 0,
        y: 0,
    });
    const [cardScores, setCardScores] = useState<CardScore[]>([]);
    const navigate = useNavigate();
    const { cardTypeObject, userProfileObject, setUserProfileObject, user } =
        useContext(AppContext);
    const { items, refreshCard } = useContext(CardContext);
    const { closeSnackbar, isOpen, showSnackbar, message, severity, duration } =
        useSnackbar();

    const { id } = useParams();

    const location = useLocation();

    const [loading, setLoading] = useState(false);

    const [cardCats, setCardCats] = useState<CardCategory[] | undefined>(
        cardCategories
    );
    const preferencesString =
        userProfileObject?.getUserProfile?.preferences || '';
    const [preferences, setPreferences] = useState<UserPreferences>(
        preferencesString.trim() !== '' ? JSON.parse(preferencesString) : {}
    );
    const userPreferencesCardCategory =
        pageIdentifier === PageIdentifier.CARDS
            ? preferences?.latestActivity?.cards?.cardCategoryId
            : pageIdentifier === PageIdentifier.WORKBOOK_INSIDE
            ? preferences?.latestActivity?.workbook?.cardCategoryId
            : null;
    const userPreferencesCardType =
        pageIdentifier === PageIdentifier.CARDS
            ? preferences?.latestActivity?.cards?.cardTypeId
            : pageIdentifier === PageIdentifier.WORKBOOK_INSIDE
            ? preferences?.latestActivity?.workbook?.cardTypeId
            : null;
    const [newCard, setNewCard] = useState<NewCard>({
        briefDescription: '',
        cardToCardCategoryId: userPreferencesCardCategory ?? '',
        cardToCardTypeId: userPreferencesCardType ?? '',
        fullDescription: '',
        name: '',
        organisation: '',
    });

    const [newWorksheet, setNewWorksheet] = useState<NewWorksheet>({
        title: '',
        commentary: '',
        playbookPageType: PlaybookPageType.List,
        options: '',
    });
    const [submitType, setSubmitType] = useState<
        | 'create'
        | 'createAnother'
        | 'createWorksheet'
        | 'createAnotherWorksheet'
    >('create');
    const [urlError, setUrlError] = useState<boolean>(false);
    const [workbookCards, setWorkbookCards] = useState<Card[]>([]);
    const [cardsFromCardSet, setCardsFromCardSet] = useState<Card[]>();
    const [storedCreateOption, setStoredCreateOption] =
        useState<CreateCardOptions>(
            () =>
                localStorage.getItem(
                    `createProcess_${pageIdentifier}`
                ) as CreateCardOptions
        );
    const [selectedCreateOption, setSelectedCreateOption] =
        useState<CreateCardOptions>(
            () =>
                (localStorage.getItem(
                    `createProcess_${pageIdentifier}`
                ) as CreateCardOptions) || CreateCardOptions.BY_PROCESS
        );
    const [selectedProcessId, setSelectedProcessId] = useState<string | null>(
        null
    );
    const [selectedStepId, setSelectedStepId] = useState<string | null>(null);
    const [selectedType, setSelectedType] = useState<string | undefined | null>(
        null
    );

    useEffect(() => {
        if (riskScores) {
            setCardScores(riskScores);
        }
    }, [riskScores]);

    useEffect(() => {
        if (preferences && pageIdentifier !== PageIdentifier.WORKSHEET) {
            setNewCard({
                briefDescription: newCard.briefDescription,
                cardToCardCategoryId: userPreferencesCardCategory || '',
                cardToCardTypeId: userPreferencesCardType || '',
                fullDescription: newCard.fullDescription,
                name: newCard.name,
                organisation: newCard.organisation,
            });
            setSelectedProcessId(
                preferences?.latestActivity?.workbook?.processId
            );
            setSelectedStepId(preferences?.latestActivity?.workbook?.stepId);
        } else if (
            pageIdentifier === PageIdentifier.WORKSHEET &&
            selectedCreateOption === CreateCardOptions.TITLE_PAGE
        ) {
            const titleconfig = JSON.stringify({
                content: 'middle',
                logo: 'top',
                color: { text: '#ffffff', background: '#414B5A' },
            });

            setNewWorksheet({
                ...newWorksheet,
                playbookPageType: PlaybookPageType.Title,
                options: JSON.stringify({ titleconfig: titleconfig }),
            });
        }
    }, [selectedCreateOption, preferences]);

    const [selectedWorksheet, setSelectedWorksheet] =
        useState<SelectedWorksheet | null>(null);

    const client = generateClient();

    const getCardCats = async () => {
        try {
            const { data } = await client.graphql<
                GraphQLQuery<ListCardCategoriesQuery>
            >({
                query: listCardCategories,
            });

            setCardCats(data?.listCardCategories?.items as CardCategory[]);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        !cardCategories && getCardCats();
    }, [cardCategories]);

    useEffect(() => {
        if (card) {
            setNewCard(card);
        }
    }, [card]);

    useEffect(() => {
        if (cardSetToCardCategoryId && cardSetToCardTypeId) {
            setNewCard({
                ...newCard,
                cardToCardCategoryId: cardSetToCardCategoryId,
                cardToCardTypeId: cardSetToCardTypeId,
            });
        } else if (cardTypeFromDrawer) {
            setNewCard({
                ...newCard,
                cardToCardCategoryId:
                    cardTypeObject[cardTypeFromDrawer].cardCategoryID,
                cardToCardTypeId: cardTypeFromDrawer,
                attributes: selectedAttributes
                    ? selectedAttributes.map((item: any) => item)
                    : [],
            });
        } else {
            setNewCard({
                ...newCard,
                attributes: [],
            });
        }
    }, [
        newCard.cardToCardCategoryId,
        newCard.cardToCardTypeId,
        selectedType,
        cardTypeFromDrawer,
    ]);
    useEffect(() => {
        if (convertSparkCardId || cardToConvert) {
            const card =
                cardToConvert ??
                items.find((item) => item.id === convertSparkCardId);

            if (card) {
                setNewCard({
                    briefDescription: card.briefDescription,
                    cardToCardCategoryId: card.cardToCardCategoryId || '',
                    cardToCardTypeId: card.cardToCardCategoryId || '',
                    fullDescription: card.fullDescription,
                    name: card.name,
                    organisation: card.organisation || '',
                });
            }
        }
    }, [convertSparkCardId, cardToConvert]);
    const handleSave = async (onSuccess: Function) => {
        setLoading(true);
        const context = getContext(location.pathname, id, worksheet);
        const query = card ? updateCard : createCard;

        const userOrganisation = getUserOrganisation(user);
        const data = newCard;

        try {
            const res = (await client.graphql({
                query,
                variables: {
                    input: {
                        ...data,
                        attributes: data?.attributes?.length
                            ? data?.attributes?.map((item) => {
                                  if (item?.value) {
                                      return {
                                          attributeDefinitionID:
                                              item?.attributeDefinitionID,
                                          value:
                                              JSON.stringify(item?.value) ?? '',
                                      } as AttributeInput;
                                  } else {
                                      return null;
                                  }
                              })
                            : [],
                        organisation: data?.organisation || 'x',
                        orgEdit: userOrganisation,
                        owner: user?.userSub,
                        _auditContextType: context.contextType,
                        _auditContextId: context.contextId,
                    },
                },
            })) as { data: CreateCardMutation };

            if (res?.data?.createCard?.id && cardScores.length) {
                const currentDate = new Date().toISOString().split('T')[0];

                for (const cardScore of cardScores) {
                    const scoreData = {
                        input: {
                            data: [
                                { date: currentDate, value: cardScore.value },
                            ],
                            cardId: res?.data?.createCard?.id,
                            organisation: res?.data?.createCard?.organisation,
                            scoreDefinitionId: cardScore.scoreDefinition || '',
                        },
                    };

                    try {
                        await client.graphql({
                            query: createOrUpdateScoreData,
                            variables: scoreData,
                        });
                    } catch (err) {
                        console.log(err);
                    }
                }

                setCardScores([]);
            } else if (res?.data?.createCard?.id && pageOptions) {
                const currentDate = new Date().toISOString().split('T')[0];
                const axisNames = [
                    pageOptions.chartData.xAxis,
                    pageOptions.chartData.yAxis,
                ];

                for (const axisName of axisNames) {
                    let scoreValue =
                        axisName === pageOptions.chartData.xAxis
                            ? scores.x
                            : scores.y;

                    const scoreDefinition =
                        res?.data?.createCard?.toCardType.scoreDefinitions.find(
                            (item) => item.name === axisName
                        );
                    const scoreData = {
                        input: {
                            data: [{ date: currentDate, value: scoreValue }],
                            cardId: res?.data?.createCard?.id,
                            organisation: res?.data?.createCard?.organisation,
                            scoreDefinitionId: scoreDefinition?.id || '',
                        },
                    };
                    try {
                        await client.graphql({
                            query: createOrUpdateScoreData,
                            variables: scoreData,
                        });
                    } catch (err) {
                        console.log(err);
                    }
                }
            }

            if (cardSet && res?.data?.createCard?.id) {
                const cardId = res?.data?.createCard?.id;
                const response = (await client.graphql({
                    query: addOrRemoveCardsFromSet,
                    variables: {
                        cardSetId: cardSet,
                        cardIds: [cardId],
                    },
                })) as { data: AddOrRemoveCardsFromSetMutation };
                if (response) {
                    if (submitType === 'create') {
                        handleClose &&
                            handleClose(res?.data?.createCard as Card);
                        setCardType && setCardType(null);
                    } else {
                        if (submitType !== 'createAnother') {
                            handleClose && handleClose();
                        }
                    }
                }
            }
            const isWorkbook =
                pageIdentifier === PageIdentifier.WORKBOOK_INSIDE;
            const isCards = pageIdentifier === PageIdentifier.CARDS;
            const userPreferences: UserPreferences = {
                latestActivity: {
                    workbook: {
                        processId:
                            selectedCreateOption ===
                            CreateCardOptions.BY_PROCESS
                                ? selectedProcessId
                                : preferences?.latestActivity?.workbook
                                      ?.processId,
                        stepId:
                            selectedCreateOption ===
                            CreateCardOptions.BY_PROCESS
                                ? selectedStepId
                                : preferences?.latestActivity?.workbook?.stepId,
                        cardTypeId:
                            isWorkbook &&
                            selectedCreateOption ===
                                CreateCardOptions.BY_PROCESS
                                ? newCard.cardToCardTypeId
                                : preferences?.latestActivity?.workbook
                                      ?.cardTypeId,
                        cardCategoryId:
                            isWorkbook &&
                            selectedCreateOption ===
                                CreateCardOptions.BY_PROCESS
                                ? newCard.cardToCardCategoryId
                                : preferences?.latestActivity?.workbook
                                      ?.cardCategoryId,
                    },
                    cards: {
                        cardTypeId: isCards
                            ? newCard.cardToCardTypeId
                            : preferences?.latestActivity?.cards?.cardTypeId,
                        cardCategoryId: isCards
                            ? newCard.cardToCardCategoryId
                            : preferences?.latestActivity?.cards
                                  ?.cardCategoryId,
                    },
                },
            };

            try {
                const res = await client.graphql({
                    query: updateUserProfile,
                    variables: {
                        input: {
                            id: userProfileObject?.getUserProfile.id ?? '',
                            organisation:
                                userProfileObject?.getUserProfile
                                    ?.organisation ?? '',
                            preferences: JSON.stringify(userPreferences),
                        },
                    },
                });

                if (res?.data?.updateUserProfile?.preferences) {
                    setPreferences(
                        JSON.parse(res?.data?.updateUserProfile?.preferences)
                    );
                    setUserProfileObject &&
                        setUserProfileObject({
                            ['getUserProfile']: res?.data?.updateUserProfile,
                        });
                }
            } catch (err) {
                console.log(err);
            }
            onSuccess(res);
            setLoading(false);
        } catch (err) {
            console.log(err);
            !!handleClose && handleClose();
            setLoading(false);
            setCardType && setCardType(null);
        }
    };
    const convertSparkCard = async () => {
        const data: SparkCard = { ...newCard, id: convertSparkCardId ?? '' };
        const context = getContext(location.pathname, id);

        try {
            const res = await client.graphql({
                query: convertCard,
                variables: {
                    cardId: data.id,
                    newCardTypeId: data.cardToCardTypeId,
                    name: data.name,
                    briefDescription: data.briefDescription,
                    fullDescription: data.fullDescription,
                    attributes: data?.attributes?.length
                        ? data?.attributes?.map((item) => {
                              if (item?.value) {
                                  return {
                                      attributeDefinitionID:
                                          item?.attributeDefinitionID,
                                      value: JSON.stringify(item?.value) ?? '',
                                  } as AttributeInput;
                              } else {
                                  return null;
                              }
                          })
                        : [],
                    _auditContextType: context.contextType,
                    _auditContextId: context.contextId,
                },
            });

            refreshCard && refreshCard(data.id, data.organisation);
            !!handleClose && handleClose();
        } catch (e) {
            console;
        }
    };

    const onSubmit = async () => {
        if (!!convertSparkCardId) {
            convertSparkCard();
        } else {
            await handleSave((res: { data: CreateCardMutation }) => {
                if (res.data.createCard) {
                    const cardWithScores = addScores(
                        res?.data?.createCard,
                        cardScores
                    );
                    addCardToWorkbook &&
                        addCardToWorkbook(
                            cardWithScores as Card,
                            !!cardTypeFromDrawer
                        );
                }
                !!handleClose && handleClose(res.data.createCard as Card);
            });
        }
    };

    const onSaveAndCreate = async () => {
        await handleSave((res: { data: CreateCardMutation }) => {
            if (res.data.createCard) {
                const cardWithScores = addScores(
                    res?.data?.createCard,
                    cardScores
                );
                addCardToWorkbook &&
                    addCardToWorkbook(
                        cardWithScores as Card,
                        !!cardTypeFromDrawer
                    );
            }
            showSnackbar({
                message: 'Card created successfully',
                severity: 'success',
            });
            setNewCard((prevState) => ({
                ...prevState,
                briefDescription: '',
                fullDescription: '',
                name: '',
                organisation: '',
            }));
            fetchCards && fetchCards(true);
        });
    };
    const handleFormSubmit = async (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        if (urlError) return;
        if (submitType === 'create') {
            await onSubmit();
        } else if (submitType === 'createAnother') {
            await onSaveAndCreate();
        } else if (
            submitType === 'createWorksheet' ||
            submitType === 'createAnotherWorksheet'
        ) {
            await createWorksheet();
        }
    };
    const toggleWorkbookCardSelection = (cardId?: string) => {
        if (!cardId) {
            setSelectedCardIds && setSelectedCardIds([]);
        } else {
            setSelectedCardIds &&
                setSelectedCardIds((prev) => {
                    if (prev.includes(cardId)) {
                        return prev.filter((id) => id !== cardId);
                    } else {
                        return [...prev, cardId];
                    }
                });
        }
    };

    const createWorksheet = async () => {
        const worksheet = worksheets.find(
            (item) => item.i === selectedWorksheet?.i
        );
        const settings: any =
            selectedWorksheet?.t === PlaybookPageType.List
                ? getListSettings()
                : getChartSettings(worksheet?.n || '');
        let options = {};
        if (
            selectedWorksheet?.t === PlaybookPageType.Chart ||
            selectedWorksheet?.t === PlaybookPageType.List
        ) {
            const nodeSizeSelection =
                settings?.options?.chartData?.nodeSize?.selection;
            options =
                selectedWorksheet?.t === PlaybookPageType.List
                    ? {
                          layout: [
                              {
                                  include: settings?.options.include,
                                  layout: settings?.options.layout,
                              },
                          ],
                      }
                    : {
                          chartData: {
                              xAxis: settings?.options.chartData
                                  .xAxisOptions[0],
                              yAxis: settings?.options.chartData
                                  .yAxisOptions[1],
                              nodeSize: {
                                  selection:
                                      nodeSizeSelection[
                                          nodeSizeSelection.length - 1
                                      ],
                              },
                              nodeColor: {
                                  selection:
                                      settings?.options.chartData.nodeColor
                                          .selection[0],
                              },
                              hideGaps: settings?.options.chartData.hideGaps,
                              radarOptions:
                                  worksheet?.n === 'Competitive landscape'
                                      ? getRadarOptions(cardTypeObject['3'])
                                      : null,
                          },
                          layout: [
                              {
                                  include: settings?.options.include,
                                  layout: settings?.options.layout,
                              },
                          ],
                      };
        }

        const leftChart = worksheet?.lp?.l;
        const rightChart = worksheet?.lp?.r;
        let include: string[] = [];

        switch (selectedWorksheet?.t) {
            case PlaybookPageType.Chart:
                if (selectedWorksheet.n === 'Retrospective') {
                    include = ['votes'];
                } else if (selectedWorksheet.n === "Porter's Five Forces") {
                    include = [
                        'illustration',
                        'brief description',
                        'score',
                        'score color',
                    ];
                } else {
                    include = ['title', 'score'];
                }
                break;
            case PlaybookPageType.List:
                if (leftChart || rightChart) {
                    include = ['charts', 'comparison table', 'details'];
                } else {
                    include = ['comparison table', 'details'];
                }
                break;
        }

        const userOrganisation = getUserOrganisation(user);

        let newOrdinal;
        if (lowestOrdinal != null) {
            newOrdinal = lowestOrdinal - 1;
        }

        try {
            const variables = {
                input: {
                    playbookPageType:
                        (selectedWorksheet?.t as PlaybookPageType) ||
                        newWorksheet.playbookPageType,
                    organisation: userOrganisation,
                    title: newWorksheet.title,
                    commentary: newWorksheet.commentary,
                    playbookId: playbookId && playbookId,
                    include,
                    ordinal: newOrdinal,
                    options:
                        newWorksheet?.playbookPageType ===
                        PlaybookPageType.Title
                            ? newWorksheet.options
                            : JSON.stringify(options),
                    worksheetId: selectedWorksheet?.i,
                    ...(selectedWorksheet?.t === PlaybookPageType.Chart && {
                        options: JSON.stringify(options),
                    }),
                },
            };

            const res = (await client.graphql({
                query: createPlaybookPage,
                variables: variables,
            })) as { data: CreatePlaybookPageMutation };
            showSnackbar({
                message: 'Playbook page created successfully',
                severity: 'success',
            });
            setPages &&
                pages &&
                setPages([
                    ...pages,
                    res.data.createPlaybookPage,
                ] as PlaybookPageDataResponse[]);
            if (submitType === 'createWorksheet') {
                handleClose && handleClose();
            } else {
                setSelectedWorksheet(null);
                setNewWorksheet({
                    title: '',
                    commentary: '',
                    playbookPageType: PlaybookPageType.List,
                });
            }
        } catch (error) {
            console.log(error);
            showSnackbar({
                message: 'Error while creating playbook page',
                severity: 'error',
            });
        }
    };
    return (
        <Box sx={{ overflowY: 'hidden' }}>
            <Snackbar
                open={isOpen}
                autoHideDuration={duration}
                onClose={closeSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                sx={{ marginBottom: '3rem', zIndex: 1600 }}
            >
                <Alert
                    variant="filled"
                    severity={severity}
                    sx={{ width: '100%' }}
                    onClose={closeSnackbar}
                >
                    {message}
                </Alert>
            </Snackbar>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    // flexDirection: 'column',
                    p: 2,
                }}
            >
                <Typography
                    variant="h5"
                    sx={{ marginBottom: 0, textAlign: 'left' }}
                >
                    {cardTypeFromDrawer
                        ? `Create ${cardTypeObject[
                              cardTypeFromDrawer
                          ].name?.toLowerCase()} card`
                        : !!convertSparkCardId
                        ? 'Convert to a different card type'
                        : pageIdentifier === PageIdentifier.WORKSHEET
                        ? 'Create a page'
                        : 'Create a card'}
                </Typography>
                {(pageIdentifier === PageIdentifier.WORKBOOK_INSIDE ||
                    pageIdentifier === PageIdentifier.WORKSHEET) && (
                    <OptionSelector
                        selectedCreateOption={selectedCreateOption}
                        setSelectedCreateOption={setSelectedCreateOption}
                        storedCreateOption={storedCreateOption}
                        setStoredCreateOption={setStoredCreateOption}
                        setSelectedStepId={setSelectedStepId}
                        setSelectedProcessId={setSelectedProcessId}
                        setNewCard={setNewCard}
                        setNewWorksheet={setNewWorksheet}
                        setSelectedWorksheet={setSelectedWorksheet}
                        setSelectedType={setSelectedType}
                        pageIdentifier={pageIdentifier}
                        preferences={preferences}
                        workbook={workbook}
                    />
                )}
            </Box>

            <form onSubmit={handleFormSubmit}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 0,
                        height: '61vh',
                        maxHeight: '670px',
                    }}
                >
                    <Grid container spacing={0} sx={{ height: '100%' }}>
                        {!cardTypeFromDrawer && (
                            <Grid
                                item
                                xs={12}
                                md={
                                    selectedCreateOption ===
                                        CreateCardOptions.BY_PROCESS &&
                                    pageIdentifier ===
                                        PageIdentifier.WORKBOOK_INSIDE
                                        ? 3
                                        : pageIdentifier ===
                                              PageIdentifier.WORKSHEET &&
                                          selectedCreateOption !==
                                              CreateCardOptions.TITLE_PAGE
                                        ? 2.5
                                        : selectedCreateOption ===
                                          CreateCardOptions.TITLE_PAGE
                                        ? 4
                                        : 3.5
                                }
                                sx={{ height: '100%' }}
                            >
                                {(selectedCreateOption ===
                                    CreateCardOptions.BY_PROCESS &&
                                    pageIdentifier ===
                                        PageIdentifier.WORKBOOK_INSIDE) ||
                                (pageIdentifier === PageIdentifier.WORKSHEET &&
                                    selectedCreateOption ===
                                        CreateCardOptions.BY_PROCESS) ? (
                                    <ProcessColumn
                                        selectedProcessId={selectedProcessId}
                                        setSelectedProcessId={
                                            setSelectedProcessId
                                        }
                                        setSelectedStepId={setSelectedStepId}
                                        setSelectedWorksheet={
                                            setSelectedWorksheet
                                        }
                                        setSelectedType={setSelectedType}
                                        newCard={newCard}
                                        setNewCard={setNewCard}
                                    />
                                ) : selectedCreateOption ===
                                  CreateCardOptions.TITLE_PAGE ? (
                                    <PagesColumn />
                                ) : (
                                    <CategoriesColumn
                                        newCard={newCard}
                                        cardCategories={cardCats}
                                        cardSetToCardCategoryId={
                                            cardSetToCardCategoryId ?? ''
                                        }
                                        setNewCard={(value) =>
                                            setNewCard(value as NewCard)
                                        }
                                        setSelectedType={setSelectedType}
                                        setSelectedWorksheet={
                                            setSelectedWorksheet
                                        }
                                        cardTypeFromDrawer={cardTypeFromDrawer}
                                        setSelectedProcessId={
                                            setSelectedProcessId
                                        }
                                        setSelectedStepId={setSelectedStepId}
                                    />
                                )}
                            </Grid>
                        )}
                        {((selectedCreateOption ===
                            CreateCardOptions.BY_PROCESS &&
                            pageIdentifier ===
                                PageIdentifier.WORKBOOK_INSIDE) ||
                            (selectedCreateOption ===
                                CreateCardOptions.BY_PROCESS &&
                                pageIdentifier ===
                                    PageIdentifier.WORKSHEET)) && (
                            <Grid
                                item
                                xs={12}
                                md={
                                    pageIdentifier === PageIdentifier.WORKSHEET
                                        ? 2.5
                                        : 3
                                }
                                sx={{ height: '100%' }}
                            >
                                <StepColumn
                                    selectedProcessId={selectedProcessId}
                                    setSelectedStepId={setSelectedStepId}
                                    selectedStepId={selectedStepId}
                                />
                            </Grid>
                        )}
                        {pageIdentifier === PageIdentifier.WORKSHEET &&
                            selectedCreateOption ===
                                CreateCardOptions.BY_CATEGORY && (
                                <Grid
                                    item
                                    xs={12}
                                    md={
                                        pageIdentifier ===
                                        PageIdentifier.WORKSHEET
                                            ? 2.5
                                            : 3
                                    }
                                    sx={{ height: '100%' }}
                                >
                                    <TypesColumn
                                        setSelectedType={setSelectedType}
                                        cardCategories={cardCats}
                                        newCard={newCard}
                                    />
                                </Grid>
                            )}
                        {selectedCreateOption !==
                            CreateCardOptions.TITLE_PAGE &&
                            !cardTypeFromDrawer && (
                                <Grid
                                    item
                                    xs={12}
                                    md={
                                        selectedCreateOption ===
                                            CreateCardOptions.BY_PROCESS &&
                                        pageIdentifier ===
                                            PageIdentifier.WORKBOOK_INSIDE
                                            ? 2
                                            : 4
                                    }
                                    sx={{ height: '100%' }}
                                >
                                    {pageIdentifier ===
                                    PageIdentifier.WORKSHEET ? (
                                        <WorksheetsColumn
                                            selectedStepId={selectedStepId}
                                            setSelectedWorksheet={
                                                setSelectedWorksheet
                                            }
                                            selectedWorksheet={
                                                selectedWorksheet
                                            }
                                            selectedType={selectedType}
                                        />
                                    ) : (
                                        <TypesColumn
                                            newCard={newCard}
                                            cardSetToCardCategoryId={
                                                cardSetToCardCategoryId ?? ''
                                            }
                                            cardSetToCardTypeId={
                                                cardSetToCardTypeId
                                            }
                                            setNewCard={(value) =>
                                                setNewCard(value as NewCard)
                                            }
                                            cardCategories={cardCats}
                                            selectedStepId={selectedStepId}
                                            cardTypeFromDrawer={
                                                cardTypeFromDrawer
                                            }
                                            pageIdentifier={pageIdentifier}
                                            selectedType={selectedType}
                                        />
                                    )}
                                </Grid>
                            )}
                        {selectedCreateOption ===
                            CreateCardOptions.TITLE_PAGE && (
                            <Grid item xs={12} md={4}>
                                <LayoutColumn
                                    newWorksheet={newWorksheet}
                                    setNewWorksheet={setNewWorksheet}
                                />
                            </Grid>
                        )}

                        <Grid
                            item
                            xs={12}
                            md={
                                cardTypeFromDrawer
                                    ? 12
                                    : selectedCreateOption ===
                                          CreateCardOptions.BY_PROCESS &&
                                      pageIdentifier ===
                                          PageIdentifier.WORKBOOK_INSIDE
                                    ? 4
                                    : pageIdentifier ===
                                          PageIdentifier.WORKSHEET &&
                                      selectedCreateOption !==
                                          CreateCardOptions.TITLE_PAGE
                                    ? 3
                                    : selectedCreateOption ===
                                      CreateCardOptions.TITLE_PAGE
                                    ? 4
                                    : 4.5
                            }
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <AttributesColumn
                                newCard={newCard}
                                setNewCard={(value) =>
                                    setNewCard(value as NewCard)
                                }
                                urlError={urlError}
                                setUrlError={setUrlError}
                                pageIdentifier={pageIdentifier}
                                selectedWorksheet={selectedWorksheet}
                                newWorksheet={newWorksheet}
                                setNewWorksheet={setNewWorksheet}
                                cardTypeFromDrawer={cardTypeFromDrawer}
                                pageOptions={pageOptions}
                                scores={scores}
                                setScores={setScores}
                                cardScores={cardScores}
                                setCardScores={setCardScores}
                                radarOptions={radarOptions}
                            />

                            {newCard.cardToCardTypeId && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        pb: 3,
                                        px: 3,
                                    }}
                                >
                                    {!card && !convertSparkCardId && (
                                        <Button
                                            disabled={loading}
                                            variant="contained"
                                            type="submit"
                                            onClick={() => {
                                                setSubmitType('createAnother');
                                            }}
                                            sx={{
                                                justifySelf: 'right',
                                                mt: 2,
                                                mr: 2,
                                                width: 'fit-content',
                                                px: '10px',
                                                fontSize: '12px',
                                                borderRadius: 2,
                                            }}
                                            data-automation-id="create-another-button"
                                        >
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={'1rem'}
                                                />
                                            ) : (
                                                'Save and create another'
                                            )}
                                        </Button>
                                    )}
                                    <Button
                                        disabled={loading}
                                        variant="contained"
                                        type="submit"
                                        onClick={() => {
                                            setSubmitType('create');
                                        }}
                                        sx={{
                                            justifySelf: 'right',
                                            mt: 2,
                                            width: 'fit-content',
                                            px: '10px',
                                            fontSize: '12px',
                                            borderRadius: 2,
                                        }}
                                        data-automation-id="create-card-button"
                                    >
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={'1rem'}
                                            />
                                        ) : !!convertSparkCardId ? (
                                            'Convert card'
                                        ) : card ? (
                                            'Edit card'
                                        ) : (
                                            'Create card'
                                        )}
                                    </Button>
                                </Box>
                            )}
                            {(selectedWorksheet ||
                                newWorksheet.playbookPageType ===
                                    PlaybookPageType.Title) && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        pb: 3,
                                        px: 3,
                                    }}
                                >
                                    {' '}
                                    <Button
                                        disabled={loading}
                                        variant="contained"
                                        type="submit"
                                        onClick={() => {
                                            setSubmitType(
                                                'createAnotherWorksheet'
                                            );
                                        }}
                                        sx={{
                                            justifySelf: 'right',
                                            mt: 2,
                                            mr: 2,
                                            width: 'fit-content',
                                            px: '10px',
                                            fontSize: '12px',
                                            borderRadius: 2,
                                        }}
                                    >
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={'1rem'}
                                            />
                                        ) : (
                                            'Save and create another'
                                        )}
                                    </Button>
                                    <Button
                                        disabled={loading}
                                        variant="contained"
                                        type="submit"
                                        onClick={() => {
                                            setSubmitType('createWorksheet');
                                        }}
                                        sx={{
                                            fontSize: '12px',
                                            justifySelf: 'right',
                                            mt: 2,
                                            width: 'fit-content',
                                            borderRadius: 2,
                                        }}
                                        data-automation-id="create-worksheet-button"
                                    >
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={'1rem'}
                                            />
                                        ) : (
                                            'Create page'
                                        )}
                                    </Button>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Box>
    );
};

export default CreateCard;
