import { Box, Checkbox, Tooltip, Typography, useTheme } from '@mui/material';
import { CardSet } from '../../../../API';
import { AnimatePresence, motion } from 'framer-motion';
import { defaultTabTransition } from '../../../../helpers/animations';
import { ReactComponent as CardSetsIcon } from '../../../../assets/icons/Small-card-set.svg';
import { ReactComponent as WorkbooksIcon } from '../../../../assets/icons/Small-workbook.svg';
import { getCategoryHex } from '../../../../helpers/category';
import { getMozillaPt } from '../../../../helpers/styleConfig';
import { truncateText } from '../../../../helpers/utils';
import { FilterCards } from '../../../../hooks/useFilterCards';

interface AnimatedDivProps {
    children: React.ReactNode;
    animatedKey: string;
}

const AnimatedDiv: React.FC<AnimatedDivProps> = ({ children, animatedKey }) => (
    <AnimatePresence>
        <motion.div
            key={animatedKey}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={defaultTabTransition}
        >
            {children}
        </motion.div>
    </AnimatePresence>
);

interface CardSetListProps {
    items: CardSet[];
    localFilters: FilterCards;
    setLocalFilters: React.Dispatch<React.SetStateAction<FilterCards>>;
    type: string;
}
const CardSetList = ({
    items,
    localFilters,
    setLocalFilters,
    type,
}: CardSetListProps) => {
    const theme = useTheme();
    const availableItems = items.filter(
        (cardSet) =>
            cardSet.cardSetToCardTypeId &&
            localFilters.cardType?.includes(cardSet.cardSetToCardTypeId)
    );
    const disabledItems = items.filter(
        (cardSet) =>
            cardSet.cardSetToCardTypeId &&
            !localFilters.cardType?.includes(cardSet.cardSetToCardTypeId)
    );
    const handleChange = (cardSetId: string, type: string) => {
        setLocalFilters((prevFilters) => {
            const newFilters = { ...prevFilters };

            if (type === 'CS') {
                if (newFilters.cardSet?.includes(cardSetId)) {
                    newFilters.cardSet = newFilters.cardSet.filter(
                        (id) => id !== cardSetId
                    );
                } else {
                    newFilters.cardSet = [
                        ...(newFilters.cardSet || []),
                        cardSetId,
                    ];
                }
            } else if (type === 'WB') {
                if (newFilters.workbook?.includes(cardSetId)) {
                    newFilters.workbook = newFilters.workbook.filter(
                        (id) => id !== cardSetId
                    );
                } else {
                    newFilters.workbook = [
                        ...(newFilters.workbook || []),
                        cardSetId,
                    ];
                }
            }

            return newFilters;
        });
    };
    const renderCardSetItems = (
        cardSetItems: CardSet[],
        isCardTypeSelected: boolean
    ) =>
        cardSetItems.map((cardSet: CardSet) => (
            <AnimatedDiv animatedKey={cardSet.id} key={cardSet.id}>
                <Box
                    key={cardSet.id}
                    sx={{
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: theme.palette.background.paper,
                        },
                    }}
                    onClick={() =>
                        !isCardTypeSelected
                            ? null
                            : handleChange(cardSet.id, cardSet.type)
                    }
                >
                    <Box display="flex" alignItems="center">
                        <Checkbox
                            sx={{
                                color: isCardTypeSelected ? 'primary' : 'grey',
                            }}
                            checked={
                                cardSet.type === 'CS'
                                    ? localFilters.cardSet?.includes(cardSet.id)
                                    : localFilters.workbook?.includes(
                                          cardSet.id
                                      )
                            }
                            disabled={!isCardTypeSelected}
                        />
                        {cardSet.type === 'CS' &&
                        cardSet.cardSetToCardCategoryId ? (
                            <CardSetsIcon
                                style={{
                                    color: isCardTypeSelected
                                        ? getCategoryHex(
                                              cardSet.cardSetToCardCategoryId
                                          )
                                        : 'grey',
                                    width: '25px',
                                    height: '30px',
                                }}
                            />
                        ) : (
                            <WorkbooksIcon
                                style={{
                                    color: 'grey[500]',
                                    width: '25px',
                                    height: '30px',
                                }}
                            />
                        )}

                        <Tooltip title={cardSet.name}>
                            <Typography
                                variant="body1"
                                sx={{
                                    ml: 1,
                                    ...getMozillaPt(2),
                                    color: isCardTypeSelected
                                        ? 'inherit'
                                        : 'grey',
                                }}
                            >
                                {truncateText(cardSet.name, 15)}
                            </Typography>
                        </Tooltip>
                    </Box>
                </Box>
            </AnimatedDiv>
        ));

    return (
        <Box sx={{ height: '200px' }}>
            <Typography variant="body1" sx={{ ml: 2, fontWeight: 'bold' }}>
                {type === 'WB' ? 'Workbooks' : 'Card sets'}
            </Typography>
            {localFilters?.cardType?.length === 0 || type === 'WB' ? (
                <Box>{renderCardSetItems(items, true)}</Box>
            ) : (
                <>
                    {' '}
                    <Box>{renderCardSetItems(availableItems, true)}</Box>
                    <Box>{renderCardSetItems(disabledItems, false)}</Box>
                </>
            )}
        </Box>
    );
};

export default CardSetList;
