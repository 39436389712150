import { DateTime } from 'luxon';
import { CardCategory, CardType, EventContext } from '../API';
import React from 'react';

export const dateFromISO = (string: string) => {
    return DateTime.fromISO(string);
};

export const dateStringFromISO = (string: string, short?: boolean) => {
    const date = new Date(string);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
    };
    if (!short) {
        options.day = 'numeric';
    }

    return date.toLocaleDateString('en-US', options);
};
export const timeStringFromISO = (isoString: string) => {
    const date = new Date(isoString);
    const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
    };
    return date.toLocaleTimeString('en-US', options);
};

export const shortDateStringFromISO = (string: string) => {
    if (!string) return '';
    const date = new Date(string);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
};

export const getMonth = (string: string, length: 'short' | 'long') => {
    const date = new Date(string);
    const options: Intl.DateTimeFormatOptions = {
        month: length,
    };
    return date.toLocaleDateString('en-US', options);
};
export const getFutureDate = (delayInDays: number) => {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + delayInDays);
    const unixTimestamp = Math.floor(currentDate.getTime() / 1000);
    return unixTimestamp;
};
export const getCssVar = (name: string) => {
    if (name.substring(0, 2) !== '--') {
        name = '--' + name;
    }

    if (typeof window !== 'undefined') {
        /* we're on the server */
        return getComputedStyle(document.documentElement).getPropertyValue(
            name
        );
    }
};

export const getMuiCssVar = (name: string) => {
    const muiStr = '--mui-palette-';

    if (name.substring(0, 14) !== muiStr) {
        return getCssVar(muiStr + name);
    } else {
        return getCssVar(name);
    }
};

export const isJsonString = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const truncateText = (str?: string, max?: number) => {
    if (!str) return '';
    if (max && max > str.length - 3) return str;

    let truncatedString = str.substring(0, max);

    return `${truncatedString}...`;
};

export const formatScore = (value?: number) => {
    if (value == null) return '';

    if (1000 > value) {
        return value;
    }
    if (1000000 > value) {
        return `${Math.round(value / 1000)}K`;
    }
    if (1000000000 > value) {
        return `${Math.round(value / 1000000)}M`;
    }
    return `${Math.round(value / 1000000000)}B`;
};

export const isValid = (
    value: number,
    min?: number,
    max?: number,
    reverseMaxMin?: boolean
) => {
    if ((min == null || value >= min) && (max == null || value <= max)) {
        return true;
    } else {
        return false;
    }

    return false;
};

export const getCardTypesFromCategory = (
    cardCategory?: CardCategory | undefined
): CardType[] => {
    if (cardCategory?.toCardTypes != null) {
        return cardCategory.toCardTypes.items.flatMap(
            (cardType) => cardType || []
        );
    }
    return [];
};

export const sortAlphabetically = () => {
    return function (a: CardType, b: CardType) {
        if (a.name == null) {
            return 1;
        }
        if (b.name == null) {
            return -1;
        }
        return a.name.localeCompare(b.name);
    };
};

export const getCurrencySymbol = () => {
    //default to £ for now. We also additional currencies when required

    return '£';
};

export const hasDuplicates = (arr: any) => arr.length !== new Set(arr).size;

export const getContext = (path: string, id?: string, worksheet?: boolean) => {
    const paths = path.split('/');

    switch (paths[1]) {
        case 'cards':
            return { contextType: EventContext.CardManager, contextId: null };
        case 'card-sets':
            return {
                contextType: id
                    ? EventContext.CardSet
                    : EventContext.CardSetManager,
                contextId: id ?? null,
            };
        case 'playbooks':
            return {
                contextType: id
                    ? EventContext.Playbook
                    : EventContext.PlaybookManager,
                contextId: id ?? null,
            };
        case 'workbooks':
            return worksheet
                ? {
                      contextType: EventContext.WorksheetDirect,
                      contextId: paths[3],
                  }
                : paths[3]
                ? {
                      contextType: EventContext.Worksheet,
                      contextId: paths[3],
                  }
                : {
                      contextType: id
                          ? EventContext.Workbook
                          : EventContext.WorkbookManager,
                      contextId: id ?? null,
                  };
        case 'dashboard':
            return { contextType: EventContext.Unknown, contextId: null };
        case 'profile':
            return { contextType: EventContext.Unknown, contextId: null };
        default:
            return { contextType: EventContext.Unknown, contextId: null };
    }
};
