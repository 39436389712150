import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CustomDataPoint } from '../../charts/BubbleChart';
import { truncateText } from '../../../helpers/utils';
import {
    Attribute,
    AttributeDefinition,
    AttributeType,
    Card,
} from '../../../API';
import { removeQuotes } from './BubblePage';
import { getAttributeCard } from '../../../helpers/worksheets';
import { AxisOptions, NodeColorOptions } from '../playbookTypes';
import BubbleKebabMenu from '../../workbooks/BubbleKebabMenu';

interface BubbleModalProps {
    node: CustomDataPoint;
    attributeDefinitions: (AttributeDefinition | null)[] | null | undefined;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    allScores: string[] | undefined;
    allAttributes: string[] | undefined;
    selectedCard: Card;
    workbook: boolean | undefined;
}
interface AttributeCardData {
    [key: string]: string;
}
const BubbleModal = ({
    node,
    attributeDefinitions,
    setIsModalOpen,
    allScores,
    allAttributes,
    selectedCard,
    workbook,
}: BubbleModalProps) => {
    const [attributeCardsData, setAttributeCardsData] =
        useState<AttributeCardData>({});
    const validAttributes = node?.card?.attributes.filter(
        (attr: Attribute) => attr !== null && attr !== undefined
    );
    const excludedScores: AxisOptions[] = [
        AxisOptions.SkillGapCurrentVsBenchmark,
        AxisOptions.SkillGapCurrentVsDesired,
        AxisOptions.SkillGapDesiredVsBenchmark,
        AxisOptions.MaturityGapCurrentVsBenchmark,
        AxisOptions.MaturityGapCurrentVsDesired,
        AxisOptions.MaturityGapDesiredVsBenchmark,
    ];
    const excludedAttributes: NodeColorOptions[] = [
        NodeColorOptions.DisableNodeColor,
        NodeColorOptions.StrategicImportance,
        NodeColorOptions.CompetitiveIntensity,
        NodeColorOptions.Risk,
    ];
    useEffect(() => {
        const fetchAttributeCards = async () => {
            const cardsData: AttributeCardData = {};
            for (const attr of validAttributes) {
                const attributeDefinition = attributeDefinitions?.find(
                    (def) => def?.id === attr.attributeDefinitionID
                );
                if (attributeDefinition?.attributeType === AttributeType.Card) {
                    const cardKey = attr.value || '';
                    const cardData = (await getAttributeCard(cardKey)) || '-';
                    cardsData[attr.attributeDefinitionID] = cardData;
                }
            }
            setAttributeCardsData(cardsData);
        };

        fetchAttributeCards();
    }, [node, attributeDefinitions]);

    return (
        <Box sx={{ pb: '8px', px: '16px', color: '#000' }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="h6">
                    {truncateText(node.label, 30)}
                </Typography>
                {!workbook ? (
                    <IconButton
                        aria-label="close"
                        onClick={() => setIsModalOpen(false)}
                        sx={{
                            mr: '-20px',
                            mb: '15px',
                        }}
                        size="small"
                    >
                        <CloseIcon />
                    </IconButton>
                ) : (
                    <BubbleKebabMenu
                        node={node}
                        card={selectedCard}
                        fullWidth={false} 
                        setIsModalOpen={setIsModalOpen}
                    />
                )}
            </Box>
            <Typography variant="body2" sx={{ fontSize: '12px' }}>
                {truncateText(node.card.briefDescription || '', 80)}
            </Typography>
            <Divider sx={{ mb: 1, borderColor: 'grey.300' }} />
            {allAttributes
                ?.map((attributeName, index) => {
                    const attributeItem = validAttributes.find(
                        (attr: Attribute) => {
                            const attributeDefinition =
                                attributeDefinitions?.find(
                                    (def) =>
                                        def?.id === attr.attributeDefinitionID
                                );
                            return attributeDefinition?.name === attributeName;
                        }
                    );
                    let displayValue = '-';
                    if (attributeItem) {
                        const attributeDefinition = attributeDefinitions?.find(
                            (def) =>
                                def?.id === attributeItem?.attributeDefinitionID
                        );
                        const attributeType =
                            attributeDefinition?.attributeType;

                        if (attributeType === AttributeType.Card) {
                            displayValue =
                                attributeCardsData[
                                    attributeItem.attributeDefinitionID
                                ] || '-';
                        } else {
                            displayValue =
                                removeQuotes(attributeItem.value) || '-';
                        }
                    }
                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{ fontSize: '12px' }}
                            >
                                {attributeName}
                            </Typography>
                            <Tooltip title={displayValue}>
                                <Typography
                                    variant="body2"
                                    sx={{ fontSize: '12px' }}
                                >
                                    {truncateText(displayValue, 12)}
                                </Typography>
                            </Tooltip>
                        </Box>
                    );
                })}
            <Divider sx={{ my: 1, borderColor: 'grey.300' }} />
            {allScores
                ?.filter(
                    (item) => !excludedScores.includes(item as AxisOptions)
                )
                .map((scoreName, index) => {
                    const scoreValue = node.card.scoreData[scoreName];
                    let displayValue = scoreValue
                        ? String(scoreValue.value)
                        : '-';
                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{ fontSize: '12px' }}
                            >
                                {scoreName}
                            </Typography>
                            <Tooltip title={displayValue}>
                                <Typography
                                    variant="body2"
                                    sx={{ fontSize: '12px' }}
                                >
                                    {truncateText(displayValue, 12)}
                                </Typography>
                            </Tooltip>
                        </Box>
                    );
                })}
        </Box>
    );
};

export default BubbleModal;
