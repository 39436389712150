import { createContext } from 'react';
import { CardComponentData } from './cardTypes';
import { CardPage } from './cardTypes';
import { UserPermissions } from '../../globals';
import { Card } from '../../API';

export const CardContext = createContext<{
    emptyAction?: () => void;
    handleClick: (cardId: string, cardPage?: CardPage) => void;
    handleClose: (card?: any) => void;
    handleDelete: (
        id: string,
        organisation: string,
        secondaryDelete?: boolean
    ) => void;
    handleEdit: (cardId: string) => void;
    handleRemove?: (cardId: string) => void;
    handleCopy?: (cardId: string) => void;
    handleConvert?: (cardId: string) => void;
    refreshCard?: (cardId: string, organisation: string) => void;
    permissions?: UserPermissions[];
    items: CardComponentData[];
    setRelationCard?: (card: Card) => void;
    backCard?: boolean;
    goBack?: () => void;
}>({
    emptyAction: () => null,
    handleClick: () => null,
    handleClose: () => null,
    handleDelete: () => null,
    handleEdit: () => null,
    handleRemove: () => null,
    handleCopy: () => null,
    refreshCard: () => null,
    setRelationCard: () => null,
    permissions: [],
    items: [],
    backCard: false,
    goBack: () => null,
});
