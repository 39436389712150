import { useRef, useState, useContext, useEffect } from 'react';
import { AnimatePresence, LayoutGroup } from 'framer-motion';
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Tooltip,
    Typography,
    keyframes,
    useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PageView, { ViewType, ViewOptions } from '../../layouts/PageView';
import CardListView from './CardListView';
import CardGridView from './CardGridView';
import CardAnalyzeView from './CardAnalyzeView';
import { AppContext } from '../../contexts';
import ManagerControls from '../ManagerControls';
import { CardPage, SortActions, PageIdentifier } from '../cardTypes';
import { FilterCards } from '../../../hooks/useFilterCards';
import { PagePreview } from '../../workbooks/WorkbookDrawerPage';
import WorkBookDrawer from '../../workbooks/WorkbookDrawer';
import CardMenu from '../CardMenu';
import { UserPermissions } from '../../../globals';
import CardActions, { CardActionItemProps } from '../CardActions';
import { truncateText } from '../../../helpers/utils';
import { getCategoryHexText } from '../../../helpers/category';
import { Card, CardSet } from '../../../API';
import { CardToDelete } from '../../../pages/cards';
import AddCardMenu from '../AddCardMenu';
import SparkIcon from '../../../assets/icons/spark-icon-transparent.gif';
import { useLocation } from 'react-router-dom';

export interface ActiveIcons {
    [key: string]: boolean;
}

export enum DrawStates {
    CLOSED = 'closed',
    HALFONE = 'halfone',
    HALFTWO = 'halftwo',
    FULL = 'full',
}
interface CardTypeLayoutProps {
    headerTitle: string;
    viewOptions: ViewOptions;
    viewTitle?: string;
    action?: () => void;
    actionLabel?: string;
    secondaryAction?: () => void;
    secondaryActionLabel?: string;
    playbookDrawerItems?: PagePreview[];
    setPlaybookDrawerItems?: (drawItems: PagePreview[]) => void;
    activeIcons?: ActiveIcons;
    setActiveIcons?: React.Dispatch<React.SetStateAction<ActiveIcons>>;
    pageIdentifier: PageIdentifier;
    token?: string | null;
    loading?: boolean;
    onLoadMore?: (initial: boolean) => void;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    filterCriteria: FilterCards;
    setFilterCriteria: React.Dispatch<React.SetStateAction<FilterCards>>;
    handleDelete?: (id: string) => Promise<void>;
    handleCopy?: (cardId: string) => Promise<void>;
    handleEdit?: (cardId: string) => void;
    permissions?: UserPermissions[];
    id?: string;
    organisation?: string;
    handleSecondaryCopy?: () => void;
    handleSecondaryDelete?: (id: string) => Promise<void>;
    currentWorkbook?: CardSet;
    setCurrentWorkbook?: (workbook: CardSet) => void;
    refreshWorkbookDrawer?: () => void;
    newWorksheet?: boolean;
    handleSecondaryEdit?: () => void;
    handleListViewEdit?: (cardId: string, cardPage?: CardPage) => void;
    cardAdded?: boolean;
    setCardItems?: React.Dispatch<React.SetStateAction<Card[]>>;
    multiSelectedCards?: CardToDelete[] | [];
    setMultiSelectedCards?: React.Dispatch<
        React.SetStateAction<CardToDelete[] | []>
    >;
    cardsObject?: {
        [key: string]: Card;
    };
    setAddMenu?: (e: React.MouseEvent<HTMLElement> | null) => void;
    addMenuAnchor?: HTMLElement | null | undefined;
    openDrawerItem?: PagePreview | null;
    setOpenDrawerItem?: React.Dispatch<
        React.SetStateAction<PagePreview | null>
    >;
    setShowCreateSparkWizard?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardTypeLayout = ({
    action,
    actionLabel,
    headerTitle,
    secondaryAction,
    secondaryActionLabel,
    playbookDrawerItems,
    setPlaybookDrawerItems,
    viewTitle,
    viewOptions,
    activeIcons,
    setActiveIcons,
    pageIdentifier,
    token,
    loading,
    onLoadMore,
    xs,
    sm,
    md,
    lg,
    xl,
    filterCriteria,
    setFilterCriteria,
    handleDelete,
    handleCopy,
    handleEdit,
    permissions,
    id,
    organisation,
    handleSecondaryCopy,
    handleSecondaryDelete,
    currentWorkbook,
    setCurrentWorkbook,
    refreshWorkbookDrawer,
    newWorksheet,
    handleSecondaryEdit,
    handleListViewEdit,
    cardAdded,
    setCardItems,
    multiSelectedCards,
    setMultiSelectedCards,
    cardsObject,
    setAddMenu,
    addMenuAnchor,
    openDrawerItem,
    setOpenDrawerItem,
    setShowCreateSparkWizard,
}: CardTypeLayoutProps) => {
    const elementRef = useRef<HTMLElement>(null);
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
    const [hideCards, setHideCards] = useState(false);

    const { viewType, setViewType } = useContext(AppContext);
    const theme = useTheme();
    const playbookDrawerWidth = localStorage.getItem(`playbookDrawerWidth`);
    const [showDrawer, setShowDrawer] = useState<DrawStates>(
        (playbookDrawerWidth as DrawStates) ?? DrawStates.CLOSED
    );
    const textColor = getCategoryHexText(id ?? '');

    const updateDrawerState = () => {
        if (showDrawer === DrawStates.CLOSED) {
            setShowDrawer(DrawStates.HALFONE);
        } else if (showDrawer === DrawStates.HALFONE) {
            setShowDrawer(DrawStates.FULL);
        } else if (showDrawer === DrawStates.FULL) {
            setShowDrawer(DrawStates.HALFTWO);
        } else {
            setShowDrawer(DrawStates.CLOSED);
        }
    };

    useEffect(() => {
        localStorage.setItem(`playbookDrawerWidth`, showDrawer);
    }, [showDrawer]);

    const toggleIcon = (
        iconName: keyof ActiveIcons,
        selectedSortAction: string
    ) => {
        if (setActiveIcons) {
            setActiveIcons((prevState) => ({
                ...prevState,
                [iconName]:
                    iconName === 'Sort'
                        ? selectedSortAction ===
                          SortActions.LAST_MODIFIED_RECENT_FIRST
                            ? false
                            : true
                        : !prevState[iconName],
            }));
        }
    };
    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            setMenuAnchor(e.currentTarget);
        } else {
            setMenuAnchor(null);
        }
    };

    const menuItems: CardActionItemProps[] = [];

    useEffect(() => {
        if (newWorksheet) {
            setShowDrawer(DrawStates.HALFONE);
        }
    }, [newWorksheet]);

    useEffect(() => {
        if (cardAdded) {
            setShowDrawer(DrawStates.HALFONE);
        }
    }, [cardAdded]);

    useEffect(() => {
        if (showDrawer === DrawStates.FULL) {
            setTimeout(() => {
                setHideCards(true);
            }, 800);
        } else {
            setHideCards(false);
        }
    }, [showDrawer]);

    useEffect(() => {
        if (menuAnchor) {
            document.body.classList.add('body-scroll-lock');
        } else {
            document.body.classList.remove('body-scroll-lock');
        }
    }, [menuAnchor]);
    return (
        <Box sx={{ paddingTop: '48px' }}>
            {(!!viewTitle || !!action) && (
                <Box
                    sx={{
                        background: theme.palette.background.default,
                        ml: '5px',
                        py: '25px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        position: 'fixed',
                        zIndex: 1100,
                        width: 'calc(100% - 91px)',
                    }}
                >
                    <Box
                        sx={{
                            paddingLeft: '110px',
                            paddingRight: '110px',
                            width: '100%',
                            margin: '0 auto',
                            display: 'flex',
                            maxWidth: '108rem',
                        }}
                    >
                        {viewTitle && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flex: 1,
                                }}
                            >
                                <Typography
                                    variant={
                                        pageIdentifier ===
                                        PageIdentifier.STRATEGY_BUILDER
                                            ? 'body1'
                                            : 'h4'
                                    }
                                    sx={{
                                        fontFamily:
                                            pageIdentifier ===
                                            PageIdentifier.STRATEGY_BUILDER
                                                ? 'Roboto'
                                                : 'Oswald',
                                        overflowWrap: 'break-word',
                                        paddingBottom: '0.3rem',
                                    }}
                                >
                                    {truncateText(
                                        viewTitle,
                                        pageIdentifier ===
                                            PageIdentifier.STRATEGY_BUILDER
                                            ? 150
                                            : 20
                                    )}
                                </Typography>
                                {permissions && (
                                    <CardActions
                                        textColor={theme.palette.action.active}
                                        setMenu={setMenu}
                                        expanded={true}
                                        items={menuItems}
                                        padding={0}
                                    />
                                )}

                                {menuAnchor &&
                                    permissions &&
                                    id &&
                                    (handleSecondaryCopy ||
                                        handleSecondaryDelete ||
                                        handleSecondaryEdit) && (
                                        <CardMenu
                                            permissions={permissions}
                                            anchor={menuAnchor}
                                            data={{
                                                organisation:
                                                    organisation || '',
                                                id,
                                            }}
                                            {...(!!handleSecondaryCopy && {
                                                handleSecondaryCopy: () => {
                                                    handleSecondaryCopy();
                                                },
                                            })}
                                            handleSecondaryDelete={() =>
                                                handleSecondaryDelete &&
                                                handleSecondaryDelete(id)
                                            }
                                            {...(!!handleSecondaryEdit && {
                                                handleSecondaryEdit: () =>
                                                    handleSecondaryEdit(),
                                            })}
                                            setMenu={setMenu}
                                        />
                                    )}

                                {addMenuAnchor &&
                                    permissions &&
                                    id &&
                                    (handleSecondaryCopy ||
                                        handleSecondaryDelete ||
                                        handleSecondaryEdit) && (
                                        <AddCardMenu
                                            permissions={permissions}
                                            anchor={addMenuAnchor}
                                            setMenu={setAddMenu}
                                            secondaryAction={secondaryAction}
                                            action={action}
                                        />
                                    )}
                            </Box>
                        )}
                        {activeIcons && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '0%',
                                    [theme.breakpoints.up('md')]: {
                                        left: '50%',
                                        right: 'initial',
                                    },
                                    transform: 'translate(-50%, -50%)',
                                }}
                            >
                                <ManagerControls
                                    activeIcons={activeIcons}
                                    toggleIcon={toggleIcon}
                                    pageIdentifier={pageIdentifier}
                                    filterCriteria={filterCriteria}
                                    setFilterCriteria={setFilterCriteria}
                                />
                            </Box>
                        )}

                        <Box sx={{ display: 'flex' }}>
                            {action && (
                                <Tooltip
                                    title={`Create a ${
                                        headerTitle === 'Playbooks'
                                            ? 'playbook'
                                            : headerTitle === 'Card sets'
                                            ? 'card set'
                                            : headerTitle === 'Workbooks'
                                            ? 'workbook'
                                            : 'card'
                                    }`}
                                    placement="left"
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={(e) =>
                                            setAddMenu
                                                ? setAddMenu(e)
                                                : action()
                                        }
                                        title={actionLabel}
                                    >
                                        <AddIcon
                                            sx={{
                                                fontSize: '3rem',
                                                width: '40px',
                                                height: '40px',
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', cursor: 'pointer' }}>
                            {setShowCreateSparkWizard && action && (
                                <Tooltip
                                    title="Create a “spark” card to spark a discussion"
                                    placement="right"
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={() =>
                                            setShowCreateSparkWizard(true)
                                        }
                                        sx={{ width: '56px', height: '56px' }}
                                    >
                                        <img
                                            src={SparkIcon}
                                            alt="Bolt Icon"
                                            style={{
                                                width: '35x',
                                                height: '35px',
                                                borderRadius: '16px',
                                                marginTop: '-5px',
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
            <PageView
                title={headerTitle}
                viewOptions={viewOptions}
                setViewOption={setViewType}
                selectedViewType={viewType}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        paddingTop: '70px',
                    }}
                    ref={elementRef}
                >
                    <AnimatePresence>
                        {playbookDrawerItems && setPlaybookDrawerItems ? (
                            <Box sx={{ display: 'flex' }}>
                                <Box
                                    sx={{
                                        flex: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display:
                                                showDrawer === DrawStates.CLOSED
                                                    ? 'inherit'
                                                    : 'flex',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width:
                                                    showDrawer ===
                                                    DrawStates.CLOSED
                                                        ? 'inherit'
                                                        : '743px',
                                                opacity:
                                                    showDrawer ===
                                                    DrawStates.FULL
                                                        ? 0
                                                        : 1,
                                            }}
                                            className="cards-container"
                                        >
                                            {!hideCards && (
                                                <LayoutGroup>
                                                    {viewType ===
                                                        ViewType.GRID && (
                                                        <CardGridView
                                                            action={action}
                                                            secondaryAction={
                                                                secondaryAction
                                                            }
                                                            actionLabel={
                                                                actionLabel
                                                            }
                                                            secondaryActionLabel={
                                                                secondaryActionLabel
                                                            }
                                                            xs={xs}
                                                            sm={sm}
                                                            md={md}
                                                            lg={
                                                                showDrawer ===
                                                                DrawStates.CLOSED
                                                                    ? lg
                                                                    : 6
                                                            }
                                                            xl={
                                                                showDrawer ===
                                                                DrawStates.CLOSED
                                                                    ? xl
                                                                    : 6
                                                            }
                                                            pageIdentifier={
                                                                pageIdentifier
                                                            }
                                                            multiSelectedCards={
                                                                multiSelectedCards
                                                            }
                                                            setMultiSelectedCards={
                                                                setMultiSelectedCards
                                                            }
                                                            cardsObject={
                                                                cardsObject
                                                            }
                                                        />
                                                    )}
                                                    {viewType ===
                                                        ViewType.LIST && (
                                                        <CardListView
                                                            showDrawer={
                                                                showDrawer
                                                            }
                                                            setCardItems={
                                                                setCardItems
                                                            }
                                                            multiSelectedCards={
                                                                multiSelectedCards
                                                            }
                                                            setMultiSelectedCards={
                                                                setMultiSelectedCards
                                                            }
                                                            cardsObject={
                                                                cardsObject
                                                            }
                                                            pageIdentifier={
                                                                pageIdentifier
                                                            }
                                                        />
                                                    )}
                                                    {viewType ===
                                                        ViewType.ANALYZE && (
                                                        <CardAnalyzeView />
                                                    )}
                                                </LayoutGroup>
                                            )}
                                        </Box>
                                        <WorkBookDrawer
                                            currentWorkbook={currentWorkbook}
                                            setCurrentWorkbook={
                                                setCurrentWorkbook
                                            }
                                            showDrawer={showDrawer}
                                            setShowDrawer={updateDrawerState}
                                            playbookDrawerItems={
                                                playbookDrawerItems
                                            }
                                            setPlaybookDrawerItems={
                                                setPlaybookDrawerItems
                                            }
                                            refreshWorkbookDrawer={
                                                refreshWorkbookDrawer
                                            }
                                            newWorksheet={newWorksheet}
                                            openDrawerItem={openDrawerItem}
                                            setOpenDrawerItem={
                                                setOpenDrawerItem
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            <LayoutGroup>
                                {viewType === ViewType.GRID && (
                                    <CardGridView
                                        action={action}
                                        secondaryAction={secondaryAction}
                                        actionLabel={actionLabel}
                                        secondaryActionLabel={
                                            secondaryActionLabel
                                        }
                                        pageIdentifier={pageIdentifier}
                                        xs={xs}
                                        sm={sm}
                                        md={md}
                                        lg={lg}
                                        xl={xl}
                                        multiSelectedCards={multiSelectedCards}
                                        setMultiSelectedCards={
                                            setMultiSelectedCards
                                        }
                                        cardsObject={cardsObject}
                                    />
                                )}
                                {viewType === ViewType.LIST && (
                                    <CardListView
                                        {...(handleListViewEdit
                                            ? {
                                                  handleListViewEdit:
                                                      handleListViewEdit,
                                              }
                                            : {})}
                                        setCardItems={setCardItems}
                                        multiSelectedCards={multiSelectedCards}
                                        setMultiSelectedCards={
                                            setMultiSelectedCards
                                        }
                                        cardsObject={cardsObject}
                                        pageIdentifier={pageIdentifier}
                                    />
                                )}
                                {viewType === ViewType.ANALYZE && (
                                    <CardAnalyzeView />
                                )}
                            </LayoutGroup>
                        )}
                    </AnimatePresence>
                    {token && (
                        <Box
                            style={{
                                width: '100%',
                                padding: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {!loading ? (
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        onLoadMore && onLoadMore(false)
                                    }
                                >
                                    Load more
                                </Button>
                            ) : (
                                <Box
                                    sx={{
                                        width: '100px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </PageView>
        </Box>
    );
};

export default CardTypeLayout;
