import {
    CardComponentData,
    CardSetComponentData,
} from '../components/cards/cardTypes';
import { Card, CardSet, CardsCardSets, Playbook } from '../API';
import { UserPermissions } from '../globals';

export const getUserPermissions = (
    user: string,
    card: CardComponentData | CardSetComponentData | Card
) => {
    const permissions = [];

    if (!user || !card?.owner) return [];

    if (user === card?.createdBy || user === card?.owner) {
        permissions.push(UserPermissions.EDIT);
        permissions.push(UserPermissions.DELETE);
    }

    if (card?.editors?.includes(user) || card.orgEdit) {
        permissions.push(UserPermissions.EDIT);
    }

    return permissions;
};

export const getCardSetPermissions = (
    user: string,
    card: CardSet | Playbook
) => {
    const permissions: UserPermissions[] = [];

    if (!user || !card.owner) return [];

    if (user === card.owner) {
        permissions.push(UserPermissions.EDIT);
        permissions.push(UserPermissions.DELETE);
    }

    if (card.editors?.includes(user) || card.orgEdit) {
        permissions.push(UserPermissions.EDIT);
    }

    return permissions;
};
