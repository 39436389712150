import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateFieldProps } from './inputFieldTypes';
import { dateFromISO } from '../../../helpers/utils';
import { FormControl, InputLabel, useTheme } from '@mui/material';

const DateField = (props: DateFieldProps) => {
    const { name, label, value, required, onChange } = props;

    const theme = useTheme();

    return (
        <FormControl
            size="small"
            data-testid={`select-${name}`}
            sx={{ paddingTop: '25px', marginBottom: '24px' }}
        >
            <InputLabel
                shrink
                htmlFor="styled-input"
                sx={{
                    background: 'initial',
                    color: theme.palette.text.primary,
                    fontSize: '1.6em',
                    fontFamily: 'Antonio,sans-serif',
                    left: '-12px',
                    '&.Mui-focused': {
                        color: theme.palette.text.primary,
                    },
                }}
            >
                {label} {required ? '*' : ''}
            </InputLabel>
            <DatePicker
                sx={{
                    '.MuiInputBase-root': {
                        '&.MuiOutlinedInput-root': { borderRadius: 0 },
                        '.MuiInputBase-input': {
                            height: '11px',
                        },
                    },
                }}
                {...(value && {
                    value: dateFromISO(value),
                })}
                {...(onChange && {
                    onChange: onChange,
                })}
                disabled={props.disabled}
            />
        </FormControl>
    );
};

export default DateField;
