import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Typography, useTheme } from '@mui/material';
import { ScoreDefinition, ScoreType, ScoreDatum } from '../../API';
import { formatScore } from '../../helpers/utils';

interface Trend {
    trend: boolean;
}

const TrendComponent = ({ trend }: Trend) =>
    trend ? (
        <ArrowDropUpIcon style={{ fill: '#00c852' }} />
    ) : (
        <ArrowDropDownIcon style={{ fill: '#ff6f00' }} />
    );

interface ScoreWithTrendProps {
    scoreData?: ScoreDatum[];
    scoreDefinition: ScoreDefinition;
}

const ScoreWithTrend = ({
    scoreDefinition,
    scoreData,
}: ScoreWithTrendProps) => {
    const { scoreType } = scoreDefinition;
    const theme = useTheme();

    const data = scoreData ? [...scoreData] : [];
    const latestDatum = data[data.length - 1]?.value;

    const trend = latestDatum > data[data.length - 2]?.value;
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                alignSelf: 'flex-start',
            }}
        >
            <Box
                sx={{
                    width: '25px',
                    height: '25px',
                    background:
                        latestDatum === 0
                            ? theme.palette.grey[700]
                            : latestDatum === 1
                            ? theme.palette.warning.dark
                            : latestDatum === 2
                            ? theme.palette.warning.light
                            : latestDatum === 3
                            ? theme.palette.success.light
                            : theme.palette.grey[700],
                    borderRadius: '6px',
                }}
            />
            {!!latestDatum && <TrendComponent trend={trend} />}
        </Box>
    );
};

export default ScoreWithTrend;
