import { Box, IconButton, Typography } from '@mui/material';
import { ReactComponent as MenuIcon } from '../../assets/icons/Menu.svg';
import { Node } from '../charts/BubbleChart';
import { Card } from '../../API';
import { useContext, useEffect, useState } from 'react';
import CardMenu, { MenuItem } from '../cards/CardMenu';
import { UserPermissions } from '../../globals';
import { CardContext } from '../cards/context';
import { AppContext } from '../contexts';
import { getUserPermissions } from '../../helpers/permissions';
import { truncateText } from '../../helpers/utils';

interface BubbleKebabMenuProps {
    node: Node;
    card: Card;
    fullWidth: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const BubbleKebabMenu = ({
    node,
    card,
    fullWidth,
    setIsModalOpen,
}: BubbleKebabMenuProps) => {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
    const { handleDelete, handleRemove, handleCopy, permissions } =
        useContext(CardContext);
    const { userProfileObject } = useContext(AppContext);
    const [cardPermissions, setCardPermissions] = useState<UserPermissions[]>();

    useEffect(() => {
        const permissions = getUserPermissions(
            userProfileObject?.getUserProfile.id ?? '',
            card
        );
        setCardPermissions(permissions);
    }, []);

    const DELETECARD: boolean = cardPermissions?.includes(UserPermissions.EDIT)
        ? true
        : false;

    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            e.stopPropagation();
            setMenuAnchor(e.currentTarget);
        } else {
            setMenuAnchor(null);
        }
    };
    const menuItems: MenuItem[] = [];

    handleRemove &&
        menuItems.push({
            text: 'Remove from workbook',
            action: () => {
                handleRemove(card.id);
                setIsModalOpen(false);
            },
        });

    handleCopy &&
        menuItems.push({
            text: 'Copy',
            action: () => {
                handleCopy(card.id);
                setIsModalOpen(false);
            },
        });
    const deleteCard = async (id: string, organisation: string) => {
        if (handleDelete) {
            await handleDelete(id, organisation);
            setIsModalOpen(false);
        }
    };
    return (
        <Box sx={{ p: 0, mr: '-20px' }}>
            <IconButton
                aria-label=""
                id="menu-button"
                onClick={setMenu}
                sx={{ color: '#666', width: '50px', height: '50px' }}
            >
                <MenuIcon />
            </IconButton>
            {menuAnchor && (DELETECARD || menuItems.length > 0) && (
                <CardMenu
                    permissions={permissions ?? []}
                    anchor={menuAnchor}
                    data={{
                        organisation: card.organisation || '',
                        id: card.id,
                    }}
                    menuItems={menuItems}
                    setMenu={setMenu}
                    {...(DELETECARD && {
                        handleDelete: deleteCard,
                    })}
                />
            )}
        </Box>
    );
};

export default BubbleKebabMenu;
