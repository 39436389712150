import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { defaultTabTransition } from '../../helpers/animations';
import { AttributeType, Card, CardSet, CreateCardMutation } from '../../API';
import TextField from './input-fields/TextField';
import { createCard } from '../../graphql/mutations';
import { getUserOrganisation } from '../../helpers/auth';
import { generateClient } from 'aws-amplify/api';
import { addCardToCardSet } from '../../actions/CardSets';
import { AiRequest } from '../../actions/AIRequest';
import { AppContext } from '../contexts';
import { getContext } from '../../helpers/utils';
import { useLocation, useParams } from 'react-router-dom';

export type SparkCard = Pick<
    Card,
    | 'briefDescription'
    | 'cardToCardCategoryId'
    | 'cardToCardTypeId'
    | 'fullDescription'
    | 'name'
>;
interface CreateSparkCardProps {
    cardSet?: CardSet;
    setShowCreateSparkWizard: React.Dispatch<React.SetStateAction<boolean>>;
    addCardToWorkbook?: (card: Card, addToWorksheet: boolean) => void;
}
const CreateSparkCard = ({
    cardSet,
    setShowCreateSparkWizard,
    addCardToWorkbook,
}: CreateSparkCardProps) => {
    const { organisationProfile, user } = useContext(AppContext);
    const aiKeyExists = !!organisationProfile?.aiKey;
    const client = generateClient();
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [typing, setTyping] = useState(false);
    const [aiError, setAiError] = useState(false);
    const [sparkCard, setSparkCard] = useState<SparkCard>({
        briefDescription: '',
        cardToCardCategoryId: '6',
        cardToCardTypeId: '69',
        fullDescription: '',
        name: '',
    });
    const { id } = useParams();
    const location = useLocation();
    const getAiSuggestion = async () => {
        setSparkCard({
            ...sparkCard,
            name: '',
        });
        setTyping(true);
        await AiRequest(
            `Suggest a short, catchy title no longer than 60 characters that reflects a new and innovative idea 
            in business strategy formulation based on the description: ${sparkCard.briefDescription}.
            The title should evoke a sense of creativity and a fresh perspective, like a spark of inspiration.`,
            'asst_YOefY5cO6lBgDw37XyFJnQGf'
        )
            .then((response) => {
                let contentObj;
                try {
                    contentObj = JSON.parse(response.content || '');
                } catch (e) {
                    setTyping(false);
                    setAiError(true);
                    console.error('Error parsing AI response content:', e);
                    return;
                }
                setSparkCard({
                    ...sparkCard,
                    name: contentObj.name || '',
                });
                setTyping(false);
                setAiError(false);
            })
            .catch((error) => {
                console.error('Error fetching AI suggestion:', error);
                setTyping(false);
                setAiError(true);
            });
    };
    const onSubmit = async (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        setLoading(true);
        const context = getContext(location.pathname, id, false);

        const userOrganisation = getUserOrganisation(user);
        try {
            const res = (await client.graphql({
                query: createCard,
                variables: {
                    input: {
                        ...sparkCard,
                        organisation: userOrganisation,
                        orgEdit: userOrganisation,
                        _auditContextType: context.contextType,
                        _auditContextId: context.contextId,
                    },
                },
            })) as { data: CreateCardMutation };
            if (res.data.createCard && cardSet) {
                await addCardToCardSet({
                    organisation: res.data.createCard.organisation,
                    cardSetId: cardSet.id,
                    cardId: res.data.createCard.id,
                });
            } else if (res.data.createCard && addCardToWorkbook) {
                addCardToWorkbook(res.data.createCard as Card, false);
            }
            setLoading(false);
            setShowCreateSparkWizard(false);
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <Box sx={{ overflowY: 'hidden' }}>
            <motion.div
                initial={false}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -300, opacity: 0 }}
                transition={defaultTabTransition}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        px: 3,
                        pt: 3,
                        legend: {
                            color: theme.palette.text.primary,
                            fontWeight: 600,
                        },
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{ marginBottom: 2, textAlign: 'center' }}
                    >
                        Create a spark card
                    </Typography>
                    <Divider />
                </Box>
                <form onSubmit={onSubmit}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            py: 2,
                            px: 3,
                            maxHeight: '870px',
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '90%',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <TextField
                                    required={true}
                                    defaultValue={sparkCard?.name ?? ''}
                                    type={AttributeType.Text}
                                    value={sparkCard?.name ?? ''}
                                    name="name"
                                    onChange={(event) =>
                                        setSparkCard({
                                            ...sparkCard,
                                            name: event.target.value,
                                        })
                                    }
                                    label="Name"
                                    inputProps={{
                                        maxLength: 256,
                                    }}
                                    briefDescription={
                                        sparkCard.briefDescription
                                    }
                                    getAiSuggestion={getAiSuggestion}
                                    typing={typing}
                                    aiKeyExists={aiKeyExists}
                                />
                                <TextField
                                    defaultValue={
                                        sparkCard?.briefDescription || ''
                                    }
                                    type={AttributeType.Text}
                                    value={sparkCard?.briefDescription ?? ''}
                                    name="briefDescription"
                                    onChange={(event) =>
                                        setSparkCard({
                                            ...sparkCard,
                                            briefDescription:
                                                event.target.value,
                                        })
                                    }
                                    label="Brief description"
                                    multiline
                                    rows={4}
                                    inputProps={{
                                        maxLength: 512,
                                    }}
                                />
                                <TextField
                                    defaultValue={
                                        sparkCard?.fullDescription || ''
                                    }
                                    type={AttributeType.Text}
                                    value={sparkCard?.fullDescription ?? ''}
                                    name="briefDescription"
                                    onChange={(event) =>
                                        setSparkCard({
                                            ...sparkCard,
                                            fullDescription: event.target.value,
                                        })
                                    }
                                    label="Full description"
                                    multiline
                                    rows={4}
                                    inputProps={{
                                        maxLength: 512,
                                    }}
                                />
                            </Box>
                            <Button
                                disabled={loading}
                                variant="contained"
                                type="submit"
                                sx={{ width: '100%' }}
                            >
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={'1rem'}
                                    />
                                ) : (
                                    'Create spark card'
                                )}
                            </Button>
                        </Box>
                    </Box>
                </form>
            </motion.div>
        </Box>
    );
};

export default CreateSparkCard;
