import { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { UserPermissions } from '../globals';
import {
    getUserPermissions,
    getCardSetPermissions,
} from '../helpers/permissions';
import { Card, CardSet, Playbook } from '../API';
import { CardComponentData } from '../components/cards/cardTypes';

export enum PermissionsType {
    CARD = 'card',
    CARDSET = 'cardset',
}

const usePermissions = (
    type: PermissionsType,
    data: CardComponentData | CardSet | Card | Playbook
) => {
    const [userPermissions, setUserPermissions] = useState<UserPermissions[]>(
        []
    );

    useEffect(() => {
        const getUserData = async () => {
            const user = await fetchAuthSession();

            const permissions =
                type === PermissionsType.CARD
                    ? getUserPermissions(
                          user?.tokens?.idToken?.payload.sub ?? '',
                          data as CardComponentData
                      )
                    : getCardSetPermissions(
                          user?.tokens?.idToken?.payload.sub ?? '',
                          data as CardSet
                      );
            setUserPermissions(permissions);
        };

        getUserData();
    }, [type]);

    return userPermissions;
};

export default usePermissions;
