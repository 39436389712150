import React, { useEffect, useRef, useState } from 'react';
import { Bubble } from 'react-chartjs-2';

import { createBubbleChartData } from '../../helpers/charts';
import { getBubbleChartOptions } from './BubblesChartOptions';
import { ColorMapping } from '../playbooks/analysis/BubblePage';
import { ChartEvent } from 'chart.js/dist/core/core.plugins';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
import { BubbleDataPoint, Chart } from 'chart.js';
import { Card } from '../../API';
import { CardScoreData } from '../../helpers/worksheets';
import { NodeColorOptions, NodeSizeOptions } from '../playbooks/playbookTypes';
import { Box, Button, useTheme } from '@mui/material';
import ResizeObserver from 'resize-observer-polyfill';
import { useDroppable } from '@dnd-kit/core';

export interface CustomDataPoint {
    x: number;
    y: number;
    r: number;
    label: string;
    name: string;
    card: CardScoreData;
    originalCard: Card;
}
interface BubbleChartOptions {
    onClick: (event: ChartEvent, elements: Array<any>, chart: Chart) => void;
}
export interface Node {
    x: number;
    y: number;
    r: number;
}
interface BubbleChartProps {
    labels: string[];
    data: CustomDataPoint[][];
    colors: ColorMapping;
    xAxisOptions: any;
    yAxisOptions: any;
    displayGrid: boolean;
    thumbnail: boolean;
    categoryColor: string;
    onBubbleClick: (
        data: CustomDataPoint,
        position: { top: number; left: number }
    ) => void;
    nodes: Node[];
    nodeSizeOption: NodeSizeOptions;
    workbook?: boolean;
    carousel?: boolean;
    nodeColorOption: NodeColorOptions;
    setSelectedCard: React.Dispatch<React.SetStateAction<Card | null>>;
    pptView?: boolean;
    cardTypeId?: string;
    chartRef: React.MutableRefObject<Chart<
        'bubble',
        BubbleDataPoint[],
        unknown
    > | null>;
    zoomStateRef?: React.MutableRefObject<{
        xMin: number | undefined;
        xMax: number | undefined;
        yMin: number | undefined;
        yMax: number | undefined;
    }>;
    handleBubbleHover: (
        data: CustomDataPoint,
        position: {
            top: number;
            left: number;
        }
    ) => void;
}

const BubbleChart = ({
    labels,
    data,
    colors,
    xAxisOptions,
    yAxisOptions,
    displayGrid,
    thumbnail,
    categoryColor,
    onBubbleClick,
    nodes,
    nodeSizeOption,
    workbook,
    carousel,
    nodeColorOption,
    setSelectedCard,
    pptView,
    cardTypeId,
    chartRef,
    zoomStateRef,
    handleBubbleHover,
}: BubbleChartProps) => {
    const { isOver, setNodeRef } = useDroppable({
        id: `${cardTypeId}_`,
    });
    const theme = useTheme();
    const chartData = createBubbleChartData(
        labels,
        data,
        colors,
        categoryColor,
        thumbnail,
        nodeSizeOption,
        nodeColorOption
    );

    const flattenedData = data.flat();
    const options: any = getBubbleChartOptions(
        xAxisOptions,
        yAxisOptions,
        displayGrid,
        thumbnail,
        flattenedData,
        carousel as boolean,
        theme,
        workbook as boolean,
        pptView
    );
    if (
        zoomStateRef?.current.xMin !== null &&
        zoomStateRef?.current.xMax !== null
    ) {
        options.scales.x.min = zoomStateRef?.current.xMin;
        options.scales.x.max = zoomStateRef?.current.xMax;
    }
    if (
        zoomStateRef?.current.yMin !== null &&
        zoomStateRef?.current.yMax !== null
    ) {
        options.scales.y.min = zoomStateRef?.current.yMin;
        options.scales.y.max = zoomStateRef?.current.yMax;
    }
    options.nodes = nodes;

    options.onClick = (
        event: MouseEvent,
        elements: Array<any>,
        chart: Chart
    ) => {
        if (!zoomStateRef) return;
        if (elements.length > 0) {
            const firstPoint = elements[0];
            const index = firstPoint.index;
            const datasetIndex = firstPoint.datasetIndex;
            const data: CustomDataPoint = chart.data.datasets[datasetIndex]
                .data[index] as CustomDataPoint;
            zoomStateRef.current = {
                xMin: chart.scales?.['x']?.min ?? undefined,
                xMax: chart.scales?.['x']?.max ?? undefined,
                yMin: chart.scales?.['y']?.min ?? undefined,
                yMax: chart.scales?.['y']?.max ?? undefined,
            };

            const canvas = chart.canvas;
            const boundingBox = canvas.getBoundingClientRect();
            const xPosition =
                boundingBox.left +
                window.scrollX +
                chart.getDatasetMeta(datasetIndex).data[index].x;
            const yPosition =
                boundingBox.top +
                window.scrollY +
                chart.getDatasetMeta(datasetIndex).data[index].y;

            setSelectedCard(data.originalCard);
            onBubbleClick(data, { top: yPosition, left: xPosition });
            if (chart.options.scales?.['x'] && chart.options.scales?.['y']) {
                chart.options.scales['x'].min = zoomStateRef.current.xMin;
                chart.options.scales['x'].max = zoomStateRef.current.xMax;
                chart.options.scales['y'].min = zoomStateRef.current.yMin;
                chart.options.scales['y'].max = zoomStateRef.current.yMax;
                chart.update();
            }
        }
    };
    // options.onHover = (
    //     event: MouseEvent,
    //     elements: Array<any>,
    //     chart: Chart
    // ) => {
    //     if (elements.length > 0) {
    //         const firstPoint = elements[0];
    //         const index = firstPoint.index;
    //         const datasetIndex = firstPoint.datasetIndex;
    //         const data: CustomDataPoint = chart.data.datasets[datasetIndex]
    //             .data[index] as CustomDataPoint;

    //         const canvas = chart.canvas;
    //         const boundingBox = canvas.getBoundingClientRect();
    //         const xPosition =
    //             boundingBox.left +
    //             window.scrollX +
    //             chart.getDatasetMeta(datasetIndex).data[index].x;
    //         const yPosition =
    //             boundingBox.top +
    //             window.scrollY +
    //             chart.getDatasetMeta(datasetIndex).data[index].y;

    //         handleBubbleHover(data, { top: yPosition, left: xPosition });
    //     }
    // };

    const plugins = [ChartDataLabels, zoomPlugin];

    return carousel ? (
        <Box sx={{ canvas: { height: '500px!important' } }}>
            <Bubble
                data={chartData}
                options={options}
                plugins={plugins}
                ref={chartRef}
            />
        </Box>
    ) : workbook ? (
        <Box
            sx={{
                height: '75vh',
                width: '100%',
                mt: -5,
            }}
            ref={setNodeRef}
        >
            <Bubble
                data={chartData}
                options={options}
                plugins={plugins}
                ref={chartRef}
            />
        </Box>
    ) : (
        <Bubble
            data={chartData}
            options={options}
            plugins={plugins}
            ref={chartRef}
        />
    );
};

export default BubbleChart;
