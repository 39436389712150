import {
    ChartOptions,
    ScriptableContext,
    ActiveElement,
    Chart,
    TooltipModel,
} from 'chart.js';
import { getMuiCssVar, truncateText } from '../../helpers/utils';
import { ScoreType } from '../../API';

export interface GlobalChartConfig {
    size?: 'small' | 'medium' | 'large';
}

interface LineChartOptionsConfig extends GlobalChartConfig {
    reverse?: boolean;
    highlightIndex?: number;
    suggestedMin?: number;
    suggestedMax?: number;
    lineOnly?: boolean;
    hideTicks?: boolean;
    onClick?: (element: ActiveElement) => void;
    comments?: string[];
    small?: boolean;
    type?: ScoreType;
}

export const customTitle = {
    id: 'customTitle',
    beforeLayout: (
        chart: {
            options: any;
            ctx: any;
            chartArea: any;
            canvas: {
                parentNode: ParentNode | null;
            };
        },
        args: any,
        opts: { display: boolean; text?: string }
    ) => {
        const { display } = opts;
        if (!display) {
            return;
        }

        const { ctx } = chart;
        ctx.font = '14px "Helvetica Neue", Helvetica, Arial, sans-serif';
        ctx.fontSize = '16px';

        const { width } = ctx.measureText(opts.text);
        chart.options.layout.padding.left = width * 1.1;
    },
    afterDraw: (
        chart: {
            ctx: any;
            chartArea: any;
            canvas: {
                parentNode: ParentNode | null;
            };
        },
        args: any,
        opts: { display: boolean; text?: string }
    ) => {
        const { text } = opts;
        const {
            ctx,
            chartArea: { top, bottom, left, right },
        } = chart;

        if (opts.display) {
            ctx.fillStyle = '#000000';
            ctx.fontSize = '16px';
            ctx.fillText(text, 3, (top + bottom) / 2);
        }
    },
};

export const getOrCreateTooltip = (
    chart: {
        canvas: {
            parentNode: ParentNode | null;
        };
    },
    end: boolean
) => {
    let tooltipEl = chart.canvas.parentNode?.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.6)';
        tooltipEl.style.paddingLeft = '50px';
        tooltipEl.style.borderRadius = '8px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = '1';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = '300px';
        tooltipEl.style.marginTop = '-80px';
        tooltipEl.style.marginLeft = end ? '-50px' : '50px';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
        tooltipEl.style.zIndex = '99';
        tooltipEl.style.width = 'fit-content';
        tooltipEl.style.boxSizing = 'border-box';
        tooltipEl.style.width = '250px';
        tooltipEl.style.maxHeight = '180px';
        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode?.appendChild(tooltipEl);
    }

    return tooltipEl;
};

export const externalTooltipHandler = (context: {
    chart: {
        canvas: {
            parentNode: ParentNode | null;
            offsetLeft: number;
            offsetTop: number;
        };
    };
    tooltip: any;
}): void => {
    // Tooltip Element
    const { chart, tooltip } = context;

    if (!context.tooltip?.dataPoints) {
        const can = document.getElementById('linechart');
        if (can) {
            can.style.cursor = 'none';
        }
        return;
    }

    const index = context.tooltip?.dataPoints[0].dataIndex;
    const colors: string[] = context?.tooltip?.dataPoints[0]?.dataset
        ?.backgroundColor as string[];
    const comment = context?.tooltip?.dataPoints[0]?.dataset?.commentary[index];
    const end = context?.tooltip?.dataPoints[0]?.dataset?.end;

    const tooltipEl = getOrCreateTooltip(chart, end);

    const can = document.getElementById('linechart');
    if (can) {
        can.style.cursor = 'pointer';
    }

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
    }

    let element = document.createElement('div');
    element.style.width = 'auto';
    element.style.marginLeft = '8px';
    element.style.marginRight = '8px';
    element.style.marginTop = '8px';
    element.style.marginBottom = '8px';
    element.style.display = 'flex';
    element.style.flexDirection = 'column';

    let scoreHeader = document.createElement('div');
    scoreHeader.style.display = 'flex';
    scoreHeader.style.flexDirection = 'row';

    let scoreBlock = document.createElement('div');
    scoreBlock.style.width = '25px';
    scoreBlock.style.height = '25px';
    scoreBlock.style.borderRadius = '5px';
    scoreBlock.style.background = colors[index];

    let scoreText = document.createElement('div');
    scoreText.style.color = '#fff';
    scoreText.style.fontSize = '16px';
    scoreText.style.fontWeight = 'bold';
    scoreText.style.marginLeft = '10px';
    scoreText.style.lineHeight = '30px';
    const text = document.createTextNode(comment.value);
    scoreText.appendChild(text);

    scoreHeader.appendChild(scoreBlock);
    scoreHeader.appendChild(scoreText);

    if (!comment.comment) {
        const noComment = document.createTextNode('No commentary');
        element.appendChild(scoreHeader);
        element.appendChild(noComment);
    } else {
        let commentary = document.createElement('div');
        commentary.style.color = '#fff';
        commentary.style.fontSize = '14px';
        commentary.style.marginTop = '10px';

        let details = document.createElement('div');
        details.style.color = '#fff';
        details.style.fontSize = '13px';
        details.style.marginTop = '10px';

        const detailsText = document.createTextNode(comment.authorInfo);
        details.appendChild(detailsText);

        const commentaryText = document.createTextNode(
            truncateText(comment.comment, 100)
        );
        commentary.appendChild(commentaryText);

        element.appendChild(scoreHeader);
        element.appendChild(commentary);
        element.appendChild(details);
    }

    tooltipEl.firstChild?.remove();
    tooltipEl.appendChild(element);

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY - 30 + 'px';
    tooltipEl.style.padding =
        tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

const getPlugins = (comments: string[], type: ScoreType): any => {
    return {
        datalabels: {
            display: false,
        },
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
                beforeFooter: (item: { dataIndex: any }[]) => {
                    const index = item[0].dataIndex;
                    if (comments && comments[index]) {
                        return `Comment: ${comments[index]}`;
                    }
                },
            },
            enabled: false,
            external: externalTooltipHandler,
        },
        customTitle: {
            display:
                type === ScoreType.Currency || type === ScoreType.Percentage
                    ? true
                    : false,
            text:
                type === ScoreType.Currency
                    ? '£'
                    : type === ScoreType.Percentage
                    ? '%'
                    : '',
        },
    };
};

export const getLineChartOptions = (
    config: LineChartOptionsConfig
): ChartOptions<'line'> => {
    const {
        highlightIndex,
        suggestedMin = 0,
        suggestedMax = 10,
        reverse,
        size,
        lineOnly,
        onClick,
        comments,
        type,
        small,
    } = config;

    return {
        onClick: (evt, ele) => (onClick ? onClick(ele[0]) : null),
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                    drawTicks: false,
                },
                ticks: {
                    font: {
                        size: small ? 8 : 10,
                    },
                    color: getMuiCssVar('text-primary'),
                    display: !lineOnly,
                },
                border: {
                    display: !lineOnly,
                },
            },
            y: {
                grid: {
                    display: !lineOnly,
                    drawTicks: false,
                },
                ticks: {
                    font: {
                        size: small ? 8 : 10,
                    },
                    color: getMuiCssVar('text-primary'),
                    display: !lineOnly,
                    callback: function (label, index, labels) {
                        // when the floored value is the same as the value we have a whole number
                        if (Math.floor(label as number) === label) {
                            return label;
                        }
                    },
                    stepSize: suggestedMax === 100 ? 10 : 1,
                },
                suggestedMin: suggestedMin,
                suggestedMax: suggestedMax,
                border: {
                    display: !lineOnly,
                },
            },
        },
        plugins: getPlugins(comments ?? [], type as ScoreType),

        elements: {
            point: {
                radius: (context: ScriptableContext<'line'>) =>
                    highlightRadius({ context, size, highlightIndex }).radius,
                borderWidth: (context: ScriptableContext<'line'>) =>
                    highlightRadius({ context, size, highlightIndex })
                        .borderWidth,
            },
            line: {
                borderWidth: 2,
                borderColor: getMuiCssVar('secondary-light'), // secondary main
            },
        },
    };
};
interface PointConfig extends GlobalChartConfig {
    context: ScriptableContext<'line'>;
    highlightIndex?: number;
}

export const highlightRadius = ({
    context,
    size,
    highlightIndex,
}: PointConfig) => {
    let index = context.dataIndex;
    let radius: number;
    let borderWidth: number;

    switch (size) {
        case 'small':
            radius = 2;
            borderWidth = 0;
            break;
        case 'medium':
            radius = 6;
            borderWidth = 2;
            break;
        case 'large':
            radius = 8;
            borderWidth = 2;
            break;
        default:
            radius = 5;
            borderWidth = 0;
    }

    return index === highlightIndex
        ? {
              radius: radius + 2,
              borderWidth: borderWidth + 8,
          }
        : {
              radius,
              borderWidth,
          };
};

interface ReverseChartValues {
    minimumValue?: number;
    maximumValue?: number;
}

export const reverseChartValues = ({
    minimumValue,
    maximumValue,
}: ReverseChartValues) => {};
