import { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Tooltip, useColorScheme } from '@mui/material';
import DarkModeOutlinedIcon from '../assets/images/menubar/Dark-mode-unselected.png';
import LightModeOutlinedIcon from '../assets/images/menubar/Dark-mode-selected.png';
import { generateClient } from 'aws-amplify/api';
import { AppContext } from './contexts';
import { UserProfile } from '../API';
import { updateUserProfile } from '../graphql/mutations';

const ThemeModeSwitch = () => {
    const { userProfileObject, setUserProfileObject } = useContext(AppContext);
    const { mode, setMode } = useColorScheme();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (userProfileObject?.getUserProfile?.preferences) {
            const preferences = JSON.parse(
                userProfileObject?.getUserProfile?.preferences
            );

            if (preferences.mode || preferences.mode === false) {
                setMode(preferences.mode);
            }
        }
    }, [userProfileObject]);

    if (!mounted) {
        return null;
    }
    const iconStyles = {
        maxWidth: '100%',
        display: 'inline',
    };
    const client = generateClient();

    const toggleMode = async (mode: 'light' | 'dark') => {
        const user = { ...userProfileObject?.getUserProfile };
        const preferences = JSON.parse(user.preferences as string);
        preferences.mode = mode;
        user.preferences = JSON.stringify(preferences);

        setUserProfileObject &&
            setUserProfileObject({
                ...userProfileObject,
                ['getUserProfile']: user as UserProfile,
            });
        await client.graphql({
            query: updateUserProfile,
            variables: {
                input: {
                    organisation: user.organisation ?? 'x',
                    id: user.id ?? '',
                    preferences: JSON.stringify(preferences),
                },
            },
        });

        setMode(mode);
    };

    return (
        <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
                if (mode === 'light') {
                    toggleMode('dark');
                } else {
                    toggleMode('light');
                }
            }}
        >
            {mode === 'light' ? (
                <Tooltip title="Apply dark theme" placement="right-start">
                    <img
                        src={DarkModeOutlinedIcon}
                        alt="Theme mode switch"
                        style={iconStyles}
                    />
                </Tooltip>
            ) : (
                <Tooltip title="Apply light theme" placement="right-start">
                    <img
                        src={LightModeOutlinedIcon}
                        alt="Theme mode switch"
                        style={iconStyles}
                    />
                </Tooltip>
            )}
        </Box>
    );
};

export default ThemeModeSwitch;
