import {
    Box,
    ListItem,
    ListItemAvatar,
    Typography,
    useTheme,
} from '@mui/material';
import { ReactComponent as CreateCardStepIcon } from '../../../assets/icons/CreateCardStep.svg';
import { processSteps } from '../processSteps';
 
interface StepColumnProps {
    selectedProcessId: string | null;
    selectedStepId: string | null;
    setSelectedStepId: React.Dispatch<React.SetStateAction<string | null>>;
}

const StepColumn = ({
    selectedProcessId,
    selectedStepId,
    setSelectedStepId,
}: StepColumnProps) => {
    const theme = useTheme();
    const selectedProcess = processSteps.find(
        (item) => item.id === selectedProcessId
    );
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                borderLeft: 'solid 1px #ccc',
            }}
        >
            <Box
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : '#efefef',
                    py: 1,
                    px: 3,
                    borderTop: 'solid 1px #ccc',
                    borderBottom: 'solid 1px #ccc',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    Step
                </Typography>
            </Box>
            <Box sx={{ overflowY: 'auto' }}>
                {selectedProcess &&
                    selectedProcess.steps.map((step) => {
                        return (
                            <ListItem
                                data-testid={step.id}
                                key={step.id}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        bgcolor: 'background.paper',
                                    },
                                    bgcolor:
                                        selectedStepId === step.id
                                            ? 'background.paper'
                                            : 'inherit',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                onClick={() => setSelectedStepId(step.id)}
                            >
                                <ListItemAvatar
                                    sx={{
                                        alignSelf:'flex-start'
                                    }}
                                >
                                    <CreateCardStepIcon
                                        style={{
                                            color: theme.palette.primary.main,
                                            width: '40px',
                                            height: '40px',
                                        }}
                                    />
                                </ListItemAvatar>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >{`${step.id}. ${step.name}`}</Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontSize: '0.9rem',
                                        }}
                                    >
                                        {step.description}
                                    </Typography>
                                </Box>
                            </ListItem>
                        );
                    })}
            </Box>
        </Box>
    );
};

export default StepColumn;
