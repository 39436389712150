import { PropsWithChildren } from 'react';
import { CardContent as MuiCardContent, styled } from '@mui/material';

interface CardContentProps {
    expanded?: boolean;
    mediumCard?: boolean;
    smallCard?: boolean;
    worksheetCard?: boolean;
}

const StyledDefaultCardContent = styled(MuiCardContent)<CardContentProps>(
    ({ theme, mediumCard, smallCard }) => ({
        height: mediumCard || smallCard ? '8rem' : '10.8rem',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
        paddingTop: theme.spacing(1),
        paddingLeft: mediumCard || smallCard ? '12px' : '27px',
        paddingRight: mediumCard || smallCard ? '12px' : '27px',
        color: theme.palette.common.white,
    })
);

const StyledExpandedCardContent = styled(MuiCardContent)<CardContentProps>(
    ({ theme, worksheetCard }) => ({
        color: theme.palette.text.primary,
        flexGrow: 2,
        paddingTop: theme.spacing(4),
        paddingBottom: 0,
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        overflowY: 'auto',
        overflowX: 'hidden',
        height: worksheetCard ? 'calc(100% - 80px)' : '75vh',
        backgroundColor: theme.palette.background.default,
    })
);

const CardContent = ({
    expanded,
    children,
    mediumCard,
    smallCard,
    worksheetCard,
}: PropsWithChildren<CardContentProps>) => {
    const Component = expanded
        ? StyledExpandedCardContent
        : StyledDefaultCardContent;

    return (
        <Component
            mediumCard={mediumCard}
            smallCard={smallCard}
            worksheetCard={worksheetCard}
            data-testid="main-element"
        >
            {children}
        </Component>
    );
};

export default CardContent;
