import { Theme } from '@mui/material';
import { ChartOptions } from 'chart.js';

export const getRadarChartOptions = (
    thumbnail: boolean,
    theme: Theme,
    pptView?: boolean,
    activeCardId?: string | null | undefined
): ChartOptions<'radar'> => {
    const options = {
        animation: {
            duration: 1000,
        },        
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            r: {
                angleLines: {
                    display: true,
                    color: '#d5d5d5',
                    lineWidth: 2,
                },
                grid: {
                    color: '#d5d5d5',
                    lineWidth: 2,
                },
                suggestedMin: 0,
                suggestedMax: 10,
                ticks: {
                    stepSize: 2,
                    font: {
                        size: thumbnail ? 8 : 14,
                    },
                },
                pointLabels: {
                    font: {
                        size: pptView ? 14 : thumbnail ? 8 : 11,
                        weight: pptView ? 600 : 400,
                    },
                    color: theme.palette.text.secondary,
                },
            },
        },
        elements: {
            point: {
                radius: 3,
                hitRadius: 20,
                hoverRadius: 10,
            },
        },
    };
    return options;
};
