export const processSteps = [
    {
        id: '1',
        name: 'Inspire',
        description: 'Set your strategic direction',
        steps: [
            {
                id: '1.1',
                name: 'Set shared purpose',
                description: 'Define your mission and vision',
                cardTypes: ['17', '18'],
                worksheets: [
                    {
                        i: '32',
                        n: 'Mission list',
                        t: 'List',
                        e: true,
                        d: 'Publish mission information',
                    },
                    {
                        i: '71',
                        n: 'Vision Statement list',
                        t: 'List',
                        e: true,
                        d: 'Publish vision statement information',
                    },
                    {
                        i: '58',
                        n: 'Shared Purpose',
                        t: 'Chart',
                        e: false,
                        d: 'Set your strategic direction',
                    },
                ],
                h1: "Defining a strategic purpose with a clear mission and vision is paramount for organizational success. It serves as a guiding compass, aligning teams towards common goals. A well-crafted mission encapsulates an organization's reason for existence, while a visionary statement paints an inspiring future, fostering unity, motivation, and informed decision-making.",
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                id: '1.2',
                name: 'Capture values',
                description: "State your organization's values",
                cardTypes: ['19'],
                worksheets: [
                    {
                        i: '33',
                        n: 'Value list',
                        t: 'List',
                        e: true,
                        d: 'Publish value information',
                    },
                    {
                        i: '58',
                        n: 'Shared Purpose',
                        t: 'Chart',
                        e: false,
                        d: 'Set your strategic direction',
                    },
                ],
                h1: 'Defining company values in strategy formulation establishes a cultural foundation. They articulate core beliefs, influencing behavior and decision-making at every level. Company values not only shape organizational identity but also guide strategic choices, fostering cohesion, employee engagement, and a sustainable framework for long-term success.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
        ],
    },
    {
        id: '2',
        name: 'Shape',
        description: 'Define the possible',
        steps: [
            {
                id: '2.1',
                name: 'Set strategic boundaries',
                description: 'Define guardrails and strategic principles',
                cardTypes: ['36'],
                worksheets: [
                    {
                        i: '49',
                        n: 'Guardrail list',
                        t: 'List',
                        e: true,
                        d: 'Publish guardrail information',
                    },
                    {
                        i: '59',
                        n: 'Guardrails',
                        t: 'Chart',
                        e: false,
                        d: 'Establish strategic principles',
                    },
                ],
                h1: 'Defining strategic principles and guardrails is integral to strategy formulation. They set ethical, operational, and cultural boundaries, guiding decision-making. These principles serve as a compass, maintaining alignment with organizational values and goals, fostering consistency, and preventing deviations that may compromise strategic integrity.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                id: '2.2',
                name: 'Set focus areas',
                description: 'Identify future areas of strategic focus',
                cardTypes: ['27', '49'],
                worksheets: [
                    {
                        i: '40',
                        n: 'Focus Area list',
                        t: 'List',
                        e: true,
                        d: 'Publish focus area information',
                    },
                    {
                        i: '77',
                        n: 'Pillar list',
                        t: 'List',
                        e: true,
                        d: 'Publish pillar overviews',
                    },
                    {
                        i: '3',
                        n: 'Strategy tree',
                        t: 'Chart',
                        e: false,
                        d: 'Visualize relationships between objectives, KPIs and action plans',
                    },
                ],
                h1: 'Identifying focus areas or pillars in strategic planning provides clarity and prioritization. These pillars serve as strategic anchors, concentrating resources and efforts on key initiatives. They streamline decision-making, enhance organizational efficiency, and ensure a cohesive, directed approach, optimizing the chances of achieving overarching strategic goals.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
        ],
    },
    {
        id: '3',
        name: 'Observe',
        description: 'Assess your internal and external environment',
        steps: [
            {
                id: '3.1',
                name: 'Assess organizational skills and capabilities',
                description:
                    'Understand what skill and capability development is needed',
                cardTypes: ['34', '35'],
                worksheets: [
                    {
                        i: '47',
                        n: 'Skill list',
                        t: 'List',
                        e: true,
                        d: 'Publish skill summaries',
                    },
                    {
                        i: '48',
                        n: 'Capability list',
                        t: 'List',
                        e: true,
                        d: 'Publish capability details',
                    },
                    {
                        i: '60',
                        n: 'Skill Development',
                        t: 'Chart',
                        e: false,
                        d: 'Identify and prioritize skill development investments',
                    },
                    {
                        i: '61',
                        n: 'Capability Development',
                        t: 'Chart',
                        e: false,
                        d: 'Identify and prioritize capability development investments',
                    },
                ],
                h1: "Reviewing organizational skills and capabilities in internal strategic assessment is essential. It unveils strengths to leverage and areas for improvement, guiding strategic decisions. This introspection ensures alignment between goals and existing competencies, facilitating effective resource allocation and bolstering the organization's competitive edge in dynamic environments.",
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                id: '3.2',
                name: 'Evaluate value streams',
                description: 'Perform value stream analysis',
                cardTypes: ['60', '61', '62', '63'],
                worksheets: [
                    {
                        i: '88',
                        n: 'Value Stream list',
                        t: 'List',
                        e: true,
                        d: 'Publish value stream details',
                    },
                    {
                        i: '89',
                        n: 'System list',
                        t: 'List',
                        e: true,
                        d: 'Publish system information',
                    },
                    {
                        i: '90',
                        n: 'Process list',
                        t: 'List',
                        e: true,
                        d: 'Publish process details',
                    },
                    {
                        i: '91',
                        n: 'Step list',
                        t: 'List',
                        e: true,
                        d: 'Publish step details',
                    },
                ],
                h1: 'Evaluating key value streams in internal strategic assessment is pivotal. It identifies critical processes driving value creation, streamlining operations. This scrutiny enables organizations to enhance efficiency, allocate resources judiciously, and adapt strategically, ensuring sustained competitive advantage by optimizing the core functions integral to delivering value to customers and stakeholders.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                id: '3.3',
                name: "Perform Porter's Five Forces industry analysis",
                description:
                    'Identify and analyzes competitive forces that shape an industry',
                cardTypes: ['55', '12'],
                worksheets: [
                    {
                        i: '83',
                        n: 'Industry list',
                        t: 'List',
                        e: true,
                        d: 'Publish industry information',
                    },
                    {
                        i: '27',
                        n: 'Force list',
                        t: 'List',
                        e: true,
                        d: 'Publish force information',
                    },
                    {
                        i: '64',
                        n: "Porter's Five Forces",
                        t: 'Chart',
                        e: false,
                        d: 'Understand industry competitve forces',
                    },
                ],
                h1: "Conducting Porter's Five Forces analysis in internal strategic assessment is crucial. It unveils market dynamics, revealing competitive forces affecting the organization. This comprehensive examination informs strategic decisions, identifies industry attractiveness, and positions the company to navigate challenges effectively, fostering resilience and sustained success in the market.",
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                id: '3.4',
                name: 'Assess market attractiveness',
                description: 'Understand which markets are attractive and why',
                cardTypes: ['8'],
                worksheets: [
                    {
                        i: '23',
                        n: 'Market Segment list',
                        t: 'List',
                        e: true,
                        d: 'Publish market segment information',
                    },
                    {
                        i: '66',
                        n: 'Market Analysis',
                        t: 'Chart',
                        e: false,
                        d: 'Prioritize target markets',
                    },
                ],
                h1: 'Assessing target market attractiveness in external strategic assessment is essential. It identifies market trends, customer preferences, and competitive landscapes. This insight informs strategic decisions, enabling organizations to tailor offerings, anticipate shifts, and position themselves advantageously in dynamic markets, ensuring relevance and competitiveness.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                id: '3.5',
                name: 'Analyze market trends',
                description: 'Use PESTLE to analyze industry and market trends',
                cardTypes: ['8', '11', '54'],
                worksheets: [
                    {
                        i: '23',
                        n: 'Market Segment list',
                        t: 'List',
                        e: true,
                        d: 'Publish market segment information',
                    },
                    {
                        i: '26',
                        n: 'Trend list',
                        t: 'List',
                        e: true,
                        d: 'Publish trend information',
                    },
                    {
                        i: '82',
                        n: 'Signal list',
                        t: 'List',
                        e: true,
                        d: 'Publish signal details',
                    },
                    {
                        i: '65',
                        n: 'PESTLE Analysis',
                        t: 'Chart',
                        e: false,
                        d: 'Identify external factors that influence organizations',
                    },
                ],
                h1: 'Conducting a PESTLE analysis in external strategic assessment is crucial. It illuminates political, economic, social, technological, legal, and environmental factors shaping the business environment. This comprehensive scrutiny enhances anticipatory planning, risk management, and informed decision-making, ensuring adaptability and strategic resilience in a rapidly changing external landscape.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                id: '3.6',
                name: 'Assess competition',
                description:
                    'Build an understanding of your competitive strengths and weaknesses',
                cardTypes: ['3'],
                worksheets: [
                    {
                        i: '18',
                        n: 'Competitor list',
                        t: 'List',
                        e: true,
                        d: 'Publish competitor information',
                    },
                    {
                        i: '63',
                        n: 'Competitive Landscape',
                        t: 'Chart',
                        e: false,
                        d: 'Prioritize competitive threats',
                    },
                ],
                h1: 'A competitive assessment in external strategic analysis is indispensable. It evaluates rivals, market dynamics, and industry trends, offering insights crucial for informed decision-making. This understanding sharpens competitive strategies, identifies opportunities, and equips organizations to navigate challenges adeptly, fostering sustained market relevance and strategic success.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                id: '3.7',
                name: 'Review customers',
                description:
                    'Understand your customers, the value you provide to them, and the value they provide to you.',
                cardTypes: ['64'],
                worksheets: [
                    {
                        i: '92',
                        n: 'Customer list',
                        t: 'List',
                        e: true,
                        d: 'Publish customer information',
                    },
                    {
                        i: '62',
                        n: 'Customer Analysis',
                        t: 'Chart',
                        e: false,
                        d: 'Analyze health of existing customer base',
                    },
                ],
                h1: 'Customer value analysis in external strategic assessment is paramount. It unveils customer needs, preferences, and expectations, shaping tailored strategies. This insight ensures market resonance, fosters customer-centric approaches, and enhances competitive advantage by delivering solutions that resonate with and surpass customer expectations, ultimately driving sustained business success.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
        ],
    },
    {
        id: '4',
        name: 'Gather',
        description: 'Gather strategic input',
        steps: [
            {
                id: '4.1',
                name: 'Prioritize ideas',
                description:
                    'Identify the most promising ideas for further development',
                cardTypes: ['10'],
                worksheets: [
                    {
                        i: '25',
                        n: 'Idea list',
                        t: 'List',
                        e: true,
                        d: 'Publish idea information',
                    },
                    {
                        i: '8',
                        n: 'Idea analysis',
                        t: 'Chart',
                        e: false,
                        d: 'Collect, review and prioritize ideas for strategic growth',
                    },
                ],
                h1: 'Ideas are the lifeblood of formulating strategy. They spark innovation, drive creativity, and offer diverse perspectives. Embracing and synthesizing ideas fosters strategic agility, enabling organizations to adapt to evolving landscapes. A culture that values and harnesses ideas cultivates resilience, ensuring strategic plans are dynamic, innovative, and effective.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                id: '4.2',
                name: 'Perform retrospective',
                description: 'Review how the previous strategy cycle went',
                cardTypes: ['13', '14', '15', '16'],
                worksheets: [
                    {
                        i: '28',
                        n: 'What Worked list',
                        t: 'List',
                        e: true,
                        d: 'Publish what worked retrospective information',
                    },
                    {
                        i: '29',
                        n: "What Didn't Work list",
                        t: 'List',
                        e: true,
                        d: "Publish what didn't work retrospective details",
                    },
                    {
                        i: '30',
                        n: 'Improvement Opportunity list',
                        t: 'List',
                        e: true,
                        d: 'Publish retrospective improvement opportunity information',
                    },
                    {
                        i: '31',
                        n: 'To Do list',
                        t: 'List',
                        e: true,
                        d: 'Publish to do summary',
                    },
                    {
                        i: '69',
                        n: 'Retrospective',
                        t: 'Chart',
                        e: false,
                        d: 'Hold a retrospective on the last delivery cycle',
                    },
                ],
                h1: "Conducting a retrospective at the strategy cycle's end is crucial. It facilitates reflection, analyzing successes and setbacks. This review informs future strategies, identifies areas for improvement, and enhances organizational learning. A retrospective fosters adaptability, ensuring continuous refinement and optimization for sustained strategic success.",
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                id: '4.3',
                name: 'Spark a discussion',
                description:
                    'Get the conversation going with others and share ideas',
                cardTypes: ['69'],
                worksheets: []
            }
        ],
    },
    {
        id: '5',
        name: 'Orient',
        description: 'Determine your strategic position',
        steps: [
            {
                id: '5.1',
                name: 'Perform SWOT analysis',
                description:
                    'Assess your strengths, weaknesses, opportunities and threats (SWOT)',
                cardTypes: ['2', '4', '5', '6'],
                worksheets: [
                    {
                        i: '1',
                        n: 'SWOT analysis',
                        t: 'Chart',
                        e: true,
                        d: 'Determine your strategic position',
                    },
                    {
                        i: '17',
                        n: 'Strength list',
                        t: 'List',
                        e: true,
                        d: 'Publish strength details',
                    },
                    {
                        i: '19',
                        n: 'Weakness list',
                        t: 'List',
                        e: true,
                        d: 'Publish weakness details',
                    },
                    {
                        i: '20',
                        n: 'Opportunity list',
                        t: 'List',
                        e: true,
                        d: 'Publish opportunity overview',
                    },
                    {
                        i: '21',
                        n: 'Threat list',
                        t: 'List',
                        e: true,
                        d: 'Publish threat information',
                    },
                ],
                h1: "SWOT analysis is vital for determining an organization's strategic position. It identifies internal strengths and weaknesses, aiding in leveraging advantages and addressing challenges. External opportunities and threats are revealed, guiding strategic decisions. This comprehensive assessment ensures informed positioning, enhancing adaptability and competitive advantage in dynamic environments.",
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
        ],
    },
    {
        id: '6',
        name: 'Explore',
        description: 'Assess your strategic options',
        steps: [
            {
                id: '6.1',
                name: 'Explore strategic options',
                description: 'Identify and prioritize strategic options',
                cardTypes: ['56'],
                worksheets: [
                    {
                        i: '84',
                        n: 'Strategic Option list',
                        t: 'List',
                        e: true,
                        d: 'Publish strategic option details',
                    },
                    {
                        i: '67',
                        n: 'Strategic Options',
                        t: 'Chart',
                        e: false,
                        d: 'Compare strategic options',
                    },
                ],
                h1: 'Identifying and evaluating strategic options post-SWOT analysis is pivotal. It transforms insights into actionable choices, aligning strengths with opportunities and mitigating weaknesses against threats. This informed decision-making optimizes resource allocation, enhances adaptability, and positions the organization strategically for sustained success in a dynamic landscape.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                id: '6.2',
                name: 'Identify strategic challenges',
                description: 'Understand strategic challenges to overcome',
                cardTypes: ['57'],
                worksheets: [
                    {
                        i: '85',
                        n: 'Strategic Challenge list',
                        t: 'List',
                        e: true,
                        d: 'Publish strategic challenge information',
                    },
                    {
                        i: '68',
                        n: 'Strategic Challenges',
                        t: 'Chart',
                        e: false,
                        d: 'Identify and prioritize strategic challenges',
                    },
                ],
                h1: 'Identifying strategic challenges after finalizing options is critical. It anticipates obstacles, allowing proactive mitigation and strategic refinement. This foresight enhances resilience, guiding organizations to navigate uncertainties, adapt strategies, and maintain agility in the face of dynamic challenges, ensuring the successful implementation of chosen strategic options.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
        ],
    },
    {
        id: '7',
        name: 'Elaborate',
        description: 'Refine selected strategic option(s)',
        steps: [
            {
                id: '7.1',
                name: 'Elaborate strategy',
                description: 'Identify risks and capture assumptions',
                cardTypes: ['7', '9'],
                worksheets: [
                    {
                        i: '22',
                        n: 'Risk list',
                        t: 'List',
                        e: true,
                        d: 'Publish risk information',
                    },
                    {
                        i: '24',
                        n: 'Assumption list',
                        t: 'List',
                        e: true,
                        d: 'Publish assumption information',
                    },
                    {
                        i: '7',
                        n: 'Risk analysis',
                        t: 'Chart',
                        e: false,
                        d: 'Manage, prioritize and track strategic risks',
                    },
                    {
                        i: '70',
                        n: 'Assumption Analysis',
                        t: 'Chart',
                        e: false,
                        d: 'Identify, track and prioritize planning assumptions',
                    },
                ],
                h1: 'Capturing risks and assumptions in strategic planning is paramount. It anticipates uncertainties, providing a proactive risk management approach. Identifying assumptions enhances strategic clarity, ensuring that plans are robust and adaptable. This comprehensive awareness enables organizations to navigate challenges and capitalize on opportunities, fostering strategic resilience and success.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
        ],
    },
    {
        id: '8',
        name: 'Define',
        description: 'Prepare your strategic plan',
        steps: [
            {
                id: '8.1',
                name: 'Capture transformational medium- or long- term objectives',
                description:
                    'Set your medium- and longer-term desired breakthrough outcomes',
                cardTypes: ['23', '39', '40', '41', '20', '21', '22'],
                worksheets: [
                    {
                        i: '37',
                        n: 'Breakthrough Objective list',
                        t: 'List',
                        e: true,
                        d: 'Publish breakthrough objective information',
                    },
                    {
                        i: '34',
                        n: 'KPI (Number, Max) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '35',
                        n: 'KPI (Percentage, Max) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '36',
                        n: 'KPI (Currency, Max) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '52',
                        n: 'KPI (Number, Min) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '53',
                        n: 'KPI (Percentage, Min) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '54',
                        n: 'KPI (Currency, Min) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '3',
                        n: 'Strategy tree',
                        t: 'Chart',
                        e: false,
                        d: 'Visualize relationships between objectives, KPIs and action plans',
                    },
                    {
                        i: '4',
                        n: 'X-Matrix',
                        t: 'Chart',
                        e: false,
                        d: 'Ensure alignment between objectives, KPIs and action plans',
                    },
                    {
                        i: '2',
                        n: 'Scorecard',
                        t: 'Chart',
                        e: false,
                        d: 'Track KPI performance over time',
                    },
                ],
                h1: 'Identifying medium- to long-term breakthrough objectives is vital for organizational vision and growth. These objectives inspire innovation, challenge the status quo, and drive transformative change. They provide a compelling roadmap, aligning efforts toward ambitious goals that propel the organization forward, fostering sustained success and relevance in the future.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                id: '8.2',
                name: 'Identify annual objectives',
                description:
                    'Set annual objectives aligned with your longer term breakthrough objectives',
                cardTypes: [
                    '24',
                    '25',
                    '26',
                    '39',
                    '40',
                    '41',
                    '20',
                    '21',
                    '22',
                ],
                worksheets: [
                    {
                        i: '38',
                        n: 'Annual Objective list',
                        t: 'List',
                        e: true,
                        d: 'Publish annual objective information',
                    },
                    {
                        i: '39',
                        n: 'Objective list',
                        t: 'List',
                        e: true,
                        d: 'Publish objective information',
                    },
                    {
                        i: '72',
                        n: 'Portfolio Objective list',
                        t: 'List',
                        e: true,
                        d: 'Publish portfolio objective details',
                    },
                    {
                        i: '34',
                        n: 'KPI (Number, Max) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '35',
                        n: 'KPI (Percentage, Max) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '36',
                        n: 'KPI (Currency, Max) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '52',
                        n: 'KPI (Number, Min) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '53',
                        n: 'KPI (Percentage, Min) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '54',
                        n: 'KPI (Currency, Min) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '3',
                        n: 'Strategy tree',
                        t: 'Chart',
                        e: false,
                        d: 'Visualize relationships between objectives, KPIs and action plans',
                    },
                    {
                        i: '4',
                        n: 'X-Matrix',
                        t: 'Chart',
                        e: false,
                        d: 'Ensure alignment between objectives, KPIs and action plans',
                    },
                    {
                        i: '2',
                        n: 'Scorecard',
                        t: 'Chart',
                        e: false,
                        d: 'Track KPI performance over time',
                    },
                ],
                h1: 'Identifying annual objectives supporting medium- to longer-term breakthrough goals is crucial. It translates overarching ambitions into manageable, actionable steps, ensuring steady progress. These short-term targets align with the broader vision, promoting strategic consistency, focused efforts, and an incremental approach that collectively propels the organization toward its transformative, long-term objectives.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
        ],
    },
    {
        id: '9',
        name: 'Cascade',
        description: 'Cascade objectives through organization',
        steps: [
            {
                id: '9.1',
                name: 'Cascade annual objectives',
                description:
                    'Translate objectives down through your organization',
                cardTypes: ['25', '26', '39', '40', '41', '20', '21', '22'],
                worksheets: [
                    {
                        i: '39',
                        n: 'Objective list',
                        t: 'List',
                        e: true,
                        d: 'Publish objective information',
                    },
                    {
                        i: '72',
                        n: 'Portfolio Objective list',
                        t: 'List',
                        e: true,
                        d: 'Publish portfolio objective details',
                    },
                    {
                        i: '34',
                        n: 'KPI (Number, Max) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '35',
                        n: 'KPI (Percentage, Max) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '36',
                        n: 'KPI (Currency, Max) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '52',
                        n: 'KPI (Number, Min) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '53',
                        n: 'KPI (Percentage, Min) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                    {
                        i: '54',
                        n: 'KPI (Currency, Min) list',
                        t: 'List',
                        e: true,
                        d: 'Publish KPI information',
                    },
                ],
                h1: 'Cascading annual objectives throughout an organization is key for alignment and synergy. It communicates strategic priorities at every level, fostering a shared vision. This downward flow ensures that individual and team efforts harmonize with overarching goals, maximizing collective impact and organizational effectiveness in achieving strategic objectives.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
        ],
    },
    {
        id: '10',
        name: 'Plan',
        description: 'Builld action plans',
        steps: [
            {
                id: '10.1',
                name: 'Develop action plans',
                description:
                    'Build action plans that will achieve your objectives',
                cardTypes: ['48', '28', '29', '30', '47', '65'],
                worksheets: [
                    {
                        i: '76',
                        n: 'Portfolio list',
                        t: 'List',
                        e: true,
                        d: 'Publish portfolio information',
                    },
                    {
                        i: '41',
                        n: 'Program list',
                        t: 'List',
                        e: true,
                        d: 'Publish program information',
                    },
                    {
                        i: '42',
                        n: 'Project list',
                        t: 'List',
                        e: true,
                        d: 'Publish project information',
                    },
                    {
                        i: '43',
                        n: 'Task list',
                        t: 'List',
                        e: true,
                        d: 'Publish tasks',
                    },
                    {
                        i: '75',
                        n: 'Cost list',
                        t: 'List',
                        e: true,
                        d: 'Publish cost information',
                    },
                    {
                        i: '93',
                        n: 'Milestone list',
                        t: 'List',
                        e: true,
                        d: 'Publish project milestone information',
                    },
                    {
                        i: '3',
                        n: 'Strategy tree',
                        t: 'Chart',
                        e: false,
                        d: 'Visualize relationships between objectives, KPIs and action plans',
                    },
                    {
                        i: '4',
                        n: 'X-Matrix',
                        t: 'Chart',
                        e: false,
                        d: 'Ensure alignment between objectives, KPIs and action plans',
                    },
                    {
                        i: '5',
                        n: 'Portfolio analysis',
                        t: 'Chart',
                        e: false,
                        d: 'Compare multiple portfolios',
                    },
                    {
                        i: '6',
                        n: 'Action plan roadmap',
                        t: 'Chart',
                        e: false,
                        d: 'View planned delivery of action plans on the planning horizon',
                    },
                    {
                        i: '9',
                        n: 'Plan analysis',
                        t: 'Chart',
                        e: false,
                        d: 'View summary information for your strategic plan',
                    },
                ],
                h1: 'Defining action plans with detailed work streams is crucial. It transforms strategic objectives into tangible, actionable steps, ensuring clarity and accountability. These plans guide resource allocation, timelines, and responsibilities, facilitating efficient execution. The result is a focused, coordinated effort that propels the organization toward successful achievement of strategic objectives.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
        ],
    },
    {
        id: '11',
        name: 'Deliver',
        description: 'Manage action plan delivery',
        steps: [
            {
                id: '11.1',
                name: 'Manage programs and projects',
                description: 'Ensure successful action plan execution',
                cardTypes: ['50', '43', '51'],
                worksheets: [
                    {
                        i: '78',
                        n: 'Review list',
                        t: 'List',
                        e: true,
                        d: 'Publish review information',
                    },
                    {
                        i: '57',
                        n: 'Action list',
                        t: 'List',
                        e: true,
                        d: 'Publish action information',
                    },
                    {
                        i: '79',
                        n: 'Note list',
                        t: 'List',
                        e: true,
                        d: 'Publish notes',
                    },
                    {
                        i: '12',
                        n: 'Program summary',
                        t: 'Chart',
                        e: false,
                        d: 'Track progress of the overall program and its projects',
                    },
                    {
                        i: '11',
                        n: 'Project summary',
                        t: 'Chart',
                        e: false,
                        d: 'Track progress of a project',
                    },
                    {
                        i: '13',
                        n: 'Portfolio summary',
                        t: 'Chart',
                        e: false,
                        d: 'Understand portfolio composition and progress of its programs and projects',
                    },
                ],
                h1: 'Overseeing the delivery of strategic projects is vital for organizational success. It ensures alignment with overall goals, timely execution, and resource optimization. Effective project management safeguards against deviations, fosters accountability, and enhances adaptability to changes, ultimately contributing to improved competitiveness and sustained growth in dynamic business environments.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
        ],
    },
    {
        id: '12',
        name: 'Check',
        description: 'Track action plan progress and KPI performance',
        steps: [
            {
                id: '12.1',
                name: 'Hold action plan reviews',
                description: 'Take preventive and corrective action',
                cardTypes: ['31', '43', '44'],
                worksheets: [
                    {
                        i: '44',
                        n: 'Countermeasure list',
                        t: 'List',
                        e: true,
                        d: 'Publish countermeasures',
                    },
                    {
                        i: '57',
                        n: 'Action list',
                        t: 'List',
                        e: true,
                        d: 'Publish action information',
                    },
                    {
                        i: '55',
                        n: "5 Why's list",
                        t: 'List',
                        e: true,
                        d: "Publish 5 why's information",
                    },
                    {
                        i: '15',
                        n: 'Strategy summary',
                        t: 'Chart',
                        e: false,
                        d: 'Understand how my organization is delivering to its strategic objectives',
                    },
                    {
                        i: '13',
                        n: 'Portfolio summary',
                        t: 'Chart',
                        e: false,
                        d: 'Understand portfolio composition and progress of its programs and projects',
                    },
                    {
                        i: '12',
                        n: 'Program summary',
                        t: 'Chart',
                        e: false,
                        d: 'Track progress of the overall program and its projects',
                    },
                    {
                        i: '11',
                        n: 'Project summary',
                        t: 'Chart',
                        e: false,
                        d: 'Track progress of a project',
                    },
                    {
                        i: '14',
                        n: 'Objective summary',
                        t: 'Chart',
                        e: false,
                        d: 'Track delivery progress and KPI performance for an objective',
                    },
                ],
                h1: 'Regularly reviewing action plan delivery is crucial for achieving strategic objectives. It enables real-time assessment, identifies potential bottlenecks, and allows for timely adjustments. This iterative process enhances organizational agility, fosters continuous improvement, and ensures that resources are optimally allocated, leading to the successful realization of strategic goals.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                id: '12.2',
                name: 'Hold KPI performance reviews',
                description: 'Review performance and progress',
                cardTypes: ['50', '43', '51'],
                worksheets: [
                    {
                        i: '78',
                        n: 'Review list',
                        t: 'List',
                        e: true,
                        d: 'Publish review information',
                    },
                    {
                        i: '57',
                        n: 'Action list',
                        t: 'List',
                        e: true,
                        d: 'Publish action information',
                    },
                    {
                        i: '79',
                        n: 'Note list',
                        t: 'List',
                        e: true,
                        d: 'Publish notes',
                    },
                    {
                        i: '10',
                        n: 'KPI summary',
                        t: 'Chart',
                        e: false,
                        d: 'Understand KPI performance and progress with any countermeasures',
                    },
                    {
                        i: '14',
                        n: 'Objective summary',
                        t: 'Chart',
                        e: false,
                        d: 'Track delivery progress and KPI performance for an objective',
                    },
                ],
                h1: 'Reviewing Key Performance Indicator (KPI) performance is essential for gauging progress toward strategic objectives. It provides valuable insights into the effectiveness of implemented strategies, identifies areas for improvement, and enables informed decision-making. Regular KPI assessments ensure alignment with goals, fostering adaptability and ensuring sustained advancement.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
        ],
    },
    {
        id: '13',
        name: 'Act',
        description: 'Take performance measures',
        steps: [
            {
                id: '13.1',
                name: 'Implement countermeasures',
                description: 'Take preventive and corrective action',
                cardTypes: ['31', '43', '44'],
                worksheets: [
                    {
                        i: '44',
                        n: 'Countermeasure list',
                        t: 'List',
                        e: true,
                        d: 'Publish countermeasures',
                    },
                    {
                        i: '57',
                        n: 'Action list',
                        t: 'List',
                        e: true,
                        d: 'Publish action information',
                    },
                    {
                        i: '55',
                        n: "5 Why's list",
                        t: 'List',
                        e: true,
                        d: "Publish 5 why's information",
                    },
                    {
                        i: '10',
                        n: 'KPI summary',
                        t: 'Chart',
                        e: false,
                        d: 'Understand KPI performance and progress with any countermeasures',
                    },
                ],
                h1: 'Taking countermeasures when KPI performance falls short of targets is crucial for strategic success. It allows for prompt identification of issues, course correction, and optimization of resources. Proactive adjustments enhance resilience, maintain momentum, and ensure that the organization remains on track to achieve its strategic objectives in dynamic environments.',
                h2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
        ],
    },
];
