import {
    Box,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    RadioGroup,
    Tooltip,
    useTheme,
} from '@mui/material';
import MuiRadio from '@mui/material/Radio';
import { RadioFieldProps } from './inputFieldTypes';
import { ReactComponent as HelpIcon } from '../../../assets/icons/Help.svg';

const RadioFields = (props: RadioFieldProps) => {
    const {
        name,
        label,
        options,
        defaultValue,
        sx,
        required,
        value,
        onChange,
    } = props;

    const theme = useTheme();

    return (
        <>
            <FormLabel
                component="legend"
                sx={{
                    background: 'initial',
                    color: theme.palette.text.primary,
                    fontSize: '1.2em',
                    fontFamily: 'Antonio,sans-serif',
                }}
            >
                {label}
            </FormLabel>
            <RadioGroup
                aria-labelledby={label}
                defaultValue={defaultValue}
                value={value}
                name={name}
                sx={sx}
                {...(onChange && {
                    onChange: onChange,
                })}
            >
                {options?.map((option) => (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            key={option.label}
                            defaultValue={defaultValue}
                            sx={{ '.MuiTypography-root': { fontWeight: 600 } }}
                            control={
                                <MuiRadio
                                    {...props}
                                    size="small"
                                    value={option.value}
                                    required={required}
                                />
                            }
                            label={option.label}
                        />

                        {option.help && (
                            <Tooltip title={option.help} placement="top">
                                <IconButton
                                    sx={{
                                        padding: '4px',
                                        cursor: 'default',
                                        color: theme.palette.text.primary,
                                        width: '32px',
                                        height: '32px',
                                    }}
                                >
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                ))}
            </RadioGroup>
        </>
    );
};

export default RadioFields;
