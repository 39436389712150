import { useContext, useEffect, useState } from 'react';
import { GraphQLQuery, generateClient } from 'aws-amplify/api';
import { AnimatePresence } from 'framer-motion';
import { Alert, Box, Snackbar } from '@mui/material';
import CardComponent from '../components/cards/CardComponent';
import {
    CardComponentData,
    CardComponentType,
    CardPage,
    SortActions,
    PageIdentifier,
    ViewType,
    sortFieldToResponseMap,
} from '../components/cards/cardTypes';
import { EntityType, GetCardQuery, UpdateCardMutation } from '../API';
import CreateCard from '../components/forms/CreateCard';
import { StyledModal } from '../components/Modal';
import ModalContainer from '../components/ModalContainer';
import CardTypeLayout from '../components/cards/views/CardTypeLayout';
import { CardContext } from '../components/cards/context';
import { getPrimaryScore, getPrimaryScoreValue } from '../helpers/scores';
import { copyCard, updateCard } from '../graphql/mutations';
import StyledCardContent from '../components/cards/CardContent';
import CardDetails from '../components/cards/CardDetails';
import CardDelete from '../components/cards/CardDelete';
import CardScores from '../components/cards/CardScores';
import useSnackbar from '../hooks/useSnackbar';
import { getCard } from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import { Card } from '../API';

import { _cards } from '../../mocks';
import CardInfo from '../components/cards/CardInfo';
import CardRelationships from '../components/cards/CardRelationships';
import useIconState from '../hooks/useIconStates';
import { AppContext } from '../components/contexts';
import { queryData } from '../actions/QueryData';
import useFilterCards from '../hooks/useFilterCards';
import { getCardDataWithScoreData } from '../graphql/custom-queries';
import { getUserOrganisation } from '../helpers/auth';
import { getSubscriptions } from '../helpers/subscriptions';
import { getContext, getFutureDate } from '../helpers/utils';
import CardActivity from '../components/cards/CardActivity';
import CardComments from '../components/cards/CardComments';
import CreateSparkCard from '../components/forms/CreateSparkCard';
import { useLocation, useParams } from 'react-router-dom';

export interface CardToDelete {
    id: string;
    organisation: string;
    cardTypeId: string | undefined;
    cardCategoryId: string | undefined;
}

export const modalStyles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: {
        xs: '95vw',
        lg: '48rem',
    },
    width: '100%',
    zIndex: 1400,
};

export const cardToCardComponentProps = (data: Card): CardComponentData => {
    const primaryScore = getPrimaryScore(
        data?.toScoreData?.items,
        data.toCardType.defaultScoreDefinitionId
    );
    const primaryScoreDefinition = data?.toCardType?.scoreDefinitions?.find(
        (e) => e.id == data.toCardType.defaultScoreDefinitionId
    );

    return {
        id: data.id,
        cardComponentType: CardComponentType.CARD,
        owner: data.owner,
        createdBy: data.createdBy,
        briefDescription: data.briefDescription,
        fullDescription: data.fullDescription,
        name: data.name,
        cardCategoryId: data.cardToCardCategoryId,
        cardCategoryName: data.toCardCategory.name,
        cardTypeId: data.toCardType.id || '',
        cardTypeName: data.toCardType.name || '',
        scoreDefinition: primaryScoreDefinition,
        scoreName:
            primaryScoreDefinition?.shortName || primaryScoreDefinition?.name,
        scoreValues: primaryScore?.data,
        scoreValue: getPrimaryScoreValue(
            data.toScoreData?.items,
            data.toCardType.defaultScoreDefinitionId
        ),
        organisation: data.organisation,
        updatedAt: data.updatedAt,
        createdAt: data.createdAt,
        editors: data.editors,
        orgEdit: data.orgEdit,
    };
};

const Cards = () => {
    const { applyFilter, applySort, setLoaded, users, user } =
        useContext(AppContext);
    const [activeCardId, setActiveCardId] = useState<string | null>();
    const [relationalCard, setRelationalCard] = useState<Card | null>(null);
    const [backCards, setBackCards] = useState<Card[]>([]);
    const [showCreateCardWizard, setShowCreateCardWizard] = useState(false);
    const [showDeleteCardWizard, setShowDeleteCardWizard] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [cardToDelete, setCardToDelete] = useState<CardToDelete | null>(null);
    const [multiSelectedCards, setMultiSelectedCards] = useState<
        CardToDelete[] | []
    >([]);
    const [convertSparkCardId, setConvertSparkCardId] = useState('');
    const [editableCard, setEditableCard] = useState<Card | null>();
    const [activeCardPage, setActiveCardPage] = useState<CardPage>(
        CardPage.DETAILS
    );
    const [cardsObject, setCardsObject] = useState<{ [key: string]: Card }>({});
    const [currentUser, setCurrentUser] = useState('');
    const [cardItems, setCardItems] = useState<Card[]>([]);
    const [activeIcons, setActiveIcons] = useIconState('CardsControls');
    const [cardSortDirection, setCardSortDirection] = useState<'ASC' | 'DESC'>(
        () =>
            (localStorage.getItem(
                `selectedSortDirection_${PageIdentifier.CARDS}`
            ) as 'ASC' | 'DESC') || 'DESC'
    );
    const [cardSortField, setCardSortField] = useState<SortActions>(
        () =>
            (localStorage.getItem(
                `selectedSortAction_${PageIdentifier.CARDS}`
            ) as SortActions) || 'UpdatedAt'
    );
    const [filterCriteria, setFilterCriteria] = useFilterCards(
        `filter_${PageIdentifier.CARDS}`
    );
    const [showCreateSparkWizard, setShowCreateSparkWizard] = useState(false);
    const client = generateClient();
    const { closeSnackbar, isOpen, showSnackbar, message, severity, duration } =
        useSnackbar();

    const location = useLocation();
    const { id } = useParams();

    useEffect(() => {
        if (users?.length) {
            const createSub = client
                .graphql({ query: subscriptions.onCreateNotification })
                .subscribe({
                    next: ({ data }) => {
                        getSubscriptions({
                            data,
                            setItems: setCardItems,
                            showSnackbar,
                            users: users,
                            createCard: true,
                            filterCriteria: filterCriteria,
                        });
                    },
                    error: (error) => console.warn(error),
                });
            return () => createSub.unsubscribe();
        }
    }, [users]);

    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState<string | null>('');

    useEffect(() => {
        const updatedCardsObject = { ...cardsObject };
        cardItems?.forEach((card) => (updatedCardsObject[card.id] = card));

        setCardsObject(updatedCardsObject);
    }, [cardItems]);

    const onCreate = (card?: Card) => {
        showSnackbar({
            message: 'Card created',
            severity: 'success',
        });
        handleClose();
    };

    useEffect(() => {
        if (user) {
            setCurrentUser(user?.tokens?.idToken?.payload.sub ?? '');
        }

        const sortAction = localStorage.getItem(
            `selectedSortAction_${PageIdentifier.CARDS}`
        ) as SortActions;
        if (sortAction) {
            setCardSortField(sortAction);
        }
        const sortDirection = localStorage.getItem(
            `selectedSortDirection_${PageIdentifier.CARDS}`
        ) as 'ASC' | 'DESC';
        if (sortDirection) {
            setCardSortDirection(sortDirection);
        }
    }, [applySort, user]);
    const fetchCards = async (initialLoad?: boolean) => {
        setLoading(true);
        try {
            const { data, nextToken } = await queryData(
                initialLoad ?? false,
                cardSortDirection,
                token,
                activeIcons,
                getCardDataWithScoreData,
                sortFieldToResponseMap[cardSortField],
                'getCardData',
                filterCriteria,
                setActiveIcons,
                '',
                user
            );

            if (initialLoad) {
                setCardItems(data as Card[]);
            } else {
                setCardItems([...cardItems, ...(data as Card[])]);
            }
            setLoading(false);
            setToken(nextToken);
            setLoaded && setLoaded(true);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (currentUser) {
            fetchCards(true);
        }
    }, [
        currentUser,
        activeIcons,
        cardSortDirection,
        cardSortField,
        applyFilter,
    ]);

    const handleClose = () => {
        setActiveCardId(null);
        setRelationalCard(null);
        setEditableCard(null);
        setShowCreateCardWizard(false);
        setConvertSparkCardId('');
        setBackCards([]);
    };

    const handleClick = (cardId: string, cardPage?: CardPage) => {
        if (cardPage) {
            if (!activeCardId || (activeCardId && cardId !== activeCardId)) {
                setActiveCardId(cardId);
            }
            setActiveCardPage(cardPage);
        }
    };

    const handleEdit = (cardId: string) => {
        setEditableCard(cardsObject[cardId]);
    };

    const handleCopy = async (cardId: string) => {
        const organisation = getUserOrganisation(user);
        const context = getContext(location.pathname, id);

        try {
            const res = await client.graphql({
                query: copyCard,
                variables: {
                    originalCardId: cardId,
                    _auditContextType: context.contextType,
                    _auditContextId: context.contextId,
                },
            });

            showSnackbar({
                message: 'Card copied successfully',
                severity: 'success',
            });

            handleClose();
        } catch (err) {
            showSnackbar({
                message: 'Error while copying the card',
                severity: 'error',
            });
        }
    };

    const handleDelete = async (id: string, organisation: string) => {
        if (multiSelectedCards.some((card) => card.id === id)) {
            setShowDeleteCardWizard(true);
        } else {
            setShowDeleteCardWizard(true);
            setCardToDelete({
                id: id,
                organisation: organisation,
                cardTypeId: cardsObject[id].toCardType.id,
                cardCategoryId: cardsObject[id].toCardCategory.id,
            });
        }
    };

    const handleConvert = async (id: string) => {
        setShowCreateCardWizard(true);
        setConvertSparkCardId(id);
    };

    const confirmDelete = async (
        id: string,
        organisation: string,
        multiDelete?: boolean
    ) => {
        const data = {
            id: id,
            organisation: organisation,
            deleteAfter: getFutureDate(30),
        };
        try {
            const res = await client.graphql<GraphQLQuery<UpdateCardMutation>>({
                query: updateCard,
                variables: {
                    input: data,
                },
            });
            showSnackbar({
                message: 'Card deleted',
                severity: 'info',
            });
            setCardItems((prevItems) =>
                prevItems.filter((item) => item.id !== id)
            );
            setLoaded && setLoaded(true);
            handleClose();
        } catch (err) {
            console.log(err);
            handleClose();
            showSnackbar({
                message: 'Error while removing card',
                severity: 'error',
            });
        }
        if (!multiDelete) setShowDeleteCardWizard(false);
    };

    const confirmMultiDelete = async () => {
        setDeleting(true);
        for (const card of multiSelectedCards) {
            await confirmDelete(card.id, card.organisation, true);
        }
        setDeleting(false);
        setMultiSelectedCards([]);
        setShowDeleteCardWizard(false);
    };

    const refreshCard = async (id: string, organisation: string) => {
        const response = (await client.graphql({
            query: getCard,
            variables: {
                id: id,
                organisation: organisation,
            },
        })) as { data: GetCardQuery };

        showSnackbar({
            message: 'Card updated',
            severity: 'success',
        });

        const card = response.data.getCard;
        if (relationalCard) {
            setRelationalCard(card as Card);
        }
        const items = [...(cardItems ?? [])];

        const index = items.findIndex((item) => item.id === id);

        if (!!card && index !== -1) {
            items[index] = card as Card;
            setCardItems(items);
        }
    };

    const setRelationCard = (card: Card) => {
        setRelationalCard(card);
        const cards = [...backCards];
        const newBackCard = relationalCard
            ? relationalCard
            : cardsObject[activeCardId ?? ''];
        cards.push(newBackCard);
        setBackCards(cards);
        setActiveCardId(null);
    };

    const goBack = () => {
        const cards = [...backCards];
        const lastItem = cards.pop();
        setBackCards(cards);
        lastItem && setRelationalCard(lastItem);
    };

    return (
        <>
            <CardContext.Provider
                value={{
                    emptyAction: () => setShowCreateCardWizard(true),
                    handleClick,
                    handleClose,
                    handleDelete,
                    handleEdit,
                    handleCopy,
                    handleConvert,
                    refreshCard,
                    setRelationCard: (card: Card) => setRelationCard(card),
                    backCard: backCards.length > 0,
                    goBack: goBack,
                    items:
                        cardItems?.map((card) =>
                            cardToCardComponentProps(card)
                        ) || [],
                }}
            >
                <CardTypeLayout
                    headerTitle={'Cards'}
                    viewTitle="All cards"
                    action={() => setShowCreateCardWizard(true)}
                    actionLabel="Create card"
                    viewOptions={{
                        [ViewType.GRID]: true,
                        [ViewType.LIST]: true,
                    }}
                    activeIcons={activeIcons}
                    setActiveIcons={setActiveIcons}
                    token={token}
                    loading={loading}
                    onLoadMore={fetchCards}
                    pageIdentifier={PageIdentifier.CARDS}
                    filterCriteria={filterCriteria}
                    setFilterCriteria={setFilterCriteria}
                    setCardItems={setCardItems}
                    multiSelectedCards={multiSelectedCards}
                    setMultiSelectedCards={setMultiSelectedCards}
                    cardsObject={cardsObject}
                    setShowCreateSparkWizard={setShowCreateSparkWizard}
                />

                <Snackbar
                    open={isOpen}
                    autoHideDuration={duration}
                    onClose={closeSnackbar}
                    sx={{ zIndex: 1600 }}
                >
                    <Alert
                        variant="filled"
                        severity={severity}
                        sx={{ width: '100%' }}
                        onClose={closeSnackbar}
                    >
                        {message}
                    </Alert>
                </Snackbar>

                <AnimatePresence>
                    {(relationalCard || activeCardId) && (
                        <>
                            <Box
                                key="modal"
                                sx={{
                                    ...modalStyles,
                                    borderRadius: '1rem',
                                }}
                                className={
                                    relationalCard ? 'refresh-modal' : ''
                                }
                            >
                                <CardComponent
                                    data={
                                        activeCardId
                                            ? cardToCardComponentProps(
                                                  cardsObject[activeCardId]
                                              )
                                            : cardToCardComponentProps(
                                                  relationalCard as Card
                                              )
                                    }
                                    handleDelete={handleDelete}
                                    onClick={(
                                        cardId: string,
                                        cardPage?: CardPage
                                    ) => handleClick(cardId, cardPage)}
                                    handleEdit={handleEdit}
                                    showPage={activeCardPage}
                                    handleCopy={handleCopy}
                                    handleClose={handleClose}
                                    handleConvert={handleConvert}
                                    expanded
                                >
                                    {!!activeCardPage && (
                                        <StyledCardContent expanded={true}>
                                            {
                                                {
                                                    [CardPage.DETAILS]: (
                                                        <CardDetails
                                                            card={
                                                                activeCardId
                                                                    ? cardsObject[
                                                                          activeCardId
                                                                      ]
                                                                    : (relationalCard as Card)
                                                            }
                                                            onUpdate={
                                                                refreshCard
                                                            }
                                                            isCard={true}
                                                        />
                                                    ),
                                                    [CardPage.SCORES]: (
                                                        <CardScores
                                                            card={
                                                                activeCardId
                                                                    ? cardsObject[
                                                                          activeCardId
                                                                      ]
                                                                    : (relationalCard as Card)
                                                            }
                                                            onUpdate={
                                                                refreshCard
                                                            }
                                                        />
                                                    ),
                                                    [CardPage.INFO]: (
                                                        <CardInfo
                                                            card={
                                                                activeCardId
                                                                    ? cardsObject[
                                                                          activeCardId
                                                                      ]
                                                                    : (relationalCard as Card)
                                                            }
                                                            onUpdate={
                                                                refreshCard
                                                            }
                                                        />
                                                    ),
                                                    [CardPage.ACTIVITY]: (
                                                        <CardActivity
                                                            card={
                                                                activeCardId
                                                                    ? cardsObject[
                                                                          activeCardId
                                                                      ]
                                                                    : (relationalCard as Card)
                                                            }
                                                        />
                                                    ),
                                                    [CardPage.RELATIONSHIPS]: (
                                                        <CardRelationships
                                                            card={
                                                                activeCardId
                                                                    ? cardsObject[
                                                                          activeCardId
                                                                      ]
                                                                    : (relationalCard as Card)
                                                            }
                                                        />
                                                    ),
                                                    [CardPage.COMMENTS]: (
                                                        <CardComments
                                                            card={
                                                                activeCardId
                                                                    ? cardsObject[
                                                                          activeCardId
                                                                      ]
                                                                    : (relationalCard as Card)
                                                            }
                                                            onUpdate={
                                                                refreshCard
                                                            }
                                                            context={
                                                                EntityType.Card
                                                            }
                                                        />
                                                    ),
                                                }[activeCardPage]
                                            }
                                        </StyledCardContent>
                                    )}
                                </CardComponent>
                            </Box>
                        </>
                    )}
                    <StyledModal
                        key="modal"
                        open={
                            !!relationalCard ||
                            !!activeCardId ||
                            showCreateCardWizard ||
                            !!editableCard
                        }
                        onClose={handleClose}
                        aria-labelledby=""
                        aria-describedby=""
                        data-automation-id="create-card-modal"
                        disableEnforceFocus
                        {...(showCreateCardWizard && {
                            sx: { zIndex: 1400 },
                        })}
                    >
                        <Box>
                            {(showCreateCardWizard || !!editableCard) && (
                                <ModalContainer sx={{ maxWidth: '75rem' }}>
                                    {
                                        <CreateCard
                                            card={editableCard || undefined}
                                            handleClose={onCreate}
                                            fetchCards={fetchCards}
                                            pageIdentifier={
                                                PageIdentifier.CARDS
                                            }
                                            convertSparkCardId={
                                                convertSparkCardId
                                            }
                                        />
                                    }
                                </ModalContainer>
                            )}
                        </Box>
                    </StyledModal>
                    <StyledModal
                        key="delete-card-modal"
                        open={showDeleteCardWizard}
                        onClose={() => {
                            setShowDeleteCardWizard(false);
                            setCardToDelete(null);
                            setMultiSelectedCards([]);
                        }}
                        disableEnforceFocus
                        sx={{
                            zIndex: 1500,
                        }}
                    >
                        <Box>
                            {showDeleteCardWizard &&
                                (multiSelectedCards || cardToDelete) &&
                                (cardToDelete ? (
                                    <ModalContainer sx={{ maxWidth: '40rem' }}>
                                        <CardDelete
                                            id={cardToDelete.id}
                                            organisation={
                                                cardToDelete.organisation
                                            }
                                            toggleDeleteWizard={
                                                setShowDeleteCardWizard
                                            }
                                            setCardToDelete={setCardToDelete}
                                            confirmDelete={confirmDelete}
                                            cardCategoryId={
                                                cardToDelete.cardCategoryId
                                            }
                                            pageIdentifier={
                                                PageIdentifier.CARDS
                                            }
                                        />
                                    </ModalContainer>
                                ) : (
                                    <ModalContainer sx={{ maxWidth: '40rem' }}>
                                        <CardDelete
                                            toggleDeleteWizard={
                                                setShowDeleteCardWizard
                                            }
                                            confirmMultiDelete={
                                                confirmMultiDelete
                                            }
                                            pageIdentifier={
                                                PageIdentifier.CARDS
                                            }
                                            multiSelectedCards={
                                                multiSelectedCards
                                            }
                                            setMultiSelectedCards={
                                                setMultiSelectedCards
                                            }
                                            deleting={deleting}
                                        />
                                    </ModalContainer>
                                ))}
                        </Box>
                    </StyledModal>
                    {showCreateSparkWizard && (
                        <StyledModal
                            key="modal"
                            open={showCreateSparkWizard}
                            onClose={() => setShowCreateSparkWizard(false)}
                            sx={{ zIndex: 1401 }}
                        >
                            <ModalContainer sx={{ maxWidth: '35rem' }}>
                                <CreateSparkCard
                                    setShowCreateSparkWizard={
                                        setShowCreateSparkWizard
                                    }
                                />
                            </ModalContainer>
                        </StyledModal>
                    )}
                </AnimatePresence>
            </CardContext.Provider>
        </>
    );
};

export default Cards;
