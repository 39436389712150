import React, { useContext, useEffect, useState } from 'react';
import {
    Attribute,
    AttributeType,
    Card,
    CardSet,
    CardsCardSetsByCardIdQuery,
} from '../../../API';
import {
    getCardSetWithId,
    getCardWithAttributeReference,
    getCardWithId,
} from '../../../helpers/card';
import { getCategoryHex } from '../../../helpers/category';
import { ReactComponent as CardIcon } from '../../../assets/icons/Small-card.svg';
import { ReactComponent as CardSetIcon } from '../../../assets/icons/Small-card-set.svg';
import { ReactComponent as WorkbooksIcon } from '../../../assets/icons/Small-workbook.svg';
import RelationshipItems from './RelationshipItems';
import { AppContext } from '../../contexts';
import { isJsonString } from '../../../helpers/utils';
import { cardsCardSetsByCardId } from '../../../graphql/queries';
import { generateClient } from 'aws-amplify/api';
interface CardRelationshipsProps {
    card: Card | CardSet;
}
const CardRelationships = ({ card }: CardRelationshipsProps) => {
    const { cardTypeObject } = useContext(AppContext);
    const [cardObject, setCardObject] = useState<Card | null>(null);
    const [containingCardSets, setContainingCardSets] = useState<any>();
    const [relatedCards, setRelatedCards] = useState<Card[]>([]);
    const [relatedCardSets, setRelatedCardSets] = useState<CardSet[]>([]);
    const { id } = card;

    const client = generateClient();

    const { attributeDefinitions } =
        cardTypeObject[cardObject?.cardToCardTypeId ?? -1] ?? {};

    useEffect(() => {
        const cardObj = card as Card;
        if (cardObj.attributes) {
            const atts = cardObj.attributes?.map((item) => {
                if (item?.value) {
                    return {
                        attributeDefinitionID: item?.attributeDefinitionID,
                        value: isJsonString(item.value)
                            ? JSON.parse(item?.value)
                            : item.value,
                    };
                }
            });

            cardObj.attributes = atts as Attribute[];
        }

        setCardObject(cardObj as Card);
    }, [card]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = (await client.graphql({
                    query: cardsCardSetsByCardId,
                    variables: {
                        cardId: id,
                    },
                })) as { data: CardsCardSetsByCardIdQuery };

                const fetchedContainingCardSets =
                    response.data.cardsCardSetsByCardId?.items.map((item) => {
                        return item?.cardSet;
                    });
                setContainingCardSets(fetchedContainingCardSets);
            } catch (err) {
                console.log(err);
                return [];
            }

            const cardAsCardType = card as Card;

            let cardSetPromises: Promise<any | null>[] = [];

            if (
                !cardAsCardType.attributes ||
                cardAsCardType.attributes.length === 0
            ) {
                const fetchedRelatedCards = await getCardWithAttributeReference(
                    id
                );
                setRelatedCards(fetchedRelatedCards);
            } else {
                const fetchedRelatedCards1 =
                    await getCardWithAttributeReference(id);
                const relatedCardPromises = cardAsCardType.attributes.map(
                    async (item) => {
                        const attributeType = attributeDefinitions?.find(
                            (attrDef) =>
                                attrDef?.id === item?.attributeDefinitionID
                        )?.attributeType;

                        try {
                            if (
                                item &&
                                typeof item.value === 'string' &&
                                item.value
                            ) {
                                if (attributeType === AttributeType.Card) {
                                    return await getCardWithId(item.value);
                                } else {
                                    cardSetPromises.push(
                                        getCardSetWithId(item.value)
                                    );
                                }
                            } else if (
                                item &&
                                Array.isArray(item.value) &&
                                attributeType === AttributeType.Cards
                            ) {
                                const cardPromises = item.value.map((value) =>
                                    getCardWithId(value)
                                );
                                const cards = await Promise.all(cardPromises);
                                return cards.filter((card) => card !== null);
                            }
                        } catch (error) {
                            console.error(
                                'Error fetching related card:',
                                error
                            );
                            return null;
                        }
                    }
                );

                const fetchedRelatedCards = (
                    await Promise.all(relatedCardPromises)
                ).flat();
                const fetchedOtherRelatedCards2 = fetchedRelatedCards.filter(
                    (card) => card !== null
                ) as Card[];
                const combinedRelatedCards = [
                    ...fetchedOtherRelatedCards2,
                    ...fetchedRelatedCards1,
                ];
                setRelatedCards(combinedRelatedCards);

                const fetchedOtherRelatedCards = await Promise.all(
                    cardSetPromises
                );
                setRelatedCardSets(
                    fetchedOtherRelatedCards.filter((card) => card !== null)
                );
            }
        };
        fetchData();
    }, [card, attributeDefinitions]);

    return (
        <>
            <RelationshipItems
                title="Cards associated with this card"
                items={relatedCards.filter((item) => item)}
                Icon={CardIcon}
                colorFn={getCategoryHex}
            />
            <RelationshipItems
                title="Card sets containing this card"
                items={containingCardSets?.filter(
                    (item: any) => item.type === 'CS'
                )}
                Icon={CardSetIcon}
                colorFn={() => getCategoryHex(card.toCardCategory?.id ?? '')}
            />
            <RelationshipItems
                title="Card sets associated with this card"
                items={relatedCardSets?.filter((item) => item.type === 'CS')}
                Icon={CardSetIcon}
                colorFn={() => getCategoryHex(card.toCardCategory?.id ?? '')}
            />
            <RelationshipItems
                title="Workbooks containing this card"
                items={containingCardSets?.filter(
                    (item: any) => item.type === 'WB'
                )}
                Icon={WorkbooksIcon}
            />
        </>
    );
};
export default CardRelationships;
