import { useState } from 'react';
import DOMPurify from 'dompurify';
import { Box, Typography, Divider, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { motion } from 'framer-motion';
import { defaultTabTransition } from '../../../helpers/animations';
import { addCardToCardSet } from '../../../actions/CardSets';
import { createCardSet, updateCardSet } from '../../../graphql/mutations';
import { CardSet, CardSetType, CreateCardSetMutation } from '../../../API';
import CardSetDetailsColumn from './CardSetDetails';
import { CardToDelete } from '../../../pages/cards';

interface CreateCardSetProps {
    cardSet?: CardSet;
    cardSetCopy?: Pick<
        CardSet,
        'cardSetToCardCategoryId' | 'cardSetToCardTypeId'
    >;
    onSubmitResponse?: (cardSet: CardSet) => void;
    handleClose?: (id?: string) => void;
    cardId?: string;
    multiSelectedCards?: CardToDelete[] | [] | undefined;
}
const CreateCardSet = ({
    cardSet,
    cardSetCopy,
    onSubmitResponse,
    handleClose,
    cardId,
    multiSelectedCards,
}: CreateCardSetProps) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [cardSetObject, setCardSetObject] = useState({
        name: '',
        briefDescription: '',
    });

    const navigate = useNavigate();

    const client = generateClient();
    const checkMultiCardType = (cards: CardToDelete[]) => {
        if (cards.length === 0) return true;

        const firstCardTypeId = cards[0].cardTypeId;

        return cards.every((card) => card.cardTypeId === firstCardTypeId);
    };
    const onSubmit = async (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        setLoading(true);

        const multiSelect =
            multiSelectedCards && multiSelectedCards.length > 0;
        const singleType =
            multiSelectedCards && checkMultiCardType(multiSelectedCards);
        const isCardIdInMultiSelect =
            multiSelect &&
            multiSelectedCards.some((card) => card.id === cardId);
        const sanitizedData = {
            name: DOMPurify.sanitize(cardSetObject.name!) || '',
            briefDescription:
                DOMPurify.sanitize(cardSetObject.briefDescription!) || '',
            organisation: cardSet?.organisation || 'x',
            cardSetToCardCategoryId:
                multiSelect && isCardIdInMultiSelect && !singleType
                    ? null
                    : multiSelect && !isCardIdInMultiSelect && singleType
                    ? multiSelectedCards[0].cardCategoryId
                    : cardSetCopy
                    ? cardSetCopy.cardSetToCardCategoryId
                    : null,
            cardSetToCardTypeId:
                multiSelect && isCardIdInMultiSelect && !singleType
                    ? null
                    : multiSelect && !isCardIdInMultiSelect && singleType
                    ? multiSelectedCards[0].cardTypeId
                    : cardSetCopy
                    ? cardSetCopy.cardSetToCardTypeId
                    : null,
            type: 'CS' as CardSetType,
        };
        const query = cardSet ? updateCardSet : createCardSet;
        const res = (await client.graphql({
            query,
            variables: {
                input: sanitizedData,
            },
        })) as { data: CreateCardSetMutation };

        const successResponse = res.data;
        if (successResponse) {
            const cardSetId = successResponse.createCardSet?.id;
            if (cardSetId) {
                if (cardId && !isCardIdInMultiSelect) {
                    await addCardToCardSet({
                        organisation: 'x',
                        cardSetId,
                        cardId,
                    });
                } else if (multiSelect) {
                    await Promise.all(
                        multiSelectedCards.map(async (card) => {
                            await addCardToCardSet({
                                organisation: 'x',
                                cardSetId,
                                cardId: card.id,
                            });
                        })
                    );
                }

                navigate(`/card-sets/${cardSetId}`);
            } else {
                if (onSubmitResponse && successResponse.createCardSet) {
                    onSubmitResponse(successResponse.createCardSet as CardSet);
                }
                navigate(`/card-sets/${successResponse.createCardSet?.id}`);
            }
        }
        setLoading(false);
    };

    return (
        <Box sx={{ overflowY: 'hidden' }}>
            <motion.div
                initial={false}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -300, opacity: 0 }}
                transition={defaultTabTransition}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        px: 3,
                        pt: 3,
                        legend: {
                            color: theme.palette.text.primary,
                            fontWeight: 600,
                        },
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{ marginBottom: 2, textAlign: 'center' }}
                    >
                        {cardSet ? 'Edit a card set' : 'Create a card set'}
                    </Typography>
                    <Divider />
                </Box>

                <form onSubmit={onSubmit}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            p: 0,
                            maxHeight: '670px',
                        }}
                    >
                        <CardSetDetailsColumn
                            loading={loading}
                            cardSet={cardSetObject}
                            setCardSetObject={setCardSetObject}
                        />
                    </Box>
                </form>
            </motion.div>
        </Box>
    );
};

export default CreateCardSet;
