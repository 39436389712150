import { useContext, useEffect, useRef, useState } from 'react';

import {
    Box,
    CircularProgress,
    IconButton,
    Typography,
    useTheme,
} from '@mui/material';
import { ReactComponent as AddIcon } from '../../assets/icons/add-worksheet.svg';
import WorkBookDrawerPage, { PagePreview } from './WorkbookDrawerPage';

import { generateClient } from 'aws-amplify/api';
import {
    CardSet,
    CardSetType,
    CreatePlaybookPageMutation,
    DeletePlaybookPageCardSetsMutation,
    PlaybookPage,
} from '../../API';

import { playbookPagesByWorkbookId } from '../../graphql/queries';
import { PlaybookPagesByWorkbookIdQuery } from '../../API';
import { useParams } from 'react-router-dom';
import AddWorksheet from './AddWorksheet';

import { deletePlaybookPage, updateCardSet } from '../../graphql/mutations';
import { DrawStates } from '../cards/views/CardTypeLayout';
import { UserPermissions } from '../../globals';
import { getUserPermissions } from '../../helpers/permissions';
import { CardSetComponentData } from '../cards/cardTypes';
import { worksheets } from '../forms/worksheets';
import AddWorksheetDrawer from './AddWorksheetDrawer';

import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    MouseSensor,
    DragStartEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { SortableListItem } from '../sortable';
import { hasDuplicates } from '../../helpers/utils';
import { AppContext } from '../contexts';

export interface PageConfig {
    id?: string;
    title: string;
    commentary: string;
    include?: string[];
}

const WorkBookDrawer = ({
    showDrawer,
    setShowDrawer,
    playbookDrawerItems,
    setPlaybookDrawerItems,
    currentWorkbook,
    setCurrentWorkbook,
    refreshWorkbookDrawer,
    newWorksheet,
    openDrawerItem,
    setOpenDrawerItem,
}: {
    showDrawer: DrawStates;
    setShowDrawer: () => void;
    playbookDrawerItems: PagePreview[];
    setPlaybookDrawerItems: any;
    currentWorkbook?: CardSet;
    setCurrentWorkbook?: (workbook: CardSet) => void;
    refreshWorkbookDrawer?: () => void;
    newWorksheet?: boolean;
    openDrawerItem?: PagePreview | null;
    setOpenDrawerItem?: React.Dispatch<
        React.SetStateAction<PagePreview | null>
    >;
}) => {
    const containerWidth = useRef<HTMLDivElement>(null);
    const [playbookItems, setPlaybookItems] = useState<PagePreview[]>([]);
    const [autoDetected, setAutoDetected] = useState<PagePreview[]>([]);
    const [pageConfig, setPageConfig] = useState<PageConfig | null>(null);
    const [removedItems, setRemovedItems] = useState<string[]>([]);
    const [updated, setUpdated] = useState(false);
    const [permissions, setPermissions] = useState<UserPermissions[]>([]);
    const [drawSliding, setDrawSliding] = useState(false);
    const [grab, setGrab] = useState<string | null>(null);
    const [newId, setNewId] = useState('');
    const [open, setOpen] = useState(false);
    const { user } = useContext(AppContext);
    const theme = useTheme();
    const { id } = useParams();

    const activeWorksheets = worksheets
        .filter((worksheet) => worksheet.e)
        .map((item) => item.n);

    const client = generateClient();
    useEffect(() => {
        if (user && currentWorkbook) {
            const userPermissions = getUserPermissions(
                user?.tokens?.idToken?.payload.sub ?? '',
                currentWorkbook as CardSetComponentData
            );
            setPermissions(userPermissions);
        }
    }, [currentWorkbook, user]);
    const getDrawerLocally = () => {
        const playbookDrawer = localStorage.getItem(`playbookDrawer_${id}`);

        return playbookDrawer ? JSON.parse(playbookDrawer) : {};
    };

    const setDrawerLocally = (item: PagePreview) => {
        const playbookDrawer: {
            [key: string]: {
                viewed: boolean;
                open: boolean;
            };
        } = getDrawerLocally();

        const playbookItem = {
            viewed: true,
            open: item.open ?? false,
        };

        playbookDrawer[item.name] = playbookItem;
        if (item.open) {
            let keys = Object.keys(playbookDrawer);

            keys.forEach((key) => {
                if (key !== item.name) {
                    playbookDrawer[key].open = false;
                }
            });
        }

        localStorage.setItem(
            `playbookDrawer_${id}`,
            JSON.stringify(playbookDrawer)
        );
    };

    const removeFromDrawer = async (typeId: string) => {
        if (
            !currentWorkbook?.hiddenWorksheets ||
            (currentWorkbook.hiddenWorksheets &&
                !currentWorkbook.hiddenWorksheets.includes(typeId))
        ) {
            const worksheets = currentWorkbook?.hiddenWorksheets
                ? [...currentWorkbook?.hiddenWorksheets]
                : [];
            worksheets.push(typeId);

            const data = {
                id: id ?? '',
                type: 'WB' as CardSetType,
                name: currentWorkbook?.name,
                capitalName: currentWorkbook?.capitalName,
                createdAt: currentWorkbook?.createdAt,
                organisation: currentWorkbook?.organisation ?? '',
                hiddenWorksheets: worksheets,
            };
            try {
                await client.graphql({
                    query: updateCardSet,
                    variables: {
                        input: data,
                    },
                });
            } catch (e) {}

            setCurrentWorkbook &&
                currentWorkbook &&
                setCurrentWorkbook({
                    ...currentWorkbook,
                    hiddenWorksheets: worksheets,
                });

            setPlaybookDrawerItems((items: any) => {
                const playbookItems = [...items];
                return playbookItems.filter(
                    (item) => item.worksheetId !== typeId
                );
            });
            setRemovedItems((items) => {
                items.push(typeId);
                return items;
            });
        }

        try {
            const response = (await client.graphql({
                query: playbookPagesByWorkbookId,
                variables: {
                    workbookId: id ?? '',
                },
            })) as { data: PlaybookPagesByWorkbookIdQuery };

            if (response.data.playbookPagesByWorkbookId?.items?.length) {
                const item = response.data.playbookPagesByWorkbookId.items.find(
                    (item) => item?.worksheetId === typeId
                );
                if (item) {
                    const data = {
                        id: item.id,
                        organisation: item.organisation,
                    };
                    const response = (await client.graphql({
                        query: deletePlaybookPage,
                        variables: {
                            input: data,
                        },
                    })) as { data: DeletePlaybookPageCardSetsMutation };
                }
            }
            setPlaybookDrawerItems((items: any) => {
                const playbookItems = [...items];
                return playbookItems.filter(
                    (item) => item.worksheetId !== typeId
                );
            });
        } catch (e) {
            console.log(e);
            return [];
        }
    };

    const addSuggestedItem = (item: PagePreview) => {
        setPlaybookItems((playbookItems: PagePreview[]) => {
            let pageItems = [...playbookItems];
            pageItems = pageItems.filter(
                (pageItem) => pageItem.worksheetId !== item.worksheetId
            );

            pageItems.unshift(item);

            return pageItems.sort(
                (a, b) => (a.ordinal ?? 1) - (b.ordinal ?? 1)
            );
        });
        setAutoDetected((detectedItems: PagePreview[]) => {
            let pageItems = [...detectedItems];
            pageItems = pageItems.filter((pageItem) => pageItem.id !== item.id);

            return pageItems;
        });
    };

    const setPlaybookDrawersOrdered = (playbookDrawerItems: PagePreview[]) => {
        const playbookDrawers = getDrawerLocally();

        let items = playbookDrawerItems.map((item, index) => {
            let local = playbookDrawers[item.name];

            if (!updated && newId && newId === item.id) {
                return {
                    ...item,
                    open: item.id === newId ? true : false,
                };
            } else {
                return {
                    ...item,
                    open:
                        !updated && newId && newId !== item.id
                            ? false
                            : local?.open ?? false,
                    viewed: local?.viewed,
                };
            }
        });

        //Remove any items from state which no longer exist
        let keys = Object.keys(playbookDrawers);

        keys.forEach((key) => {
            if (
                items.length &&
                items.findIndex((item) => item.name === key) === -1
            ) {
                removeFromLocal(key);
            }
        });

        const available = items.filter((item) =>
            activeWorksheets.includes(item.name)
        );
        const all = [
            ...available,
            ...items.filter(
                (item) => !activeWorksheets.includes(item.name) && !!item.id
            ),
        ];

        setPlaybookItems(all);
        setAutoDetected(items.filter((item) => !item.id));
    };
    const updateItem = (item: PagePreview, configuration?: boolean) => {
        const items = [...playbookItems];
        const index = items.findIndex(
            (playbookItem) => playbookItem.name === item.name
        );
        items[index] = item;
        if (configuration) {
            item.open = true;
        }
        setDrawerLocally(item);

        setPlaybookDrawersOrdered(items);

        setUpdated(true);
    };

    const removeFromLocal = (key: string) => {
        const playbookDrawer: {
            [key: string]: {
                viewed: boolean;
                open: boolean;
            };
        } = getDrawerLocally();
        delete playbookDrawer[key];

        localStorage.setItem('playbookDrawer', JSON.stringify(playbookDrawer));
    };

    useEffect(() => {
        setPlaybookDrawersOrdered(playbookDrawerItems);
    }, [playbookDrawerItems]);

    useEffect(() => {
        setDrawSliding(true);
        setTimeout(() => {
            setDrawSliding(false);
        }, 1000);
    }, [showDrawer]);

    const addNewWorksheet = (worksheet: PlaybookPage) => {
        const removed = [...removedItems];

        setNewId(worksheet.id);

        refreshWorkbookDrawer && refreshWorkbookDrawer();

        setRemovedItems(
            removed.filter((item) => item !== worksheet.worksheetId)
        );
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 50,
            },
        })
    );

    const handleDragEnd = async (event: DragEndEvent) => {
        setGrab(null);

        const { active, over } = event;

        let playbookPages = [...playbookItems];

        const autoDetected = playbookPages.filter((item) => !item.id);

        playbookPages = playbookPages
            .filter((item) => !!item.id)
            .sort((a, b) => (a.ordinal ?? 1) - (b.ordinal ?? 1));

        let ordinals = playbookPages.map(
            (playbookPage) => playbookPage.ordinal
        );

        if (hasDuplicates(ordinals)) {
            const newOrdinals = [];

            for (let x = 0 - playbookPages.length; 0 > x; x++) {
                newOrdinals.push(x);
            }
            ordinals = newOrdinals;
        }

        if (!over || active.id === over.id) return;

        const oldIndex = playbookPages.findIndex(
            (item) => item.id === active.id
        );
        const newIndex = playbookPages.findIndex(
            (item) => item.id === over?.id
        );

        playbookPages = arrayMove(playbookPages, oldIndex, newIndex).map(
            (page, index) => {
                return { ...page, ordinal: ordinals[index] };
            }
        );

        setPlaybookItems(playbookPages.concat(autoDetected));

        let inputs = '';
        let playbookQueries = '';
        interface Query {
            [key: string]: {};
        }
        let variables: Query = {};
        playbookPages
            .filter((page) => !!page.id)
            .forEach((page, index) => {
                inputs += `$input${index + 1}: UpdatePlaybookPageInput!`;
                playbookQueries += `playbook${
                    index + 1
                }: updatePlaybookPage(input: $input${
                    index + 1
                }, condition: $condition) {
                    organisation
                    id
                  }`;
                variables[`input${index + 1}`] = {
                    organisation: currentWorkbook?.organisation,
                    id: page.id,
                    ordinal: page.ordinal,
                };
            });
        const pageQuery = `mutation UpdatePlaybookPage(
                ${inputs}
                $condition: ModelPlaybookPageConditionInput
              ) {
               ${playbookQueries}
              }
              `;
        const res = (await client.graphql({
            query: pageQuery,
            variables: variables,
        })) as { data: CreatePlaybookPageMutation };
    };

    const handleDragStart = (event: DragStartEvent) => {
        setGrab(event.active.id as string);
    };

    return (
        <>
            {showDrawer === DrawStates.CLOSED && (
                <Box
                    sx={{
                        background:
                            theme.palette.mode === 'light'
                                ? '#f0f2f5'
                                : '#3A3E46',
                        height: 'fit-content',
                        display: 'flex',
                        position: 'fixed',
                        right: '0px',
                        top: '250px',
                        writingMode: 'vertical-rl',
                        padding: '1rem .5rem',
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                        cursor: 'pointer',
                        alignItems: 'center',
                        border:
                            theme.palette.mode === 'dark'
                                ? 'solid 1px #5d606c'
                                : 'none',
                        borderRight: 'none',
                    }}
                    onClick={() => setShowDrawer()}
                >
                    <Typography variant="h5">Worksheets</Typography>
                </Box>
            )}
            <Box
                sx={{
                    minHeight: '800px',
                    width:
                        showDrawer === DrawStates.FULL
                            ? 'calc(103rem + 100px)'
                            : showDrawer === DrawStates.CLOSED
                            ? 0
                            : 'calc((100vw - 1520px)/2 + 722px)',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: showDrawer === DrawStates.FULL ? 0 : '30px',
                    position: 'fixed',
                    right: 0,
                    zIndex: 1300,
                }}
                ref={containerWidth}
                className="workbook-drawer"
            >
                <Box
                    sx={{
                        display:
                            showDrawer === DrawStates.CLOSED ? 'none' : 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            background:
                                theme.palette.mode === 'light'
                                    ? '#f0f2f5'
                                    : '#3A3E46',
                            display: 'flex',
                            border:
                                theme.palette.mode === 'dark'
                                    ? 'solid 1px #5d606c'
                                    : 'none',
                            borderBottom: 'none',
                            borderTopRightRadius: 10,
                            borderTopLeftRadius: 10,
                            width: 'fit-content',
                            padding: '5px 15px',
                            cursor: 'pointer',
                            alignItems: 'center',
                        }}
                        onClick={() => setShowDrawer()}
                    >
                        <Typography variant="h5">Worksheets</Typography>
                        {playbookItems.filter((item) => !item.viewed).length >
                            0 && (
                            <Box
                                sx={{
                                    background:
                                        playbookItems.filter(
                                            (item) => !item.viewed
                                        ).length > 0
                                            ? 'red'
                                            : 'grey',
                                    width: '20px',
                                    height: '20px',
                                    paddingTop: '2px',
                                    marginTop: '2px',
                                    color: '#fff',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 600,
                                    marginLeft: '10px',
                                }}
                            >
                                {
                                    playbookItems.filter((item) => !item.viewed)
                                        .length
                                }
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box
                    sx={{
                        display:
                            showDrawer === DrawStates.CLOSED ? 'none' : 'block',
                        background: theme.palette.background.default,
                        borderLeft:
                            theme.palette.mode === 'light'
                                ? 'solid 2px rgba(240, 242, 245, 0.9)'
                                : 'solid 1px #5d606c',
                        borderTop:
                            theme.palette.mode === 'light'
                                ? 'solid 2px rgba(240, 242, 245, 0.9)'
                                : 'solid 1px #5d606c',
                        height: '700px',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        cursor: grab ? 'grabbing' : 'pointer',
                    }}
                >
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext
                            items={playbookItems
                                .filter((item) => !!item.id)
                                .sort(
                                    (a, b) =>
                                        (a.ordinal ?? 1) - (b.ordinal ?? 1)
                                )}
                        >
                            {!drawSliding ? (
                                <>
                                    {playbookItems
                                        .filter(
                                            (item) =>
                                                !!item.id &&
                                                !removedItems.includes(
                                                    item.worksheetId as string
                                                )
                                        )
                                        .sort(
                                            (a, b) =>
                                                (a.ordinal ?? 1) -
                                                (b.ordinal ?? 1)
                                        )
                                        .map((page, index) => {
                                            return (
                                                <SortableListItem id={page.id}>
                                                    <WorkBookDrawerPage
                                                        addSuggestedItem={
                                                            addSuggestedItem
                                                        }
                                                        items={playbookItems}
                                                        setPlaybookItems={setPlaybookItems}
                                                        page={page}
                                                        index={index}
                                                        single={
                                                            playbookItems.length ===
                                                            1
                                                        }
                                                        key={page?.name}
                                                        updateItem={(item) =>
                                                            updateItem(item)
                                                        }
                                                        updated={updated}
                                                        removeItem={(id) =>
                                                            removeFromDrawer(id)
                                                        }
                                                        pageConfig={pageConfig}
                                                        fullWidth={
                                                            showDrawer ===
                                                            DrawStates.FULL
                                                        }
                                                        workbookId={id}
                                                        openDrawerItem={
                                                            openDrawerItem
                                                        }
                                                        setOpenDrawerItem={
                                                            setOpenDrawerItem
                                                        }
                                                        grab={grab}
                                                    />
                                                </SortableListItem>
                                            );
                                        })}

                                    {autoDetected.map((page, index) => {
                                        return (
                                            <WorkBookDrawerPage
                                                addSuggestedItem={
                                                    addSuggestedItem
                                                }
                                                items={playbookItems}
                                                setPlaybookItems={setPlaybookItems}
                                                single={
                                                    playbookItems.length === 1
                                                }
                                                page={page}
                                                index={index}
                                                key={page?.name}
                                                updateItem={(item) =>
                                                    updateItem(item)
                                                }
                                                removeItem={(id) =>
                                                    removeFromDrawer(id)
                                                }
                                                updated={updated}
                                                pageConfig={pageConfig}
                                                fullWidth={
                                                    showDrawer ===
                                                    DrawStates.FULL
                                                }
                                                workbookId={id}
                                                openDrawerItem={openDrawerItem}
                                                setOpenDrawerItem={
                                                    setOpenDrawerItem
                                                }
                                            />
                                        );
                                    })}
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <CircularProgress size={70} />
                                </Box>
                            )}
                            {permissions.includes(UserPermissions.EDIT) && (
                                <AddWorksheetDrawer
                                    workbookId={id}
                                    currentWorkbook={currentWorkbook}
                                    refreshWorkbookDrawer={(worksheet) => {
                                        setUpdated(false);
                                        addNewWorksheet(worksheet);
                                    }}
                                    playbookDrawerItems={playbookDrawerItems}
                                    count={playbookItems?.length ?? 0}
                                />
                            )}
                        </SortableContext>
                    </DndContext>
                </Box>
            </Box>
        </>
    );
};

export default WorkBookDrawer;
