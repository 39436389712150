import { Box, Typography } from '@mui/material';
import { size } from 'cypress/types/lodash';
import { WorksheetSize } from '../../globals';

interface ImpactLegendProps {
    size: WorksheetSize;
    colors: { score: number; value: string }[];
}

const BusinessImpactLegend = ({ size, colors }: ImpactLegendProps) => {
    const labels = ['High Negative', 'No Impact', 'High Positive'];

    return (
        <Box
            sx={{
                width: '300px',
                display: 'flex',
                flexDirection: 'column',
                marginTop: size === WorksheetSize.SMALL ? '10px' : '35px',
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    fontSize:
                        size === WorksheetSize.SMALL ? '0.5rem' : 'inherit',
                }}
            >
                Business Impact
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    marginTop: size === WorksheetSize.SMALL ? '2px' : '8px',
                }}
            >
                {colors.map((color) => {
                    return (
                        <Box
                            sx={{
                                marginRight:
                                    size === WorksheetSize.SMALL
                                        ? '3px'
                                        : '5px',
                            }}
                        >
                            <Box
                                sx={{
                                    width:
                                        size === WorksheetSize.SMALL
                                            ? '15px'
                                            : '22px',
                                    height:
                                        size === WorksheetSize.SMALL
                                            ? '15px'
                                            : '22px',
                                    borderRadius:
                                        size === WorksheetSize.SMALL
                                            ? '2px'
                                            : '4px',
                                    background: color.value,
                                }}
                            />
                            <Typography
                                variant="body2"
                                fontSize={
                                    size === WorksheetSize.SMALL
                                        ? '0.4rem'
                                        : '0.7rem'
                                }
                                sx={{
                                    textAlign: 'center',
                                    width: '100%',
                                    marginTop:
                                        size === WorksheetSize.SMALL
                                            ? '2px'
                                            : '6px',
                                }}
                            >
                                {color.score > 0
                                    ? `+${color.score}`
                                    : color.score}
                            </Typography>
                        </Box>
                    );
                })}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: '15px',
                }}
            >
                {labels.map((label) => {
                    return (
                        <Typography
                            variant="body2"
                            fontSize="0.7rem"
                            sx={{
                                textAlign: 'center',
                                lineHeight: '0.85rem',
                                width: '47px',
                            }}
                        >
                            {label}
                        </Typography>
                    );
                })}
            </Box>
        </Box>
    );
};

export default BusinessImpactLegend;
