import { AlertColor } from '@mui/material';
import { useState } from 'react';

interface SnackbarOptions {
    message: string;
    duration?: number;
    severity?: AlertColor;
}

const useSnackbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [duration, setDuration] = useState(3000);
    const [severity, setSeverity] = useState<AlertColor>('info');

    const showSnackbar = ({ message, duration, severity }: SnackbarOptions) => {
        setMessage(message);
        setDuration(duration || 3000);
        setSeverity(severity || 'info');
        setIsOpen(true);
    };

    const closeSnackbar = () => {
        setIsOpen(false);
    };

    return {
        isOpen,
        message,
        duration,
        severity,
        showSnackbar,
        closeSnackbar,
    };
};

export default useSnackbar;
