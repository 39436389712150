import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface HelpHeaderProps {
    title: string | null | undefined;
    color: string;
    textColor: string;
    onClose: React.Dispatch<
        React.SetStateAction<HTMLElement | null | undefined>
    >;
}
const HelpHeader = ({ title, color, textColor, onClose }: HelpHeaderProps) => {
    return (
        <Box
            sx={{
                height: '50px',
                backgroundColor: color,
                px: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Typography variant="h5" sx={{ color: textColor }}>
                {title}
            </Typography>
            <IconButton
                aria-label="close"
                onClick={() => onClose(null)}
                sx={{
                    color: textColor,
                    mr: '-20px',
                    mb: '10px',
                }}
                size="large"
            >
                <CloseIcon />
            </IconButton>
        </Box>
    );
};

export default HelpHeader;
