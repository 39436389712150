import { ReactNode, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../contexts';
import { Typography } from '@mui/material';
import MuiSelect, {
    SelectProps as MuiSelectProps,
    SelectChangeEvent,
} from '@mui/material/Select';
import { CardFieldProps, CardSetFieldProps } from './inputFieldTypes';
import SelectField from './SelectField';
import {
    AttributeType,
    Card,
    CardSet,
    CardsOrderedByNameQuery,
    CardSetsOrderedByTypeAndNameQuery,
    ModelSortDirection,
} from '../../../API';

import { generateClient } from 'aws-amplify/api';
import {
    cardsOrderedByName,
    cardSetsOrderedByTypeAndName,
} from '../../../graphql/queries';
import { getUserOrganisation } from '../../../helpers/auth';

interface CardProps {
    id?: string;
    value?: string;
    onChange?: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
    exclude?: string;
}

const CardField = (
    props: (CardFieldProps & CardProps) | (CardSetFieldProps & CardProps)
) => {
    const { value, onChange, exclude } = props;
    const { users, user } = useContext(AppContext);
    const [cards, setCards] = useState<Card[] | CardSet[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    const client = generateClient();

    const query =
        props.type === 'Card'
            ? cardsOrderedByName
            : cardSetsOrderedByTypeAndName;

    useEffect(() => {
        const getCards = async () => {
            const userGroup = getUserOrganisation(user);

            const filter: {
                cardToCardTypeId?: { eq: string };
                cardToCardCategoryId?: { eq: string };
                cardSetToCardTypeId?: { eq: string };
                cardSetToCardCategoryId?: { eq: string };
                deleteAfter?: { attributeExists: boolean };
            }[] = [];

            props.values?.forEach((value) => {
                if (props.type === 'CardSet') {
                    filter.push({
                        cardSetToCardTypeId: { eq: value as string },
                    });
                } else {
                    filter.push({
                        cardToCardTypeId: { eq: value as string },
                        deleteAfter: { attributeExists: false },
                    });
                }
            });

            if (props.type === 'Card') {
                let allCards: Card[] = [];
                let nextToken = null;

                do {
                    const cards = (await client.graphql({
                        query: query,
                        variables: {
                            organisation: userGroup,
                            filter: {
                                or: filter,
                                not: { id: { eq: exclude ?? '' } },
                            },
                            sortDirection: ModelSortDirection.ASC,
                            nextToken: nextToken,
                        },
                    })) as { data: CardsOrderedByNameQuery };

                    allCards = [
                        ...allCards,
                        ...(cards?.data?.cardsOrderedByName?.items as Card[]),
                    ];
                    nextToken = cards?.data?.cardsOrderedByName?.nextToken;
                } while (nextToken);

                setCards(allCards);
                setLoaded(true);
            } else {
                let allCardSets: CardSet[] = [];
                let nextToken = null;

                do {
                    const cards = (await client.graphql({
                        query: query,
                        variables: {
                            organisation: userGroup,
                            filter: {
                                or: filter,
                                not: { id: { eq: exclude ?? '' } },
                            },
                            sortDirection: ModelSortDirection.ASC,
                            nextToken: nextToken,
                        },
                    })) as { data: CardSetsOrderedByTypeAndNameQuery };

                    allCardSets = [
                        ...allCardSets,
                        ...(cards?.data?.cardSetsOrderedByTypeAndName
                            ?.items as CardSet[]),
                    ];
                    nextToken =
                        cards?.data?.cardSetsOrderedByTypeAndName?.nextToken;
                } while (nextToken);

                setCards(allCardSets);
                setLoaded(true);
            }
        };
        if (user) {
            getCards();
        }
    }, [user]);

    return (
        <>
            {cards.length > 0 ? (
                <SelectField
                    options={cards.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    })}
                    {...props}
                    defaultValue={value}
                    value={value}
                    type={AttributeType.SingleSelect}
                />
            ) : (
                <SelectField
                    sx={{ background: 'grey' }}
                    {...props}
                    options={[]}
                    type={AttributeType.SingleSelect}
                    disabled={loaded ? true : false}
                    unAvailable="- No cards available"
                    label={props.label}
                />
            )}
        </>
    );
};

export default CardField;
