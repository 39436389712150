import { Typography, useTheme } from '@mui/material';
import { StyledSection } from '../../StyledElements';
import { StyledTextField } from '../../forms/FormElements';
import { AttributeType, Card, CardSet } from '../../../API';
import TextField from '../../forms/input-fields/TextField';
import React from 'react';

interface MainProps {
    selectedCard?: Card | CardSet | null;
    cardObject?: Card | CardSet | null;
    edit?: boolean;
    setCardObject: (card: Card | CardSet | null) => void;
    isCard?: boolean;
    selectedType?: string;
    worksheetCard?: any;
}
const Main = ({
    selectedCard,
    edit,
    cardObject,
    setCardObject,
    isCard,
    selectedType,
    worksheetCard,
}: MainProps) => {
    const theme = useTheme();
    return (
        <>
            {selectedCard ? (
                <>
                    <StyledSection
                        sx={{
                            border:
                                selectedType === 'name'
                                    ? 'solid 2px blue'
                                    : 'inherit',
                        }}
                    >
                        <Typography
                            variant="h6"
                            fontSize={worksheetCard ? '1.15em' : '1.25em'}
                        >
                            Name
                        </Typography>

                        <Typography
                            variant="body2"
                            fontSize="0.875em"
                            style={{
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                                whiteSpace: 'normal',
                            }}
                        >
                            {selectedCard?.name}
                        </Typography>
                    </StyledSection>

                    <StyledSection>
                        <Typography
                            variant="h6"
                            fontSize={worksheetCard ? '1.15em' : '1.25em'}
                        >
                            Brief description
                        </Typography>

                        <Typography variant="body2" fontSize="0.875em">
                            {selectedCard?.briefDescription}
                        </Typography>
                    </StyledSection>

                    <StyledSection>
                        <Typography
                            variant="h6"
                            fontSize={worksheetCard ? '1.15em' : '1.25em'}
                        >
                            Full description
                        </Typography>

                        <Typography variant="body2" fontSize="0.875em">
                            {selectedCard?.fullDescription}
                        </Typography>
                        <Typography
                            variant="body2"
                            fontSize="0.875em"
                            component="div"
                        ></Typography>
                    </StyledSection>
                </>
            ) : (
                <>
                    <StyledSection
                        {...(selectedType === 'name' && {
                            sx: {
                                padding: '10px',
                                border: `solid 2px ${theme.palette.primary.main}`,
                            },
                        })}
                    >
                        {!edit || !cardObject ? (
                            <>
                                <Typography
                                    variant="h6"
                                    fontSize={
                                        worksheetCard ? '1.15em' : '1.25em'
                                    }
                                >
                                    Name
                                </Typography>
                                <Typography
                                    variant="body2"
                                    fontSize="0.875em"
                                    style={{
                                        wordWrap: 'break-word',
                                        overflowWrap: 'break-word',
                                        whiteSpace: 'normal',
                                    }}
                                >
                                    {cardObject?.name}
                                </Typography>
                            </>
                        ) : (
                            <TextField
                                onChange={(event) =>
                                    setCardObject({
                                        ...cardObject,
                                        name: event.target.value,
                                    })
                                }
                                type={AttributeType.Text}
                                name="name"
                                label="Name"
                                required={true}
                                value={cardObject.name}
                                size="small"
                            />
                        )}
                    </StyledSection>

                    <StyledSection
                        {...(selectedType === 'briefDescription' && {
                            sx: {
                                padding: '10px',
                                border: `solid 2px ${theme.palette.primary.main}`,
                            },
                        })}
                    >
                        {!edit || !cardObject ? (
                            <>
                                <Typography
                                    variant="h6"
                                    fontSize={
                                        worksheetCard ? '1.15em' : '1.25em'
                                    }
                                >
                                    Brief description
                                </Typography>
                                <Typography variant="body2" fontSize="0.875em">
                                    {cardObject?.briefDescription
                                        ? cardObject?.briefDescription
                                        : 'No value'}
                                </Typography>
                            </>
                        ) : (
                            <TextField
                                onChange={(event) =>
                                    setCardObject({
                                        ...cardObject,
                                        briefDescription: event.target.value,
                                    })
                                }
                                type={AttributeType.Text}
                                value={cardObject.briefDescription ?? ''}
                                name="briefDescription"
                                label="Brief description"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    </StyledSection>

                    {(cardObject?.__typename === 'CardSet' &&
                        cardObject.type === 'WB') ||
                    isCard ? (
                        <StyledSection
                            {...(selectedType === 'fullDescription' && {
                                sx: {
                                    padding: '10px',
                                    border: `solid 2px ${theme.palette.primary.main}`,
                                },
                            })}
                        >
                            {!edit || !cardObject ? (
                                <>
                                    <Typography
                                        variant="h6"
                                        fontSize={
                                            worksheetCard ? '1.15em' : '1.25em'
                                        }
                                    >
                                        Full description
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontSize="0.875em"
                                        sx={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        {cardObject?.fullDescription}
                                    </Typography>
                                </>
                            ) : (
                                <TextField
                                    onChange={(event) =>
                                        setCardObject({
                                            ...cardObject,
                                            fullDescription: event.target.value,
                                        })
                                    }
                                    type={AttributeType.Text}
                                    rows={5}
                                    multiline
                                    label="Full description"
                                    name="fullDescription"
                                    value={cardObject.fullDescription ?? ''}
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        </StyledSection>
                    ) : (
                        ''
                    )}
                </>
            )}
        </>
    );
};

export default Main;
