import { Box, Link, Typography, useTheme } from '@mui/material';
import {
    Attribute,
    AttributeDefinition,
    AttributeType,
    Card,
    CardSet,
    Playbook,
} from '../../../API';
import { useContext } from 'react';
import { DateTime } from 'luxon';
import { StyledSection } from '../../StyledElements';
import InputFromAttribute from '../../forms/input-fields/InputFromAttribute';
import { getFormSchemaPropType } from '../../forms/utils';
import { isJsonString } from '../../../helpers/utils';
import { AppContext } from '../../contexts';
import CardDetailsCard from './CardDetailsCard';
import CardsDetailsCard from './CardsDetailsCard';

export interface MockEvent {
    target: {
        value: string | unknown;
    };
}

export type ChangeEvent = MockEvent | null | DateTime;

interface AttributeSectionProps {
    card: Card | CardSet | Playbook;
    selectedCard: Card | CardSet | null;
    cardObject: Card | null;
    edit: boolean;
    updateAttributes: (event: ChangeEvent, name: string) => void;
    selectedType?: string;
    worksheetCard?: any;
}

const AttributeSection = ({
    selectedCard,
    cardObject,
    card,
    edit,
    updateAttributes,
    selectedType,
    worksheetCard,
}: AttributeSectionProps) => {
    const { cardTypeObject, users } = useContext(AppContext);

    const theme = useTheme();

    const { attributeDefinitions } =
        cardTypeObject[cardObject?.cardToCardTypeId ?? -1] ?? {};

    const getSelectedCardAttributes = () => {
        if (selectedCard?.__typename === 'Card') {
            const { attributeDefinitions } =
                cardTypeObject[selectedCard?.cardToCardTypeId ?? -1] ?? {};

            return attributeDefinitions ?? [];
        }

        return [];
    };

    const getSelectValue = (id: string | boolean): string => {
        if (!cardObject?.attributes || !cardObject?.attributes.length)
            return '';

        const index = cardObject?.attributes?.findIndex(
            (attribute: Attribute | null) =>
                attribute?.attributeDefinitionID === id
        );
        if (index === -1) return '';

        const returnValue = cardObject?.attributes[index]?.value ?? '';

        return isJsonString(returnValue)
            ? JSON.parse(returnValue).split('"').join('')
            : returnValue;
    };

    const getValue = (id: string, attributeType: AttributeType) => {
        const cardObj = card as Card;
        const attr =
            selectedCard && selectedCard.__typename === 'Card'
                ? selectedCard?.attributes?.find(
                      (attr) => attr?.attributeDefinitionID === id
                  )
                : cardObj?.attributes?.find(
                      (attr) => attr?.attributeDefinitionID === id
                  );

        if (attr) {
            if (attributeType === 'URL') {
                if (attr?.value) {
                    let url = attr.value.replace(/"/g, '');
                    if (
                        !url.startsWith('http://') &&
                        !url.startsWith('https://')
                    ) {
                        url = 'http://' + url;
                    }
                    return (
                        <Link
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Typography variant="body2" fontSize="0.875em">
                                {url.replace(/^https?:\/\//, '')}
                            </Typography>
                        </Link>
                    );
                } else {
                    return 'URL not provided';
                }
            } else if (attributeType === 'User') {
                const user = users.find((user) => user.id === attr?.value);
                if (user) {
                    return `${user.firstName} ${user.lastName}`;
                }
            } else if (
                attributeType === 'Card' ||
                attributeType === 'CardSet'
            ) {
                return (
                    <CardDetailsCard
                        id={attr.value ?? ''}
                        type={attributeType}
                    />
                );
            } else if (attributeType === 'Cards') {
                return (
                    <CardsDetailsCard
                        values={attr.value}
                        type={attributeType}
                    />
                );
            } else {
                return attr?.value?.replace(/["']/g, '');
            }
        } else {
            return 'No value';
        }
    };

    return (
        <>
            {selectedCard ? (
                <>
                    {selectedCard.__typename === 'Card' &&
                        getSelectedCardAttributes().map((attr) => {
                            return (
                                <StyledSection
                                    key={attr?.id}
                                    {...(selectedType === attr?.id && {
                                        sx: {
                                            padding: '10px',
                                            border: `solid 2px ${theme.palette.primary.main}`,
                                        },
                                    })}
                                >
                                    <Typography
                                        variant="h6"
                                        fontSize={
                                            worksheetCard ? '1.15em' : '1.25em'
                                        }
                                    >
                                        {attr?.name}
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        fontSize="0.875em"
                                        sx={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        {getValue(
                                            attr?.id as string,
                                            attr?.attributeType as AttributeType
                                        )}
                                    </Typography>
                                </StyledSection>
                            );
                        })}
                </>
            ) : !edit || !cardObject ? (
                <>
                    {attributeDefinitions?.map((attr) => {
                        return (
                            <StyledSection
                                key={attr?.id}
                                {...(selectedType === attr?.id && {
                                    sx: {
                                        padding: '10px',
                                        border: `solid 2px ${theme.palette.primary.main}`,
                                    },
                                })}
                            >
                                <Typography
                                    variant="h6"
                                    fontSize={
                                        worksheetCard ? '1.15em' : '1.25em'
                                    }
                                >
                                    {
                                        attributeDefinitions?.find(
                                            (attrDef) =>
                                                attrDef?.id === attr?.id
                                        )?.name
                                    }
                                </Typography>

                                <Typography
                                    variant="body2"
                                    fontSize="0.875em"
                                    sx={{ whiteSpace: 'pre-wrap' }}
                                >
                                    {getValue(
                                        attr?.id as string,
                                        attr?.attributeType as AttributeType
                                    )}
                                </Typography>
                            </StyledSection>
                        );
                    })}
                </>
            ) : (
                <>
                    {attributeDefinitions?.map((attribute, i: number) => {
                        return (
                            attribute && (
                                <Box
                                    key={attribute.id}
                                    sx={{
                                        '.MuiFormControl-root': {
                                            width: '100%',
                                            marginBottom: '2rem',
                                            legend: { display: 'none' },
                                        },
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {
                                        <InputFromAttribute
                                            {...getFormSchemaPropType(
                                                attribute as AttributeDefinition
                                            )}
                                            {...(attribute.attributeType !==
                                                AttributeType.MultiSelect && {
                                                onChange: (
                                                    event: ChangeEvent
                                                ) =>
                                                    updateAttributes(
                                                        event,
                                                        attribute.id
                                                    ),
                                            })}
                                            {...(attribute.attributeType ===
                                                AttributeType.Card && {
                                                exclude: card.id,
                                            })}
                                            {...(attribute.attributeType ===
                                                AttributeType.CardSet && {
                                                exclude: card.id,
                                            })}
                                            selectValue={getSelectValue(
                                                attribute.id
                                            )}
                                            name={`attributes.${i}.value`}
                                        />
                                    }
                                </Box>
                            )
                        );
                    })}
                </>
            )}
        </>
    );
};

export default AttributeSection;
