import React, {
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Line } from 'react-chartjs-2';
import { generateClient } from 'aws-amplify/api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    ActiveElement,
} from 'chart.js';
import { DateTime } from 'luxon';
import { createLineChartData } from '../../../helpers/charts';
import { dateFromISO, isValid } from '../../../helpers/utils';
import {
    CreateOrUpdateScoreDataMutation,
    ScoreData,
    ScoreDatum,
    ScoreDefinition,
} from '../../../API';
import { createOrUpdateScoreData } from '../../../graphql/mutations';
import { getLineChartOptions, customTitle } from '../LineChartOptions';
import ScoreWithTrend from '../ScoreDisplay';
import ScoreItem from './ScoreItem';
import IndicatorChart from '../IndicatorChart';
import IndicatorItem from '../EditableIndicator/IndicatorItem';
import ChartForm from './ChartForm';
import IndicatorForm from './IndicatorForm';
import { AppContext } from '../../contexts';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title
);

interface ChartOptionsConfig {
    reverse?: boolean;
    highlightIndex?: number;
    suggestedMin?: number;
    suggestedMax?: number;
}

const getDates = (scoreData: ScoreDatum[]): DateTime[] => {
    return scoreData.map((value) => DateTime.fromISO(value.date));
};

interface EditableChartProps {
    organisation: string;
    id: string;
    // Only exists for submission
    scoreData?: ScoreData | null;
    scoreDefinition: ScoreDefinition;
    handleClose?: () => void;
    onUpdate?: (id: string, organisation: string) => void;
}

const EditableChart = ({
    organisation,
    id,
    scoreDefinition,
    scoreData,
    handleClose,
    onUpdate,
}: PropsWithChildren<EditableChartProps>) => {
    const theme = useTheme();
    const { users } = useContext(AppContext);
    const { minimumValue, maximumValue, scoreType } = scoreDefinition;
    const min = minimumValue != null ? minimumValue : undefined;
    const max = maximumValue != null ? maximumValue : undefined;
    const reverseMaxMin = scoreDefinition.target === 'Min';

    const client = generateClient();

    const { data } = scoreData ?? { data: [] };

    const [open, setOpen] = useState(false);

    // The main state of the form
    const [editedScoreData, setEditedScoreData] = useState<ScoreDatum[]>(data);

    //Check if user already submitted form in which case use update query
    const [submitted, setSubmitted] = useState<boolean>(false);

    // Storing all dates in a date format in the correct order
    const [datesInChart, setDatesInChart] = useState<DateTime[]>(
        getDates(data)
    );
    // Chart state generated by Edited fields from a useEffect
    const [chartData, setChartData] = useState<ChartData<'line'>>(
        createLineChartData({
            reverse: scoreDefinition.target === 'Min' ? true : false,
            data: scoreData?.data,
            users,
            scoreType,
        })
    );
    // The latest value to be added
    const [newScoreDatum, setNewScoreDatum] = useState<ScoreDatum>({
        __typename: 'ScoreDatum',
        date: '',
        value: 0,
    });
    // Store last created field to provide focus on render
    const [lastCreatedDate, setLastCreatedDate] = useState<string>();
    // The increment value to step the chart forward
    const [increment, setIncrement] = useState('Month');
    // The index of the currently focused field
    const [focusedIndex, setFocusedIndex] = useState<number | null>();

    const [dataChanged, setDataChanged] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setChartData(
            createLineChartData({
                reverse: scoreDefinition.target === 'Min' ? true : false,
                data: editedScoreData,
                users,
                scoreType,
            })
        );
        setDatesInChart(getDates(editedScoreData));
    }, [editedScoreData]);

    useEffect(() => {
        const date = new Date();

        setNewScoreDatum({
            ...newScoreDatum,
            date: date.toISOString().split('T')[0],
        });
    }, []);
    const handleNewDateInputChange = (date: DateTime | null) => {
        if (date?.month) {
            const isoDate = date?.toISODate();

            if (isoDate != null) {
                setNewScoreDatum({ ...newScoreDatum, date: isoDate });
            }
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.BaseSyntheticEvent,
        i: number
    ) => {
        const newScoreData = [...editedScoreData];
        if (e.target.name === 'comment') {
            newScoreData[i].comment = e.target.value;
        } else {
            if (
                !isValid(
                    Math.round(parseFloat(e.target.value)),
                    min,
                    max,
                    reverseMaxMin
                )
            )
                return;
            newScoreData[i].value = Number(e.target.value);
        }

        setEditedScoreData(newScoreData);
        setDataChanged(true);
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        let data = [...editedScoreData];

        const date = new Date();

        const variables = {
            input: {
                data: data.map((item) => {
                    return {
                        date: item.date,
                        value: item.value,
                        comment: item.comment,
                        commentDate: item.commentDate,
                        commentAuthor: item.commentAuthor,
                    };
                }),
                cardId: id,
                organisation: organisation,
                scoreDefinitionId: scoreDefinition.id,
            },
        };
        try {
            if (scoreData?.cardId != null || submitted) {
                (await client.graphql({
                    query: createOrUpdateScoreData,
                    variables: variables,
                })) as { data: CreateOrUpdateScoreDataMutation };
            } else {
                (await client.graphql({
                    query: createOrUpdateScoreData,
                    variables: variables,
                })) as { data: CreateOrUpdateScoreDataMutation };
                setSubmitted(true);
            }
            onUpdate && onUpdate(id, organisation);
        } catch (err) {
            console.log(err);
            //!!handleClose && handleClose();
        }
        handleClose &&
            setTimeout(() => {
                setLoading(false);
                handleClose();
            }, 500);
    };

    const insertDate = () => {
        let updatedFields: ScoreDatum[] = [];
        let inserted = false;
        setDataChanged(true);

        if (editedScoreData.length === 0) {
            setEditedScoreData([newScoreDatum]);
            setNewScoreDatum({
                __typename: 'ScoreDatum',
                date: new Date().toISOString().split('T')[0],
                comment: '',
                value: 0,
            });

            return;
        }

        setNewScoreDatum({
            __typename: 'ScoreDatum',
            date: new Date().toISOString().split('T')[0],
            comment: '',
            value: 0,
        });

        if (editedScoreData.find((item) => item.date === newScoreDatum.date)) {
            let data = [...editedScoreData];
            const index = data.findIndex(
                (item) => item.date === newScoreDatum.date
            );
            data[index].value = newScoreDatum.value;

            setLastCreatedDate(newScoreDatum.date);

            setEditedScoreData(data);
        } else {
            editedScoreData.forEach((value, i) => {
                const newDate = dateFromISO(newScoreDatum.date);

                if (
                    (i === 0 && newScoreDatum.date < value.date) ||
                    (newDate > datesInChart[i - 1] && newDate < datesInChart[i])
                ) {
                    updatedFields.push(newScoreDatum);
                    updatedFields.push(value);

                    inserted = true;
                } else if (!inserted && !datesInChart[i + 1]) {
                    updatedFields.push(value);
                    updatedFields.push(newScoreDatum);

                    inserted = true;
                } else {
                    updatedFields.push(value);
                }
            });

            setLastCreatedDate(newScoreDatum.date);

            setEditedScoreData(updatedFields);
        }
    };

    const disableDate = (date: DateTime): boolean => {
        return !!datesInChart.find(
            (existingDate) =>
                existingDate.month === date.month &&
                existingDate.year === date.year
        );
    };

    const deleteScoreDatum = (i: number) => {
        const updatedData = [...editedScoreData];
        updatedData.splice(i, 1);

        setEditedScoreData(updatedData);
        setDataChanged(true);
    };

    const handleSelect = (element: ActiveElement) => {
        if ((element && !!element.index) || element?.index === 0) {
            setFocusedIndex(element.index);

            setTimeout(() => {
                setFocusedIndex(null);
            }, 800);
            setOpen(true);
        }
    };

    return (
        <>
            <Box sx={{ height: '40%', mb: 0, pt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h4" pb="0.500rem">
                        {scoreDefinition.name}
                    </Typography>

                    <ScoreWithTrend
                        scoreData={editedScoreData}
                        scoreDefinition={scoreDefinition}
                        reverse={reverseMaxMin}
                    />
                </Box>
                {scoreDefinition.scoreType === 'Indicator' ? (
                    <>
                        <Typography variant="h6" pb="0.500rem" sx={{ mb: 4 }}>
                            {scoreDefinition.description}
                        </Typography>
                        <IndicatorChart data={editedScoreData} />
                    </>
                ) : (
                    <Box sx={{ canvas: { height: '270px' } }}>
                        <Line
                            id="linechart"
                            options={getLineChartOptions({
                                type: scoreDefinition.scoreType,
                                reverse:
                                    scoreDefinition.target === 'Min'
                                        ? true
                                        : false,
                                onClick: (ele) => handleSelect(ele),
                                ...(focusedIndex != null && {
                                    highlightIndex: focusedIndex,
                                }),
                                suggestedMin: min,
                                suggestedMax: max,
                                comments: editedScoreData.map(
                                    (item) => item.comment ?? ''
                                ),
                            })}
                            plugins={[customTitle]}
                            data={chartData}
                        />
                    </Box>
                )}
            </Box>
            <form onSubmit={onSubmit}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        pt: scoreDefinition.scoreType === 'Indicator' ? 0 : 2,
                    }}
                >
                    {scoreDefinition.scoreType === 'Indicator' ? (
                        <IndicatorForm
                            newScoreDatum={newScoreDatum}
                            handleNewDateInputChange={handleNewDateInputChange}
                            disableDate={disableDate}
                            setNewScoreDatum={setNewScoreDatum}
                            insertDate={insertDate}
                        />
                    ) : (
                        <ChartForm
                            newScoreDatum={newScoreDatum}
                            handleNewDateInputChange={handleNewDateInputChange}
                            disableDate={disableDate}
                            setNewScoreDatum={setNewScoreDatum}
                            reverseMaxMin={reverseMaxMin}
                            insertDate={insertDate}
                            scoreType={scoreType}
                            min={min}
                            max={max}
                        />
                    )}
                    <Accordion
                        expanded={open}
                        onChange={() => setOpen(!open)}
                        sx={{
                            background:
                                theme.palette.mode === 'dark'
                                    ? theme.palette.background.default
                                    : '#f6f6f6',
                            paddingLeft: '8px',
                            '&.Mui-expanded': { margin: 0 },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panelscores-content"
                            id="panelscores-header"
                            sx={{ '&.Mui-expanded': { minHeight: '48px' } }}
                        >
                            <Typography sx={{ width: '33%', fontWeight: 600 }}>
                                Previous scores
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexShrink: 1,
                                    flexDirection: 'column',
                                    overflow: 'auto',
                                    mr: 1,
                                    minHeight: '7rem',
                                }}
                            >
                                {editedScoreData.map((scoreDatum, i) => {
                                    const date = new Date(scoreDatum.date);
                                    return (
                                        <Box>
                                            {scoreDefinition.scoreType ===
                                            'Indicator' ? (
                                                <IndicatorItem
                                                    key={`${date}-${i}`}
                                                    focusedIndex={focusedIndex}
                                                    scoreDatum={scoreDatum}
                                                    date={date}
                                                    handleInputChange={
                                                        handleInputChange
                                                    }
                                                    i={i}
                                                    scoreType={scoreType}
                                                    reverseMaxMin={
                                                        reverseMaxMin
                                                    }
                                                    max={max}
                                                    min={min}
                                                    deleteScoreDatum={
                                                        deleteScoreDatum
                                                    }
                                                />
                                            ) : (
                                                <ScoreItem
                                                    key={`${date}-${i}`}
                                                    focusedIndex={focusedIndex}
                                                    scoreDatum={scoreDatum}
                                                    date={date}
                                                    handleInputChange={
                                                        handleInputChange
                                                    }
                                                    i={i}
                                                    scoreType={scoreType}
                                                    reverseMaxMin={
                                                        reverseMaxMin
                                                    }
                                                    max={max}
                                                    min={min}
                                                    deleteScoreDatum={
                                                        deleteScoreDatum
                                                    }
                                                />
                                            )}
                                        </Box>
                                    );
                                })}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        position: 'sticky',
                        bottom: 0,
                        mb: 0,
                        mt: 1,
                        ml: '-1px',
                        mr: '-1px',
                        backgroundColor: 'background.default',
                        pt: 2,
                        pb: 2,
                    }}
                >
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        sx={{ mr: 1, width: '110px' }}
                    >
                        Cancel
                    </Button>
                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={!dataChanged}
                            size="small"
                            sx={{ px: '20px' }}
                        >
                            {loading ? (
                                <CircularProgress
                                    color="inherit"
                                    size={'1rem'}
                                    sx={{ width: '110px' }}
                                />
                            ) : (
                                'Save changes'
                            )}
                        </Button>
                    </Box>
                </Box>
            </form>
        </>
    );
};

export default EditableChart;
