import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { FilterOptions } from '../cardTypes';
import CategoryList from './secondary-filters/CategoryList';
import TypeList from './secondary-filters/TypeList';
import CardSetList from './secondary-filters/CardSetList';
import OwnerList from './secondary-filters/OwnerList';
import { FilterCards } from '../../../hooks/useFilterCards';
import { generateClient } from 'aws-amplify/api';
import { getUserOrganisation } from '../../../helpers/auth';
import { cardSetsOrderedByTypeAndUpdated } from '../../../graphql/queries';
import { CardSet, CardSetsOrderedByTypeAndUpdatedQuery } from '../../../API';
import { AppContext } from '../../contexts';

interface SecondaryFilterColumnProps {
    selectedPrimaryFilter: FilterOptions | null;
    localFilters: FilterCards;
    setLocalFilters: React.Dispatch<React.SetStateAction<FilterCards>>;
    selectedCategoryId: string;
    setSelectedCategoryId: React.Dispatch<React.SetStateAction<string>>;
}

const SecondaryFilterColumn = ({
    selectedPrimaryFilter,
    localFilters,
    setLocalFilters,
    selectedCategoryId,
    setSelectedCategoryId,
}: SecondaryFilterColumnProps) => {
    const [cardSets, setCardSets] = useState<CardSet[]>([]);
    const { user } = useContext(AppContext);

    const client = generateClient();

    useEffect(() => {
        const fetchData = async () => {
            const userOrganisation = await getUserOrganisation(user);

            try {
                const response = (await client.graphql({
                    query: cardSetsOrderedByTypeAndUpdated,
                    variables: {
                        organisation: userOrganisation,
                    },
                })) as { data: CardSetsOrderedByTypeAndUpdatedQuery };
                setCardSets(
                    response?.data?.cardSetsOrderedByTypeAndUpdated
                        ?.items as CardSet[]
                );
            } catch (err) {
                console.log(err);
                return [];
            }
        };
        if (user) {
            fetchData();
        }
    }, [user]);
    return (
        <Box
            sx={{
                borderRight: '1px solid #f0f2f5',
                height: '100%',
                overflowY: 'scroll',
            }}
        >
            {!!selectedPrimaryFilter &&
                {
                    [FilterOptions.CARD_TYPE]: (
                        <CategoryList
                            localFilters={localFilters}
                            setLocalFilters={setLocalFilters}
                            selectedCategoryId={selectedCategoryId}
                            setSelectedCategoryId={setSelectedCategoryId}
                        />
                    ),
                    [FilterOptions.CARD_SET]: (
                        <CardSetList
                            localFilters={localFilters}
                            setLocalFilters={setLocalFilters}
                            items={cardSets.filter(
                                (item) => item.type === 'CS'
                            )}
                            type="CS"
                        />
                    ),
                    [FilterOptions.WORKBOOK]: (
                        <CardSetList
                            localFilters={localFilters}
                            setLocalFilters={setLocalFilters}
                            items={cardSets.filter(
                                (item) => item.type === 'WB'
                            )}
                            type="WB"
                        />
                    ),
                    [FilterOptions.OWNER]: (
                        <OwnerList
                            localFilters={localFilters}
                            setLocalFilters={setLocalFilters}
                        />
                    ),
                }[selectedPrimaryFilter]}
        </Box>
    );
};

export default SecondaryFilterColumn;
