import { PropsWithChildren, useContext, useState } from 'react';
import { Box } from '@mui/material';
import GlobalAppBar from '../GlobalAppBar';
import { appBarWidth } from '../../helpers/styleConfig';
import { AppContext } from '../contexts';
interface LayoutProps {
    title: string;
    header: string;
}
const Layout = ({ children, title }: PropsWithChildren<LayoutProps>) => {
    const { showIllustrations } = useContext(AppContext);
    return (
        <div className={!showIllustrations ? 'hide-illustrations' : ''}>
            <Box sx={{ ml: appBarWidth }}>{children}</Box>
            <GlobalAppBar />
        </div>
    );
};
export default Layout;
