import { Box, styled, Typography } from '@mui/material';
import { ViewType } from '../components/cards/cardTypes';
import PageView from '../components/layouts/PageView';

const Header = styled(Typography)(({ theme }) => {
    return {
        fontWeight: '600',
        marginTop: '20px',
    };
});

const TermsAndConditions = () => {
    return (
        <Box>
            <Box
                style={{
                    margin: 'auto',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '60%',
                }}
            >
                <Header sx={{ margin: 'auto' }}>
                    I-NEXUS DESIGN CUSTOMER STRATEGY CARDS EARLY ADOPTER TERMS
                    AND CONDITIONS
                </Header>
                <Box>
                    <Header>1 INTRODUCTION </Header>
                </Box>
                <Box>
                    1.1 These terms and conditions (Terms) govern the access and
                    use, on a time-limited basis, of strategy formulation and
                    execution features relating to the i-nexus Strategy Cards
                    software-as-service that we make available to customers we
                    selected as early adopters, as more fully described at
                    https://strategycards.i-nexus.co.uk (the Service).{' '}
                </Box>
                <Box>
                    1.2 By accessing or using the Service, you accept these
                    Terms and will be legally bound by them. By doing so you
                    will also bind to these Terms the company or other
                    organisation on whose behalf you access or use the Service.
                    Any references to you or your in these Terms shall include
                    that organisation as well as any end user we authorise to
                    access the Service in connection with that organisation
                    (Authorised User).{' '}
                </Box>
                <Box>
                    1.3 Please read these Terms carefully to understand
                    your/your organisation’s rights and liabilities before
                    accessing or using the Service. If you disagree with these
                    Terms or any part of them, you must not access or use the
                    Service.{' '}
                </Box>
                <Box>
                    <Header>2 DEFINITIONS</Header>
                </Box>
                <Box>
                    2.1 The following expressions shall have the following
                    meanings when used in these Terms:{' '}
                </Box>
                <Box>
                    2.1.1 Access Period: the period commencing on the date we
                    issue the access credentials you use to access the Service
                    and ending on the general release date of the full
                    commercial version of the Service, as determined by us in
                    our sole discretion (currently estimated as 30 May 2024).
                </Box>
                <Box>
                    {' '}
                    2.1.2 Customer Data: any data uploaded by an Authorised User
                    to the Service or any trade marks, logos or other materials
                    supplied to us by you to provide the Service to you but
                    excluding any Feedback and Usage Data.
                </Box>
                <Box>
                    {' '}
                    2.1.3 Feedback: any feedback or suggestions you provide to
                    us in connection with the Service, including any
                    improvements to the Service or changes to the current or
                    future commercial arrangements or models relating to the
                    Service.{' '}
                </Box>
                <Box>
                    2.1.4 GDPR: the UK GDPR (as defined in section 3(10) of the
                    Data Protection Act 2018, supplemented by section 205(4) of
                    the Data Protection Act 2018) and, if applicable, the
                    General Data Protection Regulation (EU 2016/679) or, in each
                    case, any successor legislation.
                </Box>
                <Box>
                    {' '}
                    2.1.5 IPRs: any of the following rights existing in any part
                    of the world: all patents, utility models, rights to
                    inventions, copyright and neighbouring and related rights,
                    moral rights, rights in designs, trade and service marks,
                    trade names, logos, rights in get-up and trade dress,
                    goodwill and the right to sue for passing off or unfair
                    competition, domain name registrations, database rights and
                    rights in confidential information (including know-how) and
                    all other intellectual property rights, in each case whether
                    registered or unregistered; applications to register any of
                    those rights; rights to apply for and be granted renewals or
                    extensions of, and rights to claim priority from, any of
                    those rights; and any similar or equivalent rights.
                </Box>
                <Box>
                    2.1.6 Usage Data: any data relating to your or any
                    Authorised Users’ use of the Service or any website,
                    application programme interface or software through which it
                    is accessed, any data derived or generated from any Customer
                    Data. 2.1.7 We/us/our: i-Solutions Global Limited, a company
                    incorporated and registered in England and Wales with
                    company number 04294356 and registered address at i-Nexus,
                    i-Nexus Suite George House, Herald Avenue, Coventry Business
                    Park, Coventry, CV5 6UB.{' '}
                </Box>
                <Box>
                    <Header>3 ACCESS AND USE OF THE SERVICE</Header>
                </Box>
                <Box>
                    3.1 During the Access Period, we shall use our reasonable
                    endeavours to provide Authorised Users with access to the
                    Service during the Access Period solely for use internally
                    within your organisation for evaluation purposes. We may
                    terminate access to the Service without notice to you at the
                    end of the Access Period.{' '}
                </Box>
                <Box>
                    3.2 You shall regularly provide to us at such intervals and
                    in such format as we require from time to time, written
                    Feedback on the Service.{' '}
                </Box>
                <Box>
                    3.3 You shall not (nor attempt to): (i) copy, modify,
                    duplicate, create derivative works from, frame, mirror,
                    republish, download, display, transmit or distribute all or
                    any portion of the Service in any form or media or by any
                    means, except as expressly permitted under these Terms; (ii)
                    reverse compile, disassemble, reverse engineer or otherwise
                    reduce to human-perceivable form all of any part of the
                    Service; (iii) access all or any part of the Services in
                    order to build a product or service which competes with the
                    Service or any of our other services; or (iv) license, sell,
                    rent, lease, transfer, assign, distribute, display,
                    disclose, or otherwise commercially exploit, or make the
                    Service available to any third party.{' '}
                </Box>
                <Box>
                    3.4 The Service is provided on an “as is” and “as available”
                    basis and, to the fullest extent permission by law, is
                    provided without any warranties, guarantees, terms or
                    undertakings (express or implied) by us, including as to its
                    quality, fitness or suitability for purpose, performance,
                    functionality, compatibility, deliverables and other
                    outputs.
                </Box>
                <Box>
                    3.5 We shall not be liable to you or any other person for
                    any loss, damage, costs or other liabilities resulting from
                    any inaccuracies, interruptions, delays, omissions or errors
                    in the Service or from making any business decision, or
                    refraining from making any such decision, based on the use
                    of the Service.
                </Box>
                <Box>
                    {' '}
                    3.6 Nothing in these Terms shall exclude or limit our
                    liability for death or personal injury resulting from our
                    negligence, for fraud or fraudulent misrepresentation or in
                    any way that is not permitted under applicable law. Except
                    as set out in this clause, our maximum aggregate liability
                    under these Terms, whether arising in contract, tort, breach
                    of statutory duty or otherwise, shall not exceed one hundred
                    pounds sterling. 4 ACCESS CREDENTIALS
                </Box>
                <Box>
                    {' '}
                    4.1 You shall ensure that any access credentials that we
                    provide allowing an Authorised User to access the Service
                    are kept in strict confidence by each such user and are not
                    shared between Authorised Users, except with our prior
                    written permission.{' '}
                </Box>
                <Box>
                    4.2 You shall notify us immediately in writing if you become
                    aware of any disclosure of any access credentials to the
                    Service, any unauthorised use of the Service or any breach
                    of these Terms.
                </Box>
                <Box>
                    <Header>5 CUSTOMER DATA</Header>
                </Box>
                <Box>
                    5.1 You shall remain the owner of all rights, title and
                    interest in and to the Customer Data and shall have sole
                    responsibility for the legality, reliability, integrity,
                    accuracy and quality of that data.
                </Box>
                <Box>
                    5.2 To the extent that we process any personal data
                    comprised in any Customer Data as your processor (as defined
                    in the GDPR), the applicable provisions set out in Article
                    28(3) of the GDPR shall be deemed incorporated into these
                    Terms as obligations on us and we and you acknowledge that:
                    (i) the subject matter, nature and purpose of the relevant
                    processing shall be to provide the Service as further
                    described in the description of processing at
                    www.i-nexus.com/dpa; (ii) the duration of processing shall
                    be the Access Period (subject to earlier termination); (iii)
                    the categories of personal data and data subjects shall be
                    as described in the description of processing at
                    www.i-nexus.com/dpa; and (iv) the description of your rights
                    and obligations as a controller (as defined in the GDPR)
                    shall be as set out in these Terms and the GDPR.
                </Box>{' '}
                <Box>
                    <Header>6 intellectual property rights</Header>
                </Box>
                <Box>
                    {' '}
                    6.1 You acknowledge and agree that we own all IPRs in and to
                    the Service, including any content made available through
                    the Service (other than any Customer Data) and all Feedback
                    and Usage Data. To the extent that the ownership of any such
                    IPRs does not automatically vest in us, you hereby assign to
                    us absolutely and with full title guarantee all right, title
                    and interest in and to those IPRs, including the right to
                    take proceedings and recover damages in respect of past
                    infringements and in respect of any aggravated or exemplary
                    damages. To the extent that any of the IPRs assigned under
                    this clause include copyright, you hereby irrevocably and
                    unconditionally waive (and shall procure your Authorised
                    Users shall procure), to the fullest extent permitted by
                    law, all moral rights in the relevant copyright-protected
                    work for the purposes of the Copyright, Designs and Patents
                    Act 1988 (as amended) and any similar or equivalent rights
                    subsisting anywhere in the world.{' '}
                </Box>
                <Box>
                    6.2 Except as expressly set out in these Terms, we do not
                    grant you any rights to, or in, patents, copyrights,
                    database rights, trade secrets, trade names, trade marks
                    (unregistered or registered) or any other rights or licences
                    in respect of the Service.{' '}
                </Box>
                <Box>
                    6.3 You grant to us a non-exclusive, royalty-free, paid-for,
                    worldwide licence to use the Customer Data (and any IPRs in
                    and to that data) for the purposes of providing the Service
                    (including making any customisations or improvements to the
                    Service from time to time).
                </Box>
                <Box>
                    {' '}
                    <Header>7 CONFIDENTIALITY</Header>
                </Box>
                <Box>
                    {' '}
                    7.1 You acknowledge that you and Authorised Users may, in
                    the course of using the Services or otherwise during the
                    Access Period, be exposed to or receive non-public
                    information which is proprietary or confidential to us or
                    third parties to whom we owe a duty of confidentiality
                    (Confidential Information). You agree to hold all
                    Confidential Information in strict confidence and not to
                    disclose the same to any third party or use such
                    Confidential Information for any purpose whatsoever other
                    than contemplated by these Terms. You shall notify each of
                    your Authorised Users or any personnel who may be exposed to
                    or receive any Confidential Information of your obligations
                    under this clause and shall immediately notify us in writing
                    as soon as you become aware if you or any other person
                    causes you to breach this clause.
                </Box>
                <Box>
                    {' '}
                    <Header>8 TERMINATION AND SUSPENSION</Header>
                </Box>
                <Box>
                    8.1 We may suspend or terminate your or any other Authorised
                    User’s access to all or part of the Service at any time and
                    for any reason in our sole discretion without notice or
                    reason, including by switching your access or Authorised
                    Users’ access to another service with fewer or alternative
                    features or functions when compared to the Service. Your
                    right to access and use the Service will terminate
                    immediately if we do so.{' '}
                </Box>
                <Box>
                    8.2 We will delete all Customer Data within 30 days of the
                    expiry of the Access Period (or the date we terminate all
                    Authorised Users’ access to the Service, if sooner) unless
                    we and you agree in writing to retain that data.
                </Box>
                <Box>
                    {' '}
                    <Header>9 GENERAL</Header>
                </Box>
                <Box>
                    {' '}
                    9.1 Except as set out in these Terms, any variation,
                    including the introduction of additional terms and
                    conditions, shall only be binding when agreed in writing and
                    signed by us. A waiver of any right under these Terms is
                    only effective if it is in writing and it applies only to
                    the party to whom the waiver is addressed and to the
                    circumstances for which it is given. You may not assign,
                    transfer, sub-contract or deal in any other manner with all
                    or any of your rights or obligations under these Terms. You
                    shall comply with all applicable laws and shall not by any
                    act or omission cause us to breach those laws. Nothing in
                    these Terms is intended to or shall operate to create a
                    partnership between you and us. Nothing in these Terms is
                    intended to benefit anyone other than the parties to it, and
                    none of these Terms shall be enforceable under the Contracts
                    (Rights of Third Parties) Act 1999 by any third party. If
                    any part of these Terms is found to be invalid,
                    unenforceable or illegal, the other provisions shall remain
                    in force. Any notice required to be given under these Terms
                    shall be in writing. These Terms constitute the entire
                    agreement between you and us and supersede and extinguish
                    all previous agreements, promises and understandings between
                    you and us, whether written or oral, relating to their
                    subject matter. We and you acknowledge that, in entered into
                    these Terms, neither we or you rely on any statement,
                    assurance or representation not expressly set out in these
                    Terms.{' '}
                </Box>
                <Box>
                    9.2 These Terms are governed by English law and we and you
                    submit to the exclusive jurisdiction of the courts of
                    England and Wales.
                </Box>
            </Box>
        </Box>
    );
};

export default TermsAndConditions;
