import { Chart, ChartArea, Scale } from 'chart.js';
import { CustomDataPoint } from './BubbleChart';
import { Tick } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { Chart as ChartJS } from 'chart.js';
import { truncateText } from '../../helpers/utils';
import { ScoreType } from '../../API';
import { Theme } from '@mui/material';
import zoomPlugin from 'chartjs-plugin-zoom';

export const chartAreaBackgroundPlugin = {
    id: 'chartAreaBackground',
    beforeDraw: (
        chart: any,
        args: any,
        options: { backgroundColor: string }
    ) => {
        const isBubbleChart = chart?.config?.type === 'bubble';
        if (!isBubbleChart) {
            return;
        }
        const ctx = chart.ctx;
        const chartArea: ChartArea = chart.chartArea;

        ctx.save();
        ctx.fillStyle = options.backgroundColor;
        ctx.fillRect(
            chartArea.left,
            chartArea.top,
            chartArea.right - chartArea.left,
            chartArea.bottom - chartArea.top
        );
        ctx.restore();
    },
};
export const calculateMaxValue = (value: number, scoreType: ScoreType) => {
    const additionalSpaceFactor = 1.1;
    if (scoreType === ScoreType.Percentage) {
        return 100;
    }
    if (scoreType === ScoreType.Currency || scoreType === ScoreType.Integer) {
        return Math.round(value * additionalSpaceFactor);
    }
    return 10;
};

export const determineDataLabelAlignment = (
    context: Context,
    chart: ChartJS,
    currentDataPoint: CustomDataPoint
) => {
    const chartArea = chart.chartArea;
    const currentBubbleRadius = currentDataPoint?.r;
    const xScale = chart.scales.x;
    const yScale = chart.scales.y;
    const currentBubbleX = xScale.getPixelForValue(currentDataPoint?.x);
    const currentBubbleY = yScale.getPixelForValue(currentDataPoint?.y);

    const isCloseToRightEdge =
        chartArea.right - currentBubbleX <= currentBubbleRadius + 15;
    const isCloseToLeftEdge =
        currentBubbleX - chartArea.left <= currentBubbleRadius + 15;
    const isCloseToTopEdge =
        currentBubbleY - chartArea.top <= currentBubbleRadius + 15;
    const isCloseToBottomEdge =
        chartArea.bottom - currentBubbleY <= currentBubbleRadius + 15;

    let isNearOtherBubbleToLeft = false;
    let isNearOtherBubbleToRight = false;
    let isNearOtherBubbleToTop = false;
    let isNearOtherBubbleToBottom = false;

    context.chart.data.datasets.forEach((dataset) => {
        dataset.data.forEach((otherDataPoint, otherIndex) => {
            if (otherIndex !== context.dataIndex) {
                const otherBubble = otherDataPoint as CustomDataPoint;
                const otherBubbleX = xScale.getPixelForValue(otherBubble.x);
                const otherBubbleY = yScale.getPixelForValue(otherBubble.y);
                const distanceX = Math.abs(otherBubbleX - currentBubbleX);
                const distanceY = Math.abs(otherBubbleY - currentBubbleY);
                const combinedRadius = currentBubbleRadius + otherBubble.r + 15;

                if (distanceX < combinedRadius && distanceY < combinedRadius) {
                    if (otherBubbleX < currentBubbleX)
                        isNearOtherBubbleToLeft = true;
                    if (otherBubbleX > currentBubbleX)
                        isNearOtherBubbleToRight = true;
                    if (otherBubbleY < currentBubbleY)
                        isNearOtherBubbleToTop = true;
                    if (otherBubbleY > currentBubbleY)
                        isNearOtherBubbleToBottom = true;
                }
            }
        });
    });

    if (isCloseToBottomEdge) return 'top';
    if (isCloseToRightEdge && !isNearOtherBubbleToLeft) return 'left';
    if (isCloseToRightEdge && isNearOtherBubbleToLeft) return 'top';
    if (isCloseToLeftEdge && !isNearOtherBubbleToRight) return 'right';
    if (isCloseToLeftEdge && isNearOtherBubbleToRight) return 'top';
    if (isNearOtherBubbleToRight) return 'left';
    if (isNearOtherBubbleToLeft) return 'right';
    if (isCloseToTopEdge && !isNearOtherBubbleToBottom) return 'bottom';
    if (isCloseToTopEdge && isNearOtherBubbleToBottom) return 'right';
    return 'right';
};

interface axisOptions {
    lowTickLabel: string;
    highTickLabel: string;
    title: string;
    scoreType: ScoreType;
}
export const getBubbleChartOptions = (
    xAxisOptions: axisOptions,
    yAxisOptions: axisOptions,
    displayGrid: boolean,
    thumbnail: boolean,
    dataset: any[],
    carousel: boolean,
    theme: Theme,
    workbook?: boolean,
    pptView?: boolean
) => {
    let maxXValue = Math.max(...dataset.map((data) => data.x));
    let maxYValue = Math.max(...dataset.map((data) => data.y));
    const maxX = calculateMaxValue(maxXValue, xAxisOptions.scoreType) || 100000;
    const maxY = calculateMaxValue(maxYValue, yAxisOptions.scoreType) || 100000;

    const bubbleChartOptions = {
        aspectRatio: 2,
        maintainAspectRatio: carousel ? false : false,
        responsive: true,
        animation: workbook ? false : true,
        scales: {
            x: {
                type: 'linear' as const,
                position: 'bottom' as const,
                beginAtZero: true,
                max: maxX,
                ticks: {
                    padding: thumbnail ? 2 : 5,
                    color: workbook ? theme.palette.text.secondary : 'grey',
                    font: {
                        weight: pptView ? 'bold' : '',
                        size:
                            !thumbnail && pptView
                                ? 20
                                : thumbnail && !pptView
                                ? 6
                                : 10,
                    },
                    callback: function (
                        value: number | string,
                        index: number,
                        values: Tick[]
                    ) {
                        const formattedValue =
                            typeof value === 'number' && value % 1 !== 0
                                ? value.toFixed(2)
                                : value;
                        if (
                            xAxisOptions.scoreType === ScoreType.Currency ||
                            xAxisOptions.scoreType === ScoreType.Integer ||
                            xAxisOptions.scoreType === ScoreType.Percentage
                        ) {
                            return formattedValue;
                        } else if (index === 0) {
                            return xAxisOptions.lowTickLabel;
                        }
                        if (typeof value === 'number' && value === maxX)
                            return xAxisOptions.highTickLabel;
                        return '';
                    },
                },
                afterFit: (scale: Scale) => {
                    scale.height = scale.height + (thumbnail ? -10 : 5);
                },
                grid: {
                    display: displayGrid,
                    drawOnChartArea: true,
                    drawTicks: false,
                },
                title: {
                    display: true,
                    text: xAxisOptions.title,
                    font: {
                        weight: 'bold',
                        size:
                            !thumbnail && pptView
                                ? 20
                                : thumbnail && !pptView
                                ? 10
                                : 16,
                    },
                    color: workbook ? theme.palette.text.secondary : 'grey',
                },
            },
            y: {
                type: 'linear' as const,
                position: 'left' as const,
                beginAtZero: true,
                max: maxY,
                ticks: {
                    padding: thumbnail ? 2 : 5,
                    color: workbook ? theme.palette.text.secondary : 'grey',
                    font: {
                        weight: pptView ? 'bold' : '',
                        size:
                            !thumbnail && pptView
                                ? 20
                                : thumbnail && !pptView
                                ? 6
                                : 10,
                    },
                    callback: function (
                        value: number | string,
                        index: number,
                        values: Tick[]
                    ) {
                        const formattedValue =
                            typeof value === 'number' && value % 1 !== 0
                                ? value.toFixed(2)
                                : value;
                        if (
                            yAxisOptions.scoreType === ScoreType.Currency ||
                            yAxisOptions.scoreType === ScoreType.Integer ||
                            yAxisOptions.scoreType === ScoreType.Percentage
                        ) {
                            return formattedValue;
                        } else if (index === 0) {
                            return yAxisOptions.lowTickLabel;
                        }
                        if (typeof value === 'number' && value === maxY)
                            return yAxisOptions.highTickLabel;
                        return '';
                    },
                },
                afterFit: (scale: Scale) => {
                    scale.width = scale.width - (thumbnail ? 30 : 10);
                },
                grid: {
                    display: displayGrid,
                    drawOnChartArea: true,
                    drawTicks: false,
                },
                title: {
                    display: true,
                    text: yAxisOptions.title,
                    font: {
                        weight: 'bold',
                        size:
                            !thumbnail && pptView
                                ? 20
                                : thumbnail && !pptView
                                ? 10
                                : 16,
                    },
                    color: workbook ? theme.palette.text.secondary : 'grey',
                },
            },
        },
        plugins: {
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    mode: 'xy',
                },
                pan: {
                    enabled: true,
                    mode: 'xy',
                },
            },
            legend: {
                display: false,
            },
            datalabels: {
                color: 'grey',
                align: (context: Context) => {
                    const chart = context.chart;
                    const currentDataPoint = context.dataset.data[
                        context.dataIndex
                    ] as CustomDataPoint;
                    return determineDataLabelAlignment(
                        context,
                        chart,
                        currentDataPoint
                    );
                },

                anchor: 'center' as const,
                offset: (context: Context) => {
                    const value = context.dataset.data[0] as CustomDataPoint;
                    const size = value?.r + 5;
                    return size;
                },
                font: {
                    size:
                        !thumbnail && pptView
                            ? 36
                            : thumbnail && !pptView
                            ? 6
                            : 10,
                    weight: 'bold' as const,
                },
                formatter: (value: any, context: any) => {
                    const dataPoint = context.chart.data.datasets[
                        context.datasetIndex
                    ].data[context.dataIndex] as CustomDataPoint;
                    return dataPoint ? truncateText(dataPoint.label, 10) : '';
                },
            },
            chartAreaBackground: {
                backgroundColor: '#f0f2f5',
            },
            tooltip: {
                enabled: false,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                bodyColor: '#ffffff',
                titleColor: '#ffffff',
                borderColor: '#333',
                borderWidth: 1,
                bodyFont: {
                    size: 14,
                },
                callbacks: {
                    title: (tooltipItems: any) => {
                        return tooltipItems[0].label;
                    },
                    label: (context: any) => {
                        const dataPoint = context.raw;
                        const xValue =
                            dataPoint.x % 1 !== 0
                                ? dataPoint.x.toFixed(2)
                                : dataPoint.x;
                        const yValue =
                            dataPoint.y % 1 !== 0
                                ? dataPoint.y.toFixed(2)
                                : dataPoint.y;
                        return `X: ${xValue}, Y: ${yValue}`;
                    },
                },
            },
        },
    };

    return bubbleChartOptions;
};
// ChartJS.register(ChartDataLabels);
Chart.register(chartAreaBackgroundPlugin);
Chart.register(zoomPlugin);
