import { generateClient } from 'aws-amplify/api';
import { getUserOrganisation } from '../helpers/auth';
import { IconStates } from '../hooks/useIconStates';
import { AuditEntry, Card, CardSet, Playbook } from '../API';
import { FilterCards } from '../hooks/useFilterCards';
import { AuthSession } from '../globals';
import { getUserNotifications } from '../graphql/queries';

export interface GraphQLResponse {
    data: {
        [key: string]: {
            items: Card[] | CardSet[] | Playbook[];
            nextToken: string | null;
        };
    };
}
export interface GraphQLNotificationResponse {
    data: {
        [key: string]: {
            names: string;
            entries: AuditEntry[];
            nextToken: string | null;
        };
    };
}

export const queryData = async (
    initialLoad: boolean,
    cardSortDirection: 'ASC' | 'DESC',
    token: string | null,
    activeIcons: IconStates,
    query: string,
    sortField: string,
    responseType: string,
    filterCriteria?: FilterCards,
    setActiveIcons?: React.Dispatch<React.SetStateAction<IconStates>>,
    context?: string,
    user?: AuthSession | null
) => {
    const userGroup = user ? await getUserOrganisation(user) : '';

    const client = generateClient();

    const data = [];
    let nextToken: string | null = initialLoad
        ? ''
        : token === null
        ? null
        : token
        ? token
        : '';

    do {
        let ownerFilters = filterCriteria?.owner;
        if (
            (activeIcons.Fingerprint && filterCriteria?.owner?.length === 0) ||
            activeIcons.Fingerprint
        ) {
            ownerFilters = [
                `${user?.tokens?.idToken?.payload.sub}::${user?.tokens?.idToken?.payload.sub}`,
                `${user?.tokens?.idToken?.payload.sub}`,
            ];
        }
        if (
            activeIcons.Fingerprint &&
            filterCriteria?.owner &&
            filterCriteria?.owner?.length > 0
        ) {
            ownerFilters = filterCriteria?.owner;
            setActiveIcons &&
                setActiveIcons({
                    ...activeIcons,
                    Fingerprint: false,
                });
        }

        const cardTypeFilters = filterCriteria?.cardType;
        const cardSetsFilters = filterCriteria?.cardSet;
        const workbooksFilters = filterCriteria?.workbook;

        let variables: {
            organisation: string;
            filter: any;
            sort: any;
            context?: string;
        } = {
            organisation: userGroup,
            filter: {
                ...(cardTypeFilters &&
                    cardTypeFilters.length > 0 && { types: cardTypeFilters }),
                ...(ownerFilters &&
                    ownerFilters.length > 0 && {
                        owner: ownerFilters,
                    }),
                ...(cardSetsFilters &&
                    cardSetsFilters.length > 0 && {
                        cardSets: cardSetsFilters,
                    }),
                ...(workbooksFilters &&
                    workbooksFilters.length > 0 && {
                        workbooks: workbooksFilters,
                    }),
            },
            sort: {
                field: sortField,
                order: cardSortDirection,
            },
            ...(!!nextToken && {
                nextToken: nextToken,
            }),
            context,
        };

        const response = (await client.graphql({
            query: query,
            variables: variables,
        })) as GraphQLResponse;
        const responseData = response?.data?.[responseType];

        data.push(...responseData?.items);
        nextToken = responseData?.nextToken;
    } while (nextToken !== null && data.length < 50);

    return { data, nextToken };
};

export const queryNotifications = async ({
    initialLoad,
    token,
    cutoffTS,
    limit,
    userGroup,
    fingerprintOn,
}: {
    initialLoad: boolean;
    token: string | null;
    cutoffTS: string;
    limit: number;
    userGroup: string;
    fingerprintOn?: boolean;
}) => {
    const client = generateClient();

    const data = [];
    let names: any = {};
    let nextToken: string | null = initialLoad
        ? ''
        : token === null
        ? null
        : token
        ? token
        : '';

    do {
        let variables: {
            organisation: string;
            cutoffTS: string;
            limit: number;
            filter?: {
                owner?: boolean;
                member?: boolean;
                creator?: boolean;
            };
            nextToken?: string;
        } = {
            organisation: userGroup,
            cutoffTS: cutoffTS,
            limit: limit,
            filter: fingerprintOn
                ? {
                      owner: true,
                      creator: true,
                  }
                : { owner: true, creator: true, member: true },
        };
        if (nextToken) {
            variables.nextToken = nextToken;
        }
        const response = (await client.graphql({
            query: getUserNotifications,
            variables: variables,
        })) as GraphQLNotificationResponse;

        data.push(...response?.data?.getUserNotifications.entries);
        nextToken = response?.data?.getUserNotifications.nextToken;
        let newNames = response?.data?.getUserNotifications.names;
        newNames = JSON.parse(newNames);
        for (const [key, value] of Object.entries(newNames)) {
            if (!names[key]) {
                names[key] = value;
            }
        }
    } while (nextToken !== null && data.length < limit);
    names = JSON.stringify(names);
    return { data, nextToken, names };
};
