import {
    CreatePlaybookPageInput,
    Card,
    CardType,
    PlaybookDataResponse,
    PlaybookPageDataResponse,
    UpdatePlaybookPageInput,
    ScoreType,
} from '../../API';
import { CardSettings, EditablePageValues } from './EditablePlaybookPage';
import { UserPermissions } from '../../globals';
import { MoveDirection } from '../../pages/playbooks/[pid]';
import {
    WorksheetSetting,
    WorksheetNames,
    swotOptions,
} from '../../helpers/worksheets';
import {
    CheckboxOptionType,
    FieldType,
} from '../forms/input-fields/inputFieldTypes';
import { RadarOption } from '../forms/input-fields/ChartSettings';
import { FormValues } from '@aws-amplify/ui-react/dist/types/components/AccountSettings/types';
export type PlaybookPageFormInputs =
    | CreatePlaybookPageInput
    | UpdatePlaybookPageInput;
export type PlaybookPageFormLocalConfig = {
    cardIds: string[];
    cardSetIds: string[];
    workbookIds: string[];
};
interface LayoutOptions {
    enabled: boolean;
    text: string;
    include: CheckboxOptionType[];
}
export interface PlaybookFormProps {
    playbook: PlaybookDataResponse;
    page?: PlaybookPageDataResponse;
    updateValue?: (name: string, value: string) => void;
    cardSettings: CardSettings;
    onSubmit: (e: React.BaseSyntheticEvent) => void;
    worksheetSettings: WorksheetSetting | null;
    helpTextAnchor?: HTMLElement | null | undefined;
    setHelpTextAnchor?: React.Dispatch<
        React.SetStateAction<HTMLElement | null | undefined>
    >;
    chartSettings?: any;
    pageOptions?: { layout: LayoutOptions[] };
    setPageOptions: React.Dispatch<React.SetStateAction<undefined>>;
    radarOptions?: RadarOption[] | null | undefined;
    setRadarOptions?:
        | React.Dispatch<React.SetStateAction<RadarOption[] | null | undefined>>
        | undefined;
}
export interface PlaybookPageProps {
    playbook?: PlaybookDataResponse;
    page?: PlaybookPageDataResponse;
    orderPages?: (id: string, direction: MoveDirection) => void;
    setEditMode: (editMode: boolean) => void;
    permissions: UserPermissions[];
    cards?: Card[];
    type?: CardType;
    playbookPageForm?: EditablePageValues;
    handleDelete?: (e: React.MouseEvent<HTMLLIElement>, id: string) => void;
    itemSelected: boolean;
    first?: boolean;
    last?: boolean;
    edit?: boolean;
    carousel?: boolean;
    pageOptions?: { layout?: any };
    setPageOptions?: React.Dispatch<React.SetStateAction<undefined>>;
    radarOptions?: RadarOption[] | null | undefined;
    setRadarOptions?:
        | React.Dispatch<React.SetStateAction<RadarOption[] | null | undefined>>
        | undefined;
    pptView?: boolean;
    slideNumber?: number;
    logo: string | null;
}
export type PlaybookPageFormType = PlaybookPageFormInputs &
    PlaybookPageFormLocalConfig;
export enum AxisOptions {
    StrategicImportance = 'Strategic importance',
    CurrentProficiency = 'Current proficiency',
    EaseOfUpskill = 'Ease of upskill',
    DesiredProficiency = 'Desired proficiency',
    BenchmarkProficiency = 'Benchmark proficiency',
    SkillGapCurrentVsDesired = 'Skill gap - current vs. desired',
    SkillGapCurrentVsBenchmark = 'Skill gap - current vs. benchmark',
    SkillGapDesiredVsBenchmark = 'Skill gap - desired vs. benchmark',
    CurrentMaturity = 'Current maturity',
    EaseOfMaturityImprovement = 'Ease of maturity improvement',
    DesiredMaturity = 'Desired maturity',
    BenchmarkMaturity = 'Benchmark maturity',
    MaturityGapCurrentVsDesired = 'Maturity gap - current vs. desired',
    MaturityGapCurrentVsBenchmark = 'Maturity gap - current vs. benchmark',
    MaturityGapDesiredVsBenchmark = 'Maturity gap - desired vs. benchmark',
    Value = 'Value',
    StrategicAlignment = 'Strategic alignment',
    ProbabilityOfSuccess = 'Probability of success',
    PlanCost = 'Plan cost',
    PercentComplete = 'Percent complete',
    PotentialImpact = 'Potential impact',
    Feasibility = 'Feasibility',
    InvestmentLevel = 'Investment level',
    StrategicFit = 'Strategic fit',
    TAM = 'TAM',
    SAM = 'SAM',
    CAGR = 'CAGR',
    Maturity = 'Maturity',
    CompetitiveIntensity = 'Competitive intensity',
    MarketProfitability = 'Market profitability',
    MarketProductFit = 'Market-product fit',
    CustomerLifetimeValue = 'Customer lifetime value, CLV',
    EngagementPurchaseFrequency = 'Engagement/purchase frequency',
    AnnualContractValue = 'Annual contract value',
    Risk = 'Risk',
    CustomerSatisfaction = 'Customer satisfaction, CSAT',
    NetPromoterScore = 'Net Promoter Score, NPS',
    Impact = 'Impact',
    Confidence = 'Confidence',
    LikelihoodOfOccurence = 'Likelihood of occurrence',
    MitigationDifficulty = 'Mitigation difficulty',
}

export enum NodeSizeOptions {
    SkillGapCurrentVsDesired = 'Skill gap - current vs. desired',
    StrategicImportance = 'Strategic importance',
    MaturityGapCurrentVsDesired = 'Maturity gap - current vs. desired',
    PlanCost = 'Plan cost',
    Value = 'Value',
    PotentialImpact = 'Potential impact',
    Feasibility = 'Feasibility',
    InvestmentLevel = 'Investment level',
    StrategicFit = 'Strategic fit',
    TAM = 'TAM',
    SAM = 'SAM',
    CAGR = 'CAGR',
    Maturity = 'Maturity',
    CompetitiveIntensity = 'Competitive intensity',
    MarketProfitability = 'Market profitability',
    MarketProductFit = 'Market-product fit',
    CustomerLifetimeValue = 'Customer lifetime value, CLV',
    EngagementPurchaseFrequency = 'Engagement/purchase frequency',
    AnnualContractValue = 'Annual contract value',
    Risk = 'Risk',
    CustomerSatisfaction = 'Customer satisfaction, CSAT',
    NetPromoterScore = 'Net Promoter Score, NPS',
    Impact = 'Impact',
    Confidence = 'Confidence',
    LikelihoodOfOccurence = 'Likelihood of occurrence',
    MitigationDifficulty = 'Mitigation difficulty',
    DefaultNodeSize = 'Default node size',
}
export enum NodeColorOptions {
    Category = 'Category',
    Type = 'Type',
    StrategicImportance = 'Strategic importance',
    ParentProgram = 'Parent program',
    Portfolio = 'Portfolio',
    Organization = 'Organization',
    Source = 'Source',
    CompetitiveIntensity = 'Competitive intensity',
    Risk = 'Risk',
    Industry = 'Industry',
    ValidationStatus = 'Validation status',
    Timing = 'Timing',
    Urgency = 'Urgency',
    DisableNodeColor = 'Disable node color',
}

export interface AxisOptionsType {
    lowTickLabel: string;
    highTickLabel: string;
    title: string;
    scoreType: ScoreType;
}

export const chartSettings = {
    type: FieldType.CHARTSETTINGS as FieldType.CHARTSETTINGS,
    options: {
        chartData: {
            xAxisOptions: Object.values(AxisOptions),
            yAxisOptions: Object.values(AxisOptions),
            nodeSize: {
                selection: Object.values(NodeSizeOptions),
            },
            nodeColor: {
                selection: Object.values(NodeColorOptions),
            },
            hideGaps: {
                enabled: true,
                text: 'Hide skills with no gaps',
            },
        },
        layout: [
            {
                enabled: true,
                text: 'Show grid lines',
                include: [],
            },
        ],
    },
};
