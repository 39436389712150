import { styled } from '@mui/system';

import RectangleRoundedIcon from '@mui/icons-material/RectangleRounded';
import { Category, getCategoryHex } from '../helpers/category';

interface StyledCardIconProps {
    category: Category;
}

export const StyledCardIcon = styled(RectangleRoundedIcon)(
    ({ category }: StyledCardIconProps) => {
        return {
            fill: getCategoryHex(category),
            transform: 'rotate(90deg)',
        };
    }
);
