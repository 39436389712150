import { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ReactComponent as Navigate } from '../../../assets/icons/Navigate.svg';
import {
    Card,
    CardSet,
    CardsOrderedByNameQuery,
    AttributeType,
} from '../../../API';
import { cardsOrderedByName } from '../../../graphql/queries';
import { getUserOrganisation } from '../../../helpers/auth';
import { CardContext } from '../context';
import { generateClient } from 'aws-amplify/api';
import { AppContext } from '../../contexts';

interface CardDetailsProps {
    values?: string | string[] | null;
    selectCard?: (card: Card | CardSet) => void;
    type: AttributeType;
}

const CardDetailsCard = ({ values, selectCard, type }: CardDetailsProps) => {
    const [cards, setCard] = useState<Card[] | null>(null);

    const { setRelationCard } = useContext(CardContext);
    const { user } = useContext(AppContext);

    const client = generateClient();

    useEffect(() => {
        const getCardInformation = async () => {
            const userGroup = await getUserOrganisation(user);

            const filter: { id: { eq: string } }[] = [];

            const ids: string[] =
                typeof values === 'string' ? JSON.parse(values ?? '') : values;

            ids.forEach((value) => {
                filter.push({ id: { eq: value } });
            });
            if (ids.length) {
                try {
                    const response = (await client.graphql({
                        query: cardsOrderedByName,
                        variables: {
                            filter: { or: filter },
                            organisation: userGroup,
                        },
                    })) as { data: CardsOrderedByNameQuery };

                    const cards = response?.data?.cardsOrderedByName?.items;

                    if (cards) {
                        setCard(cards as Card[]);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        };
        if (values && user) {
            getCardInformation();
        }
    }, [values, user]);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {cards
                ? cards.map((card) => {
                      return (
                          <Box
                              sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  my: 1,
                              }}
                          >
                              <Typography>{card?.name}</Typography>
                              <IconButton
                                  data-testid="card-relation"
                                  onClick={() => {
                                      card &&
                                          setRelationCard &&
                                          setRelationCard(card as Card);
                                  }}
                                  sx={{
                                      cursor: 'pointer',
                                      width: '40px',
                                      height: '40px',
                                  }}
                              >
                                  <Navigate style={{ width: '30px' }} />
                              </IconButton>
                          </Box>
                      );
                  })
                : 'No cards selected'}
        </Box>
    );
};

export default CardDetailsCard;
