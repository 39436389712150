import React from 'react';
import {
    Box,
    FormControl,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { monthLabels } from '../../../helpers/charts';
import {
    CreateOrUpdateScoreDataInput,
    ScoreDatum,
    ScoreType,
} from '../../../API';

interface ScoreItemInterface {
    focusedIndex: number | undefined | null;
    scoreDatum: ScoreDatum;
    date: Date;
    handleInputChange: (event: React.BaseSyntheticEvent, value: number) => void;
    i: number;
    scoreType: ScoreType;
    reverseMaxMin: boolean;
    max: number | undefined;
    min: number | undefined;
    deleteScoreDatum: (i: number) => void;
}

const IndicatorItem = ({
    scoreDatum,
    date,
    handleInputChange,
    i,
    deleteScoreDatum,
}: ScoreItemInterface) => {
    const theme = useTheme();

    const radioValues = [
        { value: 0, color: theme.palette.grey[700] },
        { value: 1, color: theme.palette.warning.main },
        { value: 2, color: theme.palette.warning.light },
        { value: 3, color: theme.palette.success.light },
    ];

    return (
        <Box
            key={`${scoreDatum.date}-box`}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Typography sx={{ marginRight: 3, width: '100px' }}>{`${
                monthLabels[date.getMonth()]
            } ${date.getFullYear()}`}</Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="health-radio-buttons"
                        name="row-radio-buttons-group"
                        value={scoreDatum.value}
                    >
                        {radioValues.map((button) => {
                            return (
                                <FormControlLabel
                                    value={button.value}
                                    control={<Radio />}
                                    label={button.value}
                                    onChange={(
                                        event: React.BaseSyntheticEvent
                                    ) => {
                                        handleInputChange(event, i);
                                    }}
                                    sx={{
                                        mr: 1,
                                        '.MuiTypography-root': {
                                            display: 'none',
                                        },
                                        '.MuiSvgIcon-root': {
                                            background: button.color,
                                            fill: 'none',
                                        },
                                        '.Mui-checked': {
                                            '.MuiSvgIcon-root': {
                                                border: `solid 3px ${theme.palette.primary.light}`,
                                            },
                                        },
                                    }}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
            </Box>
            <TextField
                sx={{
                    width: '12rem',
                    mr: 1,
                }}
                name="comment"
                size="small"
                placeholder="comment"
                value={scoreDatum.comment}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(event, i)
                }
                inputProps={{ maxLength: 1000 }}
            />
            <IconButton
                onClick={() => deleteScoreDatum(i)}
                title="Edit"
                color="secondary"
            >
                <ClearIcon style={{ width: '30px', height: '30px' }} />
            </IconButton>
        </Box>
    );
};

export default IndicatorItem;
