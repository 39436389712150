import React, { useContext, useEffect, lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AppContext } from './components/contexts';

import Home from './pages';

const LazyCards = lazy(() => import('./pages/cards'));
const LazyCardSets = lazy(() => import('./pages/card-sets'));
const LazyCardSet = lazy(() => import('./pages/card-sets/[pid]'));
const LazyPlayBooks = lazy(() => import('./pages/playbooks'));
const LazyPlayBook = lazy(() => import('./pages/playbooks/[pid]'));
const LazyProfile = lazy(() => import('./pages/profile'));
const LazyWorkBooks = lazy(() => import('./pages/workbooks'));
const LazyWorkBook = lazy(() => import('./pages/workbooks/[pid]'));
const LazyWorkSheet = lazy(() => import('./pages/worksheets/[pid]'));
const LazyStrategyBuilder = lazy(() => import('./pages/strategy-builder'));
const LazyDashboard = lazy(() => import('./pages/dashboard'));

const RouterProvider = () => {
    const { setLoaded } = useContext(AppContext);
    const location = useLocation();

    useEffect(() => {
        setLoaded && setLoaded(false);
    }, [location]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/cards" element={<LazyCards />} />
                <Route path="/card-sets" element={<LazyCardSets />} />
                <Route path="/card-sets/:id" element={<LazyCardSet />} />
                <Route path="/playbooks" element={<LazyPlayBooks />} />
                <Route path="/playbooks/:id" element={<LazyPlayBook />} />
                <Route path="/workbooks" element={<LazyWorkBooks />} />
                <Route path="/workbooks/:id" element={<LazyWorkBook />} />
                <Route
                    path="/workbooks/:workbookId/:id"
                    element={<LazyWorkSheet />}
                />
                <Route path="/profile" element={<LazyProfile />} />
                <Route
                    path="/strategy-builder"
                    element={<LazyStrategyBuilder />}
                />
                <Route path="/dashboard" element={<LazyDashboard />} />
            </Routes>
        </Suspense>
    );
};

export default RouterProvider;
