import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ReactComponent as ListIcon } from '../../../assets/images/process-thumbnails/Playboolk-list-page.svg';
import { processSteps } from '../processSteps';
import { getWorksheetThumbnail } from '../../../helpers/worksheets';
import { SelectedWorksheet } from '.';
import { worksheets } from '../worksheets';

interface WorksheetsProps {
    selectedStepId?: string | null;
    selectedWorksheet: SelectedWorksheet | null;
    setSelectedWorksheet: React.Dispatch<
        React.SetStateAction<SelectedWorksheet | null>
    >;
    selectedType: string | undefined | null;
}
export const WorksheetAvatar = ({
    worksheet,
}: {
    worksheet: SelectedWorksheet;
}) => {
    const WorksheetIcon =
        worksheet.t === 'List' ? ListIcon : getWorksheetThumbnail(worksheet.n);
    const greyOutStyle = { filter: 'grayscale(100%)' };

    if (!WorksheetIcon) {
        console.error(`Icon not found for worksheet: ${worksheet}`);
        return null;
    }

    return (
        <Box sx={{ ...(worksheet.e === false && greyOutStyle) }}>
            <WorksheetIcon />
        </Box>
    );
};
const Worksheets = ({
    selectedStepId,
    selectedWorksheet,
    setSelectedWorksheet,
    selectedType,
}: WorksheetsProps) => {
    const theme = useTheme();

    interface CardTypeIdsMap {
        [key: string]: string[];
    }

    const [cardTypeIds, setCardTypeIds] = useState<CardTypeIdsMap>({});
    const [data, setData] = useState<string[]>([]);

    useEffect(() => {
        let idsMap: CardTypeIdsMap = {};

        worksheets.forEach((worksheet) => {
            worksheet.auto.forEach((autoId) => {
                if (!idsMap[autoId]) {
                    idsMap[autoId] = [];
                }
                idsMap[autoId].push(worksheet.i);
            });
        });

        setCardTypeIds(idsMap);
    }, []);

    useEffect(() => {
        if (selectedType && cardTypeIds[selectedType]) {
            setData(cardTypeIds[selectedType]);
        } else if (selectedStepId) {
            const stepData = processSteps.flatMap((process) =>
                process.steps
                    .filter((item) => item.id === selectedStepId)
                    .flatMap((step) =>
                        step.worksheets.map((worksheet) => worksheet.i)
                    )
            );
            setData(stepData);
        } else {
            setData([]);
        }
    }, [selectedType, selectedStepId, cardTypeIds]);

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderLeft: 'solid 1px #ccc',
                borderRight: 'solid 1px #ccc',
            }}
        >
            <Box
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.default
                            : '#efefef',
                    py: 1,
                    px: 3,
                    borderTop: 'solid 1px #ccc',
                    borderBottom: 'solid 1px #ccc',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    Playbook page
                </Typography>
            </Box>
            <Box sx={{ padding: 1, overflowY: 'auto' }}>
                <Grid container spacing={1} sx={{ height: '100%' }}>
                    {data.map((worksheetId) => {
                        const worksheet = worksheets.find(
                            (w) => w.i === worksheetId
                        );
                        return (
                            <Grid item md={4} key={worksheetId}>
                                {worksheet && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            cursor: worksheet.e
                                                ? 'pointer'
                                                : 'default',
                                            border:
                                                selectedWorksheet?.n ===
                                                worksheet.n
                                                    ? `3px solid ${theme.palette.primary.main}`
                                                    : 'inherit',
                                            borderRadius: '10px',
                                            overflow: 'hidden',
                                            px: 1,
                                            height: '100%',
                                            textAlign: 'center',
                                            color: !worksheet.e
                                                ? theme.palette.text.disabled
                                                : 'inherit',
                                        }}
                                        onClick={() =>
                                            worksheet.e &&
                                            setSelectedWorksheet(worksheet)
                                        }
                                    >
                                        <WorksheetAvatar
                                            worksheet={worksheet}
                                        />
                                        <Typography
                                            variant="body1"
                                            sx={{ fontWeight: 'bold', my: 0.5 }}
                                        >
                                            {worksheet.n}
                                        </Typography>
                                        <Typography variant="body2">
                                            {worksheet.d}
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Box>
    );
};

export default Worksheets;
