import { SxProps } from '@mui/material';
import { DateTime } from 'luxon';
import { AttributeType } from '../../../API';

import { CardSettings } from '../../playbooks/EditablePlaybookPage';

export enum FieldType {
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
    RICHTEXT = 'richtext',
    CARDSELECTOR = 'cardselector',
    WORKSHEETSELECTOR = 'worksheetselector',
    CHARTSETTINGS = 'chartsettings',
    LAYOUTSETTINGS = 'layoutsetting',
    TITLECONFIG = 'titleconfig',
}

export interface StringOptionType {
    label: string;
    value: string;
    help?: string;
    options?: StringOptionType[];
}

export interface CheckboxOptionType {
    label: string;
    value: string;
    defaultChecked?: boolean;
    children?: any;
    disabled?: boolean;
    enabled?: boolean;
}

export interface BaseFieldProps {
    label?: string;
    name: string;
    type: AttributeType | FieldType;
    sx?: SxProps;
    required?: boolean;
    disabled?: boolean;
    maxLength?: number;
}

interface TextFieldAttributes extends BaseFieldProps {
    type: AttributeType.Text;
    value?: string;
    defaultValue?: string;
}

export interface MultilineTextFieldAttributes extends BaseFieldProps {
    type: AttributeType.MultilineText;
    value?: string;
    defaultValue?: string;
}

export interface MultiTextFieldProps extends BaseFieldProps {
    fieldsConfig: string[];
    // TODO: Remove or get a proper associated type
    type: AttributeType.MultiSelectWithAssociatedText;
    defaultValue?: string;
}

export interface SelectFieldAttributes extends BaseFieldProps {
    type: AttributeType.SingleSelect;
    options?: StringOptionType[];
    text?: string;
    defaultValue?: string;
    unAvailable?: string;
}

export interface UserFieldAttributes extends BaseFieldProps {
    type: AttributeType.User;
    text?: string;
    defaultValue?: string;
}

export interface CardFieldAttributes extends BaseFieldProps {
    type: AttributeType.Card | AttributeType.CardSet;
    options?: StringOptionType[];
    values?: String[];
    text?: string;
    defaultValue?: string;
}

export interface CardsFieldAttributes extends BaseFieldProps {
    type: AttributeType.Cards;
    options?: StringOptionType[];
    values?: String[];
    text?: string;
    defaultValue?: string;
}

export interface CardSelectorAttributes extends BaseFieldProps {
    type: FieldType.CARDSELECTOR;
    cardSelector?: CardSettings;
    text?: string;
    defaultValue?: string;
    onChangeCards?: (name: string, value: string) => void;
}

export interface WorksheetSelectorAttributes extends BaseFieldProps {
    type: FieldType.WORKSHEETSELECTOR;
    onChange?: (event: {
        target: {
            value: string;
        };
    }) => void;
    selectValue?: string;
    removed?: string[];
    selected?: string[];
    workbookForm?: boolean;
    loading?: boolean;
}

export interface TitleConfigAttributes extends BaseFieldProps {
    hideTitle?: boolean;
    type: FieldType.TITLECONFIG;
    onChange?: (event: {
        target: {
            value: string;
        };
    }) => void;
    selectValue?: string;
    removed?: string[];
    selected?: string[];
    maxWidth?: string;
    padding?: string;
}

export interface MultiSelectFieldProps extends BaseFieldProps {
    type: AttributeType.MultiSelect;
    value: URL;
    text?: string;
    defaultValue: string;
}

export interface URLFieldAttributes extends BaseFieldProps {
    type: AttributeType.URL;
    value?: URL | string;
    defaultValue?: string;
}

export interface CheckboxFieldAttributes extends BaseFieldProps {
    type: FieldType.CHECKBOX;
    options?: CheckboxOptionType[];
    subgroup?: boolean;
    disabled?: boolean;
    onChangeCheckbox?: (name: string, value: string) => void;
    values?: string[];
}

export interface RadioFieldAttributes extends BaseFieldProps {
    type: FieldType.RADIO;
    defaultValue?: string;
    value?: string;
    options?: StringOptionType[];
    onChange?: (event: {
        target: {
            value: string;
        };
    }) => void;
}

export interface DateFieldAttributes extends BaseFieldProps {
    type: AttributeType.Date;
    value?: string;
    disabled?: boolean;
    onChange?: (dateTime: DateTime | null) => void;
    defaultValue?: DateTime;
}
export interface ChartSettingsFieldAttributes extends BaseFieldProps {
    type: FieldType.CHARTSETTINGS;
}
export interface LayoutSettingsFieldAttributes extends BaseFieldProps {
    type: FieldType.LAYOUTSETTINGS;
}

export type TextFieldProps = TextFieldAttributes;
export type SelectFieldProps = SelectFieldAttributes;
export type MultilineTextFieldProps = MultilineTextFieldAttributes;
export type CheckboxFieldProps = CheckboxFieldAttributes;
export type RadioFieldProps = RadioFieldAttributes;
export type URLFieldProps = URLFieldAttributes;
export type DateFieldProps = DateFieldAttributes;
export type UserFieldProps = UserFieldAttributes;
export type CardFieldProps = CardFieldAttributes;
export type CardsFieldProps = CardsFieldAttributes;
export type CardSetFieldProps = CardFieldAttributes;
export type CardSelectorFieldProps = CardSelectorAttributes;
export type WorksheetSelectorFieldProps = WorksheetSelectorAttributes;
export type TitleConfigFieldProps = TitleConfigAttributes;

type FieldProps =
    | TextFieldProps
    | MultilineTextFieldProps
    | SelectFieldProps
    | MultiSelectFieldProps
    | URLFieldProps
    | CheckboxFieldProps
    | RadioFieldProps
    | DateFieldProps
    | UserFieldProps
    | CardFieldProps
    | CardsFieldProps
    | CardSetFieldProps
    | CardSelectorFieldProps
    | WorksheetSelectorFieldProps
    | ChartSettingsFieldAttributes
    | LayoutSettingsFieldAttributes
    | TitleConfigAttributes;

export type FormConfiguration = Array<FieldProps | FieldProps[]>;

export default FieldProps;
