import {
    Box,
    FormControl,
    InputAdornment,
    InputLabel,
    styled,
    useTheme,
    keyframes,
    Tooltip,
    Typography,
} from '@mui/material';
import MuiTextField, {
    TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import {
    MultilineTextFieldProps,
    TextFieldProps,
    URLFieldProps,
} from './inputFieldTypes';
import AiIcon from '@mui/icons-material/AutoAwesome';
import TypingDots from '../CreateCard/TypingDots';

export const colorChange = keyframes`
    0% {
        color: gold;
    }
    50% {
        color:#1976d2;
    }
    100% {
        color: gold;
    }
`;
export const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
export const StyledMuiTextField = styled(MuiTextField)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    width: '100%',
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-root': {
        padding: '0 12px',
        borderRadius: 0,
    },
    '& .MuiInputBase-input': {
        fontSize: 16,
        padding: '10px 0',
        '&::placeholder': {
            fontSize: '12px',
            opacity: 0.7,
            color: theme.palette.text.secondary,
        },
    },
    '& .MuiInputBase-inputMultiline': {
        padding: '10px 0',
    },
    '& .Mui-disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
    },
}));

export interface AiSuggestionProps {
    briefDescription?: string | null;
    getAiSuggestion?: () => void;
    typing?: boolean;
    aiKeyExists?: boolean;
}
const TextField = (
    props: (TextFieldProps | MultilineTextFieldProps | URLFieldProps) &
        MuiTextFieldProps &
        AiSuggestionProps
) => {
    const {
        name,
        label,
        multiline,
        rows,
        defaultValue,
        required,
        value,
        helperText,
        onChange,
        maxLength,
        briefDescription,
        getAiSuggestion,
        typing,
        aiKeyExists,
        inputRef
    } = props;

    const theme = useTheme();
    const showTypingDots = name === 'name' && aiKeyExists && briefDescription && briefDescription.length > 20 && typing;
    return (
        <FormControl
            variant="standard"
            sx={{ paddingTop: '5px', width: '100%' }}
        >
            <InputLabel
                shrink
                htmlFor="styled-input"
                sx={{
                    background: 'initial',
                    color: theme.palette.text.primary,
                    fontSize: '1.6em',
                    fontFamily: 'Antonio,sans-serif',
                    mb: 2,
                    '&.Mui-focused': {
                        color: theme.palette.text.primary,
                    },
                }}
            >
                {label} {required ? '*' : ''}
            </InputLabel>
            <StyledMuiTextField
                inputRef={inputRef}
                onChange={onChange}
                name={name}
                data-automation-id={name}
                required={required}
                value={value || ''}
                size="small"
                multiline={multiline}
                rows={rows}
                disabled={props.disabled}
                inputProps={{
                    maxLength: maxLength,
                }}
                placeholder={(name === 'name' || name === 'Name') && !typing && aiKeyExists ? ' Enter a brief description to get an AI-generated name.' : ''}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {showTypingDots ? (
                                <TypingDots />
                            ) : null}
                        </InputAdornment>
                    ),
                    endAdornment:
                        name === 'name' &&
                            aiKeyExists &&
                            briefDescription &&
                            briefDescription.length > 20 ? (
                            <InputAdornment
                                position="end"
                                sx={{
                                    margin: 0,
                                    border: 'none',
                                    padding: 0,
                                }}
                            >
                                <Box
                                    onClick={
                                        !typing ? getAiSuggestion : undefined
                                    }
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: !typing ? 'pointer' : 'default',
                                        padding: 0,
                                    }}
                                >
                                    <Tooltip
                                        title={
                                            typing
                                                ? ''
                                                : 'Get an AI-generated name suggestion'
                                        }
                                    >
                                        <AiIcon
                                            sx={{
                                                fontSize: '2rem',
                                                width: '25px',
                                                height: '25px',
                                                border: 'none',
                                                ml: 0.8,
                                                color: typing
                                                    ? 'grey'
                                                    : '#1976d2',
                                                animation: typing
                                                    ? 'none'
                                                    : `${colorChange} 2s infinite, ${spin} 5s linear infinite`,
                                            }}
                                        />
                                    </Tooltip>
                                </Box>
                            </InputAdornment>
                        ) : null,
                }}
            />
        </FormControl>
    );
};

export default TextField;
