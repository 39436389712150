import React from 'react';
import {
    Box,
    Grid,
    SxProps,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { truncateText } from '../../helpers/utils';
import { NodeColorOptions } from '../playbooks/playbookTypes';
import { CustomDataPoint } from './BubbleChart';

interface CustomLegendProps {
    sx?: SxProps<Theme>;
    labels: string[];
    data?: number[];
    colors?: string[];
    title: string;
    thumbnail: boolean | undefined;
    border?: boolean;
    chartData?: CustomDataPoint[][];
    workbook?: boolean | undefined;
    pptView?: boolean;
    customLabelSize?: string;
    width?: string | number;
    worksheet?: boolean;
    setActiveCardLabel?: React.Dispatch<React.SetStateAction<string | null>>;
    rightPanel?: boolean | undefined;
}
const getStyles = (
    thumbnail: boolean | undefined,
    pptView: boolean | undefined,
    customLabelSize: string | undefined
) => {
    return {
        boxPaddingTop: thumbnail ? 1 : 2,
        boxMarginLeft: thumbnail ? 0 : 1,
        fontSize:
            customLabelSize && pptView
                ? customLabelSize
                : !thumbnail && pptView
                ? '48px'
                : thumbnail && !pptView
                ? '6px'
                : '16px',
        textMarginBottom: thumbnail ? 0.5 : 1,
        itemMarginBottom: thumbnail ? 0.2 : 0.8,
        itemPaddingBottom: thumbnail ? 0 : 0.2,
        colorBoxSize:
            customLabelSize && pptView
                ? customLabelSize
                : !thumbnail && pptView
                ? '48px'
                : thumbnail && !pptView
                ? '6px'
                : '16px',
        itemMarginLeft:
            !thumbnail && pptView ? 3 : thumbnail && !pptView ? 0.3 : 1,
    };
};
interface LegendItemProps {
    item: {
        label: string;
        data?: number;
        color: string;
        border: boolean | undefined;
        theme: Theme;
    };
    styles: {
        [key: string]: any;
    };
    thumbnail: boolean | undefined;
    pptView?: boolean;
    title?: string;
    worksheet?: boolean;
    setActiveCardLabel?: React.Dispatch<React.SetStateAction<string | null>>;
}

const LegendItem = ({
    item,
    styles,
    thumbnail,
    pptView,
    title,
    worksheet,
    setActiveCardLabel,
}: LegendItemProps) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            '&:not(:last-child)': {
                borderBottom: item.border
                    ? `1px solid ${item.theme.palette.grey[400]}`
                    : 'none',
            },
            mb: styles.itemMarginBottom,
            pb: styles.itemPaddingBottom,
            mr: styles.itemMarginLeft,
            cursor: 'pointer',
        }}
        onClick={() => setActiveCardLabel && setActiveCardLabel(item.label)}
    >
        <Box
            sx={{
                backgroundColor: item.color,
                height: styles.colorBoxSize,
                width: styles.colorBoxSize,
            }}
        />
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexGrow: 1,
                ml: styles.itemMarginLeft,
            }}
        >
            <Tooltip title={item.label}>
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: styles.fontSize,
                        width: pptView ? '200px' : 'initial',
                    }}
                >
                    {pptView && !title
                        ? truncateText(item.label, 22)
                        : (pptView && title) || worksheet
                        ? item.label
                        : thumbnail
                        ? truncateText(item.label, 7)
                        : truncateText(item.label, 25)}
                </Typography>
            </Tooltip>
            <Typography
                variant="body2"
                sx={{ fontSize: styles.fontSize, ml: pptView ? 2 : 0.5 }}
            >
                {item.data}
            </Typography>
        </Box>
    </Box>
);
const CustomLegend: React.FC<CustomLegendProps> = ({
    sx,
    labels,
    data,
    colors,
    title,
    thumbnail,
    border,
    chartData,
    workbook,
    pptView,
    customLabelSize,
    width,
    worksheet,
    setActiveCardLabel,
    rightPanel,
}) => {
    const theme = useTheme();
    const blankData: CustomDataPoint[][] | undefined = chartData?.filter(
        (item) => {
            return item[0].name === '';
        }
    );
    const blankDataItems =
        blankData &&
        blankData[0]?.map((item) => ({
            label: `No ${title?.toLowerCase()}`,
            color: 'grey',
            data: undefined,
        }));

    const slicedLabels = thumbnail && !pptView ? labels.slice(0, 3) : labels;
    const slicedData = thumbnail && !pptView ? data?.slice(0, 3) : data;
    const combinedData = slicedLabels.map((label, index) => ({
        label: label,
        data: slicedData && slicedData[index],
        color: colors && colors[index] ? colors[index] : 'grey',
    }));

    const combinedDataWithBlankValues = combinedData.concat(
        blankDataItems || []
    );

    const styles = getStyles(thumbnail, pptView, customLabelSize);

    const splitArrayIntoHalves = (array: any) => {
        const half = Math.ceil(array.length / 2);
        return [array.slice(0, half), array.slice(half)];
    };
    const isTwoColumnLayout = !rightPanel
        ? false
        : rightPanel
        ? true
        : title === 'Competitors'
        ? false
        : combinedDataWithBlankValues.length > 4 || false;
    const [firstHalf, secondHalf] = isTwoColumnLayout
        ? splitArrayIntoHalves(combinedDataWithBlankValues)
        : [combinedDataWithBlankValues, []];

    return (
        <Box
            sx={{
                ...sx,
                pt: styles.boxPaddingTop,
                ml: styles.boxMarginLeft,
                display:
                    title === NodeColorOptions.StrategicImportance ||
                    title === NodeColorOptions.CompetitiveIntensity ||
                    title === NodeColorOptions.Risk
                        ? 'flex'
                        : 'block',
                color: workbook ? theme.palette.text.secondary : 'grey.700',
                width:
                    width ??
                    (pptView ? '100%' : workbook && !pptView ? '60%' : '100%'),
                    
            }}
        >
            <Typography
                variant="body2"
                sx={{
                    fontSize: styles.fontSize,
                    fontWeight: 'bold',
                    mb: styles.textMarginBottom,
                    mr: 2,
                }}
            >
                {title}
            </Typography>
            {isTwoColumnLayout &&
            !pptView &&
            title !== 'Legend' &&
            title !== NodeColorOptions.StrategicImportance &&
            title !== NodeColorOptions.CompetitiveIntensity ? (
                <Grid container spacing={2}>
                    {[firstHalf, secondHalf].map((half, halfIndex) => (
                        <Grid item xs={6} key={halfIndex}>
                            {half.map((item: any, index: number) => (
                                <LegendItem
                                    key={index}
                                    item={{ ...item, border, theme }}
                                    styles={styles}
                                    thumbnail={thumbnail}
                                    pptView={pptView}
                                    worksheet={worksheet}
                                    setActiveCardLabel={setActiveCardLabel}
                                />
                            ))}
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <>
                    {combinedDataWithBlankValues.map((item, index) => (
                        <LegendItem
                            key={index}
                            item={{ ...item, border, theme }}
                            styles={styles}
                            thumbnail={thumbnail}
                            pptView={pptView}
                            title={title}
                            worksheet={worksheet}
                            setActiveCardLabel={setActiveCardLabel}
                        />
                    ))}
                </>
            )}
        </Box>
    );
};
export default CustomLegend;
