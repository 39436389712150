/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://3sqqcht3fze4xnooh4oloulcc4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-teka6i2nzbezfddmx6p5m4buoe",
    "aws_cognito_identity_pool_id": "eu-west-2:cf8bbe1b-d917-44ce-8c19-e0b7d1d6b668",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_84fy3Rs9W",
    "aws_user_pools_web_client_id": "4mege0vgun0vk0ok2sm2fbcs4t",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "strategycardsnext67f467252d464011a5b7067c6708bf150906-prod",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
