import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

export const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    main: '#3d74de',
                },
                secondary: {
                    main: '#3f4957',
                },
                background: {
                    paper: '#f0f2f5',
                },
                success: {
                    main: '#8BC34A',
                },
                warning: {
                    main: '#F44336',
                    dark: '#F44336',
                    light: '#FFAC3B',
                },
            },
        },
        dark: {
            palette: {
                primary: {
                    main: '#3d74de',
                },
                secondary: {
                    main: '#3f4957',
                },
                background: {
                    default: '#222222',
                    paper: '#555555',
                },
                success: {
                    main: '#8BC34A',
                },
                warning: {
                    main: '#F44336',
                    dark: '#F44336',
                    light: '#FFAC3B',
                },
            },
        },
    },
    typography: {
        h1: {
            fontFamily: 'Antonio, sans-serif',
            fontSize: '2.225rem',
        },
        h4: {
            fontFamily: 'Antonio, sans-serif',
        },
        h5: {
            fontFamily: 'Antonio, sans-serif',
        },
        h6: {
            fontFamily: 'Antonio, sans-serif',
        },
    },
    components: {
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    paddingTop: '0.75rem',
                    paddingBottom: '0.75rem',
                    height: '3.5rem',
                },
                action: {
                    alignSelf: 'center',
                    marginTop: 0,
                    marginBottom: 0,
                    marginRight: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'inherit',
                },
                containedPrimary: {
                    background: '#2979FF',
                    border: 'solid 1px #2979FF',
                    boxShadow: 'none',
                    color: '#FFFFFF',
                    fontSize: '16px',
                    borderRadius: '5px',
                    height: '40px',
                    padding: '0 30px',
                    ':hover': {
                        background: '#004EEC',
                        border: 'solid 1px #004EEC',
                        boxShadow: 'none',
                    },
                },
                containedSecondary: {
                    background: '#FFFFFF',
                    border: 'solid 1px #2979FF',
                    boxShadow: 'none',
                    color: '#2979FF',
                    fontSize: '16px',
                    borderRadius: '5px',
                    height: '40px',
                    padding: '0 30px',
                    ':hover': {
                        background: '#E6F0FF',
                        boxShadow: 'none',
                    },
                },
                sizeMedium: {},
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#ba000d',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    color: 'inherit',
                    ':hover': {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    },
                },
            },
        },
    },
});

export default theme;
