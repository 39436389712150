import React from 'react';
import ReactDOM from 'react-dom/client';
import AuthComponent from './AuthComponent';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <>
        <AuthComponent />
    </>
);
