export const worksheets = [
    {
        i: '1',
        n: 'SWOT analysis',
        t: 'Chart',
        e: true,
        auto: ['2', '4', '5', '6'],
        d: 'Determine your strategic position',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'SWOT analysis assesses an organization’s strengths and weaknesses, as well as opportunities and threats. By evaluating these four factors, businesses can gain insights into their current strategic position. Strengths and weaknesses pertain to internal factors, while Opportunities and Threats are external. This analysis helps organizations capitalize on strengths, address weaknesses, exploit opportunities and mitigate threats; ultimately guiding the identification and evaluation of future strategic options.',
            s2: 'Inside a workbook, create a strength, weakness, opportunity or threat and a SWOT analysis will be added to the Worksheets drawer. As you add more SWOT cards, the analysis will automatically update. When SWOT cards are scored, tiles in the SWOT analysis will be shaded green (for strengths and opportunities), or red (weaknesses and threats) based on these scores.',
        },
    },
    {
        i: '2',
        n: 'Scorecard',
        t: 'Chart',
        e: false,
        auto: ['22', '40', '41', '20', '39', '21'],
        d: 'Track KPI performance over time',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: "Scorecards are used to monitor and measure an organization's performance against predefined objectives. They typically include a set of quantifiable key performance indicators (KPIs) that reflect critical operational and strategic aspects. By regularly assessing KPIs, companies can identify trends, track progress, and make data-driven decisions. The scorecard acts as a visual representation, facilitating quick and informed strategic adjustments to ensure alignment with organizational goals and continuous improvement in overall performance.",
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '3',
        n: 'Strategy tree',
        t: 'Chart',
        e: false,
        auto: [
            '22',
            '40',
            '41',
            '20',
            '39',
            '21',
            '24',
            '23',
            '26',
            '25',
            '28',
            '29',
        ],
        d: 'Visualize relationships between objectives, KPIs and action plans',
        src: {
            cards: false,
            cardSets: false,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: "A strategy map is a visual representation that outlines an organization's strategic objectives, linking them in a cause-and-effect relationship. Typically aligned with the Balanced Scorecard framework, it illustrates how various elements, such as financial, customer, internal processes, and learning and growth, contribute to the achievement of the overall strategy. By providing a clear, concise overview of strategic goals and their interdependencies, a strategy map aids in effective communication, alignment of organizational activities, and the successful execution of strategic initiatives.",
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '4',
        n: 'X-Matrix',
        t: 'Chart',
        e: false,
        auto: [
            '22',
            '40',
            '41',
            '20',
            '39',
            '21',
            '24',
            '23',
            '26',
            '25',
            '28',
            '29',
        ],
        d: 'Ensure alignment between objectives, KPIs and action plans',
        src: {
            cards: false,
            cardSets: false,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'The X-Matrix is a strategic planning framework derived from the Hoshin Kanri methodology, aligning organizational goals with actionable plans. Represented as a four-quadrant matrix, it integrates long-term breakthrough objectives, annual  objectives, key performance indicators (KPIs), and improvement initiatives (programs and projects). This visual tool fosters cross-functional alignment and communication, linking strategy deployment with execution.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '5',
        n: 'Project analysis',
        t: 'Chart',
        e: true,
        auto: ['29'],
        d: 'Compare multiple projects',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'A bubble chart in project portfolio management visually represents projects based on their strategic value, risks, and resource allocation. Each bubble represents a project, with its size indicating project scale and its position reflecting the correlation between factors like risk and return. This dynamic visualization helps stakeholders make informed decisions about portfolio composition and resource allocation. By offering a comprehensive view of project relationships and priorities, bubble charts enhance strategic planning and optimize project portfolios for organizational success.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '6',
        n: 'Action plan roadmap',
        t: 'Chart',
        e: false,
        auto: ['28', '29'],
        d: 'View planned delivery of action plans on the planning horizon',
        src: {
            cards: false,
            cardSets: false,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: "A roadmap for managing programs and projects is a strategic plan that outlines the phases, milestones, and timelines of a project's lifecycle. It serves as a visual guide, enabling effective communication and coordination among team members, stakeholders, and leadership. This comprehensive tool helps align project objectives with organizational goals, identifies dependencies, and manages resources efficiently. By providing a clear trajectory and actionable insights, a roadmap facilitates decision-making, minimizes risks, and ensures successful project delivery within defined timelines and budget constraints.",
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '7',
        n: 'Risk analysis',
        t: 'Chart',
        e: true,
        auto: ['7'],
        d: 'Manage, prioritize and track strategic risks',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'A risk matrix is a visual tool used in risk management to assess and prioritize potential risks based on their likelihood and impact. It categorizes risks into a matrix, typically with a high/medium/low likelihood and a high/medium/low impact. By assigning a risk score, teams can prioritize mitigation efforts for high-risk areas. This method provides a systematic approach to identify, evaluate, and address risks, enabling organizations to allocate resources effectively and make informed decisions to safeguard their projects or operations.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '8',
        n: 'Idea analysis',
        t: 'Chart',
        e: true,
        auto: ['10'],
        d: 'Collect, review and prioritize ideas for strategic growth',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Idea prioritization is a systematic process of evaluating and ranking potential initiatives or concepts based on predefined criteria. By assessing factors such as feasibility, impact, alignment with organizational goals, and resource requirements, teams can assign priority levels to ideas. This method ensures that limited resources are allocated to the most valuable and achievable initiatives, enhancing overall efficiency and strategic focus. Idea prioritization is crucial for effective innovation management and strategic decision-making in dynamic business environments.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '9',
        n: 'Plan analysis',
        t: 'Chart',
        e: false,
        auto: ['47', '7', '28', '29'],
        d: 'View summary information for your strategic plan',
        src: {
            cards: false,
            cardSets: false,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '10',
        n: 'KPI summary',
        t: 'Chart',
        e: false,
        auto: [],
        d: 'Understand KPI performance and progress with any countermeasures',
        src: {
            cards: true,
            cardSets: false,
            workbooks: false,
        },
        lp: null,
        h: {
            s1: 'A KPI Summary (or "A3") is a one-page report used to communicate Key Performance Indicator (KPIs) performance data in a concise manner. It serves as a visual tool for displaying KPI performance and progress with action plans that impact the KPI and improvement countermeasures.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '11',
        n: 'Project summary',
        t: 'Chart',
        e: false,
        auto: [],
        d: 'Track progress of a project',
        src: {
            cards: true,
            cardSets: false,
            workbooks: false,
        },
        lp: null,
        h: {
            s1: 'A Project Summary (or "A3") is a structured, one-page report summarizing key project management details, including objectives, KPIs, and action plans. It serves as a visual tool, promoting clear communication and alignment among team members and other stakeholders. A Project Summary facilitates efficient decision-making, problem-solving, and project management by presenting a concise overview of essential project elements.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '12',
        n: 'Program summary',
        t: 'Chart',
        e: false,
        auto: [],
        d: 'Track progress of the overall program and its projects',
        src: {
            cards: true,
            cardSets: false,
            workbooks: false,
        },
        lp: null,
        h: {
            s1: 'A Program Summary (or "A3") is a one-page report outlining key elements of a program and its associated projects, including objectives and KPIs. It provides a visual framework for program management, fostering communication and alignment among stakeholders and project teams. The Program Summary aids in decision-making, problem-solving, and overall coordination, offering a concise overview of program essentials.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '13',
        n: 'Portfolio summary',
        t: 'Chart',
        e: false,
        auto: [],
        d: 'Understand portfolio composition and progress of its programs and projects',
        src: {
            cards: true,
            cardSets: false,
            workbooks: false,
        },
        lp: null,
        h: {
            s1: 'A Portfolio Summary (or "A3") is a one-page report outlining key elements of a portfolio and its associated programs and projects, including associated objectives and KPIs. It provides a visual framework for portfolio management, fostering communication and alignment among stakeholders, program and project teams. The Portfolio Summary aids in prioritization, decision-making, problem-solving, and overall coordination, offering a concise overview of portfolio essentials.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '14',
        n: 'Objective summary',
        t: 'Chart',
        e: false,
        auto: [],
        d: 'Track delivery progress and KPI performance for an objective',
        src: {
            cards: true,
            cardSets: false,
            workbooks: false,
        },
        lp: null,
        h: {
            s1: 'An Objective Summary (or "A3") is a one-page report outlining a specific objective and its associated KPIs, programs and projects. It serves as a visual tool to articulate objectives, measures, and action plans. Used in strategic planning, the Objective Summary promotes clarity, alignment, and effective communication, aiding teams in achieving targeted outcomes with a focused and systematic approach.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '15',
        n: 'Strategy summary',
        t: 'Chart',
        e: false,
        auto: [],
        d: 'Understand how my organization is delivering to its strategic objectives',
        src: {
            cards: false,
            cardSets: false,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'A Strategy Summary (or "A3") is a concise, one-page report outlining key elements of a strategy, including objectives, action plans (programs and projects), and KPIs. Used in strategic planning, it provides a visual framework for communicating and aligning strategies across teams. The Strategy Summary facilitates focused decision-making, implementation, and continuous improvement in achieving strategic objectives.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '16',
        n: 'Organization list',
        t: 'List',
        e: true,
        auto: ['1'],
        d: 'Publish organization information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '1-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '17',
        n: 'Strength list',
        t: 'List',
        e: true,
        auto: ['2'],
        d: 'Publish strength details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '2-A-2',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '2-A-2',
                },
                {
                    t: 'S',
                    id: '2-S-1',
                },
            ],
        },
        h: {
            s1: 'In a SWOT analysis, strengths are internal factors that provide competitive advantage. These attributes distinguish a business from its competitors, contributing to its success. Strengths can encompass superior resources, brand reputation, skilled workforce, or innovative products/services. Recognizing and leveraging strengths allows organizations to capitalize on opportunities and overcome weaknesses or threats. Effective strategic planning involves harnessing these internal advantages to enhance overall performance and achieve sustainable growth in the market.',
            s2: 'Strength cards are in the SWOT category. You can use card sets to group strengths, or create a workbook for not just strengths but also weaknesses, opportunities and threats. You can also score a strength in terms of its impact and link it to specific opportunities.',
        },
    },
    {
        i: '18',
        n: 'Competitor list',
        t: 'List',
        e: true,
        auto: ['3'],
        d: 'Publish competitor information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '3-A-3',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
            ],
        },
        h: {
            s1: 'A competitor is a business or individual that operates in the same industry and offers similar products or services as another entity, vying for the same market share. Competition fosters innovation, pushing companies to enhance their offerings, improve efficiency, and cater to evolving customer needs. Businesses often analyze competitors to gain insights into market trends, pricing strategies, and customer preferences, enabling them to adapt and stay competitive in the dynamic business landscape.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '19',
        n: 'Weakness list',
        t: 'List',
        e: true,
        auto: ['4'],
        d: 'Publish weakness details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '4-A-3',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '4-A-3',
                },
                {
                    t: 'S',
                    id: '4-S-1',
                },
                {
                    t: 'S',
                    id: '4-S-3',
                },
            ],
        },
        h: {
            s1: "In a SWOT analysis, weaknesses are internal factors that hinder a business's optimal performance. These may include deficiencies in resources, outdated technology, or inefficient processes. Identifying weaknesses is crucial for strategic planning, enabling organizations to address and mitigate these internal challenges. Understanding and rectifying weaknesses is essential for maintaining competitiveness, as it allows businesses to adapt, improve, and fortify their operations against potential threats in the dynamic business environment.",
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '20',
        n: 'Opportunity list',
        t: 'List',
        e: true,
        auto: ['5'],
        d: 'Publish opportunity overview',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '5-A-2',
            r: '5-A-3',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '5-A-2',
                },
                {
                    t: 'A',
                    id: '5-A-3',
                },
                {
                    t: 'S',
                    id: '5-S-1',
                },
            ],
        },
        h: {
            s1: 'An opportunity in a SWOT analysis refers to external factors that a business can leverage to its advantage. These favorable circumstances might include emerging market trends, untapped customer segments, or advancements in technology. Recognizing opportunities allows organizations to align strategies with evolving external conditions, fostering growth and competitiveness. By capitalizing on these positive factors, businesses can position themselves to gain a market edge and achieve long-term success in the ever-changing business landscape.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '21',
        n: 'Threat list',
        t: 'List',
        e: true,
        auto: ['6'],
        d: 'Publish threat information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '6-A-2',
            r: '6-A-3',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '6-A-2',
                },
                {
                    t: 'A',
                    id: '6-A-3',
                },
                {
                    t: 'S',
                    id: '6-S-1',
                },
                {
                    t: 'S',
                    id: '6-S-2',
                },
            ],
        },
        h: {
            s1: "A threat in a SWOT analysis represents external factors that could potentially impede a business's success. These may include economic downturns, intense competition, regulatory changes, or technological disruptions. Identifying threats is crucial for strategic planning, enabling organizations to proactively mitigate risks and adapt to changing circumstances. Understanding potential challenges helps businesses fortify their defenses and develop contingency plans, ensuring resilience and sustainability in the face of external uncertainties.",
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '22',
        n: 'Risk list',
        t: 'List',
        e: true,
        auto: ['7'],
        d: 'Publish risk information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '7-A-2',
            r: '7-A-3',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '7-A-2',
                },
                {
                    t: 'A',
                    id: '7-A-3',
                },
            ],
        },
        h: {
            s1: 'A risk is an uncertain event or condition that, if it occurs, could have both positive and negative impacts on project objectives and strategy overall. Risks can arise from various sources, including external factors like market changes or internal issues such as resource constraints. Identifying, analyzing, and managing risks is essential for effective project management, allowing teams to develop proactive strategies to minimize the likelihood and impact of adverse events, ensuring successful project outcomes.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '23',
        n: 'Market Segment list',
        t: 'List',
        e: true,
        auto: ['8'],
        d: 'Publish market segment information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '8-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '8-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '24',
        n: 'Assumption list',
        t: 'List',
        e: true,
        auto: ['9'],
        d: 'Publish assumption information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '9-A-2',
            r: '9-A-5',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '9-A-2',
                },
            ],
        },
        h: {
            s1: 'In strategic planning, an assumption is a belief or expectation about future conditions that serves as the foundation for decision-making. Assumptions are integral to planning but involve uncertainty. They are educated guesses based on available information and experience. However, if assumptions prove incorrect, they can impact the success of the strategy. Regularly revisiting and validating assumptions is crucial for adaptive planning, helping organizations navigate changes and uncertainties, ensuring strategies remain relevant and effective.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '25',
        n: 'Idea list',
        t: 'List',
        e: true,
        auto: ['10'],
        d: 'Publish idea information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '10-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '10-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '26',
        n: 'Trend list',
        t: 'List',
        e: true,
        auto: ['11'],
        d: 'Publish trend information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '11-A-2',
            r: '11-A-3',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '11-A-2',
                },
                {
                    t: 'A',
                    id: '11-A-3',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '27',
        n: 'Force list',
        t: 'List',
        e: true,
        auto: ['12'],
        d: 'Publish force information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '12-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '12-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '28',
        n: 'What Worked list',
        t: 'List',
        e: true,
        auto: ['13'],
        d: 'Publish what worked retrospective information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '29',
        n: "What Didn't Work list",
        t: 'List',
        e: true,
        auto: ['14'],
        d: "Publish what didn't work retrospective details",
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '30',
        n: 'Improvement Opportunity list',
        t: 'List',
        e: true,
        auto: ['15'],
        d: 'Publish retrospective improvement opportunity information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '31',
        n: 'To Do list',
        t: 'List',
        e: true,
        auto: ['16'],
        d: 'Publish to do summary',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '32',
        n: 'Mission list',
        t: 'List',
        e: true,
        auto: ['17'],
        d: 'Publish mission information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '71',
        n: 'Vision Statement list',
        t: 'List',
        e: true,
        auto: ['18'],
        d: 'Publish vision statement information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '33',
        n: 'Value list',
        t: 'List',
        e: true,
        auto: ['19'],
        d: 'Publish value information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '34',
        n: 'KPI (Number, Max) list',
        t: 'List',
        e: true,
        auto: ['20'],
        d: 'Publish KPI information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '20-A-1',
            r: '20-A-2',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '20-A-1',
                },
                {
                    t: 'A',
                    id: '20-A-2',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '35',
        n: 'KPI (Percentage, Max) list',
        t: 'List',
        e: true,
        auto: ['21'],
        d: 'Publish KPI information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '21-A-1',
            r: '21-A-2',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '21-A-1',
                },
                {
                    t: 'A',
                    id: '21-A-2',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '36',
        n: 'KPI (Currency, Max) list',
        t: 'List',
        e: true,
        auto: ['22'],
        d: 'Publish KPI information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '22-A-1',
            r: '22-A-2',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '22-A-1',
                },
                {
                    t: 'A',
                    id: '22-A-2',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '37',
        n: 'Breakthrough Objective list',
        t: 'List',
        e: true,
        auto: ['23'],
        d: 'Publish breakthrough objective information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '23-A-3',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '23-A-1',
                },
                {
                    t: 'A',
                    id: '23-A-2',
                },
                {
                    t: 'A',
                    id: '23-A-3',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '38',
        n: 'Annual Objective list',
        t: 'List',
        e: true,
        auto: ['24'],
        d: 'Publish annual objective information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '24-A-5',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '24-A-3',
                },
                {
                    t: 'A',
                    id: '24-A-4',
                },
                {
                    t: 'A',
                    id: '24-A-5',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '39',
        n: 'Objective list',
        t: 'List',
        e: true,
        auto: ['25'],
        d: 'Publish objective information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '25-A-5',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '25-A-3',
                },
                {
                    t: 'A',
                    id: '25-A-4',
                },
                {
                    t: 'A',
                    id: '25-A-5',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '72',
        n: 'Portfolio Objective list',
        t: 'List',
        e: true,
        auto: ['26'],
        d: 'Publish portfolio objective details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '26-A-5',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '26-A-3',
                },
                {
                    t: 'A',
                    id: '26-A-4',
                },
                {
                    t: 'A',
                    id: '26-A-5',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '40',
        n: 'Focus Area list',
        t: 'List',
        e: true,
        auto: ['27'],
        d: 'Publish focus area information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '27-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '41',
        n: 'Program list',
        t: 'List',
        e: true,
        auto: ['28'],
        d: 'Publish program information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '28-A-9',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '28-A-2',
                },
                {
                    t: 'A',
                    id: '28-A-3',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '42',
        n: 'Project list',
        t: 'List',
        e: true,
        auto: ['29'],
        d: 'Publish project information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '29-A-11',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '29-A-8',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '43',
        n: 'Task list',
        t: 'List',
        e: true,
        auto: ['30'],
        d: 'Publish tasks',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '30-A-3',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '44',
        n: 'Countermeasure list',
        t: 'List',
        e: true,
        auto: ['31'],
        d: 'Publish countermeasures',
        src: {
            cards: true,
            cardSets: true,
            workbooks: false,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '31-A-5',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '45',
        n: 'Division list',
        t: 'List',
        e: true,
        auto: ['32'],
        d: 'Publish division information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '32-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '46',
        n: 'Business Unit list',
        t: 'List',
        e: true,
        auto: ['33'],
        d: 'Publish business unit information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '33-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '47',
        n: 'Skill list',
        t: 'List',
        e: true,
        auto: ['34'],
        d: 'Publish skill summaries',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '34-A-1',
            r: '34-A-2',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '34-A-1',
                },
                {
                    t: 'A',
                    id: '34-A-2',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '48',
        n: 'Capability list',
        t: 'List',
        e: true,
        auto: ['35'],
        d: 'Publish capability details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '35-A-1',
            r: '35-A-2',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '35-A-1',
                },
                {
                    t: 'A',
                    id: '35-A-2',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '49',
        n: 'Guardrail list',
        t: 'List',
        e: true,
        auto: ['36'],
        d: 'Publish guardrail information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '36-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '36-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '50',
        n: 'Product Line list',
        t: 'List',
        e: true,
        auto: ['37'],
        d: 'Publish product line information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '37-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '37-A-1',
                },
                {
                    t: 'A',
                    id: '37-A-2',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '51',
        n: 'Brand list',
        t: 'List',
        e: true,
        auto: ['38'],
        d: 'Publish brand information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '38-A-7',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '52',
        n: 'KPI (Number, Min) list',
        t: 'List',
        e: true,
        auto: ['39'],
        d: 'Publish KPI information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '39-A-1',
            r: '39-A-2',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '39-A-1',
                },
                {
                    t: 'A',
                    id: '39-A-2',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '53',
        n: 'KPI (Percentage, Min) list',
        t: 'List',
        e: true,
        auto: ['40'],
        d: 'Publish KPI information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '40-A-1',
            r: '40-A-2',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '40-A-1',
                },
                {
                    t: 'A',
                    id: '40-A-2',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '54',
        n: 'KPI (Currency, Min) list',
        t: 'List',
        e: true,
        auto: ['41'],
        d: 'Publish KPI information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '41-A-1',
            r: '41-A-2',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '41-A-1',
                },
                {
                    t: 'A',
                    id: '41-A-2',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '55',
        n: "5 Why's list",
        t: 'List',
        e: true,
        auto: ['44'],
        d: "Publish 5 why's information",
        src: {
            cards: true,
            cardSets: true,
            workbooks: false,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '44-A-7',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '56',
        n: 'Team list',
        t: 'List',
        e: true,
        auto: ['42'],
        d: 'Publish team details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: false,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '42-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '57',
        n: 'Action list',
        t: 'List',
        e: true,
        auto: ['43'],
        d: 'Publish action information',
        src: {
            cards: false,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '43-A-2',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '43-A-2',
                },
                {
                    t: 'A',
                    id: '43-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '73',
        n: 'Experiment list',
        t: 'List',
        e: true,
        auto: ['45'],
        d: 'Publish experiment information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '45-A-5',
                },
                {
                    t: 'A',
                    id: '45-A-6',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '74',
        n: 'Option list',
        t: 'List',
        e: true,
        auto: ['46'],
        d: 'Publish option details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '46-A-2',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '46-A-2',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '75',
        n: 'Cost list',
        t: 'List',
        e: true,
        auto: ['47'],
        d: 'Publish cost information',
        src: {
            cards: false,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '47-A-1',
            r: '47-A-2',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '47-A-1',
                },
                {
                    t: 'A',
                    id: '47-A-2',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '76',
        n: 'Portfolio list',
        t: 'List',
        e: true,
        auto: ['48'],
        d: 'Publish portfolio information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '48-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '48-A-1',
                },
                {
                    t: 'A',
                    id: '48-A-3',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '77',
        n: 'Pillar list',
        t: 'List',
        e: true,
        auto: ['49'],
        d: 'Publish pillar overviews',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '49-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '49-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '78',
        n: 'Review list',
        t: 'List',
        e: true,
        auto: ['50'],
        d: 'Publish review information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: false,
        },
        lp: {
            l: '50-A-5',
            r: '50-A-6',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '50-A-5',
                },
                {
                    t: 'A',
                    id: '50-A-6',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '79',
        n: 'Note list',
        t: 'List',
        e: true,
        auto: ['51'],
        d: 'Publish notes',
        src: {
            cards: false,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '80',
        n: 'Department list',
        t: 'List',
        e: true,
        auto: ['52'],
        d: 'Publish department details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: false,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '52-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '81',
        n: 'Portfolio Company list',
        t: 'List',
        e: true,
        auto: ['53'],
        d: 'Publish portfolio company information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '53-A-4',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '53-A-4',
                },
                {
                    t: 'A',
                    id: '53-A-3',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '82',
        n: 'Signal list',
        t: 'List',
        e: true,
        auto: ['54'],
        d: 'Publish signal details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '54-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '54-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '83',
        n: 'Industry list',
        t: 'List',
        e: true,
        auto: ['55'],
        d: 'Publish industry information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '55-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '55-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '84',
        n: 'Strategic Option list',
        t: 'List',
        e: true,
        auto: ['56'],
        d: 'Publish strategic option details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '56-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '56-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '85',
        n: 'Strategic Challenge list',
        t: 'List',
        e: true,
        auto: ['57'],
        d: 'Publish strategic challenge information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '57-A-1',
            r: '57-A-2',
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: '57-A-1',
                },
                {
                    t: 'A',
                    id: '57-A-2',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '86',
        n: 'Perspective list',
        t: 'List',
        e: true,
        auto: ['58'],
        d: 'Publish perspective details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '58-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '58-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '87',
        n: 'Strategic Theme list',
        t: 'List',
        e: true,
        auto: ['59'],
        d: 'Publish strategic theme overviews',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '59-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '59-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '88',
        n: 'Value Stream list',
        t: 'List',
        e: true,
        auto: ['60'],
        d: 'Publish value stream details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '89',
        n: 'System list',
        t: 'List',
        e: true,
        auto: ['61'],
        d: 'Publish system information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '61-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '90',
        n: 'Process list',
        t: 'List',
        e: true,
        auto: ['62'],
        d: 'Publish process details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '62-A-3',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '91',
        n: 'Step list',
        t: 'List',
        e: true,
        auto: ['63'],
        d: 'Publish step details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '63-A-3',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '92',
        n: 'Customer list',
        t: 'List',
        e: true,
        auto: ['64'],
        d: 'Publish customer information',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '64-A-1',
                },
                {
                    t: 'A',
                    id: '64-A-2',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '93',
        n: 'Milestone list',
        t: 'List',
        e: true,
        auto: ['65'],
        d: 'Publish project milestone information',
        src: {
            cards: false,
            cardSets: true,
            workbooks: false,
        },
        lp: {
            l: null,
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '65-A-1',
                },
                {
                    t: 'A',
                    id: '65-A-2',
                },
                {
                    t: 'A',
                    id: '65-A-3',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '58',
        n: 'Shared purpose',
        t: 'Chart',
        e: false,
        auto: ['17', '18', '19'],
        d: 'Set your strategic direction',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '59',
        n: 'Guardrails',
        t: 'Chart',
        e: false,
        auto: ['36'],
        d: 'Establish strategic principles',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '60',
        n: 'Skill development',
        t: 'Chart',
        e: true,
        auto: ['34'],
        d: 'Identify and prioritize skill development investments',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '61',
        n: 'Capability development',
        t: 'Chart',
        e: true,
        auto: ['35'],
        d: 'Identify and prioritize capability development investments',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '62',
        n: 'Customer analysis',
        t: 'Chart',
        e: true,
        auto: ['64'],
        d: 'Analyze health of existing customer base',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '63',
        n: 'Competitive landscape',
        t: 'Chart',
        e: true,
        auto: ['3'],
        d: 'Prioritize competitive threats',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '64',
        n: "Porter's Five Forces",
        t: 'Chart',
        e: true,
        auto: ['12'],
        d: 'Understand industry competitve forces',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '65',
        n: 'PESTLE analysis',
        t: 'Chart',
        e: true,
        auto: ['11'],
        d: 'Identify external factors that influence organizations',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '66',
        n: 'Market analysis',
        t: 'Chart',
        e: true,
        auto: ['8'],
        d: 'Prioritize target markets',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '67',
        n: 'Strategic options',
        t: 'Chart',
        e: true,
        auto: ['56'],
        d: 'Compare strategic options',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '68',
        n: 'Strategic challenges',
        t: 'Chart',
        e: true,
        auto: ['57'],
        d: 'Identify and prioritize strategic challenges',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '69',
        n: 'Retrospective',
        t: 'Chart',
        e: true,
        auto: ['16', '13', '15', '14'],
        d: 'Hold a retrospective on the last delivery cycle',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '70',
        n: 'Assumption analysis',
        t: 'Chart',
        e: true,
        auto: ['9'],
        d: 'Identify, track and prioritize planning assumptions',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: null,
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
    {
        i: '94',
        n: 'Observation list',
        t: 'List',
        e: true,
        auto: ['67'],
        d: 'Publish observation details',
        src: {
            cards: true,
            cardSets: true,
            workbooks: true,
        },
        lp: {
            l: '67-A-1',
            r: null,
            tbl: [
                {
                    t: 'A',
                    id: 'Name',
                },
                {
                    t: 'A',
                    id: 'Owner',
                },
                {
                    t: 'A',
                    id: 'Brief description',
                },
                {
                    t: 'A',
                    id: '67-A-1',
                },
            ],
        },
        h: {
            s1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            s2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        },
    },
];
