import { Box } from '@mui/material';
import { ReactNode, useContext } from 'react';
import { AppContext } from '../../contexts';
import MuiSelect, {
    SelectProps as MuiSelectProps,
    SelectChangeEvent,
} from '@mui/material/Select';
import { UserFieldProps } from './inputFieldTypes';
import SelectField from './SelectField';
import { AttributeType } from '../../../API';

interface UserProps {
    id?: string;
    value?: string;
    onChange?: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
}

const UserField = (props: UserFieldProps & UserProps) => {
    const { value, onChange } = props;
    const { users } = useContext(AppContext);

    return (
        <SelectField
            options={users.map((item) => {
                return {
                    value: item.id,
                    label: `${item.firstName} ${item.lastName}`,
                };
            })}
            {...props}
            defaultValue={value}
            value={value}
            type={AttributeType.SingleSelect}
        />
    );
};

export default UserField;
