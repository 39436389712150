import { Select, styled, TextField } from '@mui/material';

export const StyledSelect = styled(Select)(({ theme }) => ({
    width: '100%',
    //marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    '.MuiSelect-select': {
        display: 'flex',
        flexDirection: 'row',
    },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    width: '100%',
}));
