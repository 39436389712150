import { useState, useEffect } from 'react';

export interface IconStates {
    [key: string]: boolean;
}

const defaultIconStates: IconStates = {
    Fingerprint: false,
    Pin: false,
    Filter: false,
    Sort: false,
};

const useIconStates = (
    localStorageKey: string
): [IconStates, React.Dispatch<React.SetStateAction<IconStates>>] => {
    const [activeIcons, setActiveIcons] = useState<IconStates>(() => {
        const storedValue = window.localStorage.getItem(localStorageKey);
        return storedValue !== null
            ? JSON.parse(storedValue)
            : defaultIconStates;
    });

    useEffect(() => {
        window.localStorage.setItem(
            localStorageKey,
            JSON.stringify(activeIcons)
        );
    }, [localStorageKey, activeIcons]);

    return [activeIcons, setActiveIcons];
};

export default useIconStates;
