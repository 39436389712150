import { styled, Card } from '@mui/material';

export const StyledSection = styled('section')(({ theme }) => ({
    marginBottom: '2em',
}));

export const StyledMuiCard = styled(Card)<{ bg: string }>(({ bg }) => {
    return {
        borderRadius: '16px',
        backgroundColor: bg,
        color: '#fff',
        boxShadow: '0px 0px 13px rgba(0,0,0,0.40)',
    };
});
