import {
    Box,
    Checkbox,
    FormControlLabel,
    Typography,
    useTheme,
} from '@mui/material';
import { AppContext } from '../../../contexts';
import { useContext, useEffect, useState } from 'react';
import { getCategoryHex } from '../../../../helpers/category';
import { FilterCards } from '../../../../hooks/useFilterCards';
import { getCardSetWithId } from '../../../../helpers/card';

interface TypeListProps {
    selectedCategoryId: string;
    localFilters: FilterCards;
    setLocalFilters: React.Dispatch<React.SetStateAction<FilterCards>>;
}

const TypeList = ({
    selectedCategoryId,
    localFilters,
    setLocalFilters,
}: TypeListProps) => {
    const theme = useTheme();
    const { cardTypes } = useContext(AppContext);
    const [cardTypesFromSet, setCardTypesFromSet] = useState<string[]>([]);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const typeId = event.target.name;
        setLocalFilters((prevFilters) => {
            const currentTypes = prevFilters.cardType || [];
            const isTypeSelected = currentTypes.includes(typeId);

            return {
                ...prevFilters,
                cardType: isTypeSelected
                    ? currentTypes.filter((id) => id !== typeId)
                    : [...currentTypes, typeId],
            };
        });
    };
    useEffect(() => {
        const fetchData = async () => {
            const relatedCardPromises = (localFilters?.cardSet || []).map(
                async (item) => {
                    try {
                        return await getCardSetWithId(item);
                    } catch (error) {
                        console.error('Error fetching related card:', error);
                        return null;
                    }
                }
            );

            const fetchedRelatedCardSets = await Promise.all(
                relatedCardPromises
            );
            const types = fetchedRelatedCardSets.map((cardSet: any) => {
                return cardSet?.cardSetToCardTypeId;
            });
            setCardTypesFromSet(types);
        };
        fetchData();
    }, []);
    useEffect(() => {
        if (localFilters?.cardSet?.length === 0) {
            setCardTypesFromSet([]);
        }
    }, [localFilters]);

    return (
        <Box>
            {' '}
            <Typography variant="body1" sx={{ ml: 2, fontWeight: 'bold' }}>
                Card types
            </Typography>
            {cardTypes
                .filter((item) => item.cardCategoryID === selectedCategoryId)
                .map((type) => {
                    return (
                        <FormControlLabel
                            key={type.id}
                            control={
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Checkbox
                                        checked={
                                            ((localFilters.cardType?.length ??
                                                0) > 0 &&
                                                localFilters.cardType?.includes(
                                                    type.id
                                                )) ||
                                            cardTypesFromSet.includes(type.id)
                                        }
                                        onChange={handleChange}
                                        name={type.id}
                                        color="primary"
                                        disabled={
                                            !cardTypesFromSet.includes(
                                                type.id
                                            ) &&
                                            localFilters?.cardSet?.length !== 0
                                        }
                                    />

                                    <Box
                                        sx={{
                                            my: 1,
                                            width: '18px',
                                            height: '24px',
                                            padding: 1,
                                            borderRadius: 1,
                                            background:
                                                cardTypesFromSet.includes(
                                                    type.id
                                                ) ||
                                                localFilters?.cardSet
                                                    ?.length === 0
                                                    ? getCategoryHex(
                                                          selectedCategoryId
                                                      )
                                                    : 'grey',
                                            marginRight: 1,
                                        }}
                                    ></Box>
                                </div>
                            }
                            sx={{
                                height: '35px',
                                width: '100%',
                                paddingLeft: 1.8,
                                margin: 0,
                                color:
                                    !cardTypesFromSet.includes(type.id) &&
                                    localFilters?.cardSet?.length !== 0
                                        ? 'grey'
                                        : 'inherit',
                                '&:hover': {
                                    backgroundColor:
                                        theme.palette.background.paper,
                                },
                            }}
                            label={type.name}
                        />
                    );
                })}
        </Box>
    );
};

export default TypeList;
