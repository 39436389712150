import { useState, useEffect, RefObject } from 'react';

const useViewportHeight = (elementRef: RefObject<HTMLElement>) => {
  const [viewportHeight, setViewportHeight] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      const viewportHeight = window.innerHeight;
      const { top } = elementRef.current ? elementRef.current.getBoundingClientRect() : {top: 200};
      const heightDifference = viewportHeight - top;
      
      setViewportHeight(heightDifference);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [elementRef]);

  return viewportHeight;
};

export default useViewportHeight;
