import React, { PropsWithChildren } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/material';

interface SortableItemProps {
    id: string;
}

export const SortableItem = (props: PropsWithChildren<SortableItemProps>) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <Grid2 key={props.id} xs={12} sm={12} md={6} lg={4} xl={3}>
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
                {props.children}
            </div>
        </Grid2>
    );
};

export const SortableListItem = (
    props: PropsWithChildren<SortableItemProps>
) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <Box key={props.id}>
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
                {props.children}
            </div>
        </Box>
    );
};
